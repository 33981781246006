import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./transactionInput";

const useStyles = makeStyles(() =>
  createStyles({
    TransactionInput: {
      backgroundColor: "transparent",
      // color: (style: Theme & Props) => style.fadeText,
      color: (style: Theme & Props) => style.text,
      fontFamily: "var(--font3)",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "38px",
      lineHeight: "34px",
      letterSpacing: ".87px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "end",
      // textOverflow: "ellipsis",
      width: "100%",
      position: "relative",
    },
    mobTransactionInput:{
      backgroundColor: "transparent",
      color: (style: Theme & Props) => style.text,
      fontFamily: "var(--font1)",
      fontWeight: 0,
      fontSize: "20px",
      lineHeight: "34px",
      letterSpacing: ".87px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textAlign: "end",
      // textOverflow: "ellipsis",
      width: "100%",
      position: "relative",
    },
    spinner: {
      width: "3rem",
      animation: "1s rotate infinite",
      position: "relative",
      // top: "-1rem"
    },
  })
);

export default useStyles;
