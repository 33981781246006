import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
// import { BelowDesktop, Desktop } from "../screenHelper";
import useStyles from "./buyButtonStyles";

export interface Props {
  handleClick: () => void;
  disabled: boolean;
}

function MaxButton(props: Props) {
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });

  return (
    <button
      disabled={props.disabled}
      onClick={props.handleClick}
      className={classes.BuyButton}
    >
      buy
    </button>
  );
}

export default React.memo(MaxButton);
