import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./actionButtonsStyles";
import FillButton from "../../components/button/button";
import { useSelector } from "react-redux";
import { RootState } from "../../store/mystore";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { brc_Token, BurnTXType, MintTXType, TX_STATUS, TOKEN_ACTION, TX_STEP, StakeTXType, WithdrawTXType, WithdrawTime } from "../../constants";

export interface Props {
  setShowConfirmationPopup: React.Dispatch<React.SetStateAction<boolean>>;
  walletConnected: boolean;
  approveAction?: any;
  transactionType?: TOKEN_ACTION;
  sellToken?: string;
  swapAction?: any;
  claimAction?: any;
  step: TX_STEP;
  disabled: boolean;
  disableMessage: string;

  // withdraw actions are unique because it requires no step
  // emergencyWithdrawAction?: any;
  // withdraw type is required for distinguishing EMERGENCY or MATURE withdrawal
  // withdrawTime?: WithdrawTime;
}

function ActionButtons(props: Props) {
  const state = useSelector((state: RootState) => state);
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });
  const {
    approveAction,
    walletConnected,
    transactionType,
    // withdrawTime,
    swapAction,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    claimAction,
    step,
    disabled,
    disableMessage,
    setShowConfirmationPopup
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const activeStep = transactionType === TOKEN_ACTION.WITHDRAW ? TX_STEP.STAKE_OR_WITHDRAW : step;

  return (
    <div className={classes.ActionButtons}>
      <div className={`${classes.button}`}>
        {disabled ? (
          <FillButton
            clickFunction={() => {}}
            label={disableMessage}
            disabled={true}
            pending={false}
            success={false}
            fail={false}
          />
        ) : (
          <>
            {(step === TX_STEP.NOT_INITIALIZED || step === TX_STEP.APPROVAL) && (
              <FillButton
                clickFunction={approveAction}
                label={"Approve"}
                disabled={
                  disabled ||
                  !walletConnected ||
                  (state.mint.tx.type === MintTXType.APPROVAL && state.mint.tx.status === TX_STATUS.PENDING) ||
                  (state.burn.tx.type === BurnTXType.APPROVAL && state.burn.tx.status === TX_STATUS.PENDING) ||
                  (state.stake.tx.type === StakeTXType.APPROVAL && state.stake.tx.status === TX_STATUS.PENDING)
                }
                pending={
                  (state.mint.tx.type === MintTXType.APPROVAL && state.mint.tx.status === TX_STATUS.PENDING) ||
                  (state.burn.tx.type === BurnTXType.APPROVAL && state.burn.tx.status === TX_STATUS.PENDING) ||
                  (state.stake.tx.type === StakeTXType.APPROVAL && state.stake.tx.status === TX_STATUS.PENDING)
                }
                success={
                  (state.mint.tx.type === MintTXType.APPROVAL && state.mint.tx.status === TX_STATUS.SUCCESS) ||
                  (state.burn.tx.type === BurnTXType.APPROVAL && state.burn.tx.status === TX_STATUS.SUCCESS)
                }
                fail={
                  (state.mint.tx.type === MintTXType.APPROVAL && state.mint.tx.status === TX_STATUS.FAILED) ||
                  (state.burn.tx.type === BurnTXType.APPROVAL && state.burn.tx.status === TX_STATUS.FAILED) ||
                  (state.mint.tx.type === MintTXType.APPROVAL && state.mint.tx.status === TX_STATUS.REJECTED) ||
                  (state.burn.tx.type === BurnTXType.APPROVAL && state.burn.tx.status === TX_STATUS.REJECTED)
                }
              />
            )}

            {step === TX_STEP.SWAP  && (
              <FillButton
                clickFunction={swapAction}
                label={"Swap"}
                disabled={disabled || !walletConnected || (state.swap.tx.status === TX_STATUS.PENDING)}
                pending={state.swap.tx.status === TX_STATUS.PENDING}
                success={state.swap.tx.status === TX_STATUS.SUCCESS}
                fail={state.swap.tx.status === TX_STATUS.FAILED || state.swap.tx.status === TX_STATUS.REJECTED}
              />
            )}
            {(step === TX_STEP.MINT_OR_BURN || step === TX_STEP.STAKE_OR_WITHDRAW) && (
              <FillButton
                clickFunction={() => setShowConfirmationPopup(true)}
                label={transactionType.charAt(0).toUpperCase() + transactionType.slice(1).toLowerCase()}
                // label={withdrawTime === WithdrawTime.EMERGENCY ?
                //   withdrawTime.charAt(0).toUpperCase() + withdrawTime.slice(1).toLowerCase():
                //   transactionType.charAt(0).toUpperCase() + transactionType.slice(1).toLowerCase()}
                // size={transactionType === TX_TYPE.WITHDRAW ? "sm": "md"}
                // variant={withdrawTime === WithdrawTime.EMERGENCY ? "outline" : "fill"}
                disabled={
                  disabled || !walletConnected || (state.mint.tx.status === TX_STATUS.PENDING) ||
                  (state.burn.tx.status === TX_STATUS.PENDING) || (state.stake.tx.status === TX_STATUS.PENDING)  ||
                  (state.withdraw.tx.status === TX_STATUS.PENDING)
                }
                pending={
                  (state.mint.tx.status === TX_STATUS.PENDING) || 
                  (state.burn.tx.status === TX_STATUS.PENDING) ||
                  (state.stake.tx.status === TX_STATUS.PENDING) ||
                  (state.withdraw.tx.status === TX_STATUS.PENDING)
                }
                success={(state.mint.tx.status === TX_STATUS.SUCCESS) ||
                  (state.burn.tx.status === TX_STATUS.SUCCESS) ||
                  (state.stake.tx.status === TX_STATUS.SUCCESS) ||
                  (state.withdraw.tx.status === TX_STATUS.SUCCESS)
                }
                fail={
                  (state.mint.tx.status === TX_STATUS.FAILED) || (state.burn.tx.status === TX_STATUS.FAILED) || (state.stake.tx.status === TX_STATUS.FAILED) || (state.withdraw.tx.status === TX_STATUS.FAILED) ||
                  (state.mint.tx.status === TX_STATUS.REJECTED) || (state.burn.tx.status === TX_STATUS.REJECTED) || (state.stake.tx.status === TX_STATUS.REJECTED) || (state.withdraw.tx.status === TX_STATUS.REJECTED)
                }
              />
            )}

            {step === TX_STEP.CLAIM  && (
              <FillButton
              clickFunction={() => setShowConfirmationPopup(true)}
                label={"Claim"}
                disabled={disabled || !walletConnected || (state.claim.tx.status === TX_STATUS.PENDING)}
                pending={state.claim.tx.status === TX_STATUS.PENDING}
                success={state.claim.tx.status === TX_STATUS.SUCCESS}
                fail={state.claim.tx.status === TX_STATUS.FAILED || state.claim.tx.status === TX_STATUS.REJECTED}
              />
            )}
            
            {step === TX_STEP.NFT_WHITELIST  && (
              <FillButton
                clickFunction={() => setShowConfirmationPopup(true)}
                label={"Purchase Whitelist Spot"}
                disabled={disabled || !walletConnected}
                // pending={state.claim.tx.status === TX_STATUS.PENDING}
                // success={state.claim.tx.status === TX_STATUS.SUCCESS}
                // fail={state.claim.tx.status === TX_STATUS.FAILED || state.claim.tx.status === TX_STATUS.REJECTED}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ActionButtons;
