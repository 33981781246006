import BRC from "../../media/tokens/BRQ.svg";
import gBRC from "../../media/tokens/gbrc.svg";

declare let window: any;

export async function addNetworkToWallet() {
  if (window.ethereum) {
    const host = window.location.origin;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const brcImage = `${host}${BRC}`;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const gBrcImage = `${host}${gBRC}`;

    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xA4B1", //hex
            chainName: "Arbitrum One",
            nativeCurrency: {
              name: "AEthereum",
              symbol: "AETH",
              decimals: 18, //number
            },
            rpcUrls: ["https://arb1.arbitrum.io/rpc"],
            blockExplorerUrls: ["https://arbiscan.io"],
          },
        ],
      });
    } catch (error) {
      console.log(error);
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xA4B1", //hex
            chainName: "Arbitrum One",
            nativeCurrency: {
              name: "AEthereum",
              symbol: "ETH",
              decimals: 18, //number
            },
            rpcUrls: ["https://arb1.arbitrum.io/rpc"],
            blockExplorerUrls: ["https://arbiscan.io"],
          },
        ],
      });
    }
  }
}
