import { bignumber, create, all } from "mathjs";
import {
  BIGNUMBER_CONFIG,
  brc_Token,
  gbrc_Token,
  HTTP_METHOD,
  URL,
} from "../../constants";
import APICall from "../../utils/apiCall";
import { RootState } from "../mystore";

const math = create(all, BIGNUMBER_CONFIG);

export function GetUSDTokenEquivalence(
  rootState: RootState,
  amount: math.BigNumber,
  tokenSymbol: string
): string {
  try {
    let oneTokenBrcPrice;

    if (tokenSymbol === gbrc_Token.symbol) {
      return (
        math
          // .multiply(
          //   bignumber(
          //     Number(rootState.swap.prices.daiPrice) /
          //       Number(rootState.swap.prices.gbrcPrice)
          //   ),
          //   amount
          // )
          .multiply(bignumber(Number(rootState.swap.prices.gbrcPrice)), amount)
          .toString()
      );
    }
    if (
      (tokenSymbol !== brc_Token.symbol &&
        !rootState.swap.prices[`${tokenSymbol.toLowerCase()}Price`]) ||
      (tokenSymbol === brc_Token.symbol && !rootState.swap.prices["daiPrice"])
    ) {
      return "0";
    }
    if (tokenSymbol !== brc_Token.symbol) {
      oneTokenBrcPrice = math.evaluate(
        "1 / " +
          rootState.swap.prices[`${tokenSymbol.toLowerCase()}Price`].toString()
      );
    } else {
      oneTokenBrcPrice = bignumber(1); // for BRC
    }
    const tokenBrcAmount = math.evaluate(`${amount} * ${oneTokenBrcPrice}`);
    const tokenDaiAmount = math.evaluate(
      `${bignumber(rootState.swap.prices["daiPrice"])} * ${tokenBrcAmount}`
    );
    return tokenDaiAmount.toString();
  } catch (err) {
    console.log("failed to get USD equivalence", err);
  }
}

export function GetMarketValue(amount, currentPrice) {
  return Number(amount) * Number(currentPrice);
}

export function GetChangeRate(_old, _new) {
  return ((Number(_new) - Number(_old)) / Number(_new)) * 100;
}

export function GetProfitLossRate(
  totalAmount: number,
  currentPrice: number,
  averagePricePaid: number
) {
  // (Total BRC tokens X Current BRC price / Avg. Price paid X Total BRC tokens)
  return (
    ((totalAmount * currentPrice) / (averagePricePaid * totalAmount) - 1) * 100
  );
}

export function fetchGasPrice() {
  return APICall(URL.ETH_GAS_STATION, HTTP_METHOD.GET)
    .then((response) => {
      if (response) {
        return {
          lowCurrentGas: (response.fast / 10).toString(),
          highCurrentGas: (response.fastest / 10).toString(),
        }; //convert to gwei
      }
    })
    .catch((err) => console.log(err));
}
