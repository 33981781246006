import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./walletConnectModalStyles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import WalletOptionButton from "../walletOptionButton/walletOptionButton";
import FillButton from "../button/button";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as Exit } from "../../media/icons/ico-close.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CardMain from "../../components/card/cardMain";
import "./walletConnectModal.scss";
import { BelowDesktop } from "../screenHelper";
export interface Props {
  setShowWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleConnect: () => void;
  canConnect: boolean;
  activatingConnector: any;
  wallets: {
    name: string;
    connectFunction: () => void;
    selected: boolean;
    activating: boolean;
    active: boolean;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    mobileOnly: boolean;
  }[];
}

function WalletConnectModal(props: Props) {
  const { theme } = useContext(ThemeContext);
  // const [activatingConnector, setActivatingConnector] = useState<any>();

  const classes = useStyles({ ...props, ...theme });
  const { setShowWalletModal, wallets, handleConnect, canConnect } = props;

  const closeModal = () => {
    setShowWalletModal(false);
  };

  const buttons = wallets.map((wallet) => {
    return (
      <>
        {!wallet.mobileOnly && 
          <WalletOptionButton
            key={wallet.name}
            clickFunction={wallet.connectFunction}
            setShowWalletModal={setShowWalletModal}
            selected={wallet.selected}
            activating={wallet.activating}
            active={wallet.active}
            label={wallet.name}
            Icon={wallet.icon}
            numberOfWallets={wallets.length}
          />
        }
        <BelowDesktop>
          {wallet.mobileOnly && 
            <WalletOptionButton
              key={wallet.name}
              clickFunction={wallet.connectFunction}
              setShowWalletModal={setShowWalletModal}
              selected={wallet.selected}
              activating={wallet.activating}
              active={wallet.active}
              label={wallet.name}
              Icon={wallet.icon}
              numberOfWallets={wallets.length}
            />
          }
        </BelowDesktop>
      </>
    );
  });

  return (
    <ClickAwayListener onClickAway={closeModal}>
      <div className={`${classes.WalletConnectModal} wallet-connect-modal`}>
        {/* <button type="button" className={`${classes.exit} pop__close`} onClick={closeModal}>
              <Exit onClick={closeModal} className={"close"} />
          </button> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            hyphens: "auto",
            wordBreak: "break-word",
          }}
        >
          <h2 className={classes.title}>Connect Wallet</h2>
          <button type="button" className="pop__close" onClick={closeModal}>
            <span className="close">
              <img
                src={require("../../media/icons/ico-close.svg").default}
                alt=""
              />
            </span>
          </button>
        </div>
        <div className={classes.buttonContainer}>{buttons}</div>
        <div className={classes.connectButtonContainer}>
          <FillButton
            icon={"WalletBlack"}
            clickFunction={handleConnect}
            label={"Connect Wallet"}
            disabled={!canConnect || props.activatingConnector}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default React.memo(WalletConnectModal);
