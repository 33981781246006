import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./vault";

const useStyles = makeStyles(() =>
  createStyles({
    Wrapper: {
      backgroundColor: (style: Props & Theme) => style.background1, // TODO: change page bg
      minHeight: "100vh",
      position: "relative",
      overflowX: "hidden",
      overflowY: "auto",
    },
    boxWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "30px",
      paddingBottom: "5rem"
    }
  })
);

export default useStyles;
