import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  // CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // Label
} from "recharts";
import './analyticsChart.scss'

const toText = (decimal = 0) => `$${(decimal).toFixed(3)}`;

interface Props {
  data: any;
  labelX?: string;
}

export default function AnalyticsChart(props: Props) {

  return (
    <>
    <ResponsiveContainer height={250}>
      <AreaChart
        width={1040}
        // minWidth={320}
        height={250}
        data={props.data}
        margin={{
          top: 10,
          right:0,
          left:0,
          bottom: 0
        }}
      >
        {/* <CartesianGrid strokeDasharray="1 1" /> */}
        <XAxis dataKey="label">
          {/* <Label value="Block number" offset={0} position="center" /> */}
        </XAxis>
        <YAxis orientation="right" tickFormatter={toText}>
        </YAxis>
        <Tooltip />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1fa2ff40" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#1fa2ff40" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#7b61ff40" stopOpacity={0.8}/>
            <stop offset="95%" stopColor="#7b61ff40" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="BRC"
          stackId="2"
          stroke="#7B61FF"
          fillOpacity={1} 
          fill="url(#colorPv)"
        />
        <Area
          type="monotone"
          dataKey="gBRC"
          stackId="2"
          stroke="#1FA2FF"
          fillOpacity={1} 
          fill="url(#colorUv)"
        />
      </AreaChart>
    </ResponsiveContainer>
    <span className="chartLabel">{props.labelX}</span>
    </>
  );
}