import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    Backdrop: {
      backgroundColor: "black",
      opacity: 0.7,
      width: "100vw",
      height: "100vh",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 50,      
    },
  })
);

export default useStyles;
