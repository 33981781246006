import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./footer";

const useStyles = makeStyles(() =>
  createStyles({
    footerWrapper: {
      position: "absolute",
      bottom: 0,
      padding: "0px 6rem",    
      width: "100%",      
      // display: "flex",
      // justifyContent: "center"
    },
    footer: {
      font: (style: Theme & Props) => style.typography.p5,      
      color: "var(--fade-white)",
      width: "98%",
      margin: "0px auto",
      borderTop: "1px solid var(--x-fade-white)",
      padding: "22px 0px"
      // display: "flex",
      // justifyContent: "center"
    },    
    navSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      color: "var(--fade-white) !important",
      // width: "70%",
      margin: "auto",
      minWidth: "550px",
      padding: "0px 100px"
    },
    socialMedia: {
      height: "100%",
      width: "20%",
      display: "flex",
      alignItems: "center",
      // justifyContent: "center",
      // marginLeft: "40px",
    },
    navItem:{
      font: (style: Theme & Props) => style.typography.p5,
    },
    icon: {
      width: "4rem",
      marginRight: "2rem",
      opacity: 0.8,
      "&:hover": {
        opacity: 1,
        filter: "brightness(1.5)",
      },
    },
  })
);

export default useStyles;
