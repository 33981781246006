import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./walletConnectModal";

const useStyles = makeStyles(() =>
  createStyles({
    WalletConnectModal: {
      // backgroundColor: (style: Props & Theme) => style.background1,
      background: (style: Props & Theme) => style.bgGradient2,
      transform: (style: Theme & Props) => style.Ytransform,
      transition: (style: Theme & Props) => style.transition,
      color: (style: Theme & Props) => style.text,
      position: "fixed",
      maxWidth: "55rem",
      width: "100%",
      height: "55rem",
      // minWidth: "250px",
      // minHeight: "400px",
      borderRadius: "24px",
      left: "calc(50% - 27.5rem)",
      top: "calc(50% - 0rem)",
      zIndex: 500,
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
      padding: "4rem 4rem 2rem 4rem",
    },
    backdrop: {
      backgroundColor: "black",
      opacity: 0.7,
      width: "100vw",
      height: "100vh",
      zIndex: 4,
      left: 0,
      top: 0,
    },
    title: {
      font: (style: Theme & Props) => style.typography.p2b,      
      margin: "0rem 0rem 2rem 0rem",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: (style: Theme & Props) =>
        style.wallets.length === 3 ? "55%" : "45%",
    },
    connectButtonContainer: {
      width: "100%",
      margin: "5.8rem auto 3rem auto",
      font: (style: Theme & Props) => style.typography.h1,
      // filter: (style: Theme & Props) =>
      //   style.activatingConnector ? "brightness(0.7)" : "brightness(1)",
    },
    exit: {
      position: "absolute",
      cursor: "pointer",
      top: "4rem",
      right: "4rem",
    },
  })
);

export default useStyles;
