import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./button";

const useStyles = makeStyles(() =>
  createStyles({
    FillButtonMobile: {
      transition: "all .25s ease-in-out",
      background: (style: Props & Theme) => {
        if (style.disabled) {
          if (style.variant === "outline" && style.color === "error") {
            // return "rgba(255,100,100,0.15)";
            return ""; //var(--fade-white)
          } else if (style.variant === "outline" && style.color !== "error") {
            return "#00ccff2d";
          } else if (
            ["approve", "swap", "stake", "buy", "sell"].includes(
              style.label.toLowerCase()
            )
          ) {
            return "var(--govBlue)";
          } else {
            return "var(--lightgrey)";
          }
        } else {
          if (
            style.variant === "outline" &&
            style.color === "error" &&
            !style.pending
          ) {
            // return "rgba(255,100,100,0.15)";
            return "";
          } else if (
            style.variant === "outline" &&
            style.color !== "error" &&
            !style.pending
          ) {
            return "#00ccff2d";
          } else if (
            style.variant === "fill" &&
            style.color !== "error" &&
            !style.pending
          ) {
            return "var(--govBlue)";
          } else {
            return "#161616";
          }
        }
      },
      // style.disabled? "var(--lightgrey)" :
      //     style.variant === "outline" ? "rgba(54, 158, 73, 0.15)": "linear-gradient(320.66deg, #48DC63 14.75%, #369E49 84.81%), linear-gradient(320.66deg, #01AA68 14.75%, #00D381 84.81%)",
      backgroundOrigin: "border-box",
      boxShadow: (style: Props & Theme) =>
        style.variant !== "outline" ? "" : "",
      backgroundClip: "content-box, border-box",
      width: (style: Props & Theme) => (style.size === "sm" ? "80px" : "100%"),
      maxWidth: "450px",
      height: (style: Props & Theme) => (style.size === "sm" ? "32px" : "56px"),
      borderRadius: (style: Props & Theme) =>
        style.size === "sm" ? "8px" : "12px",
      // minHeight: "45px",
      margin: "auto",
      color: (style: Props & Theme) => {
        if (style.variant === "outline" && style.color === "error") {
          // return "var(--error)";
          return "var(--fade-white)";
        } else if (style.variant === "outline" && style.color !== "error") {
          return "var(--blue)";
        } else if (
          style.variant === "fill" &&
          style.color !== "error" &&
          !style.pending
        ) {
          return "#161616";
        } else if (!style.disabled) {
          // return "var(--govBlue)";
          return "var(--coloured-fade-white)";
        } else if (style.disabled) {
          // return "var(--govBlue)";
          return "#202024";
        } else {
          // return "var(--white)"
        }
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "2px",
      cursor: (style: Theme & Props) =>
        style.disabled ? "default" : "pointer",
      border: (style: Props & Theme) => {
        if (style.variant === "outline" && style.color === "error") {
          // return "1px solid var(--error)";
          return "1px solid var(--fade-white)";
        }
        if (style.variant === "outline" && style.color !== "error") {
          return "1px solid var(--blue)";
        }
        if (!style.disabled) {
          return "1px solid var(--govBlue)";
        } else {
          return "";
        }
      },
      "&:active, &:hover, &:focus": {
        boxShadow: (style: Theme & Props) =>
          style.disabled ? null : "0 0 0 8px transparent",
        animation: (style: Theme & Props) =>
          style.disabled ? null : `var(--pulse-animation-blue)`,
        mozAnimation: (style: Theme & Props) =>
          style.disabled ? null : `var(--pulse-animation-blue)`,
        msAnimation: (style: Theme & Props) =>
          style.disabled ? null : `var(--pulse-animation-blue)`,
        // boxShadow: (style: Props & Theme) =>
        //   style.variant !== "outline" ? "18px 6px 32px rgba(2, 3, 3, 0.55), -6px -10px 28px rgba(232, 237, 243, 0.15)" : "",
        background: (style: Theme & Props) => {
          if (style.variant === "outline" && style.color === "error") {
            return "rgba(0,124,247,0.15)";
          }
        },
        border: (style: Props & Theme) => {
          if (style.variant === "outline" && style.color === "error") {
            // return "1px solid var(--error)";
            return "none";
          }
        },
        color: (style: Props & Theme) => {
          if (!style.disabled) {
            return "var(--white)"; //#202024
          }
        },
      },
      "&:active": {
        background: (style: Theme & Props) =>
          style.disabled || style.variant === "outline"
            ? null
            : "var(--govBlue)",
        boxShadow: (style: Theme & Props) =>
          style.disabled || style.variant === "outline" ? null : "",
      },
    },
    FillButton: {
      background: (style: Props & Theme) => {
        if (style.disabled) {
          if (style.variant === "outline" && style.color === "error") {
            // return "rgba(255,100,100,0.15)";
            return ""; //var(--fade-white)
          } else if (style.variant === "outline" && style.color !== "error") {
            return "#00ccff2d";
          } else {
            return "var(--lightgrey)";
          }
        } else {
          if (
            style.variant === "outline" &&
            style.color === "error" &&
            !style.pending
          ) {
            // return "rgba(255,100,100,0.15)";
            return "";
          } else if (
            style.variant === "outline" &&
            style.color !== "error" &&
            !style.pending
          ) {
            return "#00ccff2d";
          } else {
            // return "linear-gradient(320.66deg, #48DC63 14.75%, #369E49 84.81%), linear-gradient(320.66deg, #01AA68 14.75%, #00D381 84.81%)";
            return "linear-gradient(320.66deg,#00CAFF 14.75%,#4db6d1 84.81%),linear-gradient(320.66deg,#268199 14.75%,#0b83a3 84.81%)";
          }
        }
      },
      // style.disabled? "var(--lightgrey)" :
      //     style.variant === "outline" ? "rgba(54, 158, 73, 0.15)": "linear-gradient(320.66deg, #48DC63 14.75%, #369E49 84.81%), linear-gradient(320.66deg, #01AA68 14.75%, #00D381 84.81%)",
      backgroundOrigin: "border-box",
      boxShadow: (style: Props & Theme) =>
        style.variant !== "outline"
          ? "18px 6px 32px rgba(2, 3, 3, 0.55), -6px -10px 28px rgba(232, 237, 243, 0.15)"
          : "",
      backgroundClip: "content-box, border-box",
      width: (style: Props & Theme) => (style.size === "sm" ? "80px" : "100%"),
      maxWidth: "450px",
      height: (style: Props & Theme) => (style.size === "sm" ? "32px" : "56px"),
      borderRadius: (style: Props & Theme) =>
        style.size === "sm" ? "8px" : "12px",
      // minHeight: "45px",
      margin: "auto",
      color: (style: Props & Theme) => {
        if (style.variant === "outline" && style.color === "error") {
          // return "var(--error)";
          return "var(--fade-white)";
        } else if (style.variant === "outline" && style.color !== "error") {
          return "var(--blue)";
        } else {
          return "#202024";
        }
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "2px",
      cursor: (style: Theme & Props) =>
        style.disabled ? "default" : "pointer",
      border: (style: Props & Theme) => {
        if (style.variant === "outline" && style.color === "error") {
          // return "1px solid var(--error)";
          return "1px solid var(--fade-white)";
        }
        if (style.variant === "outline" && style.color !== "error") {
          return "1px solid var(--blue)";
        }
      },
      "&:active, &:hover, &:focus": {
        boxShadow: (style: Theme & Props) =>
          style.disabled ? null : "0 0 0 8px transparent",
        animation: (style: Theme & Props) =>
          style.disabled ? null : `var(--pulse-animation)`,
        mozAnimation: (style: Theme & Props) =>
          style.disabled ? null : `var(--pulse-animation)`,
        msAnimation: (style: Theme & Props) =>
          style.disabled ? null : `var(--pulse-animation)`,
        // boxShadow: (style: Props & Theme) =>
        //   style.variant !== "outline" ? "18px 6px 32px rgba(2, 3, 3, 0.55), -6px -10px 28px rgba(232, 237, 243, 0.15)" : "",
        background: (style: Theme & Props) => {
          if (style.variant === "outline" && style.color === "error") {
            return "rgba(0,124,247,0.15)";
          }
        },
        border: (style: Props & Theme) => {
          if (style.variant === "outline" && style.color === "error") {
            // return "1px solid var(--error)";
            return "none";
          }
        },
      },
      "&:active": {
        background: (style: Theme & Props) =>
          style.disabled || style.variant === "outline"
            ? null
            : "linear-gradient(320.66deg, #4db6d1 14.75%, #00CAFF 84.81%)",
        boxShadow: (style: Theme & Props) =>
          style.disabled || style.variant === "outline"
            ? null
            : "-4px -2px 16px rgba(195, 200, 205, 0.08), 4px 4px 18px rgba(0, 0, 0, 0.5)",
      },
    },
    text: {
      font: (style: Theme & Props) => style.typography.h1b,
      fontSize: (style: Props & Theme) =>
        style.size === "sm" ? "13px !important" : "15px !important",
      letterSpacing: "-0.41px",
    },
    preIcon: {
      color: "inherit",
      marginRight: "1rem !important",
      width: "4rem",
    },
    postIcon: {
      color: "inherit",
      fill: (style: Theme & Props) =>
        style.variant === "outline" ? style.colors.brincBlue : null,
      marginLeft: (style: Theme & Props) =>
        style.variant !== "outline" ? "2rem" : "0rem",
      width: "3rem",
      height: "3rem",
      position: "relative",
      top: (style: Theme & Props) =>
        style.variant === "outline" ? "-2px" : null,
    },
    rotate: {
      animation: `rotate 1s infinite`,
    },
  })
);

export default useStyles;
