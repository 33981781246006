import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./priceConversion";

const useStyles = makeStyles(() =>
  createStyles({
    priceConversionContainer: {
      backgroundColor: "transparent",
      transition: (style: Theme & Props) => style.transition,
      color: (style: Theme & Props) => style.text,
      display: "flex",
      justifyContent: "center",
      width: "100%",
      padding: "0rem",      
      // font: (style: Theme & Props) => style.typography.h6,
    },
  })
);

export default useStyles;
