import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Contract } from "ethers";
import { FAIL_REASON, TX_STATUS, TX_STEP } from "../../constants";
import Web3 from "web3";
import { getWeb3Contracts } from "../helpers/contractBooter";
import { useResetFlagsAndTXData } from "../helpers/transactionFlagsHelper";

export interface IAppContractsNullable {
    // daiContract: Contract | null;
    // tokenContract: Contract | null;
    // govTokenContract: Contract | null;
    // routerContract: Contract | null;
    // usdcContract: Contract | null;
    // wbtcContract: Contract | null;
    // usdtContract: Contract | null;
    // stakingContract: Contract | null;
}

export interface IAppContractsNonNullable {
    daiContract: Contract;
    ethContract: Contract;
    brcContract: Contract;
    gbrcContract: Contract;
    sgbrcContract: Contract;
    routerContract: Contract;
    usdcContract: Contract;
    wbtcContract: Contract;
    usdtContract: Contract;
    ustContract: Contract;
    stakingContract: Contract;
    airdropContract: Contract;
}

export interface ITransactionData {    
    txStep: TX_STEP;
    nextTXStep: TX_STEP;
    successData: any;
    failData: any;    
    reasonForFail: FAIL_REASON;
}

//TODO: SET THE TX DATA TYPES OF EACH CATEGORY (SUCCESS AND FAIL)
export interface IApprovalTransactionData extends ITransactionData {
    successData: any,
    failData: any
}

export interface ISwapTransactionData extends ITransactionData {
    successData: any,
    failData: any
}

export interface IBurnTransactionData extends ITransactionData {
    successData: any,
    failData: any
}

export interface IMintTransactionData extends ITransactionData {
    successData: any,
    failData: any
}

export interface IStakeTransactionData extends ITransactionData {
    successData: any,
    failData: any
}

export interface IWithdrawTransactionData extends ITransactionData {
    successData: any,
    failData: any
}

export interface IClaimTransactionData extends ITransactionData {
    successData: any,
    failData: any
}

export interface IAppState {
    contracts: IAppContractsNonNullable,
    currentTXstatus: TX_STATUS
}

type ISetContracts = {
    chainId: number,
    web3?: Web3
}

const initialContracts: IAppContractsNonNullable = {
    daiContract: {} as any,
    ethContract: {} as any,
    brcContract: {} as any,
    gbrcContract: {} as any,
    sgbrcContract: {} as any,
    routerContract: {} as any,
    usdcContract: {} as any,
    wbtcContract: {} as any,
    usdtContract: {} as any,
    ustContract: {} as any,
    stakingContract: {} as any,
    airdropContract: {} as any,
};

const initialState: IAppState = {
    contracts: initialContracts,
    currentTXstatus: TX_STATUS.NOT_INITIALIZED
}

const setContractsAsync = createAsyncThunk(
    "application/setContracts",
    async (data: ISetContracts, { rejectWithValue }) => {
        try{const response = getWeb3Contracts(data.chainId, data.web3);
        // const response = getEthersContracts();
        // console.log('getContractsAsync', response);
        
        // The value we return becomes the `fulfilled` action payload
        return response;
        }catch(error){
            console.log('setContractsAsync error', error);        
            return rejectWithValue(error.response.data);
        }
    }
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const UseResetFlags = () => {
    useResetFlagsAndTXData();
}

const appSliceReducer = createSlice({
    name: "application",
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // clearFlagsAndTXData: () => {
        //     UseResetFlags();
        // }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
        .addCase(setContractsAsync.pending, (state) => {})
        .addCase(setContractsAsync.rejected, (state: any, action: any) => {})
        .addCase(setContractsAsync.fulfilled, (state: any, { payload }: PayloadAction<any>) => {
            state.contracts = payload;
        });
    },
})

export { setContractsAsync };
// export const { clearFlagsAndTXData } = appSliceReducer.actions;
export default appSliceReducer.reducer;
export const appSelector = (state: { appState: IAppState }) => state.appState