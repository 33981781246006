import { HTTP_METHOD } from "../constants";

export default function APICall(url: string, method: HTTP_METHOD, isDbData = false, data = null) {
    var apiBaseURL = '';

    if(isDbData) {
        apiBaseURL = process.env.REACT_APP_DYNAMODB_ENDPOINT;
    }

    return fetch(apiBaseURL + url, {
        method: method, mode: "cors", cache: "no-cache", credentials: "same-origin",
        headers: isDbData ? {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*", //http://localhost:3000
            "Access-Control-Request-Method": "*", //"GET, PUT, OPTIONS"
        }
        :{"Content-Type": "application/json"},
        redirect: "follow",
        referrer: "no-referrer",
        body: ((method.toLowerCase() === "get") ? null : JSON.stringify(data))
    })
    .then(response => {
        if(response.status === 401){
            console.log('unauthorized access to: ', url);
        }
        else
        {
            return response.json();
        }
    })
    .catch(error => {
        console.log(error);            
    });
}