// weight, size, family
export const typography = {
  h1_bold: "800 4.4rem var(--font1)",
  h1_light: "400 3.4rem var(--font1)",
  h1b_light: "600 3.4rem var(--font2)",
  h1b_px_light: "700 22px var(--font2)",
  h1: "600 3.4rem var(--font1)",
  h2: "400 2.4rem var(--font1)",
  h1b: "600 3.4rem var(--font2)",
  h2b: "400 2.4rem var(--font2)",
  h3b: "400 2.4rem var(--font1)",
  h4b: "600 2.2rem var(--font2)",
  h3: "700 2rem var(--font1)",
  h4: "400 2rem var(--font1)",
  h5: "400 2.2rem var(--font1)",
  h6: "800 2rem var(--font1)",
  h7: "400 2rem var(--font1)",
  p1: "600 1.8rem var(--font1)",
  p2: "400 3.2rem var(--font1)",
  p2b: "400 3.8rem var(--font2)",
  p3: "400 2rem var(--font1)",
  p4: "400 2.4rem var(--font1)",
  p5: "400 1.8rem var(--font1)",
  p6: "400 1.6rem var(--font1)",
  p7: "400 1.6rem var(--font2)",
  input: "400 2.8rem var(--font1)",
  listItem: "400 1.6rem var(--font1)",
  highlight: "700 1.4rem var(--font1)",
  maxButton: "400 13px var(--font1)",
  navItem: "300 1.8rem var(--font2)",
  token: "700 3rem var(--font2)",
  led: "600 38px var(--font3)"
};

export interface Typography {
  h1_bold: string;
  h1_light: string;
  h1b_light: string;
  h1b_px_light: string;
  h1: string;
  h1b: string;
  h2: string;
  h2b: string;
  h3b: string;
  h4b: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  h7: string;
  p1: string;
  p2: string;
  p2b: string;
  p3: string;
  p4: string;
  p5: string;
  p6: string;
  p7: string;
  input: string;
  listItem: string;
  highlight: string;
  maxButton: string;
  navItem: string;
  token: string;
  led: string;
}