import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  FAIL_REASON,
  StakeMode,
  StakeTXType,
  TX_STATUS,
  TX_STEP,
} from "../../constants";
import { BasicContractCallPayload } from "../analytics/Analytics";
import {
  approveBRCStake,
  approveGBRCStake,
  ApproveStakePayload,
  IStakeState,
  stake,
  StakingPayload,
  GetStakesPayload,
  getAllStakes,
  getWeights,
  Weights,
} from "./Stake";

const initialState: IStakeState = {
  tx: {
    type: StakeTXType.NOT_SET,
    mode: StakeMode.NOT_SET,
    status: TX_STATUS.NOT_INITIALIZED,
    data: {
      txStep: TX_STEP.NOT_INITIALIZED,
      nextTXStep: TX_STEP.NOT_INITIALIZED,
      failData: {},
      successData: {},
      reasonForFail: FAIL_REASON.NOT_SET,
    },
  },
  allStakes: [],
  poolWeights: {} as Weights,
};

const stakeAsync = createAsyncThunk(
  "stake/token",
  async (data: StakingPayload, { rejectWithValue }) => {
    try {
      const response = await stake(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getAllStakesAsync = createAsyncThunk(
  "stake/getAll",
  async (data: GetStakesPayload, { rejectWithValue }) => {
    try {
      const response = await getAllStakes(
        data.stakingContract,
        data.userAddress
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const getWeightsAsync = createAsyncThunk(
  "stake/getWeights",
  async (data: BasicContractCallPayload, { rejectWithValue }) => {
    try {
      const response = await getWeights(data.contracts.stakingContract);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const approveBRCStakeAsync = createAsyncThunk(
  "stake/approve/brc",
  async (data: ApproveStakePayload, { rejectWithValue }) => {
    try {
      const response = await approveBRCStake(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const approveGBRCStakeAsync = createAsyncThunk(
  "stake/approve/gbrc",
  async (data: ApproveStakePayload, { rejectWithValue }) => {
    try {
      const response = await approveGBRCStake(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const stakeSliceReducer = createSlice({
  name: "stake",
  initialState,
  reducers: {
    resetStakeTXState: (state) => {
      state.tx = {
        type: StakeTXType.NOT_SET,
        mode: StakeMode.NOT_SET,
        status: TX_STATUS.NOT_INITIALIZED,
        data: {
          txStep: TX_STEP.NOT_INITIALIZED,
          nextTXStep: TX_STEP.NOT_INITIALIZED,
          failData: {},
          successData: {},
          reasonForFail: FAIL_REASON.NOT_SET,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(stakeAsync.pending, (state: IStakeState, action) => {
        const data = action.meta.arg;
        state.tx.status = TX_STATUS.PENDING;
        state.tx.data.txStep = TX_STEP.STAKE_OR_WITHDRAW;
        state.tx.mode = data.stakeMode;
        state.tx.data.failData = {};
        state.tx.data.successData = {};
      })
      .addCase(stakeAsync.rejected, (state: IStakeState, action) => {
        const data = action.meta.arg;
        state.tx.status = TX_STATUS.REJECTED;
        state.tx.mode = data.stakeMode;
        state.tx.data.failData = action.payload;
        state.tx.data.successData = {};
        state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
        // update TX step for next
        state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
      })
      .addCase(stakeAsync.fulfilled, (state: IStakeState, action) => {
        const data = action.meta.arg;
        if (typeof action.payload === undefined) {
          state.tx.status = TX_STATUS.FAILED;
          state.tx.mode = data.stakeMode;
          state.tx.data.failData = {};
          state.tx.data.successData = {};
          state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
        } else {
          state.tx.status = TX_STATUS.SUCCESS;
          state.tx.mode = data.stakeMode;
          state.tx.data.successData = action.payload;
          state.tx.data.failData = {};
        }
        // update TX step for next
        state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
      })

      .addCase(approveBRCStakeAsync.pending, (state: IStakeState) => {
        state.tx.status = TX_STATUS.PENDING;
        state.tx.type = StakeTXType.APPROVAL;
        state.tx.data.txStep = TX_STEP.APPROVAL;
        state.tx.data.failData = {};
        state.tx.data.successData = {};
      })
      .addCase(approveBRCStakeAsync.rejected, (state: IStakeState, action) => {
        state.tx.status = TX_STATUS.REJECTED;
        state.tx.type = StakeTXType.APPROVAL;
        state.tx.data.failData = action.payload;
        state.tx.data.successData = {};
        state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
      })
      .addCase(approveBRCStakeAsync.fulfilled, (state: IStakeState, action) => {
        const data = action.meta.arg;
        if (typeof action.payload === undefined) {
          state.tx.status = TX_STATUS.FAILED;
          state.tx.type = StakeTXType.APPROVAL;
          state.tx.data.failData = {};
          state.tx.data.successData = {};
          state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
        } else {
          state.tx.status = TX_STATUS.SUCCESS;
          state.tx.type = StakeTXType.APPROVAL;
          state.tx.data.successData = action.payload;
          state.tx.data.failData = {};

          // update TX step for next.
          if (
            data.mode === StakeMode.MODE1 ||
            data.mode === StakeMode.MODE2 ||
            data.mode === StakeMode.MODE3
          ) {
            state.tx.data.nextTXStep = TX_STEP.STAKE_OR_WITHDRAW;
          }
          if (
            data.mode === StakeMode.MODE4 ||
            data.mode === StakeMode.MODE5 ||
            data.mode === StakeMode.MODE6
          ) {
            // in order to approve gBRC as well
            state.tx.data.nextTXStep = TX_STEP.APPROVAL;
          }
        }
      })

      .addCase(approveGBRCStakeAsync.pending, (state: IStakeState) => {
        state.tx.status = TX_STATUS.PENDING;
        state.tx.type = StakeTXType.APPROVAL;
        state.tx.data.txStep = TX_STEP.APPROVAL;
        state.tx.data.failData = {};
        state.tx.data.successData = {};
      })
      .addCase(approveGBRCStakeAsync.rejected, (state: IStakeState, action) => {
        state.tx.status = TX_STATUS.REJECTED;
        state.tx.type = StakeTXType.APPROVAL;
        state.tx.data.failData = action.payload;
        state.tx.data.successData = {};
        state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
      })
      .addCase(
        approveGBRCStakeAsync.fulfilled,
        (state: IStakeState, action) => {
          if (typeof action.payload === undefined) {
            state.tx.status = TX_STATUS.FAILED;
            state.tx.type = StakeTXType.APPROVAL;
            state.tx.data.failData = {};
            state.tx.data.successData = {};
            state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
          } else {
            state.tx.status = TX_STATUS.SUCCESS;
            state.tx.type = StakeTXType.APPROVAL;
            state.tx.data.successData = action.payload;
            state.tx.data.failData = {};
            // update TX step for next.
            // BRC would have been approved
            state.tx.data.nextTXStep = TX_STEP.STAKE_OR_WITHDRAW;
          }
        }
      )

      .addCase(getAllStakesAsync.fulfilled, (state: IStakeState, action) => {
        if (typeof action.payload === undefined) {
          console.log("FAILED TO LOAD ALL STAKES");
        } else {
          state.allStakes = action.payload as any;
        }
      })

      .addCase(getWeightsAsync.fulfilled, (state: IStakeState, action) => {
        if (typeof action.payload === undefined) {
          console.log("FAILED TO LOAD ALL POOL WEIGHTS");
        } else {
          state.poolWeights.MODE1 = action.payload.MODE1;
          state.poolWeights.MODE2 = action.payload.MODE2;
          state.poolWeights.MODE3 = action.payload.MODE3;
          state.poolWeights.MODE4 = action.payload.MODE4;
          state.poolWeights.MODE5 = action.payload.MODE5;
          state.poolWeights.MODE6 = action.payload.MODE6;
          state.poolWeights.TOTAL = (
            Number(action.payload.MODE1) +
            Number(action.payload.MODE2) +
            Number(action.payload.MODE3) +
            Number(action.payload.MODE4) +
            Number(action.payload.MODE5) +
            Number(action.payload.MODE6)
          ).toString();
        }
      });
  },
});

export {
  stakeAsync,
  approveBRCStakeAsync,
  approveGBRCStakeAsync,
  getAllStakesAsync,
  getWeightsAsync,
};
export const { resetStakeTXState } = stakeSliceReducer.actions;
export default stakeSliceReducer.reducer;
export const stakeSelector = (state: { stakeState: IStakeState }) =>
  state.stakeState;
