import ERC20ABI from "../../interfaces/ERC20.json";
import MOCK_DAI_ABI from "../../interfaces/MockDai.json";
import AIRDROP_ABI from "../../interfaces/BrincAirdrop.json";
import GOV_BRC_ABI from "../../interfaces/BrincGovToken.json";
import STAKED_GOV_BRC_ABI from "../../interfaces/StakedBrincGovToken.json";
import BRC_ABI from "../../interfaces/BrcToken.json";
import RouterV2 from "../../interfaces/RouterV2.json";
import BRC_STAKING_ABI from "../../interfaces/BrincStaking.json";
import { AbiItem } from "web3-utils";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Contract, ethers } from "ethers";
import Web3 from "web3";
import {
  AIRDROP_CONTRACT,
  AppNetworks,
  BRC_CONTRACT,
  dai_Token,
  eth_Token,
  gBRC_CONTRACT,
  infuraEndpoint,
  ROUTER_CONTRACT,
  sgBRC_CONTRACT,
  STAKING_CONTRACT,
  SUPPORTED_CHAINS,
  URL,
  usdc_Token,
  usdt_Token,
  ust_Token,
  wbtc_Token,
  ZERO_ADDRESS,
} from "../../constants";
import { IAppContractsNonNullable } from "../application/AppSlice";

declare let window: any;

//TODO: implement uniswap address validation
export const checksumAddress = (address: string | undefined): any => {
  if (!address) {
    address = ZERO_ADDRESS;
  }

  return Web3.utils.toChecksumAddress(address);
};

export function getWeb3Contracts(chainId, web3 = undefined) {
  if (!web3) {
    web3 = GetWeb3(chainId);
  }

  const daiContract = new web3.eth.Contract(
    MOCK_DAI_ABI.abi as AbiItem[],
    checksumAddress(dai_Token.address)
  );
  const ethContract = new web3.eth.Contract(
    ERC20ABI.abi as AbiItem[],
    checksumAddress(eth_Token.address)
  );
  const brcContract = new web3.eth.Contract(
    BRC_ABI.abi as AbiItem[],
    checksumAddress(BRC_CONTRACT)
  );
  const gbrcContract = new web3.eth.Contract(
    GOV_BRC_ABI.abi as AbiItem[],
    checksumAddress(gBRC_CONTRACT)
  );
  const sgbrcContract = new web3.eth.Contract(
    STAKED_GOV_BRC_ABI.abi as AbiItem[],
    checksumAddress(sgBRC_CONTRACT)
  );
  const routerContract = new web3.eth.Contract(
    RouterV2.abi as AbiItem[],
    checksumAddress(ROUTER_CONTRACT)
  );
  const usdcContract = new web3.eth.Contract(
    ERC20ABI.abi as AbiItem[],
    checksumAddress(usdc_Token.address)
  );
  const wbtcContract = new web3.eth.Contract(
    ERC20ABI.abi as AbiItem[],
    checksumAddress(wbtc_Token.address)
  );
  const usdtContract = new web3.eth.Contract(
    ERC20ABI.abi as AbiItem[],
    checksumAddress(usdt_Token.address)
  );
  const ustContract = new web3.eth.Contract(
    ERC20ABI.abi as AbiItem[],
    checksumAddress(ust_Token.address)
  );
  const stakingContract = new web3.eth.Contract(
    BRC_STAKING_ABI.abi as AbiItem[],
    checksumAddress(STAKING_CONTRACT)
  );
  const airdropContract = new web3.eth.Contract(
    AIRDROP_ABI.abi as AbiItem[],
    checksumAddress(AIRDROP_CONTRACT)
  );

  const contracts: IAppContractsNonNullable = {
    daiContract,
    ethContract,
    brcContract,
    gbrcContract,
    sgbrcContract,
    routerContract,
    usdcContract,
    wbtcContract,
    usdtContract,
    ustContract,
    stakingContract,
    airdropContract,
  };

  return contracts;
}

export function getProvider() {
  // let provider: ethers.providers.JsonRpcProvider;
  let provider: any;
  if (parseInt(`${process.env.REACT_APP_CHAIN_ID}`) !== 1337) {
    // if(window.ethereum || window?.web3?.currentProvider){
    provider = new ethers.providers.Web3Provider(
      window.ethereum || window?.web3?.currentProvider,
      "any"
    );
    // }
    if (!provider) {
      provider = new ethers.providers.JsonRpcProvider(
        `${process.env.REACT_APP_INFURA_ENDPOINT_MAINNET}`
      );
    }
  } else {
    provider = new ethers.providers.JsonRpcProvider(URL.GANACHE_RPC);
  }
  return provider;
}

export function GetWeb3(chainId) {
  const chainIdIsCorrect = chainId && SUPPORTED_CHAINS.includes(chainId);

  const network = ethers.providers.getNetwork(chainId);
  let web3 = new Web3(Web3.givenProvider);

  if (network.chainId !== 31337) {
    if (infuraEndpoint && (!window.ethereum || !chainIdIsCorrect)) {
      web3 = new Web3(infuraEndpoint);
    } else {
      try {
        web3 = new Web3(window.ethereum);
        window.ethereum.send('eth_requestAccounts');
      } catch (error) {
        console.log(`window.ethereum does not exist!! Error: ${error}`);
      }
    }
  }

  return web3;
}

// TODO: better way to check if contracts are all correctly loaded
export function areContractsLoaded(contracts: IAppContractsNonNullable) {
  try {
    if (Object.keys(contracts.brcContract).length > 0) {
      return true;
    }
  } catch (err) {
    console.log("contracts load check failed", err);
  }
  return false;
}

export function isTestnetNetwork(chainId) {
  return [
    Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
    Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
    Number.parseInt(AppNetworks.RINKEBY.toString()),
  ].includes(chainId);
}

export function isMainnetNetwork(chainId) {
  return [
    Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
    Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
    Number.parseInt(AppNetworks.MAINNET.toString()),
  ].includes(chainId);
}
