/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../store/themeContext/themeContext";
import { Link, Navigate } from "react-router-dom";
import useStyles from "./successStyles";
import PopupModal from "../../popupModal/popupModal";
import {
//   QuestionMark,
  ExternalLink,
//   Check,
//   Chevron,
//   Dai,
//   ETH,
  ICONS,
} from "../../icons/icons";
// import { ethers } from 'ethers';
// import { RootState } from "../../../store/mystore";
// import { useSelector } from "react-redux";
import {
  brc_Token,
  gbrc_Token,
  StakeTXType,
  TX_STEP,
  TOKEN_ACTION,
  WithdrawTime,
  WithdrawTXType,
  StakePeriod,
  dai_Token,
  usdc_Token,
  usdt_Token,
} from "../../../constants";
import { BigNumber } from "@ethersproject/bignumber";
import { handleNumberDisplay } from "../../../utils/utils";
import FillButton from "../../button/button";
import { ethers } from "ethers";

export interface Props {
  closeModal: () => void;
  // newBalance: string;
  etherscanLink: string;
  data: any;
  step: TX_STEP;
  transactionType: TOKEN_ACTION;

  // SWAP
  sellToken?: string;
  sellAmount?: number;
  buyAmount?: number;
  buyToken?: string;

  // STAKE
  stakeType?: StakeTXType;
  stakeSecondaryToken?: string;
  stakeSecondaryTokenAmount?: number;
  stakePrimaryTokenAmount?: number;
  stakePrimaryToken?: string;
  stakePeriod?: StakePeriod;

  // WITHDRAW
  withdrawType?: WithdrawTXType;
  withdrawTime?: WithdrawTime;
  withdrawSecondaryToken?: string;
  withdrawSecondaryTokenAmount?: string | BigNumber;
  withdrawPrimaryTokenAmount?: string | BigNumber;
  withdrawPrimaryToken?: string;
  withdrawRewards?: string | BigNumber;

  // NFT
  whitelistToken?: string;
  whitelistTokenAmount?: string | BigNumber;
}

enum RedirectTo {
  MINT,
  STAKE,
}

function Success(props: Props) {
  const { theme } = useContext(ThemeContext);
  // const state = useSelector((state: RootState) => state);
  const [redirectTo, setRedirectTo] = useState<RedirectTo>();

  const classes = useStyles({ ...props, ...theme });

  // const transfers = props.data ? props.data.events.Transfer : {};
  // const spent = ((props.data) && (transfers)) ? format(transfers[0]?.returnValues?.value).toFixed(2) : "0.00"
  // const fees = ((props.data) && (transfers)) ? format(transfers[1]?.returnValues?.value).toFixed(2) : "0.00"
  // const received = ((props.data) && (transfers)) ? format(transfers[2]?.returnValues?.value).toFixed(2) : "0.00"
  // const gasUsed = props.data ? props.data?.gasUsed : "000000";

  // separate resolution is done for BRC gBRC icons because the colours change somehow when gotten from the ICONS {}
  const OtherBuyTokenIcon = ICONS[props.buyToken];
  const OtherSellTokenIcon = ICONS[props.sellToken];
  const StakePrimaryTokenIcon =
    (props.stakePrimaryToken === brc_Token.symbol && (
      <img
        style={{ width: "21px" }}
        className={classes.brincIcon}
        src={require("../../../media/tokens/BRQ.svg").default}
        alt=""
      />
    )) ||
    (props.stakePrimaryToken === gbrc_Token.symbol && (
      <img
        style={{ width: "21px" }}
        className={classes.brincIcon}
        src={require("../../../media/tokens/gbrc.svg").default}
        alt=""
      />
    )) ||
    ICONS[props.stakePrimaryToken];
  const StakeSecondaryTokenIcon =
    (props.stakeSecondaryToken === brc_Token.symbol && (
      <img
        style={{ width: "21px" }}
        className={classes.brincIcon}
        src={require("../../../media/tokens/BRQ.svg").default}
        alt=""
      />
    )) ||
    (props.stakeSecondaryToken === gbrc_Token.symbol && (
      <img
        style={{ width: "21px" }}
        className={classes.brincIcon}
        src={require("../../../media/tokens/gbrc.svg").default}
        alt=""
      />
    )) ||
    ICONS[props.stakeSecondaryToken];
  const WithdrawPrimaryTokenIcon =
    (props.withdrawPrimaryToken === brc_Token.symbol && (
      <img
        style={{ width: "21px" }}
        className={classes.brincIcon}
        src={require("../../../media/tokens/BRQ.svg").default}
        alt=""
      />
    )) ||
    (props.withdrawPrimaryToken === gbrc_Token.symbol && (
      <img
        style={{ width: "21px" }}
        className={classes.brincIcon}
        src={require("../../../media/tokens/gbrc.svg").default}
        alt=""
      />
    )) ||
    ICONS[props.withdrawPrimaryToken];
  const WithdrawSecondaryTokenIcon =
    (props.withdrawSecondaryToken === brc_Token.symbol && (
      <img
        style={{ width: "21px" }}
        className={classes.brincIcon}
        src={require("../../../media/tokens/BRQ.svg").default}
        alt=""
      />
    )) ||
    (props.withdrawSecondaryToken === gbrc_Token.symbol && (
      <img
        style={{ width: "21px" }}
        className={classes.brincIcon}
        src={require("../../../media/tokens/gbrc.svg").default}
        alt=""
      />
    )) ||
    ICONS[props.withdrawSecondaryToken];

  // const BRCIcon = ICONS[brc_Token.symbol];
  const BRCIcon: JSX.Element = (
    <img
      style={{ width: "21px" }}
      className={classes.brincIcon}
      src={require("../../../media/tokens/BRQ.svg").default}
      alt=""
    />
  );
  const GBRCIcon: JSX.Element = (
    <img
      style={{ width: "21px" }}
      className={classes.brincIcon}
      src={require("../../../media/tokens/gbrc.svg").default}
      alt=""
    />
  );

  const onClickHandler = () => {
    if (props.transactionType !== TOKEN_ACTION.STAKE) {
      setRedirectTo(RedirectTo.STAKE);
    }
    if (props.transactionType === TOKEN_ACTION.STAKE) {
      setRedirectTo(RedirectTo.MINT);
    }
  };

  return (
    <PopupModal closeModal={props.closeModal}>
      {redirectTo === RedirectTo.MINT && <Navigate to="/mint" />}
      {redirectTo === RedirectTo.STAKE && <Navigate to="/stake" />}
      <section>
        <header
          className="pop__head"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h1 className={classes.heading}>Transaction complete</h1>
          <button
            type="button"
            className="pop__close"
            onClick={props.closeModal}
          >
            <span className="close">
              <img
                src={require("../../../media/icons/ico-close.svg").default}
                alt=""
              />
            </span>
          </button>
        </header>
        <main className="pop__body">
          <section className="">
            <ul className="alignment__list">
              {/* <li className="alignment__list__item">
								<div className="alignment__box">
									<span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span>
									<p className="alignment__txt">
										<span>Gas used:</span>
									</p>
								</div>
								<div className={`${classes.boxValue} alignment__box`}>
									<p className="alignment__txt">
										<span className="num">{handleNumberDisplay(gasUsed, true, 0)}</span>
										<span>gwei</span>
									</p>
									<span className="alignment__ico"><img src={require("../../../media/icons/ico-Gas.svg").default} alt=""/></span>
								</div>
							</li> */}
              {/* <li className="alignment__list__item">
								<div className="alignment__box">
									<span className="alignment__ico"><img src={require("../../../media/icons/ico-help-circle.svg").default} alt=""/></span>
									<p className="alignment__txt">
										<span>Fees:</span>
									</p>
								</div>
								<div className={`${classes.boxValue} alignment__box`}>
									<p className="alignment__txt">
										<span className="num">--</span>
										<span>DAI</span>
									</p>
									<span className="alignment__ico"><Dai className={classes.brincIcon} /></span>
								</div>
							</li> */}
              {props.transactionType === TOKEN_ACTION.BUY ||
              props.transactionType === TOKEN_ACTION.SELL ? (
                <ul className="alignment__list">
                  <li className="alignment__list__item">
                    <div className="alignment__box" style={{minWidth: "110px"}}>
                      {/* <span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span> */}
                      <p className="alignment__txt">
                        <span>BRC received:</span>
                      </p>
                    </div>
                    <div className={`${classes.boxValue} alignment__box`} style={{margin:"0px", minWidth: "120px"}}>
                      <p className="alignment__txt">
                        <span className="num">
                          {props.sellToken === dai_Token.symbol ? 
                          handleNumberDisplay(ethers.utils.formatEther(props.data.events.Transfer[2].returnValues.value)  , true, 3)
                            : Number(props.buyAmount).toFixed(2)}
                        </span>
                        <span>{props.buyToken}</span>
                      </p>
                      <span className="alignment__ico">
                        {props.transactionType === TOKEN_ACTION.BUY ? (
                          <>{BRCIcon}</>
                        ) : (
                          <OtherBuyTokenIcon />
                        )}
                      </span>
                      {/* <BRC className={classes.brincIcon} /> */}
                    </div>
                  </li>
                  <li className="alignment__list__item">
                    <div className="alignment__box" style={{minWidth: "118px"}}>
                      {/* <span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span> */}
                      <p className="alignment__txt">
                        <span>{props.sellToken} paid:</span>
                      </p>
                    </div>
                    <div className={`${classes.boxValue} alignment__box`} style={{margin:"0px", minWidth:"120px"}}>
                      <p className="alignment__txt">
                        <span className="num">
                          {props.sellAmount?.toString() !== "--"
                            ? handleNumberDisplay(props.sellAmount, true, 3)
                            : props.sellAmount}
                        </span>
                        <span>{props.sellToken}</span>
                      </p>
                      <span className="alignment__ico">
                        {props.transactionType === TOKEN_ACTION.SELL ? (
                          <>{BRCIcon}</>
                        ) : (
                          <OtherSellTokenIcon />
                        )}
                      </span>
                    </div>
                  </li>
                  <li className="alignment__list__item">
                    <a
                      href={props.etherscanLink}
                      rel="noreferrer"
                      target="_blank"
                      className="link"
                    >
                      <ExternalLink
                        style={{ width: "2rem", marginRight: "0.7rem" }}
                      />
                      View on Explorer
                    </a>
                  </li>
                </ul>
              ) : null}

              {props.transactionType === TOKEN_ACTION.NFT_WHITELIST ? (
                <>
                  <li className="alignment__list__item">
                    <div className="alignment__box">
                      <span className="alignment__ico">
                        <img
                          src={require("../../../media/icons/info.svg").default}
                          alt=""
                        />
                      </span>
                      <p className="alignment__txt">
                        <span>Whitelist cost:</span>
                      </p>
                    </div>
                    <div className={`${classes.boxValue} alignment__box`}>
                      <p className="alignment__txt">
                        <span className="num">
                          {props.whitelistTokenAmount?.toString() !== "--"
                            ? handleNumberDisplay(
                                props.whitelistTokenAmount.toString(),
                                true,
                                2
                              )
                            : props.whitelistTokenAmount}
                        </span>
                        <span>{props.whitelistToken}</span>
                      </p>
                      <span className="alignment__ico">{GBRCIcon}</span>
                      {/* <BRC className={classes.brincIcon} /> */}
                    </div>
                  </li>
                  <li className="alignment__list__item">
                    <div className="alignment__box">
                      <span className="alignment__ico">
                        <img
                          src={require("../../../media/icons/info.svg").default}
                          alt=""
                        />
                      </span>
                      <p className="alignment__txt">
                        <span>Received whitelist slots:</span>
                      </p>
                    </div>
                    <div className={`${classes.boxValue} alignment__box`}>
                      <p className="alignment__txt">
                        <span className="num">1</span>
                        <span>NFT</span>
                      </p>
                      <span className="alignment__ico">{/* {BRCIcon} */}</span>
                    </div>
                  </li>
                </>
              ) : null}

              {props.transactionType === TOKEN_ACTION.STAKE ||
              props.transactionType === TOKEN_ACTION.WITHDRAW ? (
                <>
                 <li>
										<div className="alignment__box">
											<p className="alignment__txt">
												{props.transactionType === TOKEN_ACTION.STAKE ?
                        <span>You staked:</span>
                        :
                        <span>You withdraw:</span>
                        }
											</p>
										</div>
								</li>
                <li className="alignment__list__item">
                  <div className="alignment__box">
                    <p className="alignment__txt" style={{ display: "flex", flexDirection: "column"}}>
                      <span>
                        BRC:
                      </span>
                      <span>
                        gBRC:
                      </span>
                    </p>
                  </div>
                  
                  <div style={{ display: "flex", flexDirection: "column"}}>
                    <div className={`${classes.boxValue} alignment__box`}>
                      <p className="alignment__txt">
                        <span className="num">
                          {props.transactionType === TOKEN_ACTION.STAKE
                            ? handleNumberDisplay(
                                props.stakePrimaryTokenAmount,
                                true,
                                3
                              )
                            : handleNumberDisplay(
                                props.withdrawPrimaryTokenAmount.toString(),
                                true,
                                2
                              )}
                        </span>
                        {/* <span>
                          {props.transactionType === TOKEN_ACTION.STAKE
                            ? props.stakePrimaryToken
                            : props.withdrawPrimaryToken}
                        </span> */}
                      </p>
                      {/* <span className="alignment__ico">
                        {props.transactionType === TOKEN_ACTION.STAKE ? (
                          <>{StakePrimaryTokenIcon}</>
                        ) : (
                          <>{WithdrawPrimaryTokenIcon}</>
                        )}
                      </span> */}
                    </div>
                    {props.stakeType === StakeTXType.gBRC_AND_BRC ||
                    props.withdrawType === WithdrawTXType.gBRC_AND_BRC ? (
                      <div className={`${classes.boxValue} alignment__box`}>
                        <p className="alignment__txt">
                          <span className="num">
                            {props.transactionType === TOKEN_ACTION.STAKE
                              ? handleNumberDisplay(
                                  props.stakeSecondaryTokenAmount,
                                  true,
                                  3
                                )
                              : handleNumberDisplay(
                                  props.withdrawSecondaryTokenAmount.toString(),
                                  true,
                                  2
                                )}
                          </span>
                          {/* <span>
                            {props.transactionType === TOKEN_ACTION.STAKE
                              ? props.stakeSecondaryToken
                              : props.withdrawSecondaryToken}
                          </span> */}
                        </p>
                        {/* <span className="alignment__ico">
                          {props.transactionType === TOKEN_ACTION.STAKE ? (
                            <>{StakeSecondaryTokenIcon}</>
                          ) : (
                            <>{WithdrawSecondaryTokenIcon}</>
                          )}
                        </span> */}
                      </div>
                    ) : null}
                  </div>
                </li>
                {props.transactionType === TOKEN_ACTION.STAKE ? 
                  <li className="alignment__list__item">
                      <div className="alignment__box">
                        <p className="alignment__txt">
                          <span className="num">{`${props.stakePeriod}`}</span> DAYS
                          {/* <span>{`${props.transactionType === TX_TYPE.STAKE ? props.stakePrimaryToken : props.withdrawPrimaryToken}`}</span> */}
                        </p>
                        {/* <span className="alignment__ico"><img width={'24px'} src={require("../../../media/icons/ico-time.svg").default} alt="stake period"/></span> */}
                      </div>
                  </li>
                  : null
                }
                <li className="alignment__list__item">
                  <div className="alignment__box" style={{marginLeft: 10}}>
                    <a
                      href={props.etherscanLink}
                      rel="noreferrer"
                      target="_blank"
                      className="link"
                    >
                      <ExternalLink
                        style={{ width: "2rem", marginRight: "0.7rem" }}
                      />
                      View on Explorer
                    </a>
                  </div>
                </li>
                </>
              ) : null}

              {props.transactionType === TOKEN_ACTION.WITHDRAW &&
              props.withdrawTime === WithdrawTime.MATURE ? (
                <li className="alignment__list__item">
                  <div className="alignment__box">
                    <p className="alignment__txt">
                      <span>Your Rewards:</span>
                    </p>
                  </div>
                  <div className={`${classes.boxValue} alignment__box`}>
                    <p className="alignment__txt">
                      <span className="num">
                        {handleNumberDisplay(
                          props.withdrawRewards?.toString(),
                          true,
                          2
                        )}
                      </span>
                      <span>{props.withdrawSecondaryToken}</span>
                    </p>
                    <span className="alignment__ico">
                      {WithdrawSecondaryTokenIcon}
                    </span>
                  </div>
                </li>
              ) : null}

              {/* <li className="alignment__line"></li>
							<li className="alignment__list__item last__alignment__item">
								<div className="alignment__box">
									<div className="alignment__txt">
										<span>New Balance</span>
										<a href={props.etherscanLink} rel="noreferrer" target="_blank" className="link">
											<ExternalLink style={{width: "2rem", marginRight: "0.7rem"}} />
											View on Explorer
										</a>
									</div>
								</div>
								<div className="alignment__box" style={{display: "flex", flexDirection: "column", alignItems:"center !important"}}>
									<div className={classes.boxValue}>
										<p className="alignment__txt" style={{display: "flex", marginLeft: "auto"}}>
											<span className="num">{handleNumberDisplay(state.wallet.balances.brcBalance.toString(), true, 3)}</span>
											<span>{brc_Token.symbol}</span>
										</p>
										<span className="alignment__ico">{BRCIcon}</span>
									</div>
									{props.transactionType !== TOKEN_ACTION.BUY && props.transactionType !== TOKEN_ACTION.SELL &&
										(props.transactionType === TOKEN_ACTION.NFT_WHITELIST || props.stakeType === StakeTXType.gBRC_AND_BRC || props.withdrawType === WithdrawTXType.gBRC_AND_BRC ||
											(props.withdrawRewards && Number.parseFloat(props.withdrawRewards.toString()) > 0)) ?
										<div className={classes.boxValue}>
											<p className="alignment__txt" style={{display: "flex"}}>
												<span className="num">{handleNumberDisplay(state.wallet.balances.gbrcBalance.toString(), true, 3)}</span>
												<span>{gbrc_Token.symbol}</span>									
											</p>								
											<span className="alignment__ico">{GBRCIcon}</span>	
										</div>		
									: null}							
								</div>								
							</li> */}
            </ul>
          </section>
        </main>
        <footer className="pop__foot" style={{ marginTop: "5rem" }}>
          <FillButton
            label={"Ok"}
            variant={"fill"}
            clickFunction={() => {
              // if (props.transactionType !== TOKEN_ACTION.STAKE) {
              //   onClickHandler();
              // } else {
              //   props.closeModal();
              // }
              props.closeModal();
            }}
            className={classes.actionButton}
          />
          {/* <section style={{ height: "1.2rem" }}></section> */}
          {/* <FillButton
            label={"Mint More BRC"}
            clickFunction={() => {
              if (props.transactionType === TOKEN_ACTION.STAKE) {
                onClickHandler();
              } else {
                props.closeModal();
              }
            }}
            className={classes.actionButton}
          /> */}
        </footer>
      </section>
    </PopupModal>
  );
}

export default React.memo(Success);
