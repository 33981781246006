/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../containers/header/header";
import { useWeb3React } from "@web3-react/core";
import { useToggle } from "../../hooks/useToggle";
import Footer from "../../containers/footer/footer";
import AlertUser from "../../components/dialogueBoxes/alertUser/alertUser";
import { RootState } from "../../store/mystore";
import { SUPPORTED_CHAINS} from "../../constants";
import { MuiGrid } from "../../components/materialUIHelper";
import ImgMask from "../../components/ImgMask/ImgMask";
import {Skeleton} from "@material-ui/lab";

import "./nft.scss";
import { callContract } from "./nftCall";

function NFTWhitelist() {
  const Grid = MuiGrid;   
  const state = useSelector((state: RootState) => state);
  const [walletConnected, setWalletConnected] = useState<boolean>(state.wallet.isConnected);
  const [showWalletModal, setShowWalletModal, toggleWalletModal] = useToggle(false);
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const { connector, active, chainId, account } = useWeb3React();
  const [showNetworkAlert, setShowNetworkAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [isOwnNFT, setIsOwnNFT] = useState([]);

  const chainIdIsCorrect =
  chainId && SUPPORTED_CHAINS.includes(chainId);

  if(!chainIdIsCorrect && !showNetworkAlert){
    setShowNetworkAlert(true);    
  }
 
  const getNFTbalance = async () => {
    const result = await callContract(account);

    if(result){
      setIsOwnNFT(result);
      setLoading(false);
    } 
  }

  useEffect(() => {
    getNFTbalance()
  }, [chainIdIsCorrect]);


  // handles logic for wallet connect modal.
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
    active && setShowWalletModal(false);
    if(active){
      
    }
    setWalletConnected(state.wallet.isConnected);
  }, [activatingConnector, connector, active, setShowWalletModal, state.wallet.isConnected]);


  return (
    
    <div id="wrap" className={''}>
      <Header
        showWalletModal={showWalletModal}
        setShowWalletModal={setShowWalletModal}
        toggleWalletModal={toggleWalletModal}
      />
     {active && !chainIdIsCorrect && showNetworkAlert && <AlertUser closeModal={() => setShowNetworkAlert(false)} />}      
     
      <div id="main">
        <div className="inner inner__sm">
          <h2 className="main__tit">
            The Brincnaut collection<br />
          </h2>
          <Grid className="nft-wrap" container spacing={5}>
          {/* {xl:2, lg:2, md: 3, xs: 1} */}
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
              <div className="nft-img-wrap">
                <img className="nft-img" src={require("../../media/gif/brincnuts_01.gif").default} alt="brincnuts01" />
                <ImgMask isOwn={isOwnNFT[0] === "0" ? false : true} link={{opensea: "https://opensea.io/collection/brincfi-brincnaut", galxe: "https://galxe.com/Brincfi/campaign/GCvquUwr4B"}}/>
                <h3 className="nft-img-title">01</h3>
              </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <img className="nft-img" src={require("../../media/gif/brincnuts_02.gif").default} alt="brincnuts02" />
                  <ImgMask isOwn={isOwnNFT[1] === "0" ? false : true} link={{opensea: "https://opensea.io/collection/the-brincnaut-02-arbitrum", galxe: "https://galxe.com/Brincfi/campaign/GCDAMUw1Ja"}}/>
                  <h3 className="nft-img-title">02</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <img className="nft-img" src={require("../../media/gif/brincnuts_03.gif").default} alt="brincnuts03" />
                  <ImgMask isOwn={isOwnNFT[2] === "0" ? false : true} link={{opensea: "https://opensea.io/collection/brinc-fi-nft-03", galxe: "https://galxe.com/Brincfi/campaign/GC9HKU4Y7x"}}/>
                  <h3 className="nft-img-title">03</h3>
                </div>
               }
              </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <img className="nft-img" src={require("../../media/gif/brincnuts_04.gif").default} alt="brincnuts04" />
                  <ImgMask isOwn={isOwnNFT[3] === "0" ? false : true} link={{opensea: "https://opensea.io/collection/brinc-fi-nft-04", galxe: "https://galxe.com/Brincfi/campaign/GCbnjU4xpJ"}}/>
                  <h3 className="nft-img-title">04</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <img className="nft-img" src={require("../../media/gif/brincnuts_05.jpg").default} alt="brincnuts05" />
                  <ImgMask isOwn={isOwnNFT[4] === "0" ? false : true} link={{opensea: "https://opensea.io/collection/brinc-fi-nft-05", galxe: "https://galxe.com/Brincfi/campaign/GCNywUTFCN"}}/>
                  <h3 className="nft-img-title">05</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <div className="nft-black-box">
                    <span className="comingsoon-text">COMING SOON</span>
                  </div>
                  <h3 className="nft-img-title">&nbsp;</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <div className="nft-black-box">
                    <span className="comingsoon-text">COMING SOON</span>
                  </div>
                  <h3 className="nft-img-title">&nbsp;</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <div className="nft-black-box">
                    <span className="comingsoon-text">COMING SOON</span>
                  </div>
                  <h3 className="nft-img-title">&nbsp;</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <div className="nft-black-box">
                    <span className="comingsoon-text">COMING SOON</span>
                  </div>
                  <h3 className="nft-img-title">&nbsp;</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <div className="nft-black-box">
                    <span className="comingsoon-text">COMING SOON</span>
                  </div>
                  <h3 className="nft-img-title">&nbsp;</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <div className="nft-black-box">
                    <span className="comingsoon-text">COMING SOON</span>
                  </div>
                  <h3 className="nft-img-title">&nbsp;</h3>
                </div>
              }
            </Grid>
            <Grid className="nft-item-wrap" item md={4} lg={3} xl={3} sm={5} xs={6}>
              {loading ? 
                <Skeleton className="skeleton" variant="rect" width={"100%"}/>
              :
                <div className="nft-img-wrap">
                  <div className="nft-black-box">
                    <span className="comingsoon-text">COMING SOON</span>
                  </div>
                  <h3 className="nft-img-title">&nbsp;</h3>
                </div>
              }
            </Grid>
          </Grid>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default React.memo(NFTWhitelist);
