import { Contract, ethers } from "ethers";
import { AirdropType, TX_STATUS } from "../../constants";
import { IClaimTransactionData } from "../application/AppSlice";
import { getAirdropMerkleData } from "../helpers/airdropHelper";
import ETH_HOLDERS_DATA from "../../pages/Airdrop/eth_holders.json";
import BRC_HOLDERS_DATA from "../../pages/Airdrop/brc_holders.json";
import SOCIAL_MEDIA_DATA from "../../pages/Airdrop/social_media.json";

export interface IClaimState {
  tx: {
    type: AirdropType;
    status: TX_STATUS;
    data: IClaimTransactionData;
  };
}

export type ClaimPayload = {
  airdropContract: Contract;
  airdropType: AirdropType;
  userAddress: string;
  // amount: math.BigNumber;
  // stakeMode: StakeMode;
};

export const claim = async (payload: ClaimPayload) => {
  try {
    let eligibleAmount = 0;
    if (payload.airdropType === AirdropType.aETH_HOLDERS) {
      eligibleAmount = ETH_HOLDERS_DATA.data.find(
        (x) => x.address === payload.userAddress
      ).amount;
    } else if (payload.airdropType === AirdropType.BRC_HOLDERS) {
      eligibleAmount = BRC_HOLDERS_DATA.data.find(
        (x) => x.address === payload.userAddress
      ).amount;
    } else if (payload.airdropType === AirdropType.SOCIAL_MEDIA) {
      eligibleAmount = SOCIAL_MEDIA_DATA.data.find(
        (x) => x.address === payload.userAddress
      ).amount;
    } else {
      return;
    }

    const merkleData = await getAirdropMerkleData(payload.airdropType);

    const leaf = ethers.utils.solidityKeccak256(
      ["address", "uint256"],
      [
        payload.userAddress,
        ethers.utils.parseUnits(eligibleAmount.toString(), 18),
      ]
    );
    const proof = merkleData.merkleTree.getHexProof(leaf);

    switch (payload.airdropType) {
      case AirdropType.aETH_HOLDERS:
        return await payload.airdropContract.methods
          .claim1(ethers.utils.parseUnits(eligibleAmount.toString(), 18), proof)
          .send({ from: payload.userAddress });

      case AirdropType.BRC_HOLDERS:
        return await payload.airdropContract.methods
          .claim2(ethers.utils.parseUnits(eligibleAmount.toString(), 18), proof)
          .send({ from: payload.userAddress });

      case AirdropType.SOCIAL_MEDIA:
        return await payload.airdropContract.methods
          .claim2(ethers.utils.parseUnits(eligibleAmount.toString(), 18), proof)
          .send({ from: payload.userAddress });

      default:
        return;
    }
  } catch (err) {
    console.log(err);
  }
};

export const isClaimed = async (
  airdropContract: Contract,
  userAddress: string,
  airdropType: AirdropType
) => {
  try {
    switch (airdropType) {
      case AirdropType.aETH_HOLDERS:
        return await airdropContract.methods.claimed1(userAddress).call();
      case AirdropType.BRC_HOLDERS:
        return await airdropContract.methods.claimed2(userAddress).call();
      case AirdropType.SOCIAL_MEDIA:
        return await airdropContract.methods.claimed3(userAddress).call();
      default:
        return;
    }
  } catch (err) {
    console.log(err);
  }
};
