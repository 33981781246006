declare let window: any;

// Opera 8.0+
// const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
export const isOpera = navigator.userAgent.match(/opr\//i)
//   navigator.userAgent.indexOf("Opera") > -1 ||
//   navigator.userAgent.indexOf("OPR") > -1 ||
//   navigator.userAgent.indexOf("OP") > -1;

// Firefox 1.0+
export const isFirefox = navigator.userAgent.match(/firefox|fxios/i); //"InstallTrigger" in window;

// Safari 3.0+ "[object HTMLElementConstructor]"
// export const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
export const isSafari = navigator.userAgent.match(/safari/i); //navigator.userAgent.indexOf("Safari") > -1;

// Internet Explorer 6-11
export const isIE = false || !!window.document.documentMode;

// Edge 20+
export const isEdge = navigator.userAgent.match(/edg/i);//!isIE && !!window.StyleMedia;

// Chrome 1 - 71
export const isChrome = navigator.userAgent.match(/chrome|chromium|crios/i)
//   !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

// Edge (based on chromium) detection
export var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") > -1;

// Blink engine detection
export const isBlink = (isChrome || isOpera) && !!window.CSS;
