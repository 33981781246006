import { ThemeProvider } from "./store/themeContext/themeContext";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Root from "./Root";
import Web3ReactManager from "./components/web3ReactManager";
import Web3ProviderNetwork from "./components/web3ProviderNetwork";

const App = () => {
  function getLibrary(provider: any): Web3Provider {
    const library = new Web3Provider(provider);
    library.pollingInterval = 12000;
    return library;
  }

  return (
    <div className="App">
      <ThemeProvider>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Web3ReactManager>
              <Root />
            </Web3ReactManager>
          </Web3ReactProvider>
        </Web3ProviderNetwork>
      </ThemeProvider>
    </div>
  );
};

export default App;
