import { ICONS } from "../icons/icons";
import {
  dai_Token,
  // eth_Token,
  usdc_Token,
  // wbtc_Token,
  usdt_Token,
  // ust_Token,
} from "../../constants";

export const tokenOptions = [
  { name: dai_Token.symbol, Icon: ICONS.DAI },
  { name: usdt_Token.symbol, Icon: ICONS.USDT },
  { name: usdc_Token.symbol, Icon: ICONS.USDC },
  // { name: ust_Token.symbol, Icon: ICONS.UST },
  // { name: eth_Token.symbol, Icon: ICONS.ETH },
  // { name: wbtc_Token.symbol, Icon: ICONS.wBTC },
];
