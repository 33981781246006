import { typography, Typography } from "./typography";

export interface Theme {
  name: string;
  text: string;
  whiteText: string;
  fadeText: string;
  colorFadeText: string;
  xxFadeText: string;
  highlightedText: string;
  hoverText: string;
  background1: string;
  background2: string;
  background3: string;
  background4: string;
  transition: string;
  transition2: string;
  Ytransform: string;
  maxButtonBackground: string;
  maxButtonBackgroundHover: string;
  maxButtonBgHover: string;
  colors: {
    error: string;
    success: string;
    warning: string;
    blackOverlay: string;
    brincBlue: string;
    govBlue: string;
  };
  typography: Typography;
  bgGradient1: string;
  bgGradient2: string;
  greenGradient: string;
}

export const themes = {
  dark: {
    name: "dark",
    text: "var(--almost-white)",
    whiteText: "var(--white)",
    fadeText: "var(--fade-white)",
    colorFadeText: "var(--coloured-fade-white)",
    xxFadeText: "var(--xx-fade-white)",
    highlightedText: "var(--white)",
    hoverText: "var(--light-black)",
    background1: "var(--almost-black)",
    background2: "var(--dark-grey)",
    background3: "var(--grey)",
    background4: "var(--lightgrey)",
    transition: "var(--transition)",
    transition2: "var(--transition2)",
    Ytransform: "var(--Ytransform)",
    maxButtonBackground: "var(--green-dark)",
    maxButtonBackgroundHover: "var(--green-dark-full)",
    maxButtonBgHover: "var(--blue-gradient-bg)",
    colors: {
      error: "var(--error)",
      success: "var(--green-dark-full)",
      warning: "var(--warning)",
      blackOverlay: "var(--black-overlay)",
      brincBlue: "var(--blue)",
      govBlue: "var(--govBlue)",
    },
    typography,
    bgGradient1: "var(--dark-gradient-bg)",
    bgGradient2: "var(--dark-gradient-bg2)",
    greenGradient: "var(--green-gradient-bg)",
  },
  light: {
    name: "light",
    text: "var(--almost-black)",
    whiteText: "var(--white)",
    fadeText: "var(--fade-white)",
    colorFadeText: "var(--coloured-fade-white)",
    xxFadeText: "var(--xx-fade-white)",
    highlightedText: "var(--black)",
    hoverText: "var(--light-black)",
    background1: "var(--almost-white)",
    background2: "var(--white)",
    background3: "",
    background4: "var(--lightgrey)",
    transition: "var(--transition)",
    transition2: "var(--transition2)",
    Ytransform: "var(--Ytransform)",
    maxButtonBackground: "var(--green-dark)",
    maxButtonBackgroundHover: "var(--green-dark-full)",
    maxButtonBgHover: "var(--blue-gradient-bg)",
    colors: {
      error: "var(--error)",
      success: "var(--green-dark-full)",
      warning: "var(--warning)",
      blackOverlay: "var(--black-overlay)",
      brincBlue: "var(--blue)",
      govBlue: "var(--govBlue)",
    },
    typography,
    bgGradient1: "",
    bgGradient2: "",
    greenGradient: "var(--green-gradient-bg)",
  },
};
