import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./addressBarStyles";
// import CardMini from "../cardMini/cardMini";
import { ReactComponent as Metamask } from "../../media/icons/metamask.svg";

export interface Props {
  address: string;
  clickFunction: any;
}

function AddressBar(props: Props) {
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });
  const { address, clickFunction } = props;

  const shortAddress = address ? `${address.slice(0, 6)}...${address.slice(
    address.length - 4
  )}` : "????...????";

  // const snippet = address ? parseInt(address.slice(2, 10), 16) : "012354678910";


  return (
    <button type="button" className="tr__menu btn__wallet" onClick={clickFunction}>
      <span className="svg__wrap">
        <Metamask className={classes.icon} />
      </span>
      <span className="txt">{shortAddress}</span>
    </button>
    // <button onClick={clickFunction}>
    //   <CardMini>
    //     <div className={classes.AddressBar}>
    //       <div className={classes.iconContainer}>
    //       <Metamask className={classes.icon} />
    //         {/* <Jazzicon
    //           className={classes.icon}
    //           diameter={
    //             2 *
    //             parseFloat(
    //               window
    //                 .getComputedStyle(document.body)
    //                 .getPropertyValue("font-size")
    //             )
    //           }
    //           seed={snippet}
    //         /> */}
    //       </div>
    //       <p className={classes.address}>{shortAddress}</p>          
    //     </div>
    //   </CardMini>
    // </button>
  );
}

export default React.memo(AddressBar);
