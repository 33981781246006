import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./maxButton";

const useStyles = makeStyles(() =>
  createStyles({
    MaxButton: {
      backgroundColor: "rgba(54,158,73,.15)",
      // backgroundColor: (style: Theme & Props) => style.maxButtonBackground,
      font: (style: Theme & Props) => style.typography.maxButton,      
      color: (style: Theme & Props) => "var(--govBlue)",    
      textTransform: "uppercase",      
      outline: "none",
      border: "1px solid var(--govBlue)",
      borderRadius: "100px",
      padding: "2px 10px",
      // width: '35px',
      whiteSpace: "nowrap",
      marginLeft: '1rem',
      cursor: (style: Theme & Props) => (style.disabled ? "auto" : "pointer"),
      "&:hover": {
        color: (style: Theme & Props) =>
          !style.disabled && style.hoverText,
        background: (style: Theme & Props) =>
          !style.disabled && style.maxButtonBgHover,
      },
      "&:focus": {
        color: (style: Theme & Props) =>
          !style.disabled && style.hoverText,
        background: (style: Theme & Props) =>
          !style.disabled && style.maxButtonBgHover,
      },
    },
    MaxButtonMobile: {
      font: (style: Theme & Props) => style.typography.maxButton,    
      fontSize: "12px",  
      color: (style: Theme & Props) => "var(--govBlue)",
      textTransform: "uppercase",
      outline: "none",
      borderBottom: "1px solid var(--govBlue)",
      cursor: (style: Theme & Props) => (style.disabled ? "auto" : "pointer"),
      zIndex: 505,
      height: 'auto',
    },
  })
);

export default useStyles;
