import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./progressBubblesStyles";
import { TOKEN_ACTION, TX_STEP } from "../../constants";
import "./progressBubbles.scss";

export interface Props {
  stepCount: number; // 2 or 3
  currentStep: TX_STEP; // 1, 2, or 3
  transactionType: TOKEN_ACTION;
}

function ProgressBubbles(props: Props) {
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });

  return (
    <article className="index__route">
      <strong className="tit">Route</strong>
      <ul className="route__list__box">
          <li className="route__item">
            <div className={`step__item ${props.currentStep === TX_STEP.APPROVAL ? 'on': ''} ${props.currentStep > 1 ? 'finish': ''}`}>
                <span className="step__txt">1</span>
            </div>
            <span className={`route__txt ${props.currentStep === TX_STEP.APPROVAL ? classes.currentStep : classes.otherSteps} ${props.currentStep > 1 ? 'finish': ''}`}>Approve</span>
          </li>
          {props.stepCount === 3 ? 
          <li className="route__item">
              <span className="route__stroke__arrow"><img src={require("../../media/icons/ico-arrow-down.svg").default} alt=""/></span>
              <div className={`step__item ${props.currentStep === TX_STEP.SWAP ? 'on': ''} ${props.currentStep > 2 ? 'finish': ''}`}>
                  <span className="step__txt">2</span>
              </div>
              <span className={`route__txt ${props.currentStep === TX_STEP.SWAP ? classes.currentStep : classes.otherSteps} ${props.currentStep > 1 ? 'finish': ''}`}>Swap</span>
          </li>:null}
          <li className="route__item">
              <span className="route__stroke__arrow"><img src={require("../../media/icons/ico-arrow-down.svg").default} alt=""/></span>
              <div className={`step__item ${props.currentStep === TX_STEP.MINT_OR_BURN || props.currentStep === TX_STEP.STAKE_OR_WITHDRAW ? 'on' : ''} ${props.currentStep > 3 ? 'finish': ''}`}>
                  <span className="step__txt">{props.stepCount === 2 ? "2" : "3"}</span>
              </div>
              <span className={`route__txt ${props.currentStep === TX_STEP.MINT_OR_BURN ? classes.currentStep : classes.otherSteps} ${props.currentStep > 2 ? 'finish': ''}`}>
                {props.transactionType === TOKEN_ACTION.BUY ? "Buy BRC":null}
                {props.transactionType === TOKEN_ACTION.SELL ? "Sell BRC":null}
                {props.transactionType === TOKEN_ACTION.STAKE ? "Stake":null}
              </span>
          </li>
      </ul>
  </article>
  );
}

export default React.memo(ProgressBubbles);
