import { TradeType } from "@uniswap/sdk";
import { bignumber } from "mathjs";
import {
  account,
  brc_Token,
  chainId,
  dai_Token,
  eth_Token,
  gbrc_Token,
  Tokens,
} from "../../constants";
import { getProvider } from "../helpers/contractBooter";
import { quote, QuotePayload } from "../helpers/v3-autoRouterSwapHelper";

export interface TokenQuotePayload {
  amount: any;
  swapToken?: string;
}

// How much Eth will I spend for x-amount of Dai

export const getEthQuoteFromDai = async (payload: any) => {
  try {
    const { amount } = payload;

    if(account === "") return;

    const provider = getProvider();

    const _payload: QuotePayload = {
      token: dai_Token,
      toToken: eth_Token,
      address: account,
      chainId: chainId,
      provider: provider,
      amount: amount,
      tradeType: TradeType.EXACT_INPUT,
    };

    return quote(_payload)
      .then((res) => {
        return res.quote.toFixed(_payload.toToken.decimals);
      })
      .catch((err) => {
        console.log("reward quote dai->eth error", err);
        return "0";
      });
  } catch (error) {
    console.log(error);
    return bignumber(0);
  }
};

export const getEthQuoteFromGbrc = async (payload: any) => {
  try {
    const { amount } = payload;

    if(account === "") return;

    const provider = getProvider();

    const _payload: QuotePayload = {
      token: gbrc_Token,
      toToken: eth_Token,
      address: account,
      chainId: chainId,
      provider: provider,
      amount: amount,
      tradeType: TradeType.EXACT_INPUT,
    };

    return quote(_payload)
      .then((res) => {
        return res.quote.toFixed(_payload.toToken.decimals);
      })
      .catch((err) => {
        console.log("reward quote gbrc->eth error", err);
        return "0";
      });
  } catch (error) {
    console.log(error);
    return bignumber(0);
  }
};

// How much Dai will I receive for x-amount of Eth.
export const getDaiQuoteForEth = async (payload: TokenQuotePayload) => {
  try {
    const { amount } = payload;

    if(account === "") return;

    const provider = getProvider();

    const _payload: QuotePayload = {
      token: eth_Token,
      toToken: dai_Token,
      address: account,
      chainId: chainId,
      provider: provider,
      amount: amount,
      tradeType: TradeType.EXACT_INPUT,
    };

    return quote(_payload)
      .then((res) => {
        return res.quote.toFixed(_payload.toToken.decimals);
      })
      .catch((err) => {
        console.log("cost quote eth->dai error", err);
        return "0";
      });
  } catch (error) {
    console.log(error);
    return bignumber(0);
  }
};

// How much of another token (USDC, wBTC etc.) will I spend for x-amount of Dai
export const getTokenQuoteFromDai = async (payload: TokenQuotePayload) => {
  try {
    const { amount, swapToken } = payload;

    if(account === "") return;

    const provider = getProvider();

    const _payload: QuotePayload = {
      token: dai_Token,
      toToken: Tokens[`${swapToken.toLowerCase()}Token`],
      address: account,
      chainId: chainId,
      provider: provider,
      amount: amount,
      tradeType: TradeType.EXACT_INPUT,
    };
    return quote(_payload)
      .then((res) => {
        return res.quote.toFixed(_payload.toToken.decimals);
      })
      .catch((err) => {
        console.log("reward quote error", err);
        return "0";
      });
  } catch (error) {
    console.log(error);
    return bignumber(0);
  }
};

// How much of another token (USDC, wBTC etc.) will I spend for x-amount of BRC
export const getTokenQuoteFromBRC = async (payload: TokenQuotePayload) => {
  try {
    const { amount, swapToken } = payload;

    if(account === "") return;

    const provider = getProvider();

    const _payload: QuotePayload = {
      token: brc_Token,
      toToken: Tokens[`${swapToken.toLowerCase()}Token`],
      address: account,
      chainId: chainId,
      provider: provider,
      amount: amount,
      tradeType: TradeType.EXACT_INPUT,
    };

    return quote(_payload)
      .then((res) => {
        return res.quote.toFixed(_payload.toToken.decimals);
      })
      .catch((err) => {
        console.log("reward quote error", err);
        return "0";
      });
  } catch (error) {
    console.log(error);
    return bignumber(0);
  }
};

// How much Dai will I receive for x-amount of another token (USDC, wBTC etc.).
export const getDaiQuoteForToken = async (payload: TokenQuotePayload) => {
  try {
    const { amount, swapToken } = payload;

    if(account === "") return;

    const provider = getProvider();

    const _payload: QuotePayload = {
      token: Tokens[`${swapToken.toLowerCase()}Token`],
      toToken: dai_Token,
      address: account,
      chainId: chainId,
      provider: provider,
      amount: amount,
      tradeType: TradeType.EXACT_INPUT,
    };
    return quote(_payload)
      .then((res) => {
        return res.quote.toFixed(_payload.toToken.decimals);
      })
      .catch((err) => {
        console.log("reward quote error", err);
        return "0";
      });
  } catch (error) {
    console.log(error);
    return bignumber(0);
  }
};
