import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./walletOptionButtonStyles";
import { ReactComponent as Check } from "../../media/icons/check_circle.svg";

export interface Props {
  clickFunction: () => void;
  label: string;
  selected: boolean;
  activating: boolean;
  active: boolean;
  setShowWalletModal: React.Dispatch<React.SetStateAction<boolean>>;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  numberOfWallets: number;
}

function WalletOptionButton(props: Props) {
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });
  const { clickFunction, Icon, activating, label, active } = props;
  const handleClick = () => {
    clickFunction && clickFunction();
  };

  return (
    <button onClick={handleClick} className={`${classes.WalletOptionButton} dark-impression`}>
      <div className={classes.container}>
        <Icon className={classes.icon} />
        <div className={classes.labelContainer}>
          <p className={classes.label}>
            {activating ? "Connecting..." : label}
          </p>
          {active && <Check className={classes.check} />}
        </div>
      </div>
    </button>
  );
}

export default React.memo(WalletOptionButton);
