import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";


import Swap from "./pages/Swap/swap";
// import Stake from "./pages/Stake/stake";
import Vault from "./pages/Vault/vault";
import Analytics from "./pages/Analytics/analytics";
// import Dashboard from "./pages/Dashboard/dashboard";
// import Referral from "./pages/Referral/referral";
import NFTWhitelist from "./pages/Nft/nft";

import { useWeb3React } from "@web3-react/core";
import { AppNetworks } from "./constants";
import { useAppDispatch } from "./store/mystore";
import { setContractsAsync } from "./store/application/AppSlice";
// import Airdrop from "./pages/Airdrop/airdrop";
import Claim from "./pages/Claim/claim";
import { getWeightsAsync } from "./store/stake/StakeSlice";
import { BasicContractCallPayload } from "./store/analytics/Analytics";
import MMBrowserLinkPopup from "./components/MMBrowserLInkPopup/MMBrowserLinkPopup";


const Root = () => {
  const { chainId, account } = useWeb3React();
  const dispatch = useAppDispatch();

  
  const _chainId =
    chainId !== AppNetworks.ARBITRUM_RINKEBY
      ? Number(AppNetworks.ARBITRUM_ONE)
      : Number(AppNetworks.ARBITRUM_RINKEBY);

  window.localStorage.setItem("LOCAL_CHAIN_ID", _chainId.toString());
  window.localStorage.setItem("USER_ADDR", account ? account.toString() : "");

  dispatch(
    setContractsAsync({ chainId: chainId ?? _chainId, web3: undefined })
  ).then((contracts) => {
    const payload = {
      contracts: contracts.payload,
      account: "",
    } as BasicContractCallPayload;

    dispatch(getWeightsAsync(payload));
  });



  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="swap" element={<Navigate to="/mint" replace />} />
          <Route path="mint" element={<Swap />} />
          {/* <Route path="stake" element={<Stake />} /> */}
          <Route path="vault" element={<Vault />} />
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="analytics" element={<Analytics />} />
          <Route path="claim" element={<Claim />} />
          {/* <Route path="/airdrop" component={Airdrop} /> */}
          <Route path="nft" element={<NFTWhitelist />} />
          {/* <Route path="/referral" component={Referral} /> */}
          <Route
            path="*"
            element={
              account ? (
                <Navigate to="/mint" replace />
              ) : (
                <Navigate to="/analytics" replace />
              )
            }
          />
        </Routes>
      </BrowserRouter>

      {/* For Mobile */}
      <MMBrowserLinkPopup />
    </>
  );
};

export default Root;
