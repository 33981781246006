import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import useStyles from "./toolTippedObjectStyles";

import ReactTooltip from 'react-tooltip';

export interface Props {
  // message: string;
}

function ToolTippedObject({ name, message}) {
  const { theme } = useContext(ThemeContext);

  // const classes = useStyles({ ...props, ...theme });

  return (
    <ReactTooltip id={name} backgroundColor={theme.background2} textColor={theme.text}>
        <div>
          {message}
        </div>
    </ReactTooltip>
  );
}

export default React.memo(ToolTippedObject);
