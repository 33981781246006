import {Accordion, AccordionSummary, AccordionDetails,
    Grid,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter,
    Paper } from "@material-ui/core";


export const MuiAccordion: any = Accordion;
export const MuiAccordionSummary: any = AccordionSummary;
export const MuiAccordionDetails: any = AccordionDetails;
export const MuiGrid: any = Grid;
export const MuiTable: any = Table;
export const MuiTableBody: any = TableBody;
export const MuiTableFooter: any = TableFooter;
export const MuiTableCell: any = TableCell;
export const MuiTableContainer: any = TableContainer;
export const MuiTableHead: any = TableHead;
export const MuiTableRow: any = TableRow;
export const MuiPaper: any = Paper;