import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./backdropStyles";

export interface Props {}

function Backdrop(props: Props) {
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });

  return <div className={classes.Backdrop}></div>;
}

export default React.memo(Backdrop);
