import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
// import { BelowDesktop, Desktop } from "../screenHelper";
import useStyles from "./maxButtonStyles";

export interface Props {
  handleClick: () => void;
  text: string;
  disabled: boolean;
}

function MaxButton(props: Props) {
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });

  return (
    <>
      {/* <Desktop>
        <button
          disabled={props.disabled}
          onClick={props.handleClick}
          className={classes.MaxButton}
        >
          max
        </button>
      </Desktop>
      <BelowDesktop> */}
        <button
          disabled={props.disabled}
          onClick={props.handleClick}
          className={classes.MaxButtonMobile}
        >
          {props.text}
        </button>
      {/* </BelowDesktop> */}
    </>
  );
}

export default React.memo(MaxButton);
