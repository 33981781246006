/* eslint-disable @typescript-eslint/no-unused-vars */
import { ethers } from "ethers";
import {
  add,
  all,
  bignumber,
  create,
  divide,
  multiply,
  pow,
  subtract,
  sum,
} from "mathjs";
import {
  BRC_CONTRACT,
  dai_Token,
  gbrc_Token,
  STAKING_CONTRACT,
  TREASURY_CONTRACT,
  BIGNUMBER_CONFIG,
  BRC_gBRC_PAIR_SUSHISWAP,
  brc_Token,
  HTTP_METHOD,
  StakeMode,
  infuraEndpoint,
  AppNetworks,
  SUSHI_BRC_GBRC_PAIR_ARBITRUM_ADDRESS,
  URL,
  eth_Token,
  ETH_TRESURY_ADDRESS,
} from "../../constants";
import { IAppContractsNonNullable } from "../application/AppSlice";
import { GetUSDTokenEquivalence } from "../helpers/priceHelper";
import { RootState } from "../mystore";
import { getBRCtoGBRCRatio } from "../services/pricesAndConversions";
import sushiData from "@sushiswap/sushi-data";
import { TokenPriceInfo } from "./AnalyticsSlice";
import Web3 from "web3";
import APICall from "../../utils/apiCall";
import { createClient } from "urql";
import { getEthBalance } from "../services/balances";
import { Weights } from "../stake/Stake";
import { getDaiQuoteForEth, getEthQuoteFromDai } from "../services/quotes";

declare let window: any;

const EthDater = require("ethereum-block-by-date");

const web3 = new Web3(
  Number(window.localStorage.getItem("LOCAL_CHAIN_ID")) ===
  Number(AppNetworks.ARBITRUM_RINKEBY)
    ? window.ethereum
    : infuraEndpoint
);
const math = create(all, BIGNUMBER_CONFIG);

export type UseAllContractsPayload = {
  contracts: IAppContractsNonNullable;
};

export type PoolsSupply = {
  brcOnly7Days: string;
  brcOnly30Days: string;
  brcOnly90Days: string;
  brcGBRC7Days: string;
  brcGBRC30Days: string;
  brcGBRC90Days: string;
};

export interface BasicContractCallPayload {
  contracts: IAppContractsNonNullable;
  account: string;
  appState?: RootState;
}

export const calculateTotalRewardsPerWeek = (
  govBrincPerMonth: math.BigNumber,
  weight: math.BigNumber,
  totalWeights: math.BigNumber
) => {
  const result = divide(
    multiply(divide(govBrincPerMonth, 4), weight),
    totalWeights
  ).toString();
  return result === "0" ? "1" : result;
};

const BLOCKS_PER_MONTH = 199384;

let totalSupplyWeight = 0;
let GOV_BRINC_PER_BLOCK = 0;

export const calculateTotalAPR = async (
  rewards: math.BigNumber,
  brcStake: math.BigNumber,
  weight: math.BigNumber,
  allWeights: Weights,
  data
) => {
  if (Number(totalSupplyWeight) === 0) {
    const pool1SupplyWeight = bignumber(
      multiply(
        bignumber(
          await data.contracts.stakingContract.methods
            .getPoolSupply(StakeMode.MODE1)
            .call()
        ),
        bignumber(allWeights.MODE1)
      ).toString()
    );
    const pool2SupplyWeight = bignumber(
      multiply(
        bignumber(
          await data.contracts.stakingContract.methods
            .getPoolSupply(StakeMode.MODE2)
            .call()
        ),
        bignumber(allWeights.MODE2)
      ).toString()
    );
    const pool3SupplyWeight = bignumber(
      multiply(
        bignumber(
          await data.contracts.stakingContract.methods
            .getPoolSupply(StakeMode.MODE3)
            .call()
        ),
        bignumber(allWeights.MODE3)
      ).toString()
    );
    const pool4SupplyWeight = bignumber(
      multiply(
        bignumber(
          await data.contracts.stakingContract.methods
            .getPoolSupply(StakeMode.MODE4)
            .call()
        ),
        bignumber(allWeights.MODE4)
      ).toString()
    );
    const pool5SupplyWeight = bignumber(
      multiply(
        bignumber(
          await data.contracts.stakingContract.methods
            .getPoolSupply(StakeMode.MODE5)
            .call()
        ),
        bignumber(allWeights.MODE5)
      ).toString()
    );
    const pool6SupplyWeight = bignumber(
      multiply(
        bignumber(
          await data.contracts.stakingContract.methods
            .getPoolSupply(StakeMode.MODE6)
            .call()
        ),
        bignumber(allWeights.MODE6)
      ).toString()
    );

    totalSupplyWeight = sum(
      pool1SupplyWeight,
      pool2SupplyWeight,
      pool3SupplyWeight,
      pool4SupplyWeight,
      pool5SupplyWeight,
      pool6SupplyWeight
    );
  }

  GOV_BRINC_PER_BLOCK = await data.contracts.stakingContract.methods
    .getGovBrincPerBlock()
    .call();
  let GOV_BRINC_PER_MONTH = multiply(BLOCKS_PER_MONTH, GOV_BRINC_PER_BLOCK);
  let TOTAL_REWARDS_PER_WEEK = divide(GOV_BRINC_PER_MONTH, 4);

  // ((totalRewards * brcStake * weight ) /  totalSupplyWeight ) / brcStake
  return multiply(
    divide(
      divide(
        multiply(multiply(bignumber(TOTAL_REWARDS_PER_WEEK), brcStake), weight),
        totalSupplyWeight
      ),
      brcStake
    ),
    5200
  ).toString();
};

export const calculateCurrentAPR = (
  brcStaked: math.BigNumber,
  rewards: math.BigNumber,
  daysPassed: number,
  stakeMode: StakeMode
) => {
  // console.log('current APR, ', {rewards: rewards.toString(), daysPassed});
  // ((gBRC rewards)*(365/number of days passed) - 1)
  // new APR=((r/n)x365)x100)

  // =((((((rewards*(30/daysPassed))-1)/brcStaked)*100)/30)*365)
  
  // switch (stakeMode) {
  //   case StakeMode.MODE1:
  //     // (((((rewards*(daysPassed/7))/brcStaked)*100)/7)*365)-1
  //     return multiply(divide(multiply(divide(multiply(bignumber(rewards),divide(bignumber(daysPassed),7)),bignumber(brcStaked)),100),7),365)
  //   case StakeMode.MODE2:
  //     return multiply(divide(multiply(divide(multiply(bignumber(rewards),divide(bignumber(daysPassed),30)),bignumber(brcStaked)),100),30),365)
  //   case StakeMode.MODE3:
  //     return multiply(divide(multiply(divide(multiply(bignumber(rewards),divide(bignumber(daysPassed),90)),bignumber(brcStaked)),100),90),365)
  //   case StakeMode.MODE4:
  //     return multiply(divide(multiply(divide(multiply(bignumber(rewards),divide(bignumber(daysPassed),7)),bignumber(brcStaked)),100),7),365)
  //   case StakeMode.MODE5:
  //     return multiply(divide(multiply(divide(multiply(bignumber(rewards),divide(bignumber(daysPassed),30)),bignumber(brcStaked)),100),30),365)
  //   case StakeMode.MODE6:
  //     return multiply(divide(multiply(divide(multiply(bignumber(rewards),divide(bignumber(daysPassed),90)),bignumber(brcStaked)),100),90),365)
  //   default:
  //     return 0;
  // }

  return multiply(multiply(divide(divide(bignumber(rewards), bignumber(brcStaked)), bignumber(daysPassed)), 365), 100);
};

// export const getPoolTotalRewards = async ({ stakingContract, stakeMode }) => {
//   if (GOV_BRINC_PER_BLOCK === 0) {
//     GOV_BRINC_PER_BLOCK = await stakingContract.methods
//       .getGovBrincPerBlock()
//       .call();
//   }
//   let weight;

//   switch (stakeMode) {
//     case StakeMode.MODE1:
//       weight = bignumber(await getPoolWeight(stakingContract, StakeMode.MODE1));
//       break;
//     case StakeMode.MODE2:
//       weight = bignumber(await getPoolWeight(stakingContract, StakeMode.MODE2));
//       break;
//     case StakeMode.MODE3:
//       weight = bignumber(await getPoolWeight(stakingContract, StakeMode.MODE3));
//       break;
//     case StakeMode.MODE4:
//       weight = bignumber(await getPoolWeight(stakingContract, StakeMode.MODE4));
//       break;
//     case StakeMode.MODE5:
//       weight = bignumber(await getPoolWeight(stakingContract, StakeMode.MODE5));
//       break;
//     case StakeMode.MODE6:
//       weight = bignumber(await getPoolWeight(stakingContract, StakeMode.MODE6));
//       break;
//     default:
//       weight = bignumber(0);
//       break;
//   }

//   const govBrincPerMonth = math.multiply(
//     bignumber(GOV_BRINC_PER_BLOCK),
//     bignumber(BLOCKS_PER_MONTH)
//   );
//   const totalWeight = 
//   const totalRewards = bignumber(
//     calculateTotalRewardsPerWeek(bignumber(govBrincPerMonth.toString()), weight, totalWeights)
//   );
//   return Number(totalRewards);
// };

export const getGovBrincPerBlock = async (data) => {
  return await data.contracts.stakingContract.methods
    .getGovBrincPerBlock()
    .call();
};

export const getBRCStakedInPool = async ({ stakingContract, stakeMode }) => {
  return stakingContract.methods
    .getPoolSupply(stakeMode)
    .call()
    .then((brcStaked) => {
      const brc =
        bignumber(brcStaked).toString() === "0"
          ? bignumber("1")
          : bignumber(brcStaked);
      return brc;
    });
};

export const getPoolsSupply = async ({ stakingContract }) => {
  let poolsSupply: PoolsSupply = {
    brcOnly7Days: "",
    brcOnly30Days: "",
    brcOnly90Days: "",
    brcGBRC7Days: "",
    brcGBRC30Days: "",
    brcGBRC90Days: "",
  };
  const pool1 = await stakingContract.methods
    .getPoolSupply(StakeMode.MODE1)
    .call();
  const pool2 = await stakingContract.methods
    .getPoolSupply(StakeMode.MODE2)
    .call();
  const pool3 = await stakingContract.methods
    .getPoolSupply(StakeMode.MODE3)
    .call();
  const pool4 = await stakingContract.methods
    .getPoolSupply(StakeMode.MODE4)
    .call();
  const pool5 = await stakingContract.methods
    .getPoolSupply(StakeMode.MODE5)
    .call();
  const pool6 = await stakingContract.methods
    .getPoolSupply(StakeMode.MODE6)
    .call();

  // console.log({pool1});

  poolsSupply.brcOnly7Days = ethers.utils
    .formatUnits(pool1, brc_Token.decimals)
    .toString();
  poolsSupply.brcOnly30Days = ethers.utils
    .formatUnits(pool2, brc_Token.decimals)
    .toString();
  poolsSupply.brcOnly90Days = ethers.utils
    .formatUnits(pool3, brc_Token.decimals)
    .toString();
  poolsSupply.brcGBRC7Days = ethers.utils
    .formatUnits(pool4, brc_Token.decimals)
    .toString();
  poolsSupply.brcGBRC30Days = ethers.utils
    .formatUnits(pool5, brc_Token.decimals)
    .toString();
  poolsSupply.brcGBRC90Days = ethers.utils
    .formatUnits(pool6, brc_Token.decimals)
    .toString();

  return poolsSupply;
};

export const getBrcOnly7DaysAPY = async (data: BasicContractCallPayload) => {
  return getBrcOnly7DaysAPR(data)
    .then((calApr) => {
      return multiply(
        subtract(
          pow(
            add(
              bignumber("1"),
              divide(bignumber(calApr / 100), bignumber("52"))
            ),
            bignumber("52")
          ),
          bignumber("1")
        ),
        100
      ).toString();
    })
    .catch((err) => {
      console.log(err);
      return "";
    });
};

export const getBrcOnly30DaysAPY = async (data: BasicContractCallPayload) => {
  return getBrcOnly30DaysAPR(data)
    .then((calApr) => {
      return multiply(
        subtract(
          pow(
            add(
              bignumber("1"),
              divide(bignumber(calApr / 100), bignumber("12"))
            ),
            bignumber("12")
          ),
          bignumber("1")
        ),
        100
      ).toString();
    })
    .catch((err) => {
      console.log(err);
      return "";
    });
};

export const getBrcOnly90DaysAPY = async (data: BasicContractCallPayload) => {
  return getBrcOnly90DaysAPR(data)
    .then((calApr) => {
      return multiply(
        subtract(
          pow(
            add(
              bignumber("1"),
              divide(bignumber(calApr / 100), bignumber("4"))
            ),
            bignumber("4")
          ),
          bignumber("1")
        ),
        100
      ).toString();
    })
    .catch((err) => {
      console.log(err);
      return "";
    });
};

export const getBrcGBRC7DaysAPY = async (data: BasicContractCallPayload) => {
  return getBrcGBRC7DaysAPR(data)
    .then((calApr) => {
      return multiply(
        subtract(
          pow(
            add(
              bignumber("1"),
              divide(bignumber(calApr / 100), bignumber("52"))
            ),
            bignumber("52")
          ),
          bignumber("1")
        ),
        100
      ).toString();
    })
    .catch((err) => {
      console.log(err);
      return "";
    });
};

export const getBrcGBRC30DaysAPY = async (data: BasicContractCallPayload) => {
  return getBrcGBRC30DaysAPR(data)
    .then((calApr) => {
      return multiply(
        subtract(
          pow(
            add(
              bignumber("1"),
              divide(bignumber(calApr / 100), bignumber("12"))
            ),
            bignumber("12")
          ),
          bignumber("1")
        ),
        100
      ).toString();
    })
    .catch((err) => {
      console.log(err);
      return "";
    });
};

export const getBrcGBRC90DaysAPY = async (data: BasicContractCallPayload) => {
  return getBrcGBRC90DaysAPR(data)
    .then((calApr) => {
      return multiply(
        subtract(
          pow(
            add(
              bignumber("1"),
              divide(bignumber(calApr / 100), bignumber("4"))
            ),
            bignumber("4")
          ),
          bignumber("1")
        ),
        100
      ).toString();
    })
    .catch((err) => {
      console.log(err);
      return "";
    });
};

export const getBrcOnly7DaysAPR = async (data: BasicContractCallPayload) => {
  return data.contracts.stakingContract.methods
    .getPoolSupply(StakeMode.MODE1)
    .call()
    .then((brcStaked) => {
      brcStaked = bignumber(brcStaked);
      return data.contracts.stakingContract.methods
        .getGovBrincPerBlock()
        .call()
        .then((_govBrincPerBlock) => {
          GOV_BRINC_PER_BLOCK = _govBrincPerBlock;
          const weight = bignumber(data.appState.stake.poolWeights.MODE1);
          const govBrincPerMonth = math.multiply(
            bignumber(_govBrincPerBlock),
            bignumber(BLOCKS_PER_MONTH)
          );

          const totalRewards = bignumber(
            calculateTotalRewardsPerWeek(
              bignumber(govBrincPerMonth.toString()),
              weight,
              bignumber(data.appState.stake.poolWeights.TOTAL)
            )
          );

          return calculateTotalAPR(totalRewards, brcStaked, weight, data.appState.stake.poolWeights, data);
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => console.log(err));
};

export const getBrcOnly30DaysAPR = async (data: BasicContractCallPayload) => {
  return data.contracts.stakingContract.methods
    .getPoolSupply(StakeMode.MODE2)
    .call()
    .then((brcStaked) => {
      return data.contracts.stakingContract.methods
        .getGovBrincPerBlock()
        .call()
        .then((_govBrincPerBlock) => {
          const weight = bignumber(data.appState.stake.poolWeights.MODE2);
          const govBrincPerMonth = math.multiply(
            bignumber(_govBrincPerBlock),
            bignumber(BLOCKS_PER_MONTH)
          );
          const totalRewards = bignumber(
            calculateTotalRewardsPerWeek(
              bignumber(govBrincPerMonth.toString()),
              weight,
              bignumber(data.appState.stake.poolWeights.TOTAL)
            )
          );

          return calculateTotalAPR(totalRewards, brcStaked, weight, data.appState.stake.poolWeights, data);
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => console.log(err));
};

export const getBrcOnly90DaysAPR = async (data: BasicContractCallPayload) => {
  return data.contracts.stakingContract.methods
    .getPoolSupply(StakeMode.MODE3)
    .call()
    .then((brcStaked) => {
      brcStaked = bignumber(brcStaked);
      return data.contracts.stakingContract.methods
        .getGovBrincPerBlock()
        .call()
        .then((_govBrincPerBlock) => {
          const weight = bignumber(data.appState.stake.poolWeights.MODE3);
          const govBrincPerMonth = math.multiply(
            bignumber(_govBrincPerBlock),
            bignumber(BLOCKS_PER_MONTH)
          );
          const totalRewards = bignumber(
            calculateTotalRewardsPerWeek(
              bignumber(govBrincPerMonth.toString()),
              weight,
              bignumber(data.appState.stake.poolWeights.TOTAL)
            )
          );

          return calculateTotalAPR(totalRewards, brcStaked, weight, data.appState.stake.poolWeights, data);
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => console.log(err));
};

export const getBrcGBRC7DaysAPR = async (data: BasicContractCallPayload) => {
  return data.contracts.stakingContract.methods
    .getPoolSupply(StakeMode.MODE4)
    .call()
    .then((brcStaked) => {
      brcStaked = bignumber(brcStaked);
      return data.contracts.stakingContract.methods
        .getGovBrincPerBlock()
        .call()
        .then((_govBrincPerBlock) => {
          const weight = bignumber(data.appState.stake.poolWeights.MODE4);
          const govBrincPerMonth = math.multiply(
            bignumber(_govBrincPerBlock),
            bignumber(BLOCKS_PER_MONTH)
          );
          const totalRewards = bignumber(
            calculateTotalRewardsPerWeek(
              bignumber(govBrincPerMonth.toString()),
              weight,
              bignumber(data.appState.stake.poolWeights.TOTAL)
            )
          );

          return calculateTotalAPR(totalRewards, brcStaked, weight, data.appState.stake.poolWeights, data);
        });
    })
    .catch((err) => console.log(err));
};

export const getBrcGBRC30DaysAPR = async (data: BasicContractCallPayload) => {
  return data.contracts.stakingContract.methods
    .getPoolSupply(StakeMode.MODE5)
    .call()
    .then((brcStaked) => {
      return data.contracts.stakingContract.methods
        .getGovBrincPerBlock()
        .call()
        .then((_govBrincPerBlock) => {
          const weight = bignumber(data.appState.stake.poolWeights.MODE5);
          const govBrincPerMonth = math.multiply(
            bignumber(_govBrincPerBlock),
            bignumber(BLOCKS_PER_MONTH)
          );
          const totalRewards = bignumber(
            calculateTotalRewardsPerWeek(
              bignumber(govBrincPerMonth.toString()),
              weight,
              bignumber(data.appState.stake.poolWeights.TOTAL)
            )
          );

          return calculateTotalAPR(totalRewards, brcStaked, weight, data.appState.stake.poolWeights, data);
        });
    })
    .catch((err) => console.log(err));
};

export const getBrcGBRC90DaysAPR = async (data: BasicContractCallPayload) => {
  return data.contracts.stakingContract.methods
    .getPoolSupply(StakeMode.MODE6)
    .call()
    .then((brcStaked) => {
      brcStaked = bignumber(brcStaked);
      return data.contracts.stakingContract.methods
        .getGovBrincPerBlock()
        .call()
        .then((_govBrincPerBlock) => {
          const weight = bignumber(data.appState.stake.poolWeights.MODE6);
          const govBrincPerMonth = math.multiply(
            bignumber(_govBrincPerBlock),
            bignumber(BLOCKS_PER_MONTH)
          );
          const totalRewards = bignumber(
            calculateTotalRewardsPerWeek(
              bignumber(govBrincPerMonth.toString()),
              weight,
              bignumber(data.appState.stake.poolWeights.TOTAL)
            )
          );

          return calculateTotalAPR(totalRewards, brcStaked, weight, data.appState.stake.poolWeights, data);
        });
    })
    .catch((err) => console.log(err));
};

export const getTotalDAIReserve = async (data: BasicContractCallPayload) => {
  return data.contracts.daiContract.methods
    .balanceOf(BRC_CONTRACT)
    .call({ from: data.account })
    .then((res) => {
      return bignumber(
        ethers.utils.formatUnits(res, dai_Token.decimals)
      ).toString();
    })
    .catch((err) => console.log(err));
};

export const getBRCTotalCirculatingSupply = async (
  data: BasicContractCallPayload
) => {
  return data.contracts.brcContract.methods
    .totalSupply()
    .call()
    .then((res) => {
      return data.contracts.brcContract.methods
        .balanceOf(TREASURY_CONTRACT)
        .call({ from: data.account })
        .then((treasuryBalance) => {
          return {
            totalSupply: ethers.utils.formatUnits(res, 18).toString(),
            totalCirculatingSupply: ethers.utils
              .formatUnits(
                ethers.BigNumber.from(res).sub(
                  ethers.BigNumber.from(treasuryBalance)
                ),
                18
              )
              .toString(),
          };
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => console.log(err));
};

export const getGBRCTotalCirculatingSupply = async (
  data: BasicContractCallPayload
) => {
  return data.contracts.gbrcContract.methods
    .totalSupply()
    .call()
    .then((res) => {
      return data.contracts.gbrcContract.methods
        .balanceOf(TREASURY_CONTRACT)
        .call({ from: data.account })
        .then((treasuryBalance) => {
          return {
            totalSupply: ethers.utils
              .formatUnits(res, gbrc_Token.decimals)
              .toString(),
            totalCirculatingSupply: ethers.utils
              .formatUnits(
                ethers.BigNumber.from(res).sub(
                  ethers.BigNumber.from(treasuryBalance)
                ),
                gbrc_Token.decimals
              )
              .toString(),
          };
        })
        .catch((err) => console.log(err));
    })
    .catch((err) => console.log(err));
};

export const getBRCPercentStaked = async (data: BasicContractCallPayload) => {
  // get BrincStaking.getPoolSupply() for all 6 pools and add them together. then divide by the total BRC token circulating supply
  return data.contracts.brcContract.methods
    .balanceOf(STAKING_CONTRACT)
    .call({ from: data.account })
    .then((stakedBrc) => {
      const _stakedBrc = ethers.utils.formatUnits(
        stakedBrc,
        brc_Token.decimals
      );

      return getBRCTotalCirculatingSupply(data).then((data) => {
        return (Number(_stakedBrc) / data?.totalCirculatingSupply) * 100;
      });
    })
    .catch((err) => console.log(err));
};

export const getGBRCPercentStaked = async (data: BasicContractCallPayload) => {
  // get BrincStaking.getPoolSupply() for pools that use gBRC (pools 4, 5, 6) and add them together. then divide by the total gBRC token circulating supply
  return data.contracts.gbrcContract.methods
    .balanceOf(STAKING_CONTRACT)
    .call({ from: data.account })
    .then((stakedGbrc) => {
      const _stakedGbrc = ethers.utils.formatUnits(
        stakedGbrc,
        gbrc_Token.decimals
      );
      return getGBRCTotalCirculatingSupply(data).then((data) => {
        return (Number(_stakedGbrc) / data?.totalCirculatingSupply) * 100;
      });
    })
    .catch((err) => console.log(err));
};

export const setTreasuryAssets = async (data: BasicContractCallPayload) => {
  // brc.balcnceOf(treasury) + GBRC.balanceOf(treasure) + dai.balanceOf(treasury)
  try {
    const treasuryDai = await data.contracts.daiContract.methods
      .balanceOf(TREASURY_CONTRACT)
      .call({ from: data.account });
    const treasuryBrc = await data.contracts.brcContract.methods
      .balanceOf(TREASURY_CONTRACT)
      .call({ from: data.account });
    //TODO: remove BRC staked by treasury
    const treasuryGbrc = await data.contracts.gbrcContract.methods
      .balanceOf(TREASURY_CONTRACT)
      .call({ from: data.account });
    const treasuryEth = await data.contracts.ethContract.methods
      .balanceOf(ETH_TRESURY_ADDRESS)
      .call({ from: data.account });

    return {
      dai: ethers.utils.formatUnits(treasuryDai, dai_Token.decimals).toString(),
      brc: GetUSDTokenEquivalence(
        data.appState,
        bignumber(ethers.utils.formatUnits(treasuryBrc, brc_Token.decimals)),
        brc_Token.symbol
      ),
      eth: treasuryEth.toString(),
      ethUsd: (await getDaiQuoteForEth({
        amount: ethers.utils.formatUnits(treasuryEth, eth_Token.decimals), swapToken: dai_Token.address
      })).toString(),
      gbrc: ethers.utils
        .formatUnits(treasuryGbrc, gbrc_Token.decimals)
        .toString(),
      gbrcUsd: GetUSDTokenEquivalence(
        data.appState,
        bignumber(ethers.utils.formatUnits(treasuryGbrc, gbrc_Token.decimals)),
        gbrc_Token.symbol
      ),
    };
  } catch (err) {
    console.log(err);
  }
};

//get all gBRC price data from sushiswap

// get gBRC price from sushiswap
export const getGBRCPriceFeed = async (rootState: RootState) => {
  let result = {
    currentPrice: 0,
    change24hrPercent: 0,
    change7dPercent: 0,
  };

  try {
    // dayData(where: {date_lge: 0}) {
    //     date
    //     reserve0
    //     reserve1
    // }
    // hourData(where: {date_lge: 0}){
    //     date
    //     reserve0
    //     reserve1
    // }
    const tokensQuery = `
            {pairs(where: {id: "${SUSHI_BRC_GBRC_PAIR_ARBITRUM_ADDRESS}"}) {
                id
                token0 {
                    symbol
                }
                token1 {
                    symbol
                }
                reserve0
                reserve1
                reserveETH
                }
            }
        `;

    const client = createClient({
      url: URL.SUSHI_SUBGRAPH_ARBITRUM,
    });

    const data = await client.query(tokensQuery).toPromise();
    // .then(data => {
    // console.log('graphql current', data);
    if (data.data.pairs.length < 0) return result;
    const gbrcPrice =
      Number(data.data.pairs[0].reserve1) / Number(data.data.pairs[0].reserve0);
    // console.log(gbrcPrice);

    result.currentPrice = Number(
      GetUSDTokenEquivalence(
        rootState,
        bignumber(gbrcPrice.toString()),
        brc_Token.symbol
      )
    );
    return result;

    // }).catch(err => {
    //     console.log(err);
    //     return result;
    // });

    /*
        const offset = 90 * 1000; // time allowance for block indexing
        return sushiData.exchange.pair({
            pair_address: BRC_gBRC_PAIR_SUSHISWAP,
            timestamp: Date.now() - offset
        })
        .then(data => {
            if(data){
                const usdCurrent = GetUSDTokenEquivalence(rootState, bignumber(data.token1Price.toString()), brc_Token.symbol);
                
                return sushiData.exchange.pair({
                    pair_address: BRC_gBRC_PAIR_SUSHISWAP,
                    timestamp: Date.now() - (24 * 60 * 60 * 1000) - offset
                })
                .then(data24hr => {
                    if(data24hr){
                        console.log({currentPrice: data.token1Price, price24hr: data24hr.token1Price});
                        const usd24 = GetUSDTokenEquivalence(rootState, bignumber(data24hr.token1Price.toString()), brc_Token.symbol);
                        return sushiData.exchange.pair({
                            pair_address: BRC_gBRC_PAIR_SUSHISWAP,
                            timestamp: Date.now() - (7 * 24 * 60 * 60 * 1000) - offset
                        })
                        .then(data7d => {
                            const usd7d = GetUSDTokenEquivalence(rootState, bignumber(data7d.token1Price.toString()), brc_Token.symbol);
                            
                            if(data7d){
                                
                                return {
                                    currentPrice: Number(usdCurrent),
                                    change24hrPercent: Number(usd24),
                                    change7dPercent: Number(usd7d),
                                };
                            }
                        }).catch(error => {
                            console.log('7d gbrc',error);
                            return {
                                currentPrice: Number(usdCurrent),
                                change24hrPercent: Number(usd24),
                                change7dPercent: 0,
                            };
                        })
                    }
                }).catch(error => {
                    console.log('24h gbrc',error);
                    return {
                        currentPrice: Number(usdCurrent),
                        change24hrPercent: 0,
                        change7dPercent: 0,
                    };
                })
            }
        }).catch(error => {
            console.log('root', error);
            return {
                currentPrice: 0,
                change24hrPercent: 0,
                change7dPercent: 0,
            };
        })*/
  } catch (error) {
    // console.log('getGBRCPriceFeed', error);
    return result;
  }
};

// get BRC price changes
export const getBRCPriceData__24hr__7d = async () => {
  const offset = 60 * 1000; // time allowance for block indexing
  const dater = new EthDater(web3);

  var yesterdayTime = Date.now() - 24 * 60 * 60 * 1000 - offset;
  var lastWeekTime = Date.now() - 7 * 24 * 60 * 60 * 1000 - offset;

  const empty = {
    change24hrPercent: 0,
    change7dPercent: 0,
  };

  return dater
    .getDate(
      yesterdayTime, // Date, required. Any valid moment.js value: string, milliseconds, Date() object, moment() object.
      true // Block after, optional. Search for the nearest block before or after the given date. By default true.
    )
    .then((block24hr) => {
      // console.log('block 24 hr: ', block24hr);

      if (block24hr && block24hr.block > 0) {
        return APICall("/tx/block/" + block24hr.block, HTTP_METHOD.GET, true)
          .then((result) => {
            // console.log('24 hr price', result);

            if (result && result.Items.length > 0) {
              const price24hr = Number(result.Items[0].daiCost);

              return dater
                .getDate(
                  lastWeekTime, // Date, required. Any valid moment.js value: string, milliseconds, Date() object, moment() object.
                  true // Block after, optional. Search for the nearest block before or after the given date. By default true.
                )
                .then((block7d) => {
                  if (block7d && block7d.block > 0) {
                    return APICall(
                      "/tx/block/" + block7d.block,
                      HTTP_METHOD.GET,
                      true
                    )
                      .then((result) => {
                        console.log("7 d price", result);
                        if (result && result.Items.length > 0) {
                          const price7d = Number(result.Items[0].daiCost);
                          console.log("both", {
                            change24hrPercent: price24hr,
                            change7dPercent: price7d,
                          });

                          return {
                            change24hrPercent: price24hr,
                            change7dPercent: price7d,
                          };
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                        return {
                          change24hrPercent: price24hr,
                          change7dPercent: 0,
                        };
                      });
                  } else {
                    return {
                      change24hrPercent: price24hr,
                      change7dPercent: 0,
                    };
                  }
                })
                .catch((err) => console.log(err));
            } else {
              return empty;
            }
          })
          .catch((err) => {
            console.log(err);
            return empty;
          });
      }
    });
};

// get all brc prices for chart
export const getChartBRCPrices = async () => {
  const chainId =
    Number(window.localStorage.getItem("LOCAL_CHAIN_ID")) ||
    Number(AppNetworks.ARBITRUM_ONE);
  const isTestnet = chainId === Number(AppNetworks.ARBITRUM_ONE) ? 0 : 1;

  const result = await APICall("/tx/all/" + isTestnet, HTTP_METHOD.GET, true);
  // .then(result => {
  if (result && result.Items.length > 0) {
    const data = result.Items;
    data.sort((a, b) => Number(b.blockNumber) - Number(a.blockNumber)); // DESC

    let refinedData = [];
    // let _blockNumber = Number(currentBlock); // used to fill up unchanged price values for  block numbers between price change events
    // console.log({currentBlock});
    let _timestamp = Number.parseInt((Date.now() / 1000).toString());

    while (_timestamp > data[0].timestamp) {
      const _dataExists = refinedData.find(
        // eslint-disable-next-line no-loop-func
        (a) => a.name.toString() === _timestamp.toString()
      );
      if (!_dataExists) {
        refinedData.push({
          name: _timestamp,
          // label: `${new Date(Number(data[i].timestamp) * 1000).toLocaleDateString(undefined, {day: 'numeric', month: 'numeric'})} (#${_blockNumber})`,
          label: `${new Date(Number(_timestamp) * 1000).toLocaleDateString(
            undefined,
            { day: "numeric", month: "short", year: "2-digit" }
          )} (# N/A)`,
          BRC: data[0]?.daiCost ? Number(data[0]?.daiCost) : 0, // use existing price (+1 because data is sorted DESC)
        });
      }
      _timestamp = _timestamp - 60 * 60 * 0.85; // ~60mins interval
    }

    for (let i = 0; i < data.length; i++) {
      let flag = false;
      const dataBlockNumber = Number(data[i].blockNumber);
      // while(_blockNumber > dataBlockNumber){
      //     const _dataExists = refinedData.find(a => a.name.toLowerCase() === _blockNumber.toString().toLowerCase());
      //     if(!_dataExists){
      //         refinedData.push({
      //             name: _blockNumber.toString(),
      //             // label: `${new Date(Number(data[i].timestamp) * 1000).toLocaleDateString(undefined, {day: 'numeric', month: 'numeric'})} (#${_blockNumber})`,
      //             label: `(#${_blockNumber})`,
      //             BRC: data[i+1]?.daiCost ? Number(data[i+1]?.daiCost) : 0, // use existing price (+1 because data is sorted DESC)
      //             // gBRC: 0
      //         })
      //     }
      //     flag = true;
      //     _blockNumber = _blockNumber - 60 * 60 * 0.85; // ~60mins interval
      // }
      const dataExists = refinedData.find(
        (a) => a.name.toString() === data[i].timestamp.toString()
      );

      if (!flag && !dataExists && Number(data[i].daiCost) > 0) {
        // const block = await web3.eth.getBlock(dataBlockNumber);
        // console.log('data', data[i]);

        refinedData.push({
          name: data[i].timestamp,
          label: `${new Date(
            Number(data[i].timestamp) * 1000
          ).toLocaleDateString(undefined, {
            day: "numeric",
            month: "short",
            year: "2-digit",
          })} (#${dataBlockNumber})`,
          // label: `(#${dataBlockNumber})`,
          BRC: data[i]?.daiCost ? Number(data[i]?.daiCost) : 0,
          // gBRC: 0
        });
      }
    }

    // refinedData.sort((a,b) =>  Number(b.name) - Number(a.name)); // DESC

    // console.log('refined data', refinedData);
    return refinedData;
  }
};
