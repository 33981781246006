import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../store/themeContext/themeContext";
import useStyles from "./confirmationStyles";
import PopupModal from "../../popupModal/popupModal";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionMark, InfoIcon, Dot, Chevron, ICONS } from "../../icons/icons";
import FillButton from "../../button/button";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AirdropType, brc_Token, gbrc_Token, StakePeriod, StakeTXType, STAKING_CONTRACT, TX_STEP, TOKEN_ACTION, WithdrawTime, WithdrawTXType } from "../../../constants";
import { BigNumber } from "@ethersproject/bignumber";
import { hasEnoughAllowance } from "../../../store/services/approvals";
import { bignumber } from "mathjs";
import { RootState } from "../../../store/mystore";
import { useSelector } from "react-redux";
import { handleNumberDisplay } from "../../../utils/utils";
// import { BigNumber } from "@ethersproject/bignumber";

export interface Props {
	closeModal: () => void;
	confirmationAction: () => void;
	step: TX_STEP;
	transactionType: TOKEN_ACTION;
	stakePeriod?: StakePeriod;

	// SWAP
	sellToken?: string;
	sellAmount?: number;
	buyAmount?: number;
	buyToken?: string;

	// STAKE
	stakeType?: StakeTXType;
	stakeSecondaryToken?: string;
	stakeSecondaryTokenAmount?: number;
	stakePrimaryTokenAmount?: number;
	stakePrimaryToken?: string;

	// WITHDRAW
	withdrawType?: WithdrawTXType;
	withdrawTime?: WithdrawTime;
	withdrawSecondaryToken?: string;
	withdrawSecondaryTokenAmount?: string | BigNumber;
	withdrawPrimaryTokenAmount?: string | BigNumber;
	withdrawPrimaryToken?: string;

	// CLAIM
	airdropType?: AirdropType;
	claimAmount?: number;

	// NFT
	whitelistToken?: string;
	whitelistTokenAmount?: string | BigNumber;
}

function Confirmation(props: Props) {
	const { theme } = useContext(ThemeContext);
	const state = useSelector((state: RootState) => state);
	const [sgbrcEnough, setGgbrcEnough] = useState<boolean>(false);

	const classes = useStyles({ ...props, ...theme });

	let label = "";
	let heading = "";
	let received = "";
	let maxSold = "";	
	let claimTitle = '';

	if(props.step <= 3){ // SWAP functions
		label = props.sellToken === brc_Token.symbol ? "Sell Now" : "Buy Now";
		heading = props.sellToken === brc_Token.symbol ? "Complete your sale" : "Review your transaction";
		received = props.buyAmount ? props.buyAmount?.toString() : "0.00";
		maxSold = props.buyAmount ? props.sellAmount?.toString() : "0.00";
	}
	else if(props.step === TX_STEP.STAKE_OR_WITHDRAW && props.transactionType === TOKEN_ACTION.STAKE && props.stakeType !== StakeTXType.NOT_SET){ // STAKE functions
		label = props.stakeType === StakeTXType.BRC_ONLY ? `Stake ${props.stakePrimaryToken} Now` : `Stake  ${props.stakePrimaryToken} + ${props.stakeSecondaryToken} Now`;
		heading = "Complete your Stake";
	}
	else if(props.step === TX_STEP.STAKE_OR_WITHDRAW && props.transactionType === TOKEN_ACTION.WITHDRAW && props.withdrawType !== WithdrawTXType.NOT_SET){ // WITHDRAW functions
		label = `${props.withdrawTime === WithdrawTime.EMERGENCY ? "" : ""} ${props.withdrawType === WithdrawTXType.BRC_ONLY ? "Withdraw BRC Now" : "Withdraw gBRC + BRC Now"}`;
		heading = `Complete your ${props.withdrawTime === WithdrawTime.EMERGENCY ? "Emergency" : ""} Withdrawal`;
	}
	else if(props.step === TX_STEP.CLAIM ){ 
		label = "Claim Airdrop(s) Now";
		heading = `Complete your Claim`;
		// set airdrops titles
		if(props.airdropType === AirdropType.aETH_HOLDERS ){
			claimTitle = "Arbitrum aETH Holders Airdrop";
		}else if(props.airdropType === AirdropType.SOCIAL_MEDIA ){
			claimTitle = "Social Media Airdrop";
		}else if(props.airdropType === AirdropType.aDAI_HOLDERS ){
			claimTitle = "Arbitrum DAI Holders Airdrop";
		}
	}
	else if(props.step === TX_STEP.NFT_WHITELIST ){ 
		label = "Purchase Whitelist Spot Now";
		heading = `Complete your Whitelist Purchase`;
	}

	// separate resolution is done for BRC gBRC icons because the colours change somehow when gotten from the ICONS {}
	const OtherBuyToken = ICONS[props.buyToken];
	const OtherSellToken = ICONS[props.sellToken];

	const BRC = <img style={{width: "21px"}} className={classes.brincIcon} src={require("../../../media/tokens/BRQ.svg").default} alt=""/>;
	const GBRC = <img style={{width: "21px"}} className={classes.brincIcon} src={require("../../../media/tokens/gbrc.svg").default} alt=""/>;

	useEffect(() => {
		// check sgbrc approval (and approve)
		if(props.withdrawType && props.withdrawSecondaryTokenAmount){
			hasEnoughAllowance(state.application.contracts.sgbrcContract, STAKING_CONTRACT, state.wallet.address, props.withdrawSecondaryTokenAmount ? bignumber(props.withdrawSecondaryTokenAmount.toString()) : bignumber(0), 1)
			.then(isEnough => {
			if(isEnough){
				setGgbrcEnough(true);
			}
			}).catch(err => console.log(err));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<PopupModal closeModal={props.closeModal}>
			<section className="pop__md__w pop__wrap">
				<header className="pop__head" style={{display: "flex", justifyContent:"space-between", hyphens: "auto", wordBreak: "break-word"}}>
					<h2 className={classes.heading}>{heading}</h2>
					<button type="button" className="pop__close" onClick={props.closeModal}>
						<span className="close"><img src={require("../../../media/icons/ico-close.svg").default} alt=""/></span>
					</button>
				</header>
				<main className="pop__body">
					<section className="" style={{marginTop:"0.5rem"}}>
						{/* <h2 className="tit__md">{props.step === TX_STEP.CLAIM ? 'Eligible Airdop Claim Details' : 'Transaction Details:'}</h2> */}
						
						{/* NFT WHITELIST */}
						{props.transactionType === TOKEN_ACTION.NFT_WHITELIST ?
							<ul className="alignment__list">
								<li className="alignment__list__item">
									<div className="alignment__box">
										<span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span>
										<p className="alignment__txt">
											<span>Whitelist Spot Fee:</span>
										</p>
									</div>
									<div className="alignment__box">
										<p className="alignment__txt">
											<span className="num">{handleNumberDisplay(props.whitelistTokenAmount.toString(), true, 2)}</span>
											<span>{props.whitelistToken}</span>
										</p>
										<span className="alignment__ico">
											{GBRC}
										</span>
									</div>
								</li>
								
								<li className="alignment__line"></li>
							</ul>:null
						}

						{/* CLAIM */}
						{props.transactionType === TOKEN_ACTION.CLAIM ?
							<ul className="alignment__list">
								<li className="alignment__list__item">
									<div className="alignment__box">
										<span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span>
										<p className="alignment__txt">
											<span>{claimTitle}:</span>
										</p>
									</div>
									<div className="alignment__box">
										<p className="alignment__txt">
											<span className="num">{handleNumberDisplay(props.claimAmount, true, 2)}</span>
											<span>{props.buyToken}</span>
										</p>
										<span className="alignment__ico">
											{GBRC}
										</span>
									</div>
								</li>
								
								<li className="alignment__line"></li>
							</ul>:null
						}
						
						{/* SWAP */}
						{props.step <= TX_STEP.MINT_OR_BURN ?
							<ul className="alignment__list">
								<li className="alignment__list__item">
									<div className="alignment__box">
										{/* <span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span> */}
										<p className="alignment__txt">
											<span>Buy:</span>
										</p>
									</div>
									<div className="alignment__box">
										<p className="alignment__txt">
											<span className="num">{handleNumberDisplay(received, true, 3)}</span>
											<span>{props.buyToken}</span>
										</p>
										<span className="alignment__ico">
											{props.transactionType === TOKEN_ACTION.BUY ? <>{BRC}</> : <OtherBuyToken />}
										</span>
									</div>
								</li>
								<li className="alignment__list__item">
									<div className="alignment__box">
										<p className="alignment__txt">
											<span>Pay:</span>
										</p>
									</div>
									<div className="alignment__box">
										<p className="alignment__txt">
											<span className="num">{handleNumberDisplay(maxSold, true, 3)}</span>
											<span>{props.sellToken}</span>
										</p>
										<span className="alignment__ico">
											{props.transactionType === TOKEN_ACTION.SELL ? <>{BRC}</> : <OtherSellToken />}
										</span>
									</div>
								</li>
								{/* <li className="alignment__list__item">
									<div className="alignment__box">
										<span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span>
										<p className="alignment__txt">
											<span>{props.transactionType === TOKEN_ACTION.BUY ? "Mint" : "Burn"} fee (9.9%):</span>
										</p>
									</div>
									<div className="alignment__box">
										<p className="alignment__txt">
											<span className="num">ALREADY DEDUCTED</span> */}
											{/* <span className="num">{handleNumberDisplay(received, true, 3)}</span>
											<span>{props.buyToken}</span> */}
										{/* </p> */}
										{/* <span className="alignment__ico">
											{props.transactionType === TOKEN_ACTION.BUY ? <>{BRC}</> : <OtherBuyToken />}
										</span> */}
									{/* </div>
								</li> */}
								<li className="alignment__line"></li>
							</ul>:null
						}

						{props.step === TX_STEP.STAKE_OR_WITHDRAW && 
							(<ul className="alignment__list">
									<li className="alignment__list__item">
										<div className="alignment__box">
											<p className="alignment__txt">
												<span>{`${props.transactionType === TOKEN_ACTION.STAKE ? props.stakePrimaryToken : props.withdrawPrimaryToken}`} Amount</span>
											</p>
										</div>
										<div className="alignment__box">
											<p className="alignment__txt">
												<span className="num">{`${props.transactionType === TOKEN_ACTION.STAKE ? handleNumberDisplay(props.stakePrimaryTokenAmount, true, 2) : handleNumberDisplay(props.withdrawPrimaryTokenAmount.toString(), true, 2)}`} </span>
												<span>{`${props.transactionType === TOKEN_ACTION.STAKE ? props.stakePrimaryToken : props.withdrawPrimaryToken}`}</span>
											</p>
											<span className="alignment__ico">{BRC}</span>
										</div>
									</li>
									{(props.stakeType === StakeTXType.gBRC_AND_BRC || props.withdrawType === WithdrawTXType.gBRC_AND_BRC) && 
										(<li className="alignment__list__item">
											<div className="alignment__box">
												<p className="alignment__txt">
													<span>{`${props.transactionType === TOKEN_ACTION.STAKE ? props.stakeSecondaryToken : props.withdrawSecondaryToken}`} Amount</span>
												</p>
											</div>
											<div className="alignment__box">
												<p className="alignment__txt">
													<span className="num">{`${props.transactionType === TOKEN_ACTION.STAKE ? handleNumberDisplay(props.stakeSecondaryTokenAmount, true, 2) : handleNumberDisplay(props.withdrawSecondaryTokenAmount.toString(), true, 2)}`}</span>
													<span>{`${props.transactionType === TOKEN_ACTION.STAKE ? props.stakeSecondaryToken : props.withdrawSecondaryToken}`}</span>
												</p>
												<span className="alignment__ico">{GBRC}</span>
											</div>
										</li>)
									}
									<li className="alignment__list__item">
										<div className="alignment__box">
											<p className="alignment__txt">
												<span>Stake Period:</span>
											</p>
										</div>
										<div className="alignment__box">
											<p className="alignment__txt">
												<span className="num">{`${props.stakePeriod}`}</span> DAYS
												{/* <span>{`${props.transactionType === TX_TYPE.STAKE ? props.stakePrimaryToken : props.withdrawPrimaryToken}`}</span> */}
											</p>
											<span className="alignment__ico"><img width={'24px'} src={require("../../../media/icons/ico-time.svg").default} alt="stake period"/></span>
										</div>
									</li>
									<li className="alignment__line"></li>
								</ul>
								
							)
						}
						<article>
							{!sgbrcEnough && props.transactionType === TOKEN_ACTION.WITHDRAW ?
							<h2 className="tit__md" style={{marginBottom: "1rem"}}>
								You will be required to APPROVE your sGBRC tokens (ONE-TIME) for successful withdraws
							</h2>:null}
							<h2 className="tit__md">
                Make sure your wallet gas fee settings are at “Market" or higher
							</h2>
							{/* <ul className="explanation__list__box">
								<li className={`explanation__list__item ${classes.bulletPoints}`}>
									<span className="ico__point"><img className={classes.dot} src={require("../../../media/icons/ico-Point.svg").default} alt=""/></span>
									<span className={`${classes.infoText} cont__txt`}>Ensure the minimum gas limit (gwei) is at or higher than <a href="">average gwei.</a></span>
								</li>
								<li className={`explanation__list__item ${classes.bulletPoints}`}>
									<span className="ico__point"><img className={classes.dot} src={require("../../../media/icons/ico-Point.svg").default} alt=""/></span>
									<span className={`${classes.infoText} cont__txt`}>Raise the gas limit (gwei) to speed up transactions if the transaction is taking too long to process</span>
								</li> */}
								{/* {props.step !== TX_STEP.STAKE_OR_WITHDRAW &&
								<li className={`explanation__list__item ${classes.bulletPoints}`}>
									<span className="ico__point"><img className={classes.dot} src={require("../../../media/icons/ico-Point.svg").default} alt=""/></span>
									<span className={`${classes.infoText} cont__txt`} >Increase slippage rate before confirming this transaction</span>
								</li>
								} */}
							{/* </ul> */}
						</article>
					</section>
				</main>
				<footer className="pop__foot" style={{marginTop: "5rem"}}>
					<FillButton
						label={label} 
						clickFunction={props.confirmationAction} 
						className={classes.actionButton}
					/>
				</footer>
			</section>
		</PopupModal>

	);
}

export default React.memo(Confirmation);
