import { brc_Token, dai_Token, gbrc_Token } from "../../constants";
import BRC from "../../media/tokens/BRQ.svg";
import gBRC from "../../media/tokens/gbrc.svg";
import dai from "../../media/tokens/dai.svg";

declare let window: any;

export async function addTokenToWallet() {
  if (window.ethereum) {
    const host = window.location.origin;

    const brcImage = `${host}${BRC}`;
    const gBrcImage = `${host}${gBRC}`;
    const daiImage = `${host}${dai}`;

    try {
      //dai
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: dai_Token.address, // The address that the token is at.
            symbol: dai_Token.symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: dai_Token.decimals, // The number of decimals in the token
            image: daiImage, // A string url of the token logo
          },
        },
      });

      //brc
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: brc_Token.address, // The address that the token is at.
            symbol: brc_Token.symbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: brc_Token.decimals, // The number of decimals in the token
            image: brcImage, // A string url of the token logo
          },
        },
      });

      //gbrc
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: gbrc_Token.address,
            symbol: gbrc_Token.symbol,
            decimals: gbrc_Token.decimals,
            image: gBrcImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}
