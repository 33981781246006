import { ReactComponent as Dai } from "../../media/tokens/dai.svg";
import { ReactComponent as ETH } from "../../media/tokens/eth_ico.svg";
import { ReactComponent as USDC } from "../../media/tokens/USDC.svg";
import { ReactComponent as WBTC } from "../../media/tokens/wBTC.svg";
import BRC_Gif from "../../media/gif/icon.gif";
import GBRC_Gif from "../../media/gif/ico-gbrc.gif";
import { ReactComponent as BRC } from "../../media/tokens/BRQ.svg";
import { ReactComponent as GBRC } from "../../media/tokens/gbrc.svg";
import { ReactComponent as USDT } from "../../media/tokens/USDT.svg";
import { ReactComponent as Spinner } from "../../media/icons/spinner.svg";
import { ReactComponent as Loader } from "../../media/icons/loader.svg";
import { ReactComponent as Success } from "../../media/icons/check_circle_regular.svg";
import { ReactComponent as Fail } from "../../media/icons/times_circle_solid.svg";
import { ReactComponent as InfoIcon } from "../../media/icons/info.svg";
import { ReactComponent as NavRight } from "../../media/icons/chevron_breadcrumb.svg";
import { ReactComponent as QuestionMark } from "../../media/icons/question.svg";
import { ReactComponent as QuestionMarkOutline } from "../../media/icons/ico-help-circle.svg";
import { ReactComponent as Switch } from "../../media/icons/switch.svg";
import { ReactComponent as Close } from "../../media/icons/close.svg";
import { ReactComponent as ExternalLink } from "../../media/icons/ico-external-link.svg";
import { ReactComponent as Dot } from "../../media/icons/dot.svg";
import { ReactComponent as Check } from "../../media/icons/check.svg";
import { ReactComponent as Chevron } from "../../media/icons/chevron.svg";
import { ReactComponent as Triangle } from "../../media/icons/triangle.svg";
import { ReactComponent as WalletBlack } from "../../media/icons/ico-wallet-black.svg";
import { ReactComponent as Wallet } from "../../media/icons/ico-wallet.svg";
import { ReactComponent as Youtube } from "../../media/icons/ico-youtube.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { brc_Token, gbrc_Token, dai_Token, usdc_Token, usdt_Token, wbtc_Token, eth_Token, ust_Token } from "../../constants";

export const ICONS = {
  [dai_Token.symbol]: Dai,
  [eth_Token.symbol]: ETH,
  [usdc_Token.symbol]: USDC,
  [wbtc_Token.symbol]: WBTC,
  [brc_Token.symbol]: BRC,
  [usdt_Token.symbol]: USDT,
  [gbrc_Token.symbol]: GBRC,
  [gbrc_Token.symbol + '_Gif']: GBRC_Gif,
  [brc_Token.symbol + '_Gif']: BRC_Gif,
  QuestionMark,
  NavRight,
  Wallet,
  WalletBlack,
  QuestionMarkOutline,
  Youtube
};

export {
  Dai,
  ETH,
  USDC,
  WBTC,
  BRC,
  GBRC,
  USDT,
  Spinner,
  Loader,
  Success,
  Fail,
  InfoIcon,
  NavRight,
  QuestionMark,
  Switch,
  Close,
  ExternalLink,
  Dot,
  Check,
  Chevron,
  Triangle,
  Wallet,
  WalletBlack
};
