import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./displayBalanceStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/mystore";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { handleNumberDisplay, noExponents } from "../../utils/utils";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AboveMobile, BelowDesktop, Desktop, Mobile } from "../screenHelper";
import { DefaultToken } from "../../constants";

export interface Props {
  token: string;
}

function DisplayBalance(props: Props) {
  const state = useSelector((state: RootState) => state);
  const { theme } = useContext(ThemeContext);
  const classes = useStyles({ ...props, ...theme });
  const { token } = props;
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if(token !== DefaultToken.symbol){
      setBalance(state.wallet.balances[`${token.toLowerCase()}Balance`]);
    }    
  }, [token, state.wallet.balances]);

  return (
    <div className={classes.DisplayBalance}>
      {/* <Desktop>
        <p className={classes.text}>
          Balance: <span>
            ~ {balance > 0 ? handleNumberDisplay(balance, true, 0) : '0.00'}
          </span>{" "}
        </p>
      </Desktop>
      <BelowDesktop> */}
        <p className={`${classes.text} colouredFade`}>
          Balance: <span>
            ~ {balance > 0 ? handleNumberDisplay(balance, true, 0) : '0.00'}
          </span>{" "}
        </p>
      {/* </BelowDesktop> */}
      
    </div>
  );
}

export default React.memo(DisplayBalance);
