/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { ThemeContext } from "../../store/themeContext/themeContext";
import Header from "../../containers/header/header";
import { useWeb3React } from "@web3-react/core";
import { useToggle } from "../../hooks/useToggle";
import Footer from "../../containers/footer/footer";
import AlertUser from "../../components/dialogueBoxes/alertUser/alertUser";
import { useInactiveListener } from "../../store/helpers/walletEventHooks";
import { RootState, useAppDispatch } from "../../store/mystore";
import {
  areContractsLoaded,
  GetWeb3,
} from "../../store/helpers/contractBooter";
import { IAppContractsNonNullable } from "../../store/application/AppSlice";
import CLAIM_WHITELIST_DATA from "./whitelist.json";
import {
  StakeMode,
  UINT_256_MAX,
  sgbrc_Token,
  BIGNUMBER_CONFIG,
  SUPPORTED_CHAINS,
  dai_Token,
  HTTP_METHOD,
  teamWhiteList,
} from "../../constants";
import {
  MuiGrid,
  MuiPaper,
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableContainer,
  MuiTableFooter,
  MuiTableHead,
  MuiTableRow,
} from "../../components/materialUIHelper";
import "./claim.scss";
import { WithdrawPayload } from "../../store/withdraw/Withdraw";

import math, { all, bignumber, create } from "mathjs";
import FillButton from "../../components/button/button";
import Countdown from "react-countdown";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { handleNumberDisplay } from "../../utils/utils";
import Web3 from "web3";
import APICall from "../../utils/apiCall";
// import {recoverTypedSignature, SignTypedDataVersion,
// } from '@metamask/eth-sig-util';
// import { getAllStakesAsync } from "../../store/stake/StakeSlice";

const _math = create(all, BIGNUMBER_CONFIG);

export interface Props {}

function Claim(props: Props) {
  const Grid = MuiGrid;

  const { theme } = useContext(ThemeContext);
  const state = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const [walletConnected, setWalletConnected] = useState<boolean>(
    state.wallet.isConnected
  );
  const [contracts, setContracts] = useState<IAppContractsNonNullable>(
    state.application.contracts
  );
  const [showWalletModal, setShowWalletModal, toggleWalletModal] =
    useToggle(false);
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const { connector, active, library, chainId, account } = useWeb3React();
  const [disableConnectButton, setDisableConnectButton] = useState(false);
  const [showNetworkAlert, setShowNetworkAlert] = useState<boolean>(false);
  const [expiry, setExpiry] = useState<boolean>(false);
  const [stakedAmount, setStakedAmount] = useState<number>(0);
  const [isEligible, setIsEligible] = useState(false);
  const [signedData, setSignedData] = useState<string>("");
  const [agreementStatus, setAgreementStatus] = useState(false);
  const [claims, setClaims] = useState([]);
  const [totalClaims, setTotalClaims] = useState(0);

  const chainIdIsCorrect = chainId && SUPPORTED_CHAINS.includes(chainId);
  const expireAt = 1670371199000;

  if (!chainIdIsCorrect && !showNetworkAlert) {
    setShowNetworkAlert(true);
  }

  useInactiveListener();

  const isEligibleForClaim = () => {
    const claimData = CLAIM_WHITELIST_DATA.data.find(
      (x) => x.address.toLowerCase() === account.toLowerCase()
    );
    if (account && claimData) {
      setStakedAmount(claimData.amount);
      return true;
    } else {
      return false;
    }
  };

  const checkClaimed = () => {
    APICall("/claim/" + account, HTTP_METHOD.GET, true).then((result) => {
      if (result && result.account.length > 0) {
        setSignedData(result.signedTypedData);
      } else {
        setSignedData("");
      }
    });
  };

  const allClaims = () => {
    APICall("/claim/all", HTTP_METHOD.GET, true).then((result) => {
      console.log(result);
      if (result && result.ScannedCount > 0) {
        setClaims(result.Items);
        let _total = 0;

        for (let i = 0; i < result.Items.length; i++) {
          const claimAccount = result.Items[i].account;
          _total +=
            CLAIM_WHITELIST_DATA.data.find(
              (x) => x.address.toLowerCase() === claimAccount.toLowerCase()
            ).amount / 10;
        }

        setTotalClaims(_total);
      }
    });
  };

  const userPrompt = walletConnected
    ? !chainIdIsCorrect
      ? "Unsupported Network"
      : signedData.length > 0
      ? "Your Claim has been received"
      : expiry
      ? "Expired!"
      : !isEligible
      ? "Address is INELIGIBLE for Claim"
      : !agreementStatus
      ? 'You have to select "I Agree" box'
      : ""
    : "Connect Wallet";

  useEffect(() => {
    if (isEligibleForClaim()) {
      setIsEligible(true);
    }
  }, [account]);

  useEffect(() => {
    if (isEligibleForClaim()) {
      setIsEligible(true);
    }

    if (Date.now() > expireAt) {
      setExpiry(true);
    }

    checkClaimed();
    allClaims();

    // every 5seconds
    const interval_id = setInterval(blockIntervalJob, 8000);
    return () => {
      // Stop the interval when the component unmounts.
      // Otherwise it will keeep going and you will get an error.
      clearInterval(interval_id);
    };
  }, []);

  const blockIntervalJob = () => {};

  useEffect(() => {
    if (
      !areContractsLoaded(contracts) &&
      areContractsLoaded(state.application.contracts)
    ) {
      setContracts(state.application.contracts);
    }
  }, [contracts, state.application.contracts]);

  // handles logic for wallet connect modal.
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
    active && setShowWalletModal(false);
    if (active) {
    }
    setWalletConnected(state.wallet.isConnected);
  }, [
    activatingConnector,
    connector,
    active,
    setShowWalletModal,
    state.wallet.isConnected,
  ]);

  const claim = async () => {
    const msgParams = JSON.stringify({
      domain: {
        // Defining the chain aka Rinkeby testnet or Ethereum Main Net
        chainId: chainId,
        // Give a user friendly name to the specific contract you are signing for.
        name: "Brinc.fi Staking",
        // If name isn't enough add verifying contract to make sure you are establishing contracts with the proper entity
        verifyingContract: "0x9A28f7Ab9aEb4f14Fc4c580938F8F5E89ce98084",
        // Just let's you know the latest version. Definitely make sure the field name is correct.
        version: "1",
      },

      // Defining the message signing data content.
      message: {
        /*
         - Anything you want. Just a JSON Blob that encodes the data you want to send
         - No required fields
         - This is DApp Specific
         - Be as explicit as possible when building out the message schema.
        */
        contents: `BY SIGNING, YOU AGREE TO CLAIM ONLY 10% [${
          stakedAmount / 10
        }DAI] OF YOUR STAKED VALUE AND GIVE UP ANY FUTURE CLAIMS. NOTE: SIGNING DOESN'T REQUIRE GAS.`,
        attachedMoneyInEth: 0,
        from: {
          name: "Staker (You)",
          wallets: [account],
        },
        to: [
          {
            name: "Brinc Finance",
            wallets: ["0x9A28f7Ab9aEb4f14Fc4c580938F8F5E89ce98084"],
          },
        ],
      },
      // Refers to the keys of the *types* object below.
      primaryType: "Mail",
      types: {
        // TODO: Clarify if EIP712Domain refers to the domain the contract is hosted on
        EIP712Domain: [
          { name: "name", type: "string" },
          { name: "version", type: "string" },
          { name: "chainId", type: "uint256" },
          { name: "verifyingContract", type: "address" },
        ],
        // Not an EIP712Domain definition
        Group: [
          { name: "name", type: "string" },
          { name: "members", type: "Person[]" },
        ],
        // Refer to PrimaryType
        Mail: [
          { name: "from", type: "Person" },
          { name: "to", type: "Person[]" },
          { name: "contents", type: "string" },
        ],
        // Not an EIP712Domain definition
        Person: [
          { name: "name", type: "string" },
          { name: "wallets", type: "address[]" },
        ],
      },
    });

    var from = account;
    var params = [from, msgParams];
    var method = "eth_signTypedData_v4";

    (GetWeb3(chainId).currentProvider as any).sendAsync(
      {
        method,
        params,
        from: from,
      },
      function (err, result) {
        if (err) return console.dir(err);
        if (result.error) {
          alert(result.error.message);
        }
        if (result.error) return console.error("ERROR", result);
        console.log("TYPED SIGNED:" + JSON.stringify(result.result));

        // TYPED SIGNED:"0xecd0a3b9bcbfa91e0bd481f7f96796c93ee6fccfe65441b9544b1e8ddd6d0f9a05ed3fac122a14e5ac5e1007ed2fc315b93436cbc11671d91cd62a94954d647b1b"

        const data = {
          account,
          signedTypedData: result.result.toString(),
          timestamp: Date.now(),
        };

        APICall("/claim/add", HTTP_METHOD.PUT, true, data)
          .then((result) => {
            alert("Successfully signed claim request.");
          })
          .catch((err) => {
            console.log("add claim", err);
            alert("An Error occured. Please sign again.");
          });

        // const recovered = recoverTypedSignature({
        //   data: JSON.parse(msgParams),
        //   signature: result.result,
        //   version: SignTypedDataVersion.V4
        // });

        // if (
        //   Web3.utils.toChecksumAddress(recovered) ===
        //   Web3.utils.toChecksumAddress(from)
        // ) {
        //   alert("Successfully recovered signer as " + from);
        // } else {
        //   alert(
        //     "Failed to verify signer when comparing " + result + " to " + from
        //   );
        // }
      }
    );
  };

  const countdownDisplay = (days, hours, minutes, seconds) => {
    return (
      <span>
        {days}d : {hours}h : {minutes}m : {seconds}s
      </span>
    );
    // if(days > 0){
    // 	return <span>{days}d</span>
    // }else if(days < 1 && hours > 0){
    // 	return <span>{hours}h:{minutes}m</span>
    // }else if(days < 1 && hours < 1 && minutes > 0){
    // 	return <span>{minutes}m</span>
    // }else{
    // 	return <span>{seconds}s</span>
    // }
  };

  // Renderer callback with condition
  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    // if (!completed) {
    // Render a countdown
    return (
      <>
        <span style={{ marginRight: "1rem", width: "100%" }}>
          {countdownDisplay(days, hours, minutes, seconds)}
        </span>
      </>
    );
    // }else{return null;}
  };

  return (
    <div id="wrap" className={""}>
      {active && !chainIdIsCorrect && showNetworkAlert && (
        <AlertUser closeModal={() => setShowNetworkAlert(false)} />
      )}

      <Header
        disableConnectButton={disableConnectButton}
        showWalletModal={showWalletModal}
        setShowWalletModal={setShowWalletModal}
        toggleWalletModal={toggleWalletModal}
      />

      <div id="main">
        <div className="inner inner__sm claim">
          {areContractsLoaded(contracts) ? (
            <>
              <Grid container spacing={3}>
                {/* {xl:2, lg:2, md: 3, xs: 1} */}
                <Grid
                  item
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    display: "flex",
                    // justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {/* <p>
                    "It is almost a year since the exploit of Brinc funds by
                    suspected former lead developer, Daniel Choi. One of the
                    victims of the incident has asked that they be refunded 10%
                    of the DAI cost of their stolen BRC. This accounts for part
                    of our original Treasury funds on the Ethereum mainnet and
                    we had planned to use these to pay for part of the legal
                    fees in a case against Daniel Choi in the Los Angeles
                    Courts.
                  </p>{" "}
                  <br />
                  <p>
                    <em>
                      As such, those opting to receive the 10% shall waive any
                      further financial claims from Brinc.fi if the case reaches
                      its conclusion. Additionally, wallets that have staked BRC
                      minted prior to the official opening of the Brinc.fi app
                      on 30 November 2021 at 5:25am UTC are excluded from the
                      refund opt-in as these are considered frontrunners".
                    </em>
                  </p>
                  <br /> */}
                  <section className="details">
                    <h1>
                      10% Offering Claim (Optional) <br />
                    </h1>
                    <hr></hr>
                  </section>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <p>
                      To begin the opt-in process, please follow these steps:
                    </p>
                    <br />
                    <ul>
                      <li>1. Connect the wallet used to stake BRC.</li>
                      <li>
                        2. Select “Agree” to waive all further financial claims
                        against Brinc.fi
                      </li>
                      <li>3. Sign the transaction.</li>
                    </ul>
                    <br />
                    <p>
                      <em>
                        This opt-in page will be available until countdown
                        elapses. We will process the refund requests within 1
                        week after the deadline. Refunds will be issued on the
                        Ethereum chain.
                      </em>
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  md={6}
                  lg={6}
                  xl={6}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <section className="details">
                    <h1 style={{ margin: "3.5rem auto" }}>
                      <span>
                        <Countdown
                          date={expireAt}
                          // onStart={}
                          renderer={(prevState) =>
                            countdownRenderer({ ...prevState })
                          }
                          onComplete={() => {
                            setExpiry(true);
                          }}
                        />
                      </span>
                    </h1>
                    <h2>
                      Exploit partial refund and waiver of claims (Optional)
                    </h2>
                    <article style={{ marginTop: "3rem" }}>
                      Initial Stake BRC Worth:{" "}
                      <span>
                        {handleNumberDisplay(stakedAmount, true, 2)}{" "}
                        {dai_Token.symbol}
                      </span>{" "}
                      <br />
                      <em>
                        10% Claim:{" "}
                        <span>
                          {handleNumberDisplay(stakedAmount / 10, true, 3)}{" "}
                          {dai_Token.symbol}
                        </span>
                      </em>{" "}
                      <br />
                      {/* Time Left:{" "}
                      <span>
                        <Countdown
                          date={Date.now() + 1000000000}
                          // onStart={}
                          renderer={(prevState) =>
                            countdownRenderer({ ...prevState })
                          }
                          onComplete={() => {
                            setExpiry(true);
                          }}
                        />
                      </span> */}
                    </article>
                    <article
                      className="claim-disclaimer"
                      style={{ marginTop: "3rem" }}
                    >
                      <FormGroup style={{ marginTop: "1rem" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              style={{
                                width: "45px",
                                height: "45px",
                              }}
                              disabled={
                                !walletConnected ||
                                !chainIdIsCorrect ||
                                !isEligible ||
                                expiry ||
                                signedData.length > 0
                              }
                              onChange={(e) =>
                                setAgreementStatus(e.target.checked)
                              }
                            />
                          }
                          label="** I UNDERSTAND AND AGREE THAT BY SIGNING I CLAIM ONLY 10%
                          OF MY STAKED VALUE AND GIVE UP ANY FUTURE CLAIMS.
                          OTHERWISE I WILL WAIT TILL THE CONCLUSION OF THE LAWSUIT
                          FOR FULL 100% PAYBACK SHOULD IT SUCCEED WITH FUNDS
                          RECOVERY."
                        />
                      </FormGroup>
                    </article>
                  </section>
                  {!walletConnected ||
                  !chainIdIsCorrect ||
                  !isEligible ||
                  !agreementStatus ||
                  expiry ||
                  signedData.length > 0 ? (
                    <div className={"action-button"}>
                      <FillButton
                        clickFunction={toggleWalletModal}
                        icon={!walletConnected ? "WalletBlack" : null}
                        label={userPrompt}
                        disabled={walletConnected ? true : false}
                        pending={false}
                        success={false}
                        fail={false}
                      />
                    </div>
                  ) : (
                    <div className={"action-button"}>
                      <FillButton
                        clickFunction={() => claim()}
                        icon={!walletConnected ? "WalletBlack" : null}
                        label={"Claim 10% Only"}
                        disabled={userPrompt.length > 0}
                        pending={false}
                        success={false}
                        fail={false}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
              <div className="action"></div>

              {teamWhiteList.includes(account) ? (
                <MuiTableContainer
                  component={MuiPaper}
                  // className={`${classes.wrapper}`}
                >
                  <MuiTable
                    className={""}
                    aria-label="stake table"
                    style={{ marginTop: "3rem" }}
                  >
                    <MuiTableHead>
                      <MuiTableRow>
                        <MuiTableCell style={{}}>User</MuiTableCell>
                        <MuiTableCell className="text-right">
                          Claimed Amount
                        </MuiTableCell>
                        <MuiTableCell
                          style={{ width: "300px" }}
                          className="text-right"
                        >
                          Signed At
                        </MuiTableCell>
                      </MuiTableRow>
                    </MuiTableHead>
                    <MuiTableBody>
                      {claims && claims.length > 0
                        ? claims.map((row, index) => (
                            <MuiTableRow key={index}>
                              <MuiTableCell style={{}}>
                                <span style={{}}>{row.account}</span>
                              </MuiTableCell>
                              <MuiTableCell className="text-right">
                                {handleNumberDisplay(
                                  (
                                    CLAIM_WHITELIST_DATA.data.find(
                                      (x) =>
                                        x.address.toLowerCase() ===
                                        row.account.toLowerCase()
                                    ).amount / 10
                                  ).toString(),
                                  true,
                                  3
                                )}
                              </MuiTableCell>

                              <MuiTableCell
                                style={{ width: "300px" }}
                                className="text-right"
                              >
                                {new Date(row.timestamp).toLocaleString()}
                              </MuiTableCell>
                            </MuiTableRow>
                          ))
                        : null}
                    </MuiTableBody>
                    <MuiTableFooter className={`MuiTableFoot-root `}>
                      <MuiTableRow>
                        <MuiTableCell className={`point-txt`}>
                          Total
                        </MuiTableCell>
                        <MuiTableCell className={`point-txt text-right`}>
                          {handleNumberDisplay(totalClaims, true, 2)}
                        </MuiTableCell>
                        <MuiTableCell
                          className={`point-txt text-right`}
                        ></MuiTableCell>
                      </MuiTableRow>
                    </MuiTableFooter>
                  </MuiTable>
                </MuiTableContainer>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default React.memo(Claim);
