import { Contract, ethers } from "ethers";
import { bignumber } from "mathjs";
import { brc_Token, BurnTXType, chainId, TX_STATUS } from "../../constants";
import { noExponents } from "../../utils/utils";
import {
  IBurnTransactionData,
  IAppContractsNonNullable,
} from "../application/AppSlice";
import { GetWeb3 } from "../helpers/contractBooter";
import { approve } from "../services/approvals";
import { IBalances } from "../wallet/WalletSlice";

export interface IBurnState {
  tx: {
    type: BurnTXType;
    status: TX_STATUS;
    data: IBurnTransactionData;
  };
}

export type BurnTokenPayload = {
  readonly tokenInstanceContract: Contract;
  readonly amount: math.BigNumber;
  readonly userAddress: string;
};

export type ApproveBurnPayload = {
  contracts: IAppContractsNonNullable;
  ethAddress: string; //
  amount: math.BigNumber;
  slippage: number;
  balances: IBalances;
};

export const burnBRCToken = async (payload: BurnTokenPayload) => {
  const { tokenInstanceContract, amount, userAddress } = payload;
  let web3 = GetWeb3(chainId);
  let gasPrice = await web3.eth.getGasPrice();
  const amountParsed = ethers.utils.parseUnits(
    noExponents(amount),
    brc_Token.decimals
  );
  return await tokenInstanceContract.methods
    .burn(amountParsed)
    .send({ from: userAddress, gasPrice: gasPrice }); //, type: "0x2"
};

export const approveBurn = async (payload: ApproveBurnPayload) => {
  const { contracts, ethAddress, amount, slippage, balances } = payload;
  return await approve(
    contracts.brcContract,
    contracts.brcContract._address,
    ethAddress,
    bignumber(amount),
    bignumber(balances.brcBalance.toString()),
    slippage
  );
};
