/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./vaultStyles";
import Header from "../../containers/header/header";
import { useWeb3React } from "@web3-react/core";
import { useToggle } from "../../hooks/useToggle";
import Footer from "../../containers/footer/footer";
import AlertUser from "../../components/dialogueBoxes/alertUser/alertUser";
import { useInactiveListener } from "../../store/helpers/walletEventHooks";
import { SUPPORTED_CHAINS } from "@uniswap/smart-order-router";
import "./vault.scss";


export interface Props { }

function Vault(props: Props) {
  

  const { theme } = useContext(ThemeContext);
  
  const [showWalletModal, setShowWalletModal, toggleWalletModal] = useToggle(false);
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const { connector, active, library, chainId, account } = useWeb3React();
  const [disableConnectButton, setDisableConnectButton] = useState(false);
  const [showNetworkAlert, setShowNetworkAlert] = useState<boolean>(false);

  const classes = useStyles({ ...props, ...theme });

  const chainIdIsCorrect =
    chainId && SUPPORTED_CHAINS.includes(chainId);

  if(!chainIdIsCorrect && !showNetworkAlert){
    setShowNetworkAlert(true);    
  }

  useInactiveListener();

  // handles logic for wallet connect modal.
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
    active && setShowWalletModal(false);
  }, [activatingConnector, connector, active, setShowWalletModal]);

  return (
    <div id="wrap" className={classes.Wrapper}>
      
      {active && !chainIdIsCorrect && showNetworkAlert && <AlertUser closeModal={() => setShowNetworkAlert(false)} />}      

      <Header
        disableConnectButton={disableConnectButton}
        showWalletModal={showWalletModal}
        setShowWalletModal={setShowWalletModal}
        toggleWalletModal={toggleWalletModal}
      />

      <div id="main">
        <div className="inner inner__sm">
          <h1 className="comming-soon">Coming Soon</h1>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Vault;
