/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  dai_Token,
  eth_Token,
  SwapTXType,
  Tokens,
  TX_STATUS,
  UNISWAP_V3_AUTOROUTER_ADDRESS,
} from "../../constants";
import { ISwapTransactionData } from "../application/AppSlice";
import { IConversions, IPrices } from "../services/pricesAndConversions";
import { ethers, Contract } from "ethers";
import { quote, QuotePayload } from "../helpers/v3-autoRouterSwapHelper";
import { TradeType } from "@uniswap/sdk";

export interface ISwapState {
  conversions: IConversions;
  prices: IPrices;
  tx: {
    type: SwapTXType;
    status: TX_STATUS;
    data: ISwapTransactionData;
  };
}

export type SwapPayload = {
  userAddress: string;
  daiAddress: string;
  router: Contract;
  amount: number;
  token?: string;
};

export const swapEthForDai = async (payload: SwapPayload) => {
  const { userAddress, daiAddress, router, amount = "0.0001" } = payload;

  const provider = new ethers.providers.Web3Provider(
    (window as any).ethereum,
    "any"
  );

  const _payload: QuotePayload = {
    token: eth_Token,
    toToken: dai_Token,
    address: userAddress,
    chainId: dai_Token.chainId,
    provider: provider,
    amount: amount,
    tradeType: TradeType.EXACT_INPUT,
  };

  return quote(_payload)
    .then((res) => {
      const tx = {
        data: res.methodParameters.calldata,
        to: UNISWAP_V3_AUTOROUTER_ADDRESS,
        value: ethers.BigNumber.from(res.methodParameters.value),
        from: userAddress,
        gasPrice: ethers.BigNumber.from(res.gasPriceWei),
        gasLimit: ethers.utils.hexlify(1000000),
      };

      // get a signer wallet!
      const signer = provider.getSigner();

      return signer
        .sendTransaction(tx)
        .then((res) => {
          console.log("res", res);
          return res;
        })
        .catch((err) => {
          console.log("sendTransaction ", err);
          err.error = 1;
          return err;
        });
    })
    .catch((err) => {
      console.error("swapEthForDai reward quote dai->eth error", err);
      return "0";
    });
};

export const swapTokenForDai = async (payload: SwapPayload) => {
  const { userAddress, daiAddress, router, token, amount = "0.0001" } = payload;

  const provider = new ethers.providers.Web3Provider(
    (window as any).ethereum,
    "any"
  );

  const _payload: QuotePayload = {
    token: Tokens[`${token.toLowerCase()}Token`],
    toToken: dai_Token,
    address: userAddress,
    chainId: dai_Token.chainId,
    provider: provider,
    amount: amount,
    tradeType: TradeType.EXACT_INPUT,
  };

  return quote(_payload)
    .then((res) => {
      const tx = {
        data: res.methodParameters.calldata,
        to: UNISWAP_V3_AUTOROUTER_ADDRESS,
        value: ethers.BigNumber.from(res.methodParameters.value),
        from: userAddress,
        gasPrice: ethers.BigNumber.from(res.gasPriceWei),
        gasLimit: ethers.utils.hexlify(10000000),
      };

      // get a signer wallet!
      const signer = provider.getSigner(userAddress);

      return signer
        .sendTransaction(tx)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          err.error = 1;
          console.error("swapTokenForDai reward quote dai->eth error", err);
          return err;
        });
    })
    .catch((err) => {
      console.error("swapTokenForDai reward quote dai->eth error", err);
      return "0";
    });
};
