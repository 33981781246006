// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Contract, ethers } from "ethers";
import { bignumber } from "mathjs";
import { TX_STATUS, WithdrawTime, WithdrawTXType } from "../../constants";
import { IAppContractsNonNullable, IWithdrawTransactionData } from "../application/AppSlice";
import { approve } from "../services/approvals";
import { IBalances } from "../wallet/WalletSlice";

export interface IWithdrawState {
    tx: {
      type: WithdrawTXType,
      status: TX_STATUS,
      data: IWithdrawTransactionData,
      time: WithdrawTime
    }
}

export type WithdrawPayload = {
    stakingContract: Contract;
    stakeIndex: number;
    userAddress: string;
    // stakeMode: StakeMode;
}

export type ApproveWithdrawalPayload = {
  contracts: IAppContractsNonNullable;
  account: string; //
  amount: math.BigNumber;
  slippage: number;
  token: string;
  balances: IBalances;
}

export const emergencyWithdraw = async (payload: WithdrawPayload) => {
  const { stakingContract, stakeIndex, userAddress } = payload;
  // try{
    return await stakingContract.methods
    .emergencyWithdraw(stakeIndex)
    .send({ from: userAddress}); //, type: "0x2" 
  // }catch(error){
  //   console.log(error);
  // }
};

export const withdraw = async (payload: WithdrawPayload) => {
  const { stakingContract, stakeIndex, userAddress } = payload;
  // try{
    return await stakingContract.methods
    .withdraw(stakeIndex)
    .send({ from: userAddress}); //, type: "0x2" 
  // }catch(error){
  //   console.log(error);
  // }
};
 
export const approveWithdraw = async (payload: ApproveWithdrawalPayload) => {
  const { contracts, account, amount, slippage, balances, token } = payload;
  return await approve(
      contracts[`${token.toLowerCase()}Contract`],
      contracts.stakingContract._address,
      account,
      bignumber(amount),
      bignumber(balances[`${token.toLowerCase()}Balance`].toString()),
      slippage
  );
};