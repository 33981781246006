import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@material-ui/core";
import { useEffect, useState } from "react";
import { isChrome, isEdge, isFirefox, isIE, isOpera, isSafari } from "../../store/helpers/browserDetectHelper";

declare let window: any;

function MMBrowserLinkPopup(){
  const [open, setOpen] = useState(false);

  function openMetaMaskUrl(url: string){
    const a = document.createElement("a");
    a.href = url;
    a.target = "_self";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  useEffect(() => {
    const userDevice = navigator.userAgent;
    if((!window.ethereum || !window.web3) && (isOpera || isFirefox || isSafari || isChrome || isIE || isEdge) && (userDevice.indexOf("iPhone") > -1 || userDevice.indexOf("Android") > -1)){
      setOpen(true);
    }
    }, []);

  return(
  <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogContent style={{backgroundColor: "#383838"}}>
      <DialogContentText id="alert-dialog-description">
        Re-directing to MetaMask browser
      </DialogContentText>
    </DialogContent>
    <DialogActions style={{backgroundColor: "#383838"}}>
      <Button onClick={() => setOpen(false)}>
        <span style={{color: "white"}}>Disagree</span>
      </Button>
      <Button onClick={() => openMetaMaskUrl("https://metamask.app.link/dapp/app.brincx.io")} autoFocus>
        <span style={{color: "white"}}>Agree</span>
      </Button>
    </DialogActions>
  </Dialog>
  )
}

export default MMBrowserLinkPopup