import React, { useContext } from "react";
import { ThemeContext } from "../../../store/themeContext/themeContext";
import useStyles from "./failureStyles";
import PopupModal from "../../popupModal/popupModal";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { QuestionMark, ExternalLink, Close, Dot, Chevron, ICONS } from "../../icons/icons";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TX_STEP, TOKEN_ACTION, StakeTXType, WithdrawTXType, FAIL_REASON, StakePeriod, WithdrawTime, brc_Token, gbrc_Token } from "../../../constants";
import { BigNumber } from "@ethersproject/bignumber";
import FillButton from "../../button/button";
import { handleNumberDisplay } from "../../../utils/utils";


export interface Props {
  closeModal: () => void;
	// newBalance: string;
	etherscanLink: string;
	data: any;
	step: TX_STEP;
	transactionType: TOKEN_ACTION;
	reason: FAIL_REASON;

	// SWAP
	sellToken?: string;
	sellAmount?: number;
	buyAmount?: number;
	buyToken?: string;

	// STAKE
	stakeType?: StakeTXType;
	stakeSecondaryToken?: string;
	stakeSecondaryTokenAmount?: number;
	stakePrimaryTokenAmount?: number;
	stakePrimaryToken?: string;
  	stakePeriod?: StakePeriod;

	// WITHDRAW
	withdrawType?: WithdrawTXType;
	withdrawTime?: WithdrawTime;
	withdrawSecondaryToken?: string;
	withdrawSecondaryTokenAmount?: string | BigNumber;
	withdrawPrimaryTokenAmount?: string | BigNumber;
	withdrawPrimaryToken?: string;

	// NFT
	whitelistToken?: string;
	whitelistTokenAmount?: string | BigNumber;
}

function Failure(props: Props) {
	const { theme } = useContext(ThemeContext);

	const classes = useStyles({ ...props, ...theme });

	let received = "0.0";
	let maxSold = "0.0";

 	if(props.step <= 3){ // SWAP functions
		received = props.buyAmount ? props.buyAmount?.toString() : "0.00";
		maxSold = props.buyAmount ? props.sellAmount?.toString() : "0.00";
	}
	// separate resolution is done for BRC gBRC icons because the colours change somehow when gotten from the ICONS {}
	const OtherBuyToken = ICONS[props.buyToken];
	const OtherSellToken = ICONS[props.sellToken];

	const BRC = <img style={{width: "21px"}} className={classes.brincIcon} src={require("../../../media/tokens/BRQ.svg").default} alt=""/>;
	const GBRC = <img style={{width: "21px"}} className={classes.brincIcon} src={require("../../../media/tokens/gbrc.svg").default} alt=""/>;

  return (
    <PopupModal closeModal={props.closeModal}>
      <section className="pop__md__w pop__wrap">
				<header className="pop__head" style={{display: "flex", justifyContent:"space-between", hyphens: "auto", wordBreak: "break-word"}}>
					<h2 className={classes.heading}>Transaction failed</h2>
					<button type="button" className="pop__close" onClick={props.closeModal}>
						<span className="close"><img src={require("../../../media/icons/ico-close.svg").default} alt=""/></span>
					</button>
				</header>
				<main className="pop__body">
            <section className="" style={{marginTop:"0.5rem"}}>
                {/* <h2 className="tit__md">{props.reason === FAIL_REASON.REJECTED ? "Your transaction was rejected." : "Your transaction did not go through."}</h2> */}
				{/* NFT WHITELIST */}
				{props.transactionType === TOKEN_ACTION.NFT_WHITELIST ?
					<ul className="alignment__list">
						<li className="alignment__list__item">
							<div className="alignment__box">
								<span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span>
								<p className="alignment__txt">
									<span>Whitelist cost:</span>
								</p>
							</div>
							<div className="alignment__box">
								<p className="alignment__txt">
								<span className="num">{props.whitelistTokenAmount?.toString() !== "--" ? handleNumberDisplay(props.whitelistTokenAmount.toString(), true, 3) : props.whitelistTokenAmount}</span>
									<span>{props.whitelistToken}</span>
								</p>
								<span className="alignment__ico">
									{GBRC}
								</span>
							</div>
						</li>
						<li className="alignment__list__item">
							<div className="alignment__box">
								<span className="alignment__ico"><img src={require("../../../media/icons/info.svg").default} alt=""/></span>
								<p className="alignment__txt">
									<span>Ought to receive (whitelist slots):</span>
								</p>
							</div>
							<div className="alignment__box">
								<p className="alignment__txt">
									<span className="num">1</span>
									<span>NFT</span>
								</p>
								<span className="alignment__ico">
									
								</span>
							</div>
						</li>
						<li className="alignment__line"></li>
					</ul>:null
				}
				
				{/* APPROVAL */}
                {props.step <= TX_STEP.MINT_OR_BURN && props.transactionType !== TOKEN_ACTION.STAKE && props.transactionType !== TOKEN_ACTION.WITHDRAW ?
					<ul className="alignment__list">
						<li className="alignment__list__item">
							<div className="alignment__box">
								<p className="alignment__txt">
									<span>Buy:</span>
								</p>
							</div>
							<div className="alignment__box">
								<p className="alignment__txt">
									<span className="num">{handleNumberDisplay(received, true, 3)}</span>
									<span>{props.buyToken}</span>
								</p>
								<span className="alignment__ico">
									{props.transactionType === TOKEN_ACTION.BUY ? <>{BRC}</> : <OtherBuyToken />}
								</span>
							</div>
						</li>
						<li className="alignment__list__item">
							<div className="alignment__box">
								<p className="alignment__txt">
								{props.transactionType === TOKEN_ACTION.APPROVAL ? 
									<span>Ought to Approve:</span>
									:<span>Pay:</span>}
								</p>
							</div>
							<div className="alignment__box">
								<p className="alignment__txt">
									<span className="num">{handleNumberDisplay(maxSold, true, 3)}</span>
									<span>{props.sellToken}</span>
								</p>
								<span className="alignment__ico">
									{props.transactionType === TOKEN_ACTION.SELL ? <>{BRC}</> : <OtherSellToken />}
								</span>
							</div>
						</li>
						<li className="alignment__line"></li>
					</ul>:null
				}

				{props.step === TX_STEP.STAKE_OR_WITHDRAW ||
					(props.step === TX_STEP.APPROVAL && (props.transactionType === TOKEN_ACTION.STAKE || props.transactionType === TOKEN_ACTION.WITHDRAW)) ?
					(<ul className="alignment__list">
							<li className="alignment__list__item">
								<div className="alignment__box">
									<p className="alignment__txt">
										<span>{`${props.transactionType === TOKEN_ACTION.STAKE ? props.stakePrimaryToken : props.withdrawPrimaryToken}`} Amount</span>
									</p>
								</div>
								<div className="alignment__box">
									<p className="alignment__txt">
										<span className="num">{`${props.transactionType === TOKEN_ACTION.STAKE ? handleNumberDisplay(props.stakePrimaryTokenAmount, true, 3) : handleNumberDisplay(props.withdrawPrimaryTokenAmount.toString(), true, 2)}`} </span>
										<span>{`${props.transactionType === TOKEN_ACTION.STAKE ? props.stakePrimaryToken : props.withdrawPrimaryToken}`}</span>
									</p>
									<span className="alignment__ico">{BRC}</span>
								</div>
							</li>
							{(props.stakeType === StakeTXType.gBRC_AND_BRC || props.withdrawType === WithdrawTXType.gBRC_AND_BRC) && 
								(<li className="alignment__list__item">
									<div className="alignment__box">
										<p className="alignment__txt">
											<span>{`${props.transactionType === TOKEN_ACTION.STAKE ? props.stakeSecondaryToken : props.withdrawSecondaryToken}`} Amount</span>
										</p>
									</div>
									<div className="alignment__box">
										<p className="alignment__txt">
											<span className="num">{`${props.transactionType === TOKEN_ACTION.STAKE ? handleNumberDisplay(props.stakeSecondaryTokenAmount, true, 3) : handleNumberDisplay(props.withdrawSecondaryTokenAmount.toString(), true, 2)}`}</span>
											<span>{`${props.transactionType === TOKEN_ACTION.STAKE ? props.stakeSecondaryToken : props.withdrawSecondaryToken}`}</span>
										</p>
										<span className="alignment__ico">{GBRC}</span>
									</div>
								</li>)
							}
							<li className="alignment__list__item">
								<div className="alignment__box">
									<p className="alignment__txt">
										<span>Stake Period:</span>
									</p>
								</div>
								<div className="alignment__box">
									<p className="alignment__txt">
										<span className="num">{`${props.stakePeriod}`}</span> DAYS
										{/* <span>{`${props.transactionType === TX_TYPE.STAKE ? props.stakePrimaryToken : props.withdrawPrimaryToken}`}</span> */}
									</p>
									<span className="alignment__ico"><img width={'24px'} src={require("../../../media/icons/ico-time.svg").default} alt="stake period"/></span>
								</div>
							</li>
							{props.transactionType === TOKEN_ACTION.WITHDRAW ?
								<li className="alignment__list__item">
								<div className="alignment__box">
									<p className="alignment__txt">
										<span>Withdraw Maturity:</span>
									</p>
								</div>
								<div className="alignment__box">
									<p className="alignment__txt">
										<span className="num">{`${props.withdrawTime}`}</span>
									</p>
									{/* <span className="alignment__ico"><img src={require("../../../media/icons/icon.svg").default} alt="BRC"/></span> */}
								</div>
							</li>:null}
							<li className="alignment__line"></li>
						</ul>
					):null
				}
              <article>
                <h2 className="tit__md">
                  {/* By clicking APPROVE, you are confirming this transaction. <br/> */}
                  Your gas fee settings may be too low! In your (Metamask) <br />
                  wallet go to:
                </h2>
                <br />
                <ul className="explanation__list__box">
                  <li className={`explanation__list__item ${classes.bulletPoints}`}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <span className={`${classes.infoText} cont__txt`}>
                      1) Click to Edit gas fees 
                    <span className="ico__point" ><img src={require("../../../media/pngs/go_to_MM.png").default} alt="" style={{width: "90px", marginLeft: 33}}/></span>
                    </span>
                  </li>
                  <li className={`explanation__list__item ${classes.bulletPoints}`}>
                    
                    <span className={`${classes.infoText} cont__txt`}>
                      2) Increase the gas setting:
                      <span className="ico__point" ><img src={require("../../../media/pngs/aggressive.png").default} alt="" style={{width: "90px", marginLeft: 10}}/></span>
                    </span>
                    
                  </li>
                  {/* {props.step !== TX_STEP.STAKE_OR_WITHDRAW &&
                  <li className={`explanation__list__item ${classes.bulletPoints}`}>
                    <span className="ico__point"><img className={classes.dot} src={require("../../../media/icons/ico-Point.svg").default} alt=""/></span>
                    <span className={`${classes.infoText} cont__txt`} >Increase slippage rate before confirming this transaction</span>
                  </li>
                  } */}
                </ul>
              </article>
            </section>
        </main>
        <footer className="pop__foot" style={{marginTop: "5rem"}}>
          <FillButton
            label={"Close"} 
            clickFunction={props.closeModal} 
            className={classes.actionButton}
          />
        </footer>
			</section>		
    </PopupModal>

  );
}

export default React.memo(Failure);
