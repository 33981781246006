/* eslint-disable @typescript-eslint/no-unused-vars */
import { ethers, Contract } from "ethers";
import math, { all, bignumber, create } from "mathjs";
import { BIGNUMBER_CONFIG, chainId, UINT_256_MAX } from "../../constants";
import { noExponents } from "../../utils/utils";
import { GetWeb3 } from "../helpers/contractBooter";
import { tokenDecimals } from "../helpers/routerSwapHelper";
import { IBalances } from "../wallet/WalletSlice";

const maths = create(all, BIGNUMBER_CONFIG);

export const approve = async (
  contract: Contract,
  spender: string,
  ethAddress: string,
  amount: math.BigNumber,
  tokenBalance: math.BigNumber,
  slippage: number,
  tokenDecimal: number = 18 // ? default to 18 for ETH & DAI approves
) => {
  try {
    let web3 = GetWeb3(chainId);
    // let gasPrice = await web3.eth.getGasPrice();

    let amountPlusSlippage = maths.multiply(
      amount,
      bignumber(1 + (slippage === 0 ? 0 : slippage / 100))
    );

    if (
      maths
        .compare(
          maths.bignumber(amountPlusSlippage.toString()),
          maths.bignumber(tokenBalance.toString())
        )
        .toString() === "1"
    ) {
      amountPlusSlippage = amount;
    }

    let slicedAmount = ""; // utils.parseUnits fails if tokenDecimal doesn't cover all decimal values
    if (amountPlusSlippage.toString().includes(".")) {
      slicedAmount = amountPlusSlippage
        .toString()
        .substring(0, tokenDecimal + 2);
    } else {
      slicedAmount = amountPlusSlippage.toString().substring(0, tokenDecimal);
    }

    const parsedAmount =
      amount.toString() === maths.bignumber(UINT_256_MAX).toString()
        ? UINT_256_MAX
        : ethers.utils.parseUnits(slicedAmount, tokenDecimal);

    return await contract.methods
      .approve(spender, parsedAmount)
      .send({ from: ethAddress }); // type: "0x2" , gasPrice: Number(gasPrice)
  } catch (e) {
    alert("Error: " + e.message);
  }
};

export const approveTwice = async (
  balances: IBalances,
  firstContract: Contract,
  firstSpender: string,
  ethAddress: string,
  firstAmount: math.BigNumber,
  secondContract: Contract,
  secondSpender: string,
  secondAmount: math.BigNumber,
  slippage: number,
  token: string
) => {
  try {
    const tokenDecimal = tokenDecimals[token];
    return Promise.all([
      approve(
        firstContract,
        firstSpender,
        ethAddress,
        firstAmount,
        bignumber(balances[`${token.toLowerCase()}Balance`].toString()),
        slippage,
        tokenDecimal
      ),
      approve(
        secondContract,
        secondSpender,
        ethAddress,
        secondAmount,
        bignumber(balances.daiBalance.toString()),
        slippage
      ),
    ]);
  } catch (error) {
    alert("Error: " + error.message);
  }
};

export const hasEnoughAllowance = async (
  contract: Contract,
  spender: string,
  account: string,
  amount: math.BigNumber,
  slippage: number,
  tokenDecimal: number = 18 // ? default to 18 for ETH & DAI approves
) => {
  try {
    if (Number(amount.toString()) <= 0) return false;

    const parsedAmount = ethers.utils.parseUnits(
      noExponents(amount.toString()),
      tokenDecimal
    );
    return contract.methods
      .allowance(account, spender)
      .call({ from: account })
      .then((allowance) => {
        if (ethers.BigNumber.from(allowance).gte(parsedAmount)) {
          return true;
        } else {
          return false;
        }
      });
  } catch (error) {
    console.log("hasEnoughAllowance", error);
  }
};
