import { ChainId } from "@uniswap/smart-order-router";
import { Token } from "@uniswap/sdk-core";
import { all, ConfigOptions, create } from "mathjs";

export const BIGNUMBER_PRECISION = 78;
export const BIGNUMBER_CONFIG = {
  number: "BigNumber", // Default type of number: 'number' (default), 'BigNumber', or 'Fraction'
  precision: BIGNUMBER_PRECISION, // Number of significant digits for BigNumbers
} as ConfigOptions;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const math = create(all, BIGNUMBER_CONFIG);

// export const UINT_256_MAX = math.subtract(math.pow(math.bignumber(2), math.bignumber(256)), bignumber(1));
export const UINT_256_MAX =
  "115792089237316195423570985008687907853269984665640564039457584007913129639935";

export enum AppColor {
  BRINC_GREEN = "#379e48",
}

export const URL = {
  DEVELOPMENT: "http://localhost:3000/",
  TEST: "https://brinc-dev.netlify.app/",
  GANACHE_RPC: "http://localhost:8545/",
  ETH_GAS_STATION: "https://ethgasstation.info/api/ethgasAPI.json?",
  SUSHI_SUBGRAPH_ARBITRUM:
    "https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange",
};

export const NetworkContextName = "NETWORK";
export enum AppNetworks {
  MAINNET = 1,
  RINKEBY = 4,
  GOERLI = 5,
  OPTIMISTIC_MAINNET = 10,
  KOVAN = 42,
  BSC_MAINNET = 56,
  OPTIMISTIC_KOVAN = 69,
  BSC_TESTNET = 97,
  POLYGON = 137,
  ARBITRUM_xDAI = 200,
  ARBITRUM_ONE = 42161,
  ARBITRUM_NITRO_GOERLI = 421613,
  ARBITRUM_RINKEBY = 421611,
  POLYGON_MUMBAI = 80001,
}

export let SUPPORTED_CHAINS = [42161, 421611, 421613, "0xA4B1"];

export enum ScreenSize {
  MOBILE,
  SMALL_MOBILE,
  TABLET,
  DESKTOP,
  MAX_DESKTOP,
}
export enum AppTokens {
  DAI = "DAI",
  USDC = "USDC",
  USDT = "USDT",
  BRC = "BRC",
  wBTC = "wBTC",
  ETH = "ETH",
  gBRC = "gBRC",
  sgBRC = "sgBRC",
  UST = "UST",
}

export const gbrcTokenStaking = {
  symbol: "gBRCStaking",
};

enum AppContracts {
  STAKING,
  CURVE,
  ROUTER,
  TREASURY,
  BRC,
  gBRC,
  sgBRC,
  AIRDROP,
}

export const linkModifiers = {
  1: "etherscan.io",
  4: "rinkeby.etherscan.io",
  42161: "arbiscan.io",
  421611: "testnet.arbiscan.io",
};

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export enum SET_STATE_STATUS {
  NOT_INITIALIZED = "NOT_INITIALIZED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
}
export enum TX_STATUS {
  NOT_INITIALIZED = "NOT_INITIALIZED",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  REJECTED = "REJECTED",
}

export enum MintTXType {
  NOT_SET = "NOT_SET",
  APPROVAL = "APPROVAL",
  WITH_COLLATERAL_TOKEN = "WITH_COLLATERAL_TOKEN",
  WITH_CURVE_TOKEN = "WITH_CURVE_TOKEN",
}
export enum SwapTXType {
  NOT_SET = "NOT_SET",
  ETH_TO_DAI = "ETH_TO_DAI",
  ERC20Token_TO_DAI = "ERC20Token_TO_DAI",
}
export enum BurnTXType {
  NOT_SET = "NOT_SET",
  APPROVAL = "APPROVAL",
  BURN_TOKEN = "BURN_TOKEN",
}
export enum WithdrawTXType {
  NOT_SET = "NOT_SET",
  APPROVAL = "APPROVAL",
  gBRC_AND_BRC = "gBRC_AND_BRC",
  BRC_ONLY = "BRC_ONLY",
}
export enum WithdrawTime {
  NOT_SET = "NOT_SET",
  MATURE = "MATURE",
  EMERGENCY = "EMERGENCY",
}
export enum AirdropType {
  NOT_SET = "NOT_SET",
  BRC_HOLDERS = "BRC_HOLDERS",
  aETH_HOLDERS = "aETH_HOLDERS",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  aDAI_HOLDERS = "aDAI_HOLDERS",
}

export enum StakeTXType {
  NOT_SET = "NOT_SET",
  APPROVAL = "APPROVAL",
  gBRC_AND_BRC = "gBRC_AND_BRC",
  BRC_ONLY = "BRC_ONLY",
}
export enum StakePool {
  NOT_SET = "NOT_SET",
  BRC = "BRC",
  gBRC_AND_BRC = "BRC+gBRC",
}
export enum StakePeriod {
  NOT_SET = "NOT_SET",
  DAYS_7 = 7,
  DAYS_30 = 30,
  DAYS_90 = 90,
}
// { MODE1 = "30DAYS_BRC_ONLY", MODE2 = "90DAYS_BRC_ONLY", MODE3 = "180DAYS_BRC_ONLY", MODE4 = "30DAYS_BRC_AND_gBRC", MODE5 = "90DAYS_BRC_AND_gBRC", MODE6 = "180DAYS_BRC_AND_gBRC"};
export enum StakeMode {
  MODE1,
  MODE2,
  MODE3,
  MODE4,
  MODE5,
  MODE6,
  NOT_SET = "NOT_SET",
}

export enum AirdropStatusTypes {
  ACTIVE = "active",
  CLAIMED = "claimed",
  CLOSED = "closed",
  COMING_SOON = "coming soon",
  NOT_ELIGIBLE = "not eligible",
}

export const BlockCountHelper = {
  // ONE_MINUTE: 5, // 1 block = 13 seconds
  // ONE_HOUR: 277, // 1 block = 13 seconds
  // ONE_DAY: 6646, // 1 block = 13 seconds
  // ONE_WEEK: 46523, // 1 block = 13 seconds
  // ONE_MONTH: 199384, // 1 block = 13 seconds
  // ONE_YEAR: 2425847 // 1 block = 13 seconds
  ONE_MINUTE: 0.85 * 60, // 1 block = 1.6  seconds
  ONE_HOUR: 0.85 * 60 * 60, // 1 block = 1.6 seconds
  ONE_DAY: 0.85 * 60 * 60 * 24, // 1 block = 1.6  seconds
  ONE_WEEK: 0.85 * 60 * 60 * 24 * 7, // 1 block = 1.6 seconds
  ONE_MONTH: 0.85 * 60 * 60 * 24 * 30, // 1 block = 1.6  seconds
  ONE_YEAR: 0.85 * 60 * 60 * 24 * 365, // 1 block = 1.6  seconds
};

export const StakeModeLockBlockNumber = {
  // [StakeMode.MODE1]: 5, // 30 days in block count. 1 block = 13 seconds
  // TODO: Restore
  [StakeMode.MODE1]: BlockCountHelper.ONE_WEEK + 1,
  [StakeMode.MODE2]: BlockCountHelper.ONE_MONTH + 1,
  [StakeMode.MODE3]: BlockCountHelper.ONE_MONTH * 3 + 1,
  [StakeMode.MODE4]: BlockCountHelper.ONE_WEEK + 1,
  [StakeMode.MODE5]: BlockCountHelper.ONE_MONTH + 1,
  [StakeMode.MODE6]: BlockCountHelper.ONE_MONTH * 3 + 1,
};

export enum TX_STEP {
  NOT_INITIALIZED = 0,
  APPROVAL = 1,
  SWAP = 2,
  MINT_OR_BURN = 3,
  STAKE_OR_WITHDRAW = 4,
  CLAIM = 5,
  NFT_WHITELIST = 6,
}
export enum TOKEN_ACTION {
  APPROVAL = "APPROVE",
  BUY = "BUY",
  SELL = "SELL",
  STAKE = "STAKE",
  WITHDRAW = "WITHDRAW",
  CLAIM = "CLAIM",
  NFT_WHITELIST = "PURCHASE WHITELIST SPOT",
} // MINT = "MINT", BURN = "BURN",
export enum TX_CODE {
  BUY = 1,
  SELL = 2,
}

export enum FAIL_REASON {
  NOT_SET = "NOT_SET",
  REJECTED = "rejected",
  REVERTED = "reverted",
}
export enum HTTP_METHOD {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export const chainId =
  Number(window.localStorage.getItem("LOCAL_CHAIN_ID")) ||
  Number(AppNetworks.ARBITRUM_ONE);
export const account = window.localStorage.getItem("USER_ADDR");
// export const infuraEndpoint = chainId === Number(AppNetworks.ARBITRUM_ONE) || Number(chainId) === 0 ? process.env.REACT_APP_INFURA_ENDPOINT_MAINNET : process.env.REACT_APP_INFURA_ENDPOINT_RINKEBY;
export const infuraEndpoint =
  chainId !== Number(AppNetworks.ARBITRUM_RINKEBY)
    ? process.env.REACT_APP_INFURA_ENDPOINT_MAINNET
    : process.env.REACT_APP_INFURA_ENDPOINT_RINKEBY;

// ============= TOKEN DETAILS =============== //
const getToken = (symbol: AppTokens, chainId: number) => {
  if (symbol === AppTokens.DAI) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          "0x6b175474e89094c44da98b954eedeac495271d0f",
          18,
          AppTokens.DAI,
          "Dai Stablecoin"
        );
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          "0x96bdb0FcD44e2D36acd15e5fa6e766784802440C",
          18,
          AppTokens.DAI,
          "Dai Stablecoin"
        );
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          "0xb423100b5F121803A968e15a9c8D5008E71B34dD",
          18,
          AppTokens.DAI,
          "Dai Stablecoin"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
          18,
          AppTokens.DAI,
          "Dai Stablecoin"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          "0xb423100b5F121803A968e15a9c8D5008E71B34dD",
          18,
          AppTokens.DAI,
          "Dai Stablecoin"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
          18,
          AppTokens.DAI,
          "Dai Stablecoin"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else if (symbol === AppTokens.ETH) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
          18,
          AppTokens.ETH,
          "Wrapped ETH"
        );
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          "0xc778417E063141139Fce010982780140Aa0cD5Ab",
          18,
          AppTokens.ETH,
          "Wrapped ETH"
        );
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          ZERO_ADDRESS,
          18,
          AppTokens.ETH,
          "Wrapped ETH"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          ZERO_ADDRESS,
          18,
          AppTokens.ETH,
          "Wrapped ETH"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
          18,
          AppTokens.ETH,
          "Wrapped ETH"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
          18,
          AppTokens.ETH,
          "Wrapped ETH"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else if (symbol === AppTokens.USDC) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
          6,
          AppTokens.USDC,
          "USD//C"
        );
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          "0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b",
          6,
          AppTokens.USDC,
          "USD//C"
        );
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          ZERO_ADDRESS,
          6,
          AppTokens.USDC,
          "USD//C"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          ZERO_ADDRESS,
          6,
          AppTokens.USDC,
          "USD//C"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          "0x09b98F8b2395D076514037fF7D39a091a536206C",
          6,
          AppTokens.USDC,
          "USD//C"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
          6,
          AppTokens.USDC,
          "USD//C"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else if (symbol === AppTokens.USDT) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          "0xdac17f958d2ee523a2206206994597c13d831ec7",
          18,
          AppTokens.USDT,
          "Tether USD"
        );
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          "0xd9ba894e0097f8cc2bbc9d24d308b98e36dc6d02",
          18,
          AppTokens.USDT,
          "Tether USD"
        );
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          ZERO_ADDRESS,
          18,
          AppTokens.USDT,
          "Tether USD"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          ZERO_ADDRESS,
          18,
          AppTokens.USDT,
          "Tether USD"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          ZERO_ADDRESS,
          18,
          AppTokens.USDT,
          "Tether USD"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
          6,
          AppTokens.USDT,
          "Tether USD"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else if (symbol === AppTokens.wBTC) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
          8,
          AppTokens.wBTC,
          "Wrapped BTC"
        );
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          "0x577d296678535e4903d59a4c929b718e1d575e0a",
          8,
          AppTokens.wBTC,
          "Wrapped BTC"
        );
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          ZERO_ADDRESS,
          8,
          AppTokens.wBTC,
          "Wrapped BTC"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          ZERO_ADDRESS,
          8,
          AppTokens.wBTC,
          "Wrapped BTC"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          ZERO_ADDRESS,
          8,
          AppTokens.wBTC,
          "Wrapped BTC"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          ZERO_ADDRESS,
          8,
          AppTokens.wBTC,
          "Wrapped BTC"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else if (symbol === AppTokens.BRC) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          "0x7BFf1FC001054c4FfFF4E9117415112E07212A4E",
          18,
          AppTokens.BRC,
          "Brinc Token"
        );
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          "0x31191F1A17Ce5513Bc6cC36405335A9d2Dc14c29",
          18,
          AppTokens.BRC,
          "Brinc Token"
        );
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          "0x7d3A4d979DB4acfF38ac3dEE70Df62E802e3b298",
          18,
          AppTokens.BRC,
          "Brinc Token"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          "0xEC5F874412801b485f6ECF98F8cEb24800c1aB8A",
          18,
          AppTokens.BRC,
          "Brinc Token"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          "0x9D4971BB00a99C51CB63b9C20CcaD78452A14728",
          18,
          AppTokens.BRC,
          "Brinc Token"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          "0xB5de3f06aF62D8428a8BF7b4400Ea42aD2E0bc53",
          18,
          AppTokens.BRC,
          "Brinc Token"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else if (symbol === AppTokens.gBRC || symbol === gbrcTokenStaking.symbol) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          "0xEA3Ca90897959b4E8c9799ad03f20C62f9492C1a",
          18,
          AppTokens.gBRC,
          "Governance Token"
        ); //0x5fE5E1d5D86BDD4a7D84B4cAfac1E599c180488f
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          "0xE1FC548FB1eC433A1d2C66361d4D9a1635303810",
          18,
          AppTokens.gBRC,
          "Governance Token"
        );
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          "0xE876C2a351329f659375D86566DC2DE8767a4bE9",
          18,
          AppTokens.gBRC,
          "Governance Token"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          "0x409D845Ed944C0D63749d9b34cdF1858CB327Ed4",
          18,
          AppTokens.gBRC,
          "Governance Token"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          "0xeAc504dB0698eD1A746c7Bc7724f363FD012F6C0",
          18,
          AppTokens.gBRC,
          "Governance Token"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          "0x62C7e128e7c3205964429F58A0C6f63a776D10d1",
          18,
          AppTokens.gBRC,
          "Governance Token"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else if (symbol === AppTokens.sgBRC) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          "0x209F9442C45e0852201625112d66014A7eF3980A",
          18,
          AppTokens.sgBRC,
          "Staked Governance Token"
        ); //0xFF395641C57d4a53d35E5eBA3C8384aE9076DaE0
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          "0x1eFBE8215a89eb174149FD2e97294c6A10a93D7a",
          18,
          AppTokens.sgBRC,
          "Staked Governance Token"
        ); //
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          "0xCf55070B81145A33B45d658a0208feF37f5262d2",
          18,
          AppTokens.sgBRC,
          "Staked Governance Token"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          "0x7DFA178c4Ea6c25E55D52be8D86981b783D82148",
          18,
          AppTokens.sgBRC,
          "Staked Governance Token"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          "0xF5cF17752De687207328a7a5401DaF69dF021046",
          18,
          AppTokens.sgBRC,
          "Staked Governance Token"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          "0x55998B7F61b2737EA9BD217135A6fD4e8727e351",
          18,
          AppTokens.sgBRC,
          "Staked Governance Token"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else if (symbol === AppTokens.UST) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return new Token(
          ChainId.MAINNET,
          ZERO_ADDRESS,
          6,
          AppTokens.UST,
          "TerraUSD"
        );
      case AppNetworks.RINKEBY:
        return new Token(
          ChainId.RINKEBY,
          ZERO_ADDRESS,
          6,
          AppTokens.UST,
          "TerraUSD"
        );
      case AppNetworks.OPTIMISTIC_KOVAN:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_KOVAN.toString()),
          ZERO_ADDRESS,
          6,
          AppTokens.UST,
          "TerraUSD"
        );
      case AppNetworks.OPTIMISTIC_MAINNET:
        return new Token(
          Number.parseInt(AppNetworks.OPTIMISTIC_MAINNET.toString()),
          ZERO_ADDRESS,
          6,
          AppTokens.UST,
          "TerraUSD"
        );
      case AppNetworks.ARBITRUM_RINKEBY:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_RINKEBY.toString()),
          ZERO_ADDRESS,
          6,
          AppTokens.UST,
          "TerraUSD"
        );
      case AppNetworks.ARBITRUM_ONE:
        return new Token(
          Number.parseInt(AppNetworks.ARBITRUM_ONE.toString()),
          "0x13780E6d5696DD91454F6d3BbC2616687fEa43d0",
          6,
          AppTokens.UST,
          "TerraUSD"
        );
      default:
        return new Token(0, ZERO_ADDRESS, 0, "", "");
    }
  } else {
    return new Token(
      0,
      "0x0000000000000000000000000000000000000000",
      0,
      "",
      ""
    );
  }
};

export const dai_Token = getToken(AppTokens.DAI, chainId);
export const eth_Token = getToken(AppTokens.ETH, chainId);
export const usdc_Token = getToken(AppTokens.USDC, chainId);
export const ust_Token = getToken(AppTokens.UST, chainId);
export const usdt_Token = getToken(AppTokens.USDT, chainId);
export const wbtc_Token = getToken(AppTokens.wBTC, chainId);
export const brc_Token = getToken(AppTokens.BRC, chainId);
export const gbrc_Token = getToken(AppTokens.gBRC, chainId);
export const sgbrc_Token = getToken(AppTokens.sgBRC, chainId);
export const DefaultToken = {
  symbol: "Select a Token",
};
export const Tokens = {
  daiToken: dai_Token,
  ethToken: eth_Token,
  usdcToken: usdc_Token,
  usdtToken: usdt_Token,
  ustToken: ust_Token,
  wbtcToken: wbtc_Token,
  brcToken: brc_Token,
  gbrcToken: gbrc_Token,
  sgbrcToken: sgbrc_Token,
};
export const STAKE_PRIMARY_TOKEN: string = brc_Token.symbol;
export const STAKE_SECONDARY_TOKEN: string = gbrc_Token.symbol;
export const WITHDRAW_PRIMARY_TOKEN: string = brc_Token.symbol;
export const WITHDRAW_SECONDARY_TOKEN: string = gbrc_Token.symbol;

export const ETH_TRESURY_ADDRESS = "0xa08ce4C9a8A13aa3fcdef243E9430eb49C14B425";
export const SUSHI_BRC_GBRC_PAIR_ARBITRUM_ADDRESS =
  "0xfef634b2ec633881fe134d5f1b3ba68fa4da0704";
export const UNISWAP_BRC_GBRC_POOL_ARBITRUM_ADDRESS =
  "0xa2630b04fc6b30cc1430081f0c138566be3e61ad";
export const UNISWAP_WETH_GBRC_POOL_ARBITRUM_ADDRESS =
  "0xa08ce4c9a8a13aa3fcdef243e9430eb49c14b425";
export const SUSHI_BRC_GBRC_PAIR_ARBITRUM_LINK = `https://arbitrum.sushi.com/swap?inputCurrency=${brc_Token.address}&outputCurrency=${gbrc_Token.address}`;
export const UNISWAP_BRC_GBRC_PAIR_ARBITRUM_LINK = `https://app.uniswap.org/#/swap?chain=arbitrum&inputCurrency=${"0"}&outputCurrency=${
  gbrc_Token.address
}`; // brc_Token.address
export const UNISWAP_ETH_DAI_PAIR_ARBITRUM_LINK = `https://app.uniswap.org/#/swap?chain=arbitrum&inputCurrency=${eth_Token.address}&outputCurrency=${dai_Token.address}`;
export const SUSHI_ETH_DAI_PAIR_ARBITRUM_LINK = `https://arbitrum.sushi.com/swap?inputCurrency=${eth_Token.address}&outputCurrency=${dai_Token.address}`;

// ============= CONTRACTS DETAILS =============== //
const getContract = (name: AppContracts, chainId: number) => {
  if (name === AppContracts.CURVE) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return "0x208996eBc57dC4103Eb1922CF4e168D79fb97664";
      case AppNetworks.RINKEBY:
        return "0x9F5e245D87DB750c2BdEEB8544948dF5EA613C66";
      case AppNetworks.OPTIMISTIC_KOVAN:
        return "0xF5cF17752De687207328a7a5401DaF69dF021046";
      case AppNetworks.OPTIMISTIC_MAINNET:
        return "0x63ad3Ae5476021053D12Bec542AD9546704eB877";
      case AppNetworks.ARBITRUM_RINKEBY:
        return "0x8113901EEd7d41Db3c9D327484be1870605e4144";
      case AppNetworks.ARBITRUM_ONE:
        return "0x91Bd43beB23Eb58A5c6375059eABE9852607d1D0";
      default:
        return "";
    }
  } else if (name === AppContracts.ROUTER) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
      case AppNetworks.RINKEBY:
        return "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
      case AppNetworks.OPTIMISTIC_KOVAN:
        return "";
      case AppNetworks.OPTIMISTIC_MAINNET:
        return "";
      case AppNetworks.ARBITRUM_RINKEBY:
        return "0xe592427a0aece92de3edee1f18e0157c05861564";
      case AppNetworks.ARBITRUM_ONE:
        // return "0xe592427a0aece92de3edee1f18e0157c05861564";
        return "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45";
      default:
        return "";
    }
  } else if (name === AppContracts.AIRDROP) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return "";
      case AppNetworks.RINKEBY:
        return "";
      case AppNetworks.OPTIMISTIC_KOVAN:
        return "";
      case AppNetworks.OPTIMISTIC_MAINNET:
        return "";
      case AppNetworks.ARBITRUM_RINKEBY:
        return "0x7BA8031946C032c0B0519964e0FcA4EB0E3155FA";
      case AppNetworks.ARBITRUM_ONE:
        return "";
      default:
        return "";
    }
  } else if (name === AppContracts.STAKING) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return "0x9196Ab73B7a28388e9F47c68dfb6E34394d4Ae77"; //0xE5Df6583eE8DAe9F532e65D7D2C30A961c442f8a
      case AppNetworks.RINKEBY:
        return "0xeAc35F910a18E18daF4bED7E741264Cf23372726";
      case AppNetworks.OPTIMISTIC_KOVAN:
        return "0x3524228902F1dA726cfD5e52b345fB2B4b26300b";
      case AppNetworks.OPTIMISTIC_MAINNET:
        return "0x47E4B625Ef537300b82C8532608345Eb18bcdf81";
      case AppNetworks.ARBITRUM_RINKEBY:
        return "0x8C326Da653d077bb6D0b2219701c50fD0b63FaD6";
      case AppNetworks.ARBITRUM_ONE:
        return "0x9A28f7Ab9aEb4f14Fc4c580938F8F5E89ce98084";
      default:
        return "";
    }
  } else if (name === AppContracts.TREASURY) {
    switch (chainId) {
      case AppNetworks.MAINNET:
        return "0xE9287AFBF33c911ea80aa8257753027d193E5CC2";
      case AppNetworks.RINKEBY:
        return "0x43e0Acd5314D0B8BCf34d45Fc9F5B8eA2DD403b9";
      case AppNetworks.OPTIMISTIC_KOVAN:
        return "0x55416E498c2540Fd3388E76E08AFbA8527900000";
      case AppNetworks.OPTIMISTIC_MAINNET:
        return "0x9a43d572C90967c23546ab5A9440Ff5FB0658389";
      case AppNetworks.ARBITRUM_RINKEBY:
        return "0x55416E498c2540Fd3388E76E08AFbA8527900000";
      case AppNetworks.ARBITRUM_ONE:
        return "0x936782f2d07039B44CbfF9A1Bbe799BfB38E0564";
      default:
        return "";
    }
  } else {
    return "";
  }
};

//TODO checksum addresses
export const BRC_gBRC_PAIR_SUSHISWAP =
  "0xfeF634b2ec633881fE134d5f1b3bA68Fa4Da0704";
export const STAKING_CONTRACT = getContract(AppContracts.STAKING, chainId);
export const ROUTER_CONTRACT = getContract(AppContracts.ROUTER, chainId);
export const TREASURY_CONTRACT = getContract(AppContracts.TREASURY, chainId);
export const CURVE_CONTRACT = getContract(AppContracts.CURVE, chainId);
export const BRC_CONTRACT = brc_Token.address;
export const gBRC_CONTRACT = gbrc_Token.address;
export const sgBRC_CONTRACT = sgbrc_Token.address;
export const AIRDROP_CONTRACT = getContract(AppContracts.AIRDROP, chainId);

export const UNISWAP_QUOTER_ADDRESS =
  "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6";
export const UNISWAP_V3_AUTOROUTER_ADDRESS =
  "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45";

export const teamWhiteList = [
  "0xdA2492f0918B3e3F0B791649A3c76831a869E7ED",
  "0xD1ead84490dae0f75028bF568F7d97f4F12A8D19",
  "0xD5911F8cB4a7bA38667B0A2DdDD92b50cC8FDCB1",
  "0x0e5b416bc9937769101b01d1FCC4e23CF121e5a9",
  "0xa1B94ef0f24d7F4fd02285EFcb9202E6C6EC655B",
  "0x30d5D30e43b6167a345f3D71d61673464e16d711",
];

export const claimWhiteList = [
  "0x007f2a96cbdf08bf1a58f1de3599cb67571a40ac",
  "0x050e05a86e4d59f577ef4be2f31de9e18e66e439",
  "0x067aa2ffdc80e558a2b01c349190eef0c6a773cf",
  "0x0e5b416bc9937769101b01d1fcc4e23cf121e5a9",
  "0x0f7d71750c2c2c172f197146d793bece6b736b3b",
  "0x155ddac174dc33a1c7054b90ae8c31228776d147",
  "0x1582fcc3354f45eca55a7d6bbaac6d23fbc4d75f",
  "0x16fb8ff7a4628bb1aef5206fd2edaeb842a6a9e6",
  "0x1a9a6ef57e028916473bd63b870612b8a5f1207d",
  "0x1ccb1d25afa93fb6308dec59f5fd23d0c6f7834c",
  "0x23ae0a1ead69aacc651d65962acf0070bacaf863",
  "0x249245b8f119cc3a77403cae848d43eb879a98ea",
  "0x26bc5cf2269cac024b106856ad37a1439cadd731",
  "0x306b78622ce9574eba9eaa55478fb56ffd9b32af",
  "0x319deb909e8f0a372be3c477700e88cfc1110db5",
  "0x35b740da360c5920c07b42dd81e866e4cc666140",
  "0x3bffe09239a9c6f8edd82bd492e8fc5e33bef2fb",
  "0x3c5aac016ef2f178e8699d6208796a2d67557fe2",
  "0x41f6d098eb907d551f6107c166f14da00cb8eaad",
  "0x43da6d2db9651b7042e31ffb2607a7cfa4d5d03b",
  "0x4b96624c4a8e19aecdac67780b5bce399fa216c4",
  "0x4bc68c2c4155415db38d6c50caae6c06b0b84399",
  "0x568b47151865198395259de634d05c3111776fad",
  "0x57d839040ba038230583ec455a03bbb041eee90b",
  "0x58088f06a87f06f442bcfac58057619c39f7e9fa",
  "0x5855b0c42811f013555219c845736b47ddf37f9a",
  "0x59bb91ece99829fd6a95b527a76152e389b14218",
  "0x5f87be7dcd9911989e9d028c424eff1d998bedc2",
  "0x65f32f9172146b778373818f99b0b25c52bf275d",
  "0x67a832856f772463adfe1c7f92a632c10657a6de",
  "0x6a11ce7accf044dad73d731cbccae66bc087cdd9",
  "0x6ece2d48d427af5d3803baabaf20e2f0f69f0dea",
  "0x799492f23330045b461f7941f1f9d2759b2a024d",
  "0x7aa91528a93a095c4e7efc7b2f6d259a39164022",
  "0x81aa6141923ea42fcaa763d9857418224d9b025a",
  "0xa6a2b356718faf8cce70e78f06712f1ce5917d04",
  "0xadf35694826e7251506463c7a673040b50179ca9",
  "0xb27d9d5c58ac94d92c793c5d6bc03d06abfdf908",
  "0xb3ec46fc26f3dec60dde3351be7ec1063d46589d",
  "0xbb73c02cd7a3f8417ef31a40bbf7b517dedc13d1",
  "0xc053047d5d6bc002dbe48d0f919aecd075496980",
  "0xc5378916480fcf67838004cafd27ad90e14c86e2",
  "0xcca178a04d83af193330c7927fb9a42212fb1c25",
  "0xcfa544832178e3979155cfdc7e23cea43ac73dc3",
  "0xd1ead84490dae0f75028bf568f7d97f4f12a8d19",
  "0xd5911f8cb4a7ba38667b0a2dddd92b50cc8fdcb1",
  "0xd5d8d4e34c1f3b1a9906be3501be481184458a7a",
  "0xd6a6e5bcd43caf9d8702a151f53b1f07aa1765f7",
  "0xd74e63d3545a6c045a70d68c4a8d6560df71827c",
  "0xdbab0b75921e3008fd0bb621a8248d969d2d2f0d",
  "0xdfc6365cd8495ebe5f7a1f165a053b971a19d997",
  "0xe0f851bf7e6f9a98fbc9e77d8c1282205836ca3b",
  "0xe11a50e299121db7849cb28604917cd615dc1bcc",
  "0xe38c38550b4aa208791279fd91ff9cb0d81e1dac",
  "0xe8812ec6038bf0966fd79b1bb68b14e57e46fafb",
  "0xea3a5cfc008b57eba6cfa42eb423f29613aadb3e",
  "0xee32d08e674dbc7ed9547f02e39da62a4689e7b5",
  "0xfaf4f27c59a7d7c03f7b93fc63ae03053560c89b",
  "0xfc4acbc937653de7814d0abdf9f7ec320122d18e",
  "0xff9bee367d1096ec93bfc2d085b1f9899219473d",
];
