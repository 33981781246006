import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import './header.scss'
import { addTokenToWallet } from '../../store/helpers/addTokenHelper';
import { useSelector } from 'react-redux';
import { RootState } from "../../store/mystore";

    
function SelectNav(){
    const state = useSelector((state: RootState) => state);    

    const [open, setOpen] = useState(false);
    return <>
        <ul className={open ? 'mob__sub__seelect active' : 'mob__sub__seelect'}>
            {state.wallet.isConnected ?
                <li className="sub__select__item" onClick={() => addTokenToWallet()}>
                    <div style={{cursor:"pointer"}} className="sub__select__link" onClick={() => setOpen(false)}>
                        <span className="svg__wrap"><img src={require("../../media/icons/ico-wallet.svg").default} alt="Add Tokens to Wallet"/></span>
                        <span className="sub__txt">Add Tokens (BRC | gBRC)</span>
                    </div>                            
                </li>
            :null}
            <li className="sub__select__item" onClick={() => window.open('https://brincx.io/#/AboutUs' , '_blank')}>
                <NavLink to="/aboutUs" className="sub__select__link" onClick={() => setOpen(false)}>
                    <span className="svg__wrap"><img width="24" src={require("../../media/tokens/brc_bw.png").default} alt="About us"/></span>
                    <span className="sub__txt">About us</span>
                </NavLink>
            </li>
            <li className="sub__select__item" onClick={() => window.open('https://brinc-fi.gitbook.io/documentation/' , '_blank')}>
                <NavLink to="/documentation" className="sub__select__link" onClick={() => setOpen(false)}>
                    <span className="svg__wrap"><img src={require("../../media/icons/ico-frame-text.svg").default} alt="Documentation"/></span>
                    <span className="sub__txt">Documentation</span>
                </NavLink>
            </li>
            <li className="sub__select__item" onClick={() => window.open('https://github.com/brinc-finance' , '_blank')}>
                <NavLink to="/code" className="sub__select__link" onClick={() => setOpen(false)}>
                    <span className="svg__wrap"><img src={require("../../media/icons/ico-frame-code.svg").default} alt="Code"/></span>
                    <span className="sub__txt">Code</span>
                </NavLink>
            </li>
            <li className="sub__select__item" onClick={() => window.open('https://discord.com/invite/QFFrBu68rp' , '_blank')}>
                <NavLink to="/discord" className="sub__select__link" onClick={() => setOpen(false)}>
                    <span className="svg__wrap"><img src={require("../../media/icons/ico-frame-Discord.svg").default} alt="Discord"/></span>
                    <span className="sub__txt">Discord</span>
                </NavLink>
            </li>
            {/* <li className="sub__select__item">
                <NavLink to="/referral" className="sub__select__link" onClick={() => setOpen(false)}>
                    <span className="svg__wrap"><img src={require("../../media/icons/ico-frame-user.svg").default} alt="Referral program"/></span>
                    <span className="sub__txt">Referral program</span>
                </NavLink>
            </li> */}
        </ul>
    </>
}

export default SelectNav