import { useMediaQuery } from 'react-responsive';

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1000 })
  return isDesktop ? children : null
}
export const BelowDesktop = ({ children }) => {
  const isDesktop = useMediaQuery({ maxWidth: 999 })
  return isDesktop ? children : null
}
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 601, maxWidth: 999 })
  return isTablet ? children : null
}
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 })
  return isMobile ? children : null
}
export const AboveMobile = ({ children }) => {
  const isAboveMobile = useMediaQuery({ minWidth: 601 })
  return isAboveMobile ? children : null
}
export const SmallMobile = ({ children }) => {
  const isSmallMobile = useMediaQuery({ maxWidth: 420 })
  return isSmallMobile ? children : null
}
export const AboveSmallMobile = ({ children }) => {
  const isAboveSmallMobile = useMediaQuery({ minWidth: 421 })
  return isAboveSmallMobile ? children : null
}