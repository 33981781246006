import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
// import CardMini from "../cardMini/cardMini";
import useStyles from "./walletConnectButtonStyles";
import OpenApp from "react-open-app";
// import { ReactComponent as MetamaskGrey } from "../../media/icons/metamask-grey.svg";
// import { ReactComponent as PortisGrey } from "../../media/icons/portis.svg";
// import { ReactComponent as LedgerGrey } from "../../media/icons/LedgerIcon.svg";
// import { ReactComponent as WalletIcon } from "../../media/icons/wallet-outline.svg";
import { BelowDesktop, Desktop } from "../screenHelper";
import FillButton from "../button/button";
import {
  isChrome,
  isEdge,
  isFirefox,
  isIE,
  isOpera,
  isSafari,
} from "../../store/helpers/browserDetectHelper";

declare let window: any;

export interface Props {
  disabled?: boolean;
  clickFunction: () => void;
}

function WalletConnectButton(props: Props) {
  const { theme } = useContext(ThemeContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles({ ...props, ...theme });

  return (
    <>
      <Desktop>
        {(!window.ethereum ||
        !window.web3) &&
        (isOpera || isFirefox || isSafari || isChrome || isIE || isEdge) ? (
          <OpenApp
            href="https://metamask.app.link/dapp/app.brinc.fi"
            android="https://metamask.app.link/dapp/app.brinc.fi"
            ios="https://metamask.app.link/dapp/app.brinc.fi"
          >
            <button
              type="button"
              className="tr__menu btn__wallet"
              
            >
              <span className="svg__wrap">
                <img
                  src={require("../../media/icons/ico-wallet.svg").default}
                  alt="Connect wallet"
                />
              </span>
              <span className="txt">Connect Wallet</span>
            </button>
          </OpenApp>
        ) : (
          <button
            type="button"
            className="tr__menu btn__wallet"
            onClick={props.clickFunction}
          >
            <span className="svg__wrap">
              <img
                src={require("../../media/icons/ico-wallet.svg").default}
                alt="Connect wallet"
              />
            </span>
            <span className="txt">Connect Wallet</span>
          </button>
        )}
      </Desktop>
      {/* {!window.ethereum &&
      !window.web3 &&
      (isOpera || isFirefox || isSafari || isChrome || isIE || isEdge) ? (
        <OpenApp
          href="https://metamask.app.link/dapp/app.brinc.fi"
          android="https://metamask.app.link/dapp/app.brinc.fi"
          ios="https://metamask.app.link/dapp/app.brinc.fi"
        >
          <BelowDesktop>
            <FillButton
              label={`Connect Wallet`}
              variant="fill"
              icon={"WalletBlack"}
              size="md"
              clickFunction={null}
            />
          </BelowDesktop>
        </OpenApp>
      ) : ( */}
        <BelowDesktop>
          <FillButton
            label={`Connect Wallet`}
            variant="fill"
            icon={"WalletBlack"}
            size="md"
            clickFunction={props.clickFunction}
          />
        </BelowDesktop>
      {/* )} */}
    </>
  );
}

export default React.memo(WalletConnectButton);
