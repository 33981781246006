import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FAIL_REASON, MintTXType, TX_STATUS, TX_STEP } from "../../constants";
import {
  approveMint,
  ApproveMintPayload,
  IMintState,
  MintTokenPayload,
  mintWithSpecificCollateralToken,
  mintWithSpecificCurveToken,
} from "./Mint";

const initialState: IMintState = {
  tx: {
    type: MintTXType.NOT_SET,
    status: TX_STATUS.NOT_INITIALIZED,
    data: {
      txStep: TX_STEP.NOT_INITIALIZED,
      nextTXStep: TX_STEP.NOT_INITIALIZED,
      failData: {},
      successData: {},
      reasonForFail: FAIL_REASON.NOT_SET,
    },
  },
};

const mintWithCollateralTokenAsync = createAsyncThunk(
  "mint/collateralToken",
  async (data: MintTokenPayload, { rejectWithValue }) => {
    try {
      const response = await mintWithSpecificCollateralToken(data);
      console.log("mintWithCollateralTokenAsync", response);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const mintWithSpecificCurveTokenAsync = createAsyncThunk(
  "mint/curveToken",
  async (data: MintTokenPayload, { rejectWithValue }) => {
    try {
      const response = await mintWithSpecificCurveToken(data);
      console.log("mintWithSpecificCurveTokenAsync", response);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const approveMintAsync = createAsyncThunk(
  "mint/approve",
  async (data: ApproveMintPayload, { rejectWithValue }) => {
    try {
      const response = await approveMint(data);
      return response;
    } catch (err) {
      console.log("approveMintAsync err", err);
      return rejectWithValue(err.response.data);
    }
  }
);

const mintSliceReducer = createSlice({
  name: "mint",
  initialState,
  reducers: {
    resetMintTXState: (state) => {
      state.tx = {
        type: MintTXType.NOT_SET,
        status: TX_STATUS.NOT_INITIALIZED,
        data: {
          txStep: TX_STEP.NOT_INITIALIZED,
          nextTXStep: TX_STEP.NOT_INITIALIZED,
          failData: {},
          successData: {},
          reasonForFail: FAIL_REASON.NOT_SET,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // mint with specific collateral token (DAI)
      // TODO: Handle (verify) when mint is rejected
      // ? mint returns err.code === 4001 if rejected
      .addCase(mintWithCollateralTokenAsync.pending, (state: IMintState) => {
        state.tx.status = TX_STATUS.PENDING;
        state.tx.type = MintTXType.WITH_COLLATERAL_TOKEN;
        state.tx.data.txStep = TX_STEP.MINT_OR_BURN;
        state.tx.data.failData = {};
        state.tx.data.successData = {};
        // state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
      })
      .addCase(
        mintWithCollateralTokenAsync.rejected,
        (state: IMintState, action) => {
          // console.log('mint failed', action);
          state.tx.status = TX_STATUS.REJECTED;
          state.tx.type = MintTXType.WITH_COLLATERAL_TOKEN;
          state.tx.data.failData = action.payload;
          state.tx.data.successData = {};
          state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
          // update TX step for next
          state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
        }
      )
      .addCase(
        mintWithCollateralTokenAsync.fulfilled,
        (state: IMintState, action) => {
          if (typeof action.payload === undefined) {
            state.tx.status = TX_STATUS.FAILED;
            state.tx.data.failData = {};
            state.tx.data.successData = {};
            state.tx.type = MintTXType.WITH_COLLATERAL_TOKEN;
            state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
          } else {
            console.log("action raw success", action);
            state.tx.status = TX_STATUS.SUCCESS;
            state.tx.type = MintTXType.WITH_COLLATERAL_TOKEN;
            state.tx.data.successData = action.payload;
            state.tx.data.failData = {};
          }
          // update TX step for next
          state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
        }
      )

      // mint with specific curve token (BRC)
      // TODO: Handle (verify) when mint is rejected
      // ? mint returns err.code === 4001 if rejected
      .addCase(mintWithSpecificCurveTokenAsync.pending, (state: IMintState) => {
        state.tx.status = TX_STATUS.PENDING;
        state.tx.type = MintTXType.WITH_CURVE_TOKEN;
        state.tx.data.txStep = TX_STEP.MINT_OR_BURN;
        state.tx.data.failData = {};
        state.tx.data.successData = {};
      })
      .addCase(
        mintWithSpecificCurveTokenAsync.rejected,
        (state: IMintState, action) => {
          state.tx.status = TX_STATUS.REJECTED;
          state.tx.type = MintTXType.WITH_CURVE_TOKEN;
          state.tx.data.failData = action.payload;
          state.tx.data.successData = {};
          state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
          // update TX step for next
          state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
        }
      )
      .addCase(
        mintWithSpecificCurveTokenAsync.fulfilled,
        (state: IMintState, action) => {
          if (typeof action.payload === undefined) {
            state.tx.status = TX_STATUS.FAILED;
            state.tx.type = MintTXType.WITH_CURVE_TOKEN;
            state.tx.data.failData = {};
            state.tx.data.successData = {};
            state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
          } else {
            state.tx.status = TX_STATUS.SUCCESS;
            state.tx.type = MintTXType.WITH_CURVE_TOKEN;
            state.tx.data.successData = action.payload;
          }
          // update TX step for next
          state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
        }
      )

      // approve mint
      .addCase(approveMintAsync.pending, (state: IMintState) => {
        state.tx.status = TX_STATUS.PENDING;
        state.tx.type = MintTXType.APPROVAL;
        state.tx.data.txStep = TX_STEP.APPROVAL;
        state.tx.data.failData = {};
        state.tx.data.successData = {};
      })
      .addCase(approveMintAsync.rejected, (state: IMintState, action) => {
        state.tx.status = TX_STATUS.REJECTED;
        state.tx.type = MintTXType.APPROVAL;
        state.tx.data.failData = action.payload;
        state.tx.data.successData = {};
        state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
      })
      .addCase(approveMintAsync.fulfilled, (state: IMintState, action) => {
        if (typeof action.payload === undefined) {
          state.tx.status = TX_STATUS.FAILED;
          state.tx.type = MintTXType.APPROVAL;
          state.tx.data.failData = {};
          state.tx.data.successData = {};
          state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
        } else {
          state.tx.status = TX_STATUS.SUCCESS;
          state.tx.type = MintTXType.APPROVAL;
          state.tx.data.successData = action.payload;
          state.tx.data.failData = {};
          // update step to next step
          if (action.meta.arg.token === "DAI") {
            state.tx.data.nextTXStep = TX_STEP.MINT_OR_BURN;
          } else {
            state.tx.data.nextTXStep = TX_STEP.SWAP;
          }
        }
      });
  },
});

export {
  mintWithCollateralTokenAsync,
  mintWithSpecificCurveTokenAsync,
  approveMintAsync,
};
export const { resetMintTXState } = mintSliceReducer.actions;
export default mintSliceReducer.reducer;
export const mintSelector = (state: { mintState: IMintState }) =>
  state.mintState;
