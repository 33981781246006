import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./cardMain";

const useStyles = makeStyles(() =>
	createStyles({
		CardMain: {
			background: (style: Props & Theme) => style.bgGradient2,
			transition: (style: Theme & Props) => style.transition,
			color: (style: Theme & Props) => style.text,
			// boxShadow: "36px 12px 64px rgba(2, 3, 3, 0.55), -12px -20px 56px rgba(232, 237, 243, 0.15)",
			borderRadius: "24px",
			padding: "25px", //4rem
			// marginTop: "48px",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		CardMainMobile: {
			background: 'var(--almost-black-2)',
			transition: (style: Theme & Props) => style.transition,
			color: (style: Theme & Props) => style.text,
			// boxShadow: "36px 12px 64px rgba(2, 3, 3, 0.55), -12px -20px 56px rgba(232, 237, 243, 0.15)",
			borderRadius: "10px",
			padding: "6px", //4rem
			// marginTop: "48px",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
	})
);

export default useStyles;