import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./tokenDropdown";
import { brc_Token, DefaultToken, ScreenSize } from "../../constants";

const useStyles = makeStyles(() =>
  createStyles({
    TokenDropdownContainer: {
      backgroundColor: "transparent",
      color: (style: Theme & Props) => style.highlightedText,
      position: "relative",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderRadius: "5px",
      marginRight: "4px",
      transition: (style: Theme & Props) => 
        style.token === brc_Token.symbol || !style.collapsible ? 
        "" : "all 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: (style: Theme & Props) => 
        style.token === brc_Token.symbol || !style.collapsible ? 
        "" :"var(--almost-black-2)",
      },
    },
    dropdownButton: {
      width: (style: Theme & Props) => style.screenSize === ScreenSize.MAX_DESKTOP ? "130px" : "115px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: "auto",
      // marginRight: "4px",
      "&:hover": {
        filter: (style: Theme & Props) =>
          style.collapsible ? "brightness(1.2)" : "brightness(1)",
      },
    },
    tokenContainer: {
      margin: "auto 0 auto -5px",
      font: (style: Theme & Props) => style.typography.token,      
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      alignSelf: "center",
      position: "relative",
    },
    buttonText: {
      margin: (style: Theme & Props) =>
        style.collapsible ? "auto 1rem auto auto" : "auto",
      marginLeft: (style: Theme & Props) =>
        style.token === DefaultToken.symbol ? "1rem" : "auto",
      font: (style: Theme & Props) => style.typography.token,
      fontSize: (style: Theme & Props) => style.token === DefaultToken.symbol ? "16px" : "20px",
      color: "var(--coloured-fade-white)",
      height: "100%",
      textAlign: "center",
      alignSelf: "center",
      fontWeight: "bold",
    },
    buttonIcon: {
      margin: (style: Theme & Props) => style.token === DefaultToken.symbol ? "auto auto auto 1rem" : "auto .6rem",
      height: "1.6rem",
      width: "1.6rem",
    },
    dropdownMenu: {
      backgroundColor: "var(--almost-black-2)",
      color: (style: Theme & Props) => style.text,
      boxShadow: "0 0 2px rgba(0,0,0)",
      position: "absolute",
      top: "4.55rem",
      left: "-5px",
      height: "fit-content",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      zIndex: 1001,
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      // borderRadius: "10px",
      padding: "0 0px 8px",
    },
    dropdownItem: {
      width: "100%",
      height: "4rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "0.5rem auto",
      cursor: "pointer",
      userSelect: "none",
      "&:hover": {
        filter: "brightness(1.2)",
      },
    },
    tokenName: {
      font: (style: Theme & Props) => style.typography.h2,
      margin: "auto auto auto 1rem",
    },
    tokenIcon: {
      width: "3rem",
      height: "3rem",
      margin: "auto 1rem",
      padding: 0
    },
    bigger: {
      transform: "scale(1.4)"
    }
  })
);

export default useStyles;