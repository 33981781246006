/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { JsonRpcProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { SET_STATE_STATUS, ZERO_ADDRESS } from "../../constants";
import { IAppContractsNonNullable } from "../application/AppSlice";
import { IThunkAPI } from "../mystore";
import { getBalances } from "../services/balances";
import { getProvider } from "../helpers/contractBooter";

export interface IBalances {
  brcBalance: number;
  gbrcBalance: number;
  sgbrcBalance: number;
  daiBalance: number;
  ethBalance: number;
  usdcBalance?: number;
  wbtcBalance?: number;
  usdtBalance?: number;
  ustBalance?: number;
  status?: string;
}

interface IProvider {
  data?: ethers.providers.JsonRpcProvider;
  status?: string;
}

export interface IWalletState {
  balances: IBalances;
  isConnected: boolean;
  address: string | undefined | null;
  provider: any; //IProvider
}

const initialBalances: IBalances = {
  brcBalance: 0,
  gbrcBalance: 0,
  sgbrcBalance: 0,
  daiBalance: 0,
  ethBalance: 0,
  usdcBalance: 0,
  wbtcBalance: 0,
  usdtBalance: 0,
  ustBalance: 0,
  status: SET_STATE_STATUS.NOT_INITIALIZED,
};

const initialState: IWalletState = {
  balances: initialBalances,
  isConnected: false,
  address: ZERO_ADDRESS,
  provider: {},
};

export type SetBalancePayload = {
  ethAddress: string | null | undefined;
  contracts: IAppContractsNonNullable;
  provider: any;
};

const setBalancesAsync = createAsyncThunk<
  IBalances,
  SetBalancePayload,
  IThunkAPI
>("wallet/setBalances", async (data: SetBalancePayload, thunkAPI) => {
  const response = await getBalances(data);
  return response;
});

const setProviderAsync = createAsyncThunk("wallet/setProvider", async () => {
  const response = getProvider();
  // The value we return becomes the `fulfilled` action payload
  return response;
});

const walletSliceReducer = createSlice({
  name: "wallet",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // sets wallet connection status
    setWalletConnection: (state, action) => {
      state.isConnected = action.payload;
    },
    // sets current wallet address
    setWalletAddress: (state, action) => {
      state.address = action.payload;
    },
    // sets current wallet address
    setProvider: (state, action) => {
      state.provider = action.payload;
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(setBalancesAsync.pending, (state) => {
        state.balances.status = SET_STATE_STATUS.PENDING;
      })
      .addCase(setBalancesAsync.rejected, (state) => {
        state.balances.status = SET_STATE_STATUS.FAILED;
      })
      .addCase(
        setBalancesAsync.fulfilled,
        (state, { payload }: PayloadAction<IBalances>) => {
          state.balances = payload;
          state.balances.status = SET_STATE_STATUS.SUCCESS;
        }
      )

      .addCase(setProviderAsync.pending, (state) => {
        state.provider.status = SET_STATE_STATUS.PENDING;
      })
      .addCase(setProviderAsync.rejected, (state) => {
        state.provider.status = SET_STATE_STATUS.FAILED;
      })
      .addCase(
        setProviderAsync.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.provider.data = payload;
          state.provider.status = SET_STATE_STATUS.SUCCESS;
        }
      );
  },
});

export { setBalancesAsync, setProviderAsync };
export const { setWalletConnection, setWalletAddress, setProvider } =
  walletSliceReducer.actions;
export default walletSliceReducer.reducer;
export const walletSelector = (state: { walletState: IWalletState }) =>
  state.walletState;
