import { ethers, Contract } from "ethers";
import { noExponents } from "../../utils/utils";
import {
  brc_Token,
  dai_Token,
  gbrc_Token,
  usdc_Token,
  usdt_Token,
  wbtc_Token,
  eth_Token,
  ust_Token,
  gbrcTokenStaking,
} from "../../constants";
import { IAppContractsNonNullable } from "../application/AppSlice";
import {
  getDaiQuoteForEth,
  getDaiQuoteForToken,
  getTokenQuoteFromDai,
  getEthQuoteFromDai,
  getTokenQuoteFromBRC,
  getEthQuoteFromGbrc,
} from "./quotes";
import math, { bignumber, divide, multiply } from "mathjs";

export interface IConversions {
  priceForOneInDAI: number;
  rewardForOneInDAI: number;
  status?: string;
}

export interface IPrices {
  usdPrice: string;
  daiPrice: string;
  ethPrice?: string;
  usdcPrice?: string;
  wbtcPrice?: string;
  usdtPrice?: string;
  ustPrice?: string;
  gbrcStakingPrice: string;
  gbrcPrice?: string;
  status?: string;
}

export const getCost = async (
  contracts: IAppContractsNonNullable,
  amount: math.BigNumber,
  token?: string, //buyToken | *fromToken*
  otherToken?: string // sellToken | *toToken*
) => {
  // console.log({to: token, from: otherToken});
  
  if (amount) {
    switch (token) {
      case brc_Token.symbol:
        switch (otherToken) {
          case gbrcTokenStaking.symbol:
            const ratio = await getBRCtoGBRCRatio(contracts.stakingContract);
            return divide(multiply(bignumber(ratio), amount), bignumber(1e10)); // (ratio * _amount) / 1e10;
          case gbrc_Token.symbol:
            return await getTokenQuoteFromBRC({
              amount,
              swapToken: gbrc_Token.symbol,
            });
          case wbtc_Token.symbol:
            return await getTokenQuoteFromDai({
              amount: await getDaiCostFromBRCReward(
                contracts.brcContract,
                amount
              ),
              swapToken: wbtc_Token.symbol,
            });
          // TODO: Check ETH Cost
          case eth_Token.symbol:
            return await getEthQuoteFromDai({
              daiAddress: contracts.daiContract._address,
              amount: await getDaiCostFromBRCReward(
                contracts.brcContract,
                amount
              ),
            });
          case usdc_Token.symbol:
            return await getTokenQuoteFromDai({
              amount: await getDaiCostFromBRCReward(
                contracts.brcContract,
                amount
              ),
              swapToken: usdc_Token.symbol,
            });
          case usdt_Token.symbol:
            return await getTokenQuoteFromDai({
              amount: await getDaiCostFromBRCReward(
                contracts.brcContract,
                amount
              ),
              swapToken: usdt_Token.symbol,
            });
          case ust_Token.symbol:
            return await getTokenQuoteFromDai({
              amount: await getDaiCostFromBRCReward(
                contracts.brcContract,
                amount
              ),
              swapToken: ust_Token.symbol,
            });
          case dai_Token.symbol:
            return await getDaiCostFromBRCReward(contracts.brcContract, amount);
          default:
            return;
        }
      case dai_Token.symbol:
        return await getBrqCostForDaiReward(contracts, amount);
      case gbrc_Token.symbol:
        switch (otherToken) {
          case eth_Token.symbol:
            const ethQuote = await getEthQuoteFromGbrc({
              amount,
            });
            return await getDaiQuoteForEth({
              amount: ethQuote.toString(),
            });
          default:
            return;
        }
      case gbrcTokenStaking.symbol:
        switch (otherToken) {
          case brc_Token.symbol:
            const ratio = await getBRCtoGBRCRatio(contracts.stakingContract);
            return divide(multiply(amount, bignumber(1e10)), bignumber(ratio)); //(amount * 1e10) / ratio
          default:
            return bignumber(0);
        }
      default:
        return bignumber(0);
    }
  } else {
    return bignumber(0);
  }
};

export const getReward = async (
  contracts: any,
  amount: math.BigNumber,
  token?: string
) => {
  if (amount) {
    switch (token) {
      case brc_Token.symbol:
        return await getDaiRewardFromBRCCost(contracts.brcContract, amount);
      case dai_Token.symbol:
        return await getBRCRewardForDaiCost(contracts, amount);
      case usdt_Token.symbol:
        const daiOutputFromUSDT = await getDaiQuoteForToken({
          amount: amount,
          swapToken: usdt_Token.symbol,
        });
        return await getBRCRewardForDaiCost(
          contracts,
          bignumber(daiOutputFromUSDT)
        );
      case ust_Token.symbol:
        const daiOutputFromUST = await getDaiQuoteForToken({
          amount: amount,
          swapToken: ust_Token.symbol,
        });
        return await getBRCRewardForDaiCost(
          contracts,
          bignumber(daiOutputFromUST)
        );
      case usdc_Token.symbol:
        const daiOutputFromUSDC = await getDaiQuoteForToken({
          amount: amount,
          swapToken: usdc_Token.symbol,
        });
        return await getBRCRewardForDaiCost(
          contracts,
          bignumber(daiOutputFromUSDC)
        );
      case wbtc_Token.symbol:
        const daiOutputFromWBTC = await getDaiQuoteForToken({
          amount: amount,
          swapToken: wbtc_Token.symbol,
        });
        return await getBRCRewardForDaiCost(
          contracts,
          bignumber(daiOutputFromWBTC)
        );
      case eth_Token.symbol:
        const daiOutputFromETH = await getDaiQuoteForEth({
          amount: amount,
        });
        return await getBRCRewardForDaiCost(
          contracts,
          bignumber(daiOutputFromETH)
        );
      default:
        return 0;
    }
  } else {
    return 0;
  }
};

// ? BRC to gBRC ratio
export const getBRCtoGBRCRatio = async (stakingContract: Contract) => {
  const RATIO = await stakingContract.methods.getRatioBtoG().call();
  return RATIO;
};

// ? Current BRC Token Supply
export const getTokenSupply = async (contracts: any) => {
  const supply = await contracts.brcContract.methods.totalSupply().call();
  return ethers.utils.formatUnits(supply, brc_Token.decimals);
};

// ? Price of 1 BRC in DAI
export const getPrice = async (contracts: any) => {
  return await getDaiCostFromBRCReward(contracts.brcContract, bignumber(1));
};

export const getCurveData = async (contracts: any) => {
  try {
    const brincTokenSupply = await contracts.brcContract.methods
      .totalSupply()
      .call();
    const curveCollateral = await contracts.daiContract.methods
      .balanceOf(contracts.brcContract._address)
      .call();
    const reserveRatio = await contracts.brcContract.methods
      .reserveRatio()
      .call();

    const supply = brincTokenSupply;
    const reserveBalance = curveCollateral;
    const reserveWeight = reserveRatio;

    return { supply, reserveBalance, reserveWeight };
  } catch (error) {
    console.log("Failed to get Curve Data \n Error => ", error);
  }
};

//TODO: this will have to be rewritten to be more dynamic based on passed in token
export const getConversion = async (contracts: IAppContractsNonNullable) => {
  // try {
  let rewardFor1 = await getReward(contracts, bignumber(1));
  let costFor1 = await getCost(
    contracts,
    bignumber(1),
    brc_Token.symbol,
    dai_Token.symbol
  );

  return {
    rewardForOneInDAI: rewardFor1,
    priceForOneInDAI: costFor1,
  } as IConversions;
  // } catch (error) {
  //   console.log("Failed to get Conversion \n Error => ", error);
  // }
};

// What is the DAI cost of 1 BRC?
export const getDaiCostFromBRCReward = async (
  brcContract: Contract,
  amount: math.BigNumber
) => {
  try {
    const formattedAmount = ethers.utils.parseUnits(
      noExponents(amount),
      brc_Token.decimals
    );
    const cost = await brcContract.methods.mintCost(formattedAmount).call();
    const formattedCost = bignumber(
      ethers.utils.formatUnits(cost, dai_Token.decimals)
    );
    return formattedCost;
  } catch (error) {
    console.log("Failed to get Dai Cost from brq reward \n Error => ", error);
    return bignumber(0);
  }
};

// What is the DAI reward for 1 BRC?
export const getDaiRewardFromBRCCost = async (
  brcContract: Contract,
  amount: math.BigNumber
) => {
  try {
    const reward = await brcContract.methods
      .burnReward(
        ethers.utils.parseUnits(noExponents(amount), brc_Token.decimals)
      )
      .call();
    return bignumber(ethers.utils.formatUnits(reward, dai_Token.decimals)); // TODO: verify symbol
  } catch (error) {
    console.log("Failed to get dai reward from brq cost \n Error => ", error);
    return bignumber(0);
  }
};

// How much BRC will I receive for x-amount of DAI?
export const getBRCRewardForDaiCost = async (
  contracts: any,
  amount: math.BigNumber
) => {
  try {
    const { supply, reserveBalance, reserveWeight } = await getCurveData(
      contracts
    );
    const formattedAmount = ethers.utils.parseUnits(
      noExponents(amount),
      dai_Token.decimals
    );
    const cost = await contracts.brcContract.methods
      .purchaseTargetAmount(
        supply,
        reserveBalance,
        reserveWeight,
        formattedAmount
      )
      .call();
    return bignumber(ethers.utils.formatUnits(cost, brc_Token.decimals));
  } catch (error) {
    console.log("Failed to get BRC reward from Dai cost \n Error => ", error);
    return bignumber(0);
  }
};

// How much BRC must I sell to get x-amount of DAI?
export const getBrqCostForDaiReward = async (
  contracts: any,
  amount: math.BigNumber
) => {
  try {
    const { supply, reserveBalance, reserveWeight } = await getCurveData(
      contracts
    );
    const formattedAmount = ethers.utils.parseUnits(
      noExponents(amount),
      dai_Token.decimals
    );
    const cost = await contracts.brcContract.methods
      .purchaseTargetAmount(supply, reserveBalance, reserveWeight, formattedAmount)
      .call();
    const _amount = ethers.utils.formatUnits(cost, brc_Token.decimals)
    return bignumber(Number(_amount) + Number(_amount) * 0.25);
  } catch (error) {
    console.log("Failed to get BRC cost from Dai reward \n Error => ", error);
    return bignumber(0);
  }
};

async function getUSDTPriceForOne(contracts: IAppContractsNonNullable) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      brc_Token.symbol,
      usdt_Token.symbol
    );
  } catch (error) {
    console.log(
      `OOPS! Could not get price of BRC in USDT \n Error => ${error}`
    );
    return bignumber(0);
  }
}

async function getUSTPriceForOne(contracts: IAppContractsNonNullable) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      brc_Token.symbol,
      ust_Token.symbol
    );
  } catch (error) {
    console.log(`OOPS! Could not get price of BRC in UST \n Error => ${error}`);
    return bignumber(0);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
async function getWBTCPriceForOne(contracts: IAppContractsNonNullable) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      brc_Token.symbol,
      wbtc_Token.symbol
    );
  } catch (error) {
    console.log(
      `OOPS! Could not get price of BRC in wBTC \n Error => ${error}`
    );
    return bignumber(0);
  }
}

async function getUSDCPriceForOne(contracts: IAppContractsNonNullable) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      brc_Token.symbol,
      usdc_Token.symbol
    );
  } catch (error) {
    console.log(
      `OOPS! Could not get price of BRC in USDC \n Error => ${error}`
    );
    return bignumber(0);
  }
}

async function getEthPriceForOne(contracts: IAppContractsNonNullable) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      brc_Token.symbol,
      eth_Token.symbol
    );
  } catch (error) {
    console.log(`OOPS! Could not get price of BRC in ETH \n Error => ${error}`);
    return bignumber(0);
  }
}

export async function getDaiPriceForOne(contracts: IAppContractsNonNullable) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      brc_Token.symbol,
      dai_Token.symbol
    );
  } catch (error) {
    console.log(`OOPS! Could not get price of BRC in DAI \n Error => ${error}`);
    return bignumber(0);
  }
}

export async function getGBRCStakingPriceForOne(
  contracts: IAppContractsNonNullable
) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      brc_Token.symbol,
      gbrcTokenStaking.symbol
    );
  } catch (error) {
    console.log(
      `OOPS! Could not get price of BRC in gBRC \n Error => ${error}`
    );
    return bignumber(0);
  }
}

export async function getGBRCPriceForOne(contracts: IAppContractsNonNullable) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      brc_Token.symbol,
      gbrc_Token.symbol
    );
  } catch (error) {
    console.log(
      `OOPS! Could not get uniswap price of BRC in gBRC \n Error => ${error}`
    );
    return bignumber(0);
  }
}
export async function getGBRCPriceInETH(contracts: IAppContractsNonNullable) {
  try {
    return await getCost(
      contracts,
      bignumber(1),
      gbrc_Token.symbol,
      eth_Token.symbol
    );
  } catch (error) {
    console.log(
      `OOPS! Could not get uniswap price of gBRC in ETH \n Error => ${error}`
    );
    return bignumber(0);
  }
}

export const setPrices = async (contracts: IAppContractsNonNullable) => {
  const [
    fullDaiPrice,
    fullEthPrice,
    fullUsdcPrice,
    // fullWbtcPrice,
    fullUsdtPrice,
    fullUstPrice,
    fullGbrcPrice,
    fullGbrcStakingPrice,
  ] = await Promise.all([
    getDaiPriceForOne(contracts),
    getEthPriceForOne(contracts),
    getUSDCPriceForOne(contracts),
    // getWBTCPriceForOne(contracts),
    getUSDTPriceForOne(contracts),
    getUSTPriceForOne(contracts),
    // getGBRCPriceForOne(contracts), // original BRC-gBRC pricing
    getGBRCPriceInETH(contracts), // gBRC market price
    getGBRCStakingPriceForOne(contracts),
  ]);
  const daiPrice = (fullDaiPrice as any)?.toString();
  const ethPrice = (fullEthPrice as any)?.toString();
  const usdcPrice = (fullUsdcPrice as any)?.toString();
  // const wbtcPrice = fullWbtcPrice?.toString();
  const usdtPrice = (fullUsdtPrice as any)?.toString();
  const ustPrice = (fullUstPrice as any)?.toString();
  const gbrcStakingPrice = (fullGbrcStakingPrice as any)?.toString();
  const gbrcPrice = (fullGbrcPrice as any)?.toString();
  const usdPrice = daiPrice; //TODO: get USD -> DAI quote

  return {
    usdPrice: usdPrice,
    daiPrice: daiPrice,
    ethPrice: ethPrice,
    usdcPrice: usdcPrice,
    // wbtcPrice: wbtcPrice,
    usdtPrice: usdtPrice,
    ustPrice: ustPrice,
    gbrcStakingPrice: gbrcStakingPrice,
    gbrcPrice: gbrcPrice,
  } as IPrices;
};
