import React, { useEffect, useContext, useState } from "react";
import MultiSwitch from 'react-multi-switch-toggle';
import { ThemeContext } from "../../store/themeContext/themeContext";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BelowDesktop, Desktop } from "../screenHelper";
import useStyles from "./toggleSwitchStyles";

export interface Props {
	children?: React.ReactNode;
	toggleFunction: (option: number) => void;
	toggleList: string[];
	round: boolean;
	current?: number;
	disabled?: boolean;
}

function ToggleSwitch(props: Props) {
	const { theme } = useContext(ThemeContext);
	const { toggleFunction, toggleList, current } = props;
	const [selected, setSelected] = useState<number>(current);
	
	// const toggleRef = React.useRef(null);

	const classes = useStyles({ ...props, ...theme });

	useEffect(() => {
		setSelected(current);
		// const el = document.getElementById(current.toString());
		// if (el.onclick) {
		// 	el.onclick();
		// } else if (el.click) {
		// 	el.click();
		// }

		// toggleRef.current.click();
	}, [current]);

	return (
		<div className={classes.wrapper}>
			{/* <Desktop>
				<div className={`${classes.ToggleSwitch} ${props.round ? "" : "square"}`} style={props.disabled && {pointerEvents: 'none'}} onClick={ props.disabled ? event => { event.preventDefault()} : null}>
					<MultiSwitch
						texts={toggleList || ['Sample']}
						selectedSwitch={selected}
						bgColor={'transparent'}
						onToggleCallback={toggleFunction}
						fontColor={"var(--fade-white)"}
						selectedFontColor={"var(--almost-black)"}
						eachSwitchWidth={95}
						height={'45px'}
						fontSize={'15px'}
					>
					</MultiSwitch>
				</div>
			</Desktop>
			<BelowDesktop> */}
				<div className={`${classes.ToggleSwitchMobile} square `} style={props.disabled && {pointerEvents: 'none'}} onClick={ props.disabled ? event => { event.preventDefault()} : null}>
					<MultiSwitch
						texts={toggleList || ['Sample']}
						selectedSwitch={selected}
						bgColor={'transparent'}
						onToggleCallback={toggleFunction}
						fontColor={"var(--coloured-fade-white)"}
						selectedFontColor={"var(--white)"}
						eachSwitchWidth={95}
						height={'34px'}
						fontSize={'15px'}
					>
					</MultiSwitch>
				</div>
			{/* </BelowDesktop> */}
		</div>
	);
}

export default React.memo(ToggleSwitch);
