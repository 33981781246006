import React, { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./buySellBoxStyles";
import TransactionInput from "../../components/transactionInput/transactionInput";
import TokenDropdown from "../tokenDropdown/tokenDropdown";
import DisplayBalance from "../displayBalance/displayBalance";
import MaxButton from "../../components/maxButton/maxButton";
import { useSelector } from "react-redux";
import { RootState } from "../../store/mystore";
import { TX_STATUS, STAKE_PRIMARY_TOKEN, brc_Token, TOKEN_ACTION, DefaultToken, gbrc_Token, dai_Token, 
  // eth_Token, 
  // SUSHI_BRC_GBRC_PAIR_ARBITRUM_LINK, SUSHI_ETH_DAI_PAIR_ARBITRUM_LINK, 
  ScreenSize, UNISWAP_BRC_GBRC_PAIR_ARBITRUM_LINK, UNISWAP_ETH_DAI_PAIR_ARBITRUM_LINK } from "../../constants";
import { GetUSDTokenEquivalence } from "../../store/helpers/priceHelper";
import { 
  // formatNumberCommas, 
   print2ndDecimal } from "../../utils/utils";
import { bignumber } from "mathjs";
// import { BelowDesktop, Desktop } from "../screenHelper";
import BuyButton from "../buyButton/buyButton";
import { useMediaQuery } from "react-responsive";

enum AmountPercentage { _25 = 0.25, _50 = 0.50, _75 = 0.75, _100 = 1};

export interface Props {
  buyToken?: string;
  buyAmountRef?: any;
  sellAmountRef?: any;
  stakePrimaryTokenAmountRef?: any;
  stakeSecondaryTokenAmountRef?: any;
  withdrawPrimaryTokenAmountRef?: any;
  withdrawSecondaryTokenAmountRef?: any;
  tokenAction: TOKEN_ACTION;
  token: string;
  amount: number;
  defaultCurrency: string;
  showSpinner: boolean;
  onSwapInputChange?: (amount: number, token: string) => void;
  onWithdrawPrimaryInputChange?: (amount: number, token: string) => void;
  onWithdrawSecondaryInputChange?: (amount: number, token: string) => void;
  onStakePrimaryInputChange?: (amount: number, token: string) => void;
  onStakeSecondaryInputChange?: (amount: number, token: string) => void;
  setBuyToken?: (token: string) => void;
  setSellToken?: (token: string) => void;
  setBuyAmount?: (amount: number) => void;
  setSellAmount?: (amount: number) => void;
  setStakePrimaryToken?: (token: string) => void;
  setStakeSecondaryToken?: (token: string) => void;
  setStakePrimaryTokenAmount?: (amount: number) => void;
  setStakeSecondaryTokenAmount?: (amount: number) => void;
  setWithdrawPrimaryToken?: (token: string) => void;
  setWithdrawSecondaryToken?: (token: string) => void;
  setWithdrawPrimaryTokenAmount?: (amount: number) => void;
  setWithdrawSecondaryTokenAmount?: (amount: number) => void;
}

function BuySellBox(props: Props) {
  // const { state } = useContext(StoreContext);
  // const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);
  const [screen, setScreen] = useState<ScreenSize>();

  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });
  const [usdAmount, setUsdAmount] = useState(undefined);

  const isMaxDesktop = useMediaQuery({ minWidth: 1400 });

  const {
    amount,
    token,
    buyToken,
    tokenAction,
    defaultCurrency,
    showSpinner,
    sellAmountRef,
    buyAmountRef,
    stakePrimaryTokenAmountRef,
    stakeSecondaryTokenAmountRef,
    withdrawPrimaryTokenAmountRef,
    withdrawSecondaryTokenAmountRef,
    setBuyToken,
    setSellToken,
    onSwapInputChange,
    onStakePrimaryInputChange,
    onStakeSecondaryInputChange,
    onWithdrawPrimaryInputChange,
    onWithdrawSecondaryInputChange,
    setSellAmount,
    setBuyAmount,
    setStakePrimaryToken,
    setStakeSecondaryToken,
    setStakePrimaryTokenAmount,
    setStakeSecondaryTokenAmount,
    setWithdrawPrimaryToken,
    setWithdrawSecondaryToken,
    setWithdrawPrimaryTokenAmount,
    setWithdrawSecondaryTokenAmount,
  } = props;

  const autoAmount = (percentage: AmountPercentage) => {
    switch(tokenAction){
      case TOKEN_ACTION.STAKE:
        const val = Number.parseInt(state.wallet.balances[`${token.toLowerCase()}Balance`]) ;
        if(token === STAKE_PRIMARY_TOKEN){
          onStakePrimaryInputChange(val, token);
        }else{
          onStakeSecondaryInputChange(val, token);
        }
        break;
      // case TX_TYPE.WITHDRAW:
      //   if(token === WITHDRAW_PRIMARY_TOKEN){
      //     onWithdrawPrimaryInputChange(state.wallet.balances[`${token.toLowerCase()}Balance`], token);
      //   }else{
      //     onWithdrawSecondaryInputChange(state.wallet.balances[`${token.toLowerCase()}Balance`], token);
      //   }
      //   break;
      default:
        if(token !== DefaultToken.symbol){
          let bal = (Number(state.wallet.balances[`${token.toLowerCase()}Balance`]) * percentage).toString();
          if(bal.toString().includes(".")){
            bal = bal.slice(0, (bal.indexOf(".")) + 11);
          }
          onSwapInputChange(Number(bal), token);
        }
        break;
    }
  };

  useEffect(() => {
    if(isMaxDesktop){
      setScreen(ScreenSize.MAX_DESKTOP);
    }
  },[isMaxDesktop])

  useEffect(() => {
    if(token !== DefaultToken.symbol){
      let _amount = 0;
      if(isNaN(amount) || amount.toString() === ''){
        _amount = 0;
      }else if(amount.toString() === "."){
        _amount = 0.0;
      }else{
        _amount = amount;
      }
      // console.log({state, _amount, token});
      
      const tokenUSDAmount = GetUSDTokenEquivalence(state, bignumber(_amount), token);
      setUsdAmount(tokenUSDAmount);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, state.swap.prices, token])

  return (
    <>
      {/* <Desktop>
          <div className={`${classes.BuySellBox} dark-impression`}>
          <div className={classes.boxRow}>
            <div className={classes.leftContainer}>
              {((tokenAction === TOKEN_ACTION.SELL || tokenAction === TOKEN_ACTION.BUY) && 
                <TokenDropdown
                  setBuyAmount={setBuyAmount}
                  buyAmountRef={buyAmountRef}
                  sellAmountRef={sellAmountRef}
                  setSellAmount={setSellAmount}
                  token={token}
                  setToken={tokenAction === TOKEN_ACTION.BUY ? setBuyToken : setSellToken}
                  collapsible={false} //
                  // collapsible={tokenAction === TX_TYPE.BURN && token !== brc_Token.symbol} //
                />
              )}

              {(tokenAction === TOKEN_ACTION.STAKE && 
                <TokenDropdown
                  stakePrimaryTokenAmountRef={stakePrimaryTokenAmountRef}
                  stakeSecondaryTokenAmountRef={stakeSecondaryTokenAmountRef}
                  setStakePrimaryTokenAmount={setStakePrimaryTokenAmount}
                  setStakeSecondaryTokenAmount={setStakeSecondaryTokenAmount}
                  token={token}
                  setStakePrimaryToken={setStakePrimaryToken}            
                  setStakeSecondaryToken={setStakeSecondaryToken}            
                  collapsible={false}
                />
              )}

              {(tokenAction === TOKEN_ACTION.WITHDRAW && 
                <TokenDropdown
                  withdrawPrimaryTokenAmountRef={withdrawPrimaryTokenAmountRef}
                  withdrawSecondaryTokenAmountRef={withdrawSecondaryTokenAmountRef}
                  setWithdrawPrimaryTokenAmount={setWithdrawPrimaryTokenAmount}
                  setWithdrawSecondaryTokenAmount={setWithdrawSecondaryTokenAmount}
                  token={token}
                  setWithdrawPrimaryToken={setWithdrawPrimaryToken}            
                  setWithdrawSecondaryToken={setWithdrawSecondaryToken}            
                  collapsible={false}
                />
              )}
            </div>
            <div className={classes.rightContainer}>
              <TransactionInput
                defaultCurrency={defaultCurrency}
                amount={amount}
                onSwapInputChange={onSwapInputChange}
                onStakePrimaryInputChange={onStakePrimaryInputChange}
                onStakeSecondaryInputChange={onStakeSecondaryInputChange}
                onWithdrawPrimaryInputChange={onWithdrawPrimaryInputChange}
                onWithdrawSecondaryInputChange={onWithdrawSecondaryInputChange}
                token={token}
                showSpinner={showSpinner}
                transaction={tokenAction}
                isSetDefaultCurrency={buyToken === DefaultToken.symbol ? true:false}
              />
            </div>
          </div>
          <div className={classes.boxRow} style={{marginTop: "0.5rem"}}>
            <div className={classes.leftContainer}>
              <div style={{display: "flex", flexDirection: "row"}}>
                <DisplayBalance token={token} />
                {(tokenAction === TOKEN_ACTION.SELL || tokenAction === TOKEN_ACTION.STAKE || tokenAction === TOKEN_ACTION.WITHDRAW) && (
                  <MaxButton
                    disabled={(!state.wallet.isConnected) || buyToken === DefaultToken.symbol || (state.application.currentTXstatus === TX_STATUS.PENDING)}
                    handleClick={handleMax}
                  />
                )}
              </div>
            </div>
            <div className={classes.rightContainer}>
              <div className={classes.equivalentUSD}>
                ~ ${usdAmount ? handleNumberDisplay(usdAmount, true, 0) : '--'}
              </div>
            </div>        
          </div>
        </div>
      </Desktop> */}
      {/* <BelowDesktop> */}
        <div className={`${classes.BuySellBoxMobile}`}>
        <div className={classes.boxRow}>
            <div className={classes.leftContainer}>
              {(tokenAction === TOKEN_ACTION.SELL && 
                <span className={classes.mobFadeSmallText}>From:</span>
              )}
              {(tokenAction === TOKEN_ACTION.BUY && 
                <span className={classes.mobFadeSmallText}>To:</span>
              )}
            </div>
            <div className={classes.rightContainer}>
              {(tokenAction === TOKEN_ACTION.SELL || tokenAction === TOKEN_ACTION.STAKE || tokenAction === TOKEN_ACTION.WITHDRAW) && (
                <>
                  {/* <MaxButton
                    text="25%"
                    disabled={(!state.wallet.isConnected) || buyToken === DefaultToken.symbol || (state.application.currentTXstatus === TX_STATUS.PENDING)}
                    handleClick={()=>autoAmount(AmountPercentage._25)}
                  />
                  <MaxButton
                    text="50%"
                    disabled={(!state.wallet.isConnected) || buyToken === DefaultToken.symbol || (state.application.currentTXstatus === TX_STATUS.PENDING)}
                    handleClick={()=>autoAmount(AmountPercentage._50)}
                  />
                  <MaxButton
                    text="75%"
                    disabled={(!state.wallet.isConnected) || buyToken === DefaultToken.symbol || (state.application.currentTXstatus === TX_STATUS.PENDING)}
                    handleClick={()=>autoAmount(AmountPercentage._75)}
                  /> */}
                  
                  <MaxButton
                    text="max"
                    disabled={(!state.wallet.isConnected) || buyToken === DefaultToken.symbol || (state.application.currentTXstatus === TX_STATUS.PENDING)}
                    handleClick={()=>autoAmount(AmountPercentage._100)}
                  />
                </>
              )}
            </div>
          </div>
          <div className={classes.boxRow}>
            <div className={classes.leftContainer}>
              {/* <h2 className={classes.title}>{transaction}</h2> */}
              {((tokenAction === TOKEN_ACTION.SELL || tokenAction === TOKEN_ACTION.BUY) && 
                <TokenDropdown
                  setBuyAmount={setBuyAmount}
                  buyAmountRef={buyAmountRef}
                  sellAmountRef={sellAmountRef}
                  setSellAmount={setSellAmount}
                  token={token}
                  setToken={tokenAction === TOKEN_ACTION.BUY ? setBuyToken : setSellToken}
                  // collapsible={false} //
                  collapsible={tokenAction === TOKEN_ACTION.SELL && token !== brc_Token.symbol} //
                  screenSize={screen}
                />
              )}

              {(tokenAction === TOKEN_ACTION.STAKE && 
                <TokenDropdown
                  stakePrimaryTokenAmountRef={stakePrimaryTokenAmountRef}
                  stakeSecondaryTokenAmountRef={stakeSecondaryTokenAmountRef}
                  setStakePrimaryTokenAmount={setStakePrimaryTokenAmount}
                  setStakeSecondaryTokenAmount={setStakeSecondaryTokenAmount}
                  token={token}
                  setStakePrimaryToken={setStakePrimaryToken}            
                  setStakeSecondaryToken={setStakeSecondaryToken}            
                  collapsible={false}
                  screenSize={screen}
                />
              )}

              {(tokenAction === TOKEN_ACTION.WITHDRAW && 
                <TokenDropdown
                  withdrawPrimaryTokenAmountRef={withdrawPrimaryTokenAmountRef}
                  withdrawSecondaryTokenAmountRef={withdrawSecondaryTokenAmountRef}
                  setWithdrawPrimaryTokenAmount={setWithdrawPrimaryTokenAmount}
                  setWithdrawSecondaryTokenAmount={setWithdrawSecondaryTokenAmount}
                  token={token}
                  setWithdrawPrimaryToken={setWithdrawPrimaryToken}            
                  setWithdrawSecondaryToken={setWithdrawSecondaryToken}            
                  collapsible={false}
                  screenSize={screen}
                />
              )}
              
            </div>
            <div className={classes.rightContainer}>
              <TransactionInput
                defaultCurrency={defaultCurrency}
                amount={amount}
                onSwapInputChange={onSwapInputChange}
                onStakePrimaryInputChange={onStakePrimaryInputChange}
                onStakeSecondaryInputChange={onStakeSecondaryInputChange}
                onWithdrawPrimaryInputChange={onWithdrawPrimaryInputChange}
                onWithdrawSecondaryInputChange={onWithdrawSecondaryInputChange}
                token={token}
                showSpinner={showSpinner}
                transaction={tokenAction}
                isSetDefaultCurrency={buyToken === DefaultToken.symbol ? true:false}
              />
            </div>
          </div>
          <div className={`${classes.boxRow}`} style={{height: '22px'}}>
            <div className={`${classes.leftContainer}`}>
              {/* {token !== DefaultToken.symbol && */}
              <div style={{display: "flex", flexDirection: "row", color: "var(--coloured-fade-white)"}}>
                <DisplayBalance token={token} />
              </div>

              {(token === gbrc_Token.symbol) &&
                <div style={{height: "auto", marginRight: "10px", zIndex: 500, color: 'rgb(139, 142, 184) !important'}}>
                  <BuyButton
                    disabled={(!state.wallet.isConnected) || buyToken === DefaultToken.symbol || (state.application.currentTXstatus === TX_STATUS.PENDING)}
                    handleClick={() => window.open(UNISWAP_BRC_GBRC_PAIR_ARBITRUM_LINK , '_blank')}
                  />
                </div>
              }
              {(token === dai_Token.symbol && tokenAction === TOKEN_ACTION.SELL) &&
                <div style={{height: "auto", marginRight: "10px", zIndex: 500, color: 'rgb(139, 142, 184) !important'}}>
                  <BuyButton
                    disabled={(!state.wallet.isConnected) || buyToken === DefaultToken.symbol || (state.application.currentTXstatus === TX_STATUS.PENDING)}
                    handleClick={() => window.open( UNISWAP_ETH_DAI_PAIR_ARBITRUM_LINK, '_blank')}
                  />
                </div>
              }
            </div>
            <div className={classes.rightContainer}>
              <div className={`${classes.equivalentUSD} colouredFade`}>
                ~ ${usdAmount ? print2ndDecimal(usdAmount) : '--'}
              </div>
            </div>        
          </div>
        </div>
      {/* </BelowDesktop> */}
    </>
  );
}

export default React.memo(BuySellBox);
