import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
// import { BelowDesktop, Desktop } from "../screenHelper";
import useStyles from "./cardMainStyles";

export interface Props {
	children?: React.ReactNode;
}

function CardMain(props: Props) {
	const { theme } = useContext(ThemeContext);

	const classes = useStyles({ ...props, ...theme });

	return (
		<>
		{/* <Desktop>
			<div className={classes.CardMain}>
				{props.children}
			</div>
		</Desktop>
		<BelowDesktop> */}
			<div className={classes.CardMainMobile}>
				{props.children}
			</div>
		{/* </BelowDesktop> */}
		</>
	);
}

export default React.memo(CardMain);
