import React, { useContext } from "react";
import { ThemeContext } from "../../../store/themeContext/themeContext";
import useStyles from "./alertUserStyles";
// import { InfoIcon } from "../../icons/icons";
import FillButton from "../../button/button";
import PopupModal from "../../popupModal/popupModal";
import { addNetworkToWallet } from "../../../store/helpers/addNetworkHelper";


export interface Props {
  closeModal: () => void;
}

function AlertUser(props: Props) {
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });

  return (
    <PopupModal closeModal={props.closeModal}>
			<section className="pop__md__w pop__wrap">
				<header className="pop__head" style={{display: "flex", justifyContent:"space-between", hyphens: "auto", wordBreak: "break-word"}}>
					<h2 className={classes.heading}>Wrong Network</h2>
					{/* <button type="button" className="pop__close" onClick={() => window.location.reload()}>
            {/* props.closeModal 
						<span className="close"><img src={require("../../../media/icons/ico-close.svg").default} alt=""/></span>
					</button> */}
				</header>
				<main className="pop__body">
          <section className="" style={{marginTop:"2rem"}}>
              <h2 className="tit__md">
              You need to be on the Arbitrum One network.<br />
              Please switch your network to Arbitrum or “Add Network”
              to input the following information in your wallet:
              </h2>							
              <ul className="alignment__list">
                <li className="alignment__list__item">
                  <div className="alignment__box">
                    <span className="alignment__tit">
                      Name: 
                    </span>
                    <p className="alignment__txt">
                      <span>Arbitrum One</span>
                    </p>
                  </div>
                </li>
                <li className="alignment__list__item">
                  <div className="alignment__box">
                    <span className="alignment__tit">
                      URL:
                    </span>
                    <p className="alignment__txt">
                      <span>https://arb1.arbitrum.io/rpc</span>
                    </p>
                  </div>
                </li>
                <li className="alignment__list__item">
                  <div className="alignment__box">
                    <span className="alignment__tit">
                    Chain ID:
                    </span>
                    <p className="alignment__txt">
                      <span>42161</span>
                    </p>
                  </div>
                </li>
                {/* <li className="alignment__list__item">
                  <div className="alignment__box">
                    <span className="alignment__ico"><img src={require("../../../media/icons/ico-help-circle.svg").default} alt=""/></span>
                    <p className="alignment__txt">
                      <span>Arbitrum Rinkeby (Testnet)</span>
                    </p>
                  </div>
                  <div className="alignment__box">
                    <p className="alignment__txt">
                      
                    </p>
                    <span className="alignment__ico">
                      
                    </span>
                  </div>
                </li> */}
              </ul>
          </section>
        </main>
        <footer className="pop__foot" style={{marginTop: "7rem"}}>
          <FillButton
            label={"Add/Switch Network"} 
            clickFunction={() => addNetworkToWallet()}             
          />
        </footer>
			</section>
		</PopupModal>
  );
}

export default React.memo(AlertUser);
