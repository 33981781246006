import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import { useSelector } from "react-redux";
import useStyles from "./transactionInputStyles";
import { Spinner } from "../icons/icons";
import { RootState } from "../../store/mystore";
import {
  TX_STATUS,
  TOKEN_ACTION,
  STAKE_PRIMARY_TOKEN,
  DefaultToken,
  brc_Token,
  // eth_Token,
  dai_Token,
} from "../../constants";
import {
  checkIfNumber,
  eraseZeroIfFirstNonDecimalDigit,
  formatNumberCommas,
  //   handleNumberDisplay,
} from "../../utils/utils";

export interface Props {
  onSwapInputChange?: (amount: number | "", token: string) => void;
  onWithdrawPrimaryInputChange?: (amount: number | "", token: string) => void;
  onWithdrawSecondaryInputChange?: (amount: number | "", token: string) => void;
  onStakePrimaryInputChange?: (amount: number | "", token: string) => void;
  onStakeSecondaryInputChange?: (amount: number | "", token: string) => void;
  transaction: TOKEN_ACTION;
  amount: number;
  defaultCurrency: string;
  isSetDefaultCurrency: boolean;
  token: string;
  showSpinner: boolean;
}

function TransacationInput(props: Props) {
  const state = useSelector((state: RootState) => state);
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });

  const {
    amount,
    token,
    transaction,
    onSwapInputChange,
    onStakePrimaryInputChange,
    onStakeSecondaryInputChange,
    // onWithdrawPrimaryInputChange,
    // onWithdrawSecondaryInputChange,
    // defaultCurrency,
    isSetDefaultCurrency,
  } = props;

  const handleFocus = (e: any) => {
    let val = e.target.value;
    if (val && Number(val) === 0) {
      // if(val && Number.parseInt(val) === 0){
      switch (transaction) {
        case TOKEN_ACTION.STAKE:
          if (token === STAKE_PRIMARY_TOKEN) {
            onStakePrimaryInputChange("", token);
          } else {
            onStakeSecondaryInputChange("", token);
          }
          break;
        case TOKEN_ACTION.WITHDRAW:
          break;
        default:
          if (token !== DefaultToken.symbol) {
            onSwapInputChange("", token);
          }
          break;
      }
    }
  };

  const handleChange = (e: any) => {
    let value = e.target.value;
    value = value.toString().replaceAll(",", "");
    value = value.toString().replaceAll(" ", "");
    if (transaction === TOKEN_ACTION.STAKE && value.toString().includes(".")) {
      value = Number.parseInt(value.toString());
    }
    let val = eraseZeroIfFirstNonDecimalDigit(value);
    // if(val === undefined || value === undefined){val = 0}
    // let val = eraseZeroIfFirstNonDecimalDigit(e.target.value);

    if (checkIfNumber(val)) {
      
      switch (transaction) {
        case TOKEN_ACTION.STAKE:
          if (token === STAKE_PRIMARY_TOKEN) {
            onStakePrimaryInputChange(val, token);
          } else {
            onStakeSecondaryInputChange(val, token);
          }
          break;
        case TOKEN_ACTION.WITHDRAW:
          // if(token === WITHDRAW_PRIMARY_TOKEN){
          //   onWithdrawPrimaryInputChange(val, token);
          // }else{
          //   onWithdrawSecondaryInputChange(val, token);
          // }
          break;
        default:
          // console.log('details', {token, defaultCurrency, isSetDefaultCurrency});
          if (isSetDefaultCurrency) {
            onSwapInputChange("", token);
          } else {
            onSwapInputChange(val, token);
          }
          break;
      }
    }
  };
  
  return (
    <>
      {props.showSpinner ? (
        <Spinner className={classes.spinner} />
      ) : (
        <>
          {/* <Desktop>
          <input
            disabled={ isSetDefaultCurrency || token === DefaultToken.symbol || state.application.currentTXstatus === TX_STATUS.PENDING || !state.wallet.isConnected}
            value={formatNumberCommas(amount.toString(), true, false)}
            onFocus={handleFocus}
            onChange={handleChange}
            type="text"
            className={`transactionInput ${classes.TransactionInput}`}
            placeholder="0"
            autoComplete="off"
            autoCorrect="off"
            pattern="^[0-9]*[,.]?[0-9]*$"
          ></input>
        </Desktop>
        <BelowDesktop> */}
          <input
            disabled={
              isSetDefaultCurrency ||
              token === DefaultToken.symbol ||
              state.application.currentTXstatus === TX_STATUS.PENDING ||
              !state.wallet.isConnected || 
              (token === brc_Token.symbol && transaction === TOKEN_ACTION.BUY) ||
              (token === dai_Token.symbol && transaction === TOKEN_ACTION.BUY) ||
              token === "init" ||
              !state.wallet.isConnected
            }
            value={amount > 0 - 0.99 ? formatNumberCommas(amount.toString(), true, false, true) : ""}
            // value={
            //   token !== eth_Token.symbol
            //     ? amount > 0
            //       ? amount.toString()
            //       : ""
            //     : amount > 0
            //     ? formatNumberCommas(amount.toString(), true, false, true)
            //     : ""
            // }
            onFocus={handleFocus}
            onChange={handleChange}
            type="text"
            className={`transactionInput ${classes.mobTransactionInput} ${
              transaction === TOKEN_ACTION.BUY ? "colouredFade" : null
            }`}
            placeholder={
              transaction === TOKEN_ACTION.SELL ||
              (token === brc_Token.symbol && transaction === TOKEN_ACTION.STAKE)
                ? "Enter Amount"
                : "0"
            }
            autoComplete="off"
            autoCorrect="off"
            pattern="^[0-9]*[,.]?[0-9]*$"
          ></input>
          {/* </BelowDesktop> */}
        </>
      )}
    </>
  );
}

export default React.memo(TransacationInput);
