import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  FAIL_REASON,
  SET_STATE_STATUS,
  SwapTXType,
  TX_STATUS,
  TX_STEP,
} from "../../constants";
import { IAppContractsNonNullable } from "../application/AppSlice";
import {
  getConversion,
  IConversions,
  IPrices,
  setPrices,
} from "../services/pricesAndConversions";
import {
  SwapPayload,
  swapEthForDai,
  swapTokenForDai,
  ISwapState,
} from "./Swap";

const initialState: ISwapState = {
  conversions: {
    priceForOneInDAI: 0,
    rewardForOneInDAI: 0,
    status: SET_STATE_STATUS.NOT_INITIALIZED,
  },
  prices: {
    usdPrice: "",
    daiPrice: "",
    ethPrice: "",
    usdcPrice: "",
    usdtPrice: "",
    wbtcPrice: "",
    gbrcStakingPrice: "",
    gbrcPrice: "",
    status: SET_STATE_STATUS.NOT_INITIALIZED,
  },
  tx: {
    type: SwapTXType.NOT_SET,
    status: TX_STATUS.NOT_INITIALIZED,
    data: {
      txStep: TX_STEP.NOT_INITIALIZED,
      nextTXStep: TX_STEP.NOT_INITIALIZED,
      successData: {},
      failData: {},
      reasonForFail: FAIL_REASON.NOT_SET,
    },
  },
};

const setConversionsAsync = createAsyncThunk(
  "swap/setConversions",
  async (data: IAppContractsNonNullable, { rejectWithValue }) => {
    try {
      const response = await getConversion(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const setPricesAsync = createAsyncThunk(
  "swap/setPrices",
  async (data: IAppContractsNonNullable, { rejectWithValue }) => {
    try {
      const response = await setPrices(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const swapEthForDaiAsync = createAsyncThunk(
  "swap/ethForDai",
  async (data: SwapPayload, { rejectWithValue }) => {
    try {
      const response = await swapEthForDai(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const swapERC20TokenForDaiAsync = createAsyncThunk(
  "swap/tokenForDai",
  async (data: SwapPayload, { rejectWithValue }) => {
    try {
      const response = await swapTokenForDai(data);
      console.log("swapERC20TokenForDaiAsync", response);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const swapSliceReducer = createSlice({
  name: "swap",
  initialState,
  reducers: {
    resetSwapTXState: (state) => {
      state.tx = {
        type: SwapTXType.NOT_SET,
        status: TX_STATUS.NOT_INITIALIZED,
        data: {
          txStep: TX_STEP.NOT_INITIALIZED,
          nextTXStep: TX_STEP.NOT_INITIALIZED,
          successData: {},
          failData: {},
          reasonForFail: FAIL_REASON.NOT_SET,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // conversions
      .addCase(setConversionsAsync.pending, (state) => {
        state.conversions.status = SET_STATE_STATUS.PENDING;
      })
      .addCase(setConversionsAsync.rejected, (state) => {
        state.conversions.status = SET_STATE_STATUS.FAILED;
      })
      .addCase(setConversionsAsync.fulfilled, (state, { payload }: any) => {
        //: PayloadAction<IConversions>
        if (typeof payload === undefined) {
          state.conversions.status = SET_STATE_STATUS.FAILED;
        } else {
          state.conversions = payload as IConversions;
          state.conversions.status = SET_STATE_STATUS.SUCCESS;
        }
      })

      // set swap prices
      .addCase(setPricesAsync.pending, (state) => {
        state.prices.status = SET_STATE_STATUS.PENDING;
      })
      .addCase(setPricesAsync.rejected, (state) => {
        state.prices.status = SET_STATE_STATUS.FAILED;
      })
      .addCase(setPricesAsync.fulfilled, (state, { payload }: any) => {
        //PayloadAction<IPrices>
        if (typeof payload === undefined) {
          state.prices.status = SET_STATE_STATUS.FAILED;
        } else {
          state.prices = payload as IPrices;
          state.prices.status = SET_STATE_STATUS.SUCCESS;
        }
      })

      // swap ETH for DAI states
      // TODO: Handle (verify) when swap is rejected
      .addCase(swapEthForDaiAsync.pending, (state) => {
        state.tx.status = TX_STATUS.PENDING;
        state.tx.type = SwapTXType.ETH_TO_DAI;
        state.tx.data.txStep = TX_STEP.SWAP;
        state.tx.data.failData = {};
        state.tx.data.successData = {};
      })
      .addCase(swapEthForDaiAsync.rejected, (state, action) => {
        // TODO: error handling for contract calls
        state.tx.status = TX_STATUS.REJECTED;
        state.tx.type = SwapTXType.ETH_TO_DAI;
        state.tx.data.failData = action.payload;
        state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
      })
      .addCase(swapEthForDaiAsync.fulfilled, (state, action) => {
        if (
          typeof action.payload === undefined ||
          (action.payload as any).error
        ) {
          state.tx.status = TX_STATUS.FAILED;
          state.tx.type = SwapTXType.ETH_TO_DAI;
          state.tx.data.failData = action.payload.message;
          state.tx.data.successData = {};
          state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
        } else {
          // update TX step for next
          state.tx.data.nextTXStep = TX_STEP.MINT_OR_BURN;
          state.tx.data.failData = {};
          state.tx.status = TX_STATUS.SUCCESS;
          state.tx.type = SwapTXType.ETH_TO_DAI;
          state.tx.data.successData = action.payload;
        }
        // console.log('NEXT STEP', state.tx.data.txStep);
      })

      // swap ERC20 Token for DAI states
      // TODO: Handle (verify) when swap is rejected
      .addCase(swapERC20TokenForDaiAsync.pending, (state) => {
        state.tx.status = TX_STATUS.PENDING;
        state.tx.type = SwapTXType.ERC20Token_TO_DAI;
        state.tx.data.txStep = TX_STEP.SWAP;
        state.tx.data.failData = {};
        state.tx.data.successData = {};
      })
      .addCase(swapERC20TokenForDaiAsync.rejected, (state, action) => {
        state.tx.status = TX_STATUS.REJECTED;
        state.tx.type = SwapTXType.ERC20Token_TO_DAI;
        state.tx.data.failData = action.payload;
        state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
      })
      .addCase(swapERC20TokenForDaiAsync.fulfilled, (state, action) => {
        if (
          typeof action.payload === undefined ||
          (action.payload as any).error
        ) {
          state.tx.status = TX_STATUS.FAILED;
          state.tx.type = SwapTXType.ERC20Token_TO_DAI;
          state.tx.data.failData = action.payload.message;
          state.tx.data.successData = {};
          state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
        } else {
          // update TX step for next
          state.tx.data.nextTXStep = TX_STEP.MINT_OR_BURN;
          state.tx.data.failData = {};
          state.tx.status = TX_STATUS.SUCCESS;
          state.tx.type = SwapTXType.ERC20Token_TO_DAI;
          state.tx.data.successData = action.payload;
        }
      });
  },
});

export {
  setConversionsAsync,
  setPricesAsync,
  swapEthForDaiAsync,
  swapERC20TokenForDaiAsync,
};
export const { resetSwapTXState } = swapSliceReducer.actions;
export default swapSliceReducer.reducer;
export const swapSelector = (state: { swapState: ISwapState }) =>
  state.swapState;
