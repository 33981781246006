import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./toggleSwitch";

const useStyles = makeStyles(() =>
	createStyles({
		ToggleSwitch: {
			font: (style: Theme & Props) => style.typography.navItem,
			padding: "0px 2px",
			height: "55px",
			width: "auto",						
			borderRadius: (props: Props) => props.round ? "50px" : "12px",
			background: "#202226",
			boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.75), inset -2px -2px 2px #4E5154",			
		},
		ToggleSwitchMobile: {
			font: (style: Theme & Props) => style.typography.navItem,
			marginTop: "-10px",
			padding: "0px 2px",
			height: "40px",
			width: "auto",						
			// borderRadius: (props: Props) => props.round ? "50px" : "12px",
			borderRadius: "10px",
			background: "var(--nearBlack)",
			// boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.75), inset -2px -2px 2px #4E5154",			
		},
		wrapper: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}
	})
);

export default useStyles;
