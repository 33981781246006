import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./popupModal";

const useStyles = makeStyles(() =>
	createStyles({
		PopupModal: {
			backgroundColor: (style: Props & Theme) => style.background2,
			background: (style: Props & Theme) => style.bgGradient2,
			width: '65rem',
			minHeight: '50rem',
			zIndex: 7000,
			margin: 'auto',
			borderRadius: '24px',
			overflow: 'hidden',
			padding: '4rem'
		},

	})
);

export default useStyles;
