import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./walletOptionButton";

const useStyles = makeStyles(() =>
  createStyles({
    WalletOptionButton: {
      // backgroundColor: "transparent",
      color: (style: Theme & Props) => style.text,
      width: "100%",
      // height: (style: Theme & Props) =>
      //   style.numberOfWallets === 3 ? "30%" : "40%",
      height: "80px",
      borderRadius: "10px",
      marginTop: "2rem",
      border: (style: Theme & Props) =>
        style.selected
          ? `2px solid var(--blue)`
          : ``,
      "&:hover": {
        // filter: "brightness(1.2)",
        border: "2px solid var(--blue)",
      },
    },
    container: {
      width: "100%",
      height: "55%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // justifyContent: "space-between",
    },
    labelContainer: {
      display: "flex",
      // justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: "0",
      width: "fit-content",
    },
    label: {
      margin: "0",
      fontFamily: "var(--font1)",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      color: (style: Theme & Props) => style.text,
      lineHeight: "20px",
      letterSpacing: ".76px",
      textTransform: "capitalize",
    },
    icon: {
      height: "3.5rem",
      width: "3.5rem",
      margin: "auto 3.5rem",
    },
    check: {
      margin: "auto auto auto 2rem",
      height: "80%",
      width: "4rem",
      color: "var(--blue)",
    },
  })
);

export default useStyles;
