import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  getBrcGBRC7DaysAPY,
  getBrcGBRC30DaysAPY,
  getBrcGBRC90DaysAPY,
  getBrcOnly7DaysAPY,
  getBrcOnly30DaysAPY,
  getBrcOnly90DaysAPY,
  getTotalDAIReserve,
  UseAllContractsPayload,
  BasicContractCallPayload,
  getBRCTotalCirculatingSupply,
  getGBRCTotalCirculatingSupply,
  setTreasuryAssets,
  getBRCPercentStaked,
  getBrcOnly7DaysAPR,
  getBrcOnly30DaysAPR,
  getBrcOnly90DaysAPR,
  // eslint-disable-next-line no-useless-rename
  getBrcGBRC7DaysAPR as getBrcGBRC7DaysAPR,
  getBrcGBRC30DaysAPR,
  getBrcGBRC90DaysAPR,
  getGBRCPercentStaked,
  getGBRCPriceFeed,
  getBRCPriceData__24hr__7d,
  getChartBRCPrices,
  getPoolsSupply,
  PoolsSupply,
} from "./Analytics";
import { RootState } from "../mystore";

export type TokenPriceInfo = {
  currentPrice?: number;
  change24hrPercent: number;
  change7dPercent: number;
  chartData?: any;
};

export interface ITreasuryAssets {
  dai: number | string;
  brc: number | string;
  eth: number | string;
  gbrc: number | string;
  ethUsd: number | string ;
  gbrcUsd: number | string;
}
export interface IAnalyticsState {
  brcGBRCStakingAPR?: number | string;
  brcOnlyStakingAPR?: number | string;
  brcGBRCStakingAPY?: number | string;
  brcOnlyStakingAPY?: number | string;

  brcOnly7DaysAPY: number | string;
  brcOnly30DaysAPY: number | string;
  brcOnly90DaysAPY: number | string;
  brcGBRC7DaysAPY: number | string;
  brcGBRC30DaysAPY: number | string;
  brcGBRC90DaysAPY: number | string;

  brcOnly7DaysSUPPLY: number | string;
  brcOnly30DaysSUPPLY: number | string;
  brcOnly90DaysSUPPLY: number | string;
  brcGBRC7DaysSUPPLY: number | string;
  brcGBRC30DaysSUPPLY: number | string;
  brcGBRC90DaysSUPPLY: number | string;

  brcOnly7DaysAPR?: number | string;
  brcOnly30DaysAPR?: number | string;
  brcOnly90DaysAPR?: number | string;
  brcGBRC7DaysAPR?: number | string;
  brcGBRC30DaysAPR?: number | string;
  brcGBRC90DaysAPR?: number | string;

  brcPriceUSD: number | string;
  brcAverageBuyPriceUSD: number | string;
  gbrcAverageBuyPriceUSD: number | string;

  totalReserveDAI: number | string;
  brcTotalCirculatingSupply: number | string;
  gbrcTotalCirculatingSupply: number | string;
  brcTotalSupply: number | string;
  gbrcTotalSupply: number | string;

  treasuryAssets: ITreasuryAssets;
  brcPercentStaked: number | string;
  gbrcPercentStaked: number | string;

  gbrcPriceInfo?: TokenPriceInfo;
  brcPriceInfo?: TokenPriceInfo;

  marketCap?: number;
}

const initialState: IAnalyticsState = {
  brcGBRCStakingAPR: 0,
  brcOnlyStakingAPR: 0,
  brcGBRCStakingAPY: 0,
  brcOnlyStakingAPY: 0,
  brcOnly7DaysAPY: 0,
  brcOnly30DaysAPY: 0,
  brcOnly90DaysAPY: 0,
  brcGBRC7DaysAPY: 0,
  brcGBRC30DaysAPY: 0,
  brcGBRC90DaysAPY: 0,
  brcOnly7DaysSUPPLY: 0,
  brcOnly30DaysSUPPLY: 0,
  brcOnly90DaysSUPPLY: 0,
  brcGBRC7DaysSUPPLY: 0,
  brcGBRC30DaysSUPPLY: 0,
  brcGBRC90DaysSUPPLY: 0,
  brcOnly7DaysAPR: 0,
  brcOnly30DaysAPR: 0,
  brcOnly90DaysAPR: 0,
  brcGBRC7DaysAPR: 0,
  brcGBRC30DaysAPR: 0,
  brcGBRC90DaysAPR: 0,
  brcPriceUSD: 0,
  totalReserveDAI: 0,
  brcTotalCirculatingSupply: 0,
  gbrcTotalCirculatingSupply: 0,
  brcTotalSupply: 0,
  gbrcTotalSupply: 0,
  treasuryAssets: {
    dai: 0,
    brc: 0,
    eth: 0,
    gbrc: 0,
    ethUsd: 0,
    gbrcUsd: 0,
  },
  brcPercentStaked: 0,
  gbrcPercentStaked: 0,
  brcAverageBuyPriceUSD: 0,
  gbrcAverageBuyPriceUSD: 0,
  gbrcPriceInfo: {
    currentPrice: 0,
    change24hrPercent: 0,
    change7dPercent: 0,
  },
  brcPriceInfo: {
    currentPrice: 0,
    change24hrPercent: 0,
    change7dPercent: 0,
  },
  marketCap: 0,
};

export type IAPYs = {
  brcGBRCStakingAPY: any;
  brcOnlyStakingAPY: any;
  brcGBRCStakingAPR: any;
  brcOnlyStakingAPR: any;
  brcOnly7DaysAPY: any;
  brcOnly30DaysAPY: any;
  brcOnly90DaysAPY: any;
  brcGBRC7DaysAPY: any;
  brcGBRC30DaysAPY: any;
  brcGBRC90DaysAPY: any;
  brcOnly7DaysAPR: any;
  brcOnly30DaysAPR: any;
  brcOnly90DaysAPR: any;
  brcGBRC7DaysAPR: any;
  brcGBRC30DaysAPR: any;
  brcGBRC90DaysAPR: any;
};

const setAPY_APRsAsync = createAsyncThunk(
  "analytics/setAPY_APRs",
  async (data: BasicContractCallPayload, { rejectWithValue }) => {
    try {
      const _brcOnly7DaysAPY = Number.parseFloat(
        await getBrcOnly7DaysAPY(data)
      );
      const _brcOnly30DaysAPY = Number.parseFloat(
        await getBrcOnly30DaysAPY(data)
      );
      const _brcOnly90DaysAPY = Number.parseFloat(
        await getBrcOnly90DaysAPY(data)
      );
      const _brcGBRC7DaysAPY = Number.parseFloat(
        await getBrcGBRC7DaysAPY(data)
      );
      const _brcGBRC30DaysAPY = Number.parseFloat(
        await getBrcGBRC30DaysAPY(data)
      );
      const _brcGBRC90DaysAPY = Number.parseFloat(
        await getBrcGBRC90DaysAPY(data)
      );

      const _brcOnly7DaysAPR = Number.parseFloat(
        await getBrcOnly7DaysAPR(data)
      );
      const _brcOnly30DaysAPR = Number.parseFloat(
        await getBrcOnly30DaysAPR(data)
      );
      const _brcOnly90DaysAPR = Number.parseFloat(
        await getBrcOnly90DaysAPR(data)
      );
      const _brcGBRC7DaysAPR = Number.parseFloat(
        await getBrcGBRC7DaysAPR(data)
      );
      const _brcGBRC30DaysAPR = Number.parseFloat(
        await getBrcGBRC30DaysAPR(data)
      );
      const _brcGBRC90DaysAPR = Number.parseFloat(
        await getBrcGBRC90DaysAPR(data)
      );
      // console.log('apr',{
      //     _brcOnly30DaysAPR,
      //     _brcOnly90DaysAPR,
      //     _brcOnly180DaysAPR,
      //     _brcGBRC30DaysAPR,
      //     _brcGBRC90DaysAPR,
      //     _brcGBRC180DaysAPR
      // })

      const _brcGBRCStakingAPY =
        (_brcGBRC7DaysAPY + _brcGBRC30DaysAPY + _brcGBRC90DaysAPY) / 3;
      const _brcOnlyStakingAPY =
        (_brcOnly7DaysAPY + _brcOnly30DaysAPY + _brcOnly90DaysAPY) / 3;

      const _brcGBRCStakingAPR =
        (_brcGBRC7DaysAPR + _brcGBRC30DaysAPR + _brcGBRC90DaysAPR) / 3;
      const _brcOnlyStakingAPR =
        (_brcOnly7DaysAPR + _brcOnly30DaysAPR + _brcOnly90DaysAPR) / 3;

      const APYs: IAPYs = {
        brcGBRCStakingAPR: _brcGBRCStakingAPR,
        brcOnlyStakingAPR: _brcOnlyStakingAPR,
        brcGBRCStakingAPY: _brcGBRCStakingAPY,
        brcOnlyStakingAPY: _brcOnlyStakingAPY,
        brcOnly7DaysAPY: _brcOnly7DaysAPY,
        brcOnly30DaysAPY: _brcOnly30DaysAPY,
        brcOnly90DaysAPY: _brcOnly90DaysAPY,
        brcGBRC7DaysAPY: _brcGBRC7DaysAPY,
        brcGBRC30DaysAPY: _brcGBRC30DaysAPY,
        brcGBRC90DaysAPY: _brcGBRC90DaysAPY,
        brcOnly7DaysAPR: _brcOnly7DaysAPR,
        brcOnly30DaysAPR: _brcOnly30DaysAPR,
        brcOnly90DaysAPR: _brcOnly90DaysAPR,
        brcGBRC7DaysAPR: _brcGBRC7DaysAPR,
        brcGBRC30DaysAPR: _brcGBRC30DaysAPR,
        brcGBRC90DaysAPR: _brcGBRC90DaysAPR,
      };
      // console.log('setAPYsAsync', APYs);

      // The value we return becomes the `fulfilled` action payload
      return APYs;
    } catch (error) {
      console.log("setAPYsAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setTotalDAIReserveAsync = createAsyncThunk(
  "analytics/setTotalReserve",
  async (data: BasicContractCallPayload, { rejectWithValue }) => {
    try {
      const totalReserve: number = await getTotalDAIReserve(data);

      // The value we return becomes the `fulfilled` action payload
      return totalReserve;
    } catch (error) {
      console.log("setTotalReserveAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setPoolsSupplyAsync = createAsyncThunk(
  "analytics/setPoolsSupply",
  async (data: UseAllContractsPayload, { rejectWithValue }) => {
    try {
      const poolsSupply = await getPoolsSupply({
        stakingContract: data.contracts.stakingContract,
      });

      // The value we return becomes the `fulfilled` action payload
      return poolsSupply;
    } catch (error) {
      console.log("setPoolsSupplyAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setGBRCTotalCirculatingSupplyAsync = createAsyncThunk(
  "analytics/setGBRCTotalCirculatingSupply",
  async (data: BasicContractCallPayload, { rejectWithValue }) => {
    try {
      const result = await getGBRCTotalCirculatingSupply(data);

      // The value we return becomes the `fulfilled` action payload
      return {
        totalSupply: result.totalSupply,
        totalCirculatingSupply: result.totalCirculatingSupply,
      };
    } catch (error) {
      console.log("setGBRCTotalCirculatingSupplyAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setGBRCPriceInfoAsync = createAsyncThunk(
  "analytics/setGBRCPriceInfoAsync",
  async (data: RootState, { rejectWithValue }) => {
    try {
      const priceInfo: TokenPriceInfo = await getGBRCPriceFeed(data);

      // The value we return becomes the `fulfilled` action payload
      return priceInfo;
    } catch (error) {
      console.log("setGBRCPriceInfoAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setBRCPriceInfoAsync = createAsyncThunk(
  "analytics/setBRCPriceInfoAsync",
  async (data = null, { rejectWithValue }) => {
    try {
      const priceInfo: TokenPriceInfo = await getBRCPriceData__24hr__7d();

      // The value we return becomes the `fulfilled` action payload
      return priceInfo;
    } catch (error) {
      console.log("setBRCPriceInfoAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setBRCPriceChartInfoAsync = createAsyncThunk(
  "analytics/setBRCPriceChartInfoAsync",
  async (data = null, { rejectWithValue }) => {
    try {
      const chartData = await getChartBRCPrices();

      // The value we return becomes the `fulfilled` action payload
      return chartData;
    } catch (error) {
      console.log("setBRCPriceChartInfoAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setBRCTotalCirculatingSupplyAsync = createAsyncThunk(
  "analytics/setBRCTotalCirculatingSupply",
  async (data: BasicContractCallPayload, { rejectWithValue }) => {
    try {
      const result = await getBRCTotalCirculatingSupply(data);

      // The value we return becomes the `fulfilled` action payload
      return {
        totalSupply: result.totalSupply,
        totalCirculatingSupply: result.totalCirculatingSupply,
      };
    } catch (error) {
      console.log("setBRCTotalCirculatingSupplyAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setTreasuryAssetsAsync = createAsyncThunk(
  "analytics/setTreasuryAssets",
  async (data: BasicContractCallPayload, { rejectWithValue }) => {
    try {
      const treasuryAssets: ITreasuryAssets = await setTreasuryAssets(data);

      // The value we return becomes the `fulfilled` action payload
      return treasuryAssets;
    } catch (error) {
      console.log("setTreasuryAssetsAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const setPercentageStakeAsync = createAsyncThunk(
  "analytics/setPercentStake",
  async (data: BasicContractCallPayload, { rejectWithValue }) => {
    try {
      const brcPercentStake = await getBRCPercentStaked(data);
      const gbrcPercentStake = await getGBRCPercentStaked(data);

      const percentStakes = {
        brcPercentStake: brcPercentStake,
        gbrcPercentStake: gbrcPercentStake,
      };

      // The value we return becomes the `fulfilled` action payload
      return percentStakes;
    } catch (error) {
      console.log("setPercentageStakeAsync error", error);
      return rejectWithValue(error.response.data);
    }
  }
);

const analyticsSliceReducer = createSlice({
  name: "analytics",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetAnalyticsState: (state) => {
      state = {
        brcGBRCStakingAPR: 0,
        brcOnlyStakingAPR: 0,
        brcGBRCStakingAPY: 0,
        brcOnlyStakingAPY: 0,
        brcOnly7DaysAPY: 0,
        brcOnly30DaysAPY: 0,
        brcOnly90DaysAPY: 0,
        brcGBRC7DaysAPY: 0,
        brcGBRC30DaysAPY: 0,
        brcGBRC90DaysAPY: 0,
        brcOnly7DaysAPR: 0,
        brcOnly30DaysAPR: 0,
        brcOnly90DaysAPR: 0,
        brcGBRC7DaysAPR: 0,
        brcGBRC30DaysAPR: 0,
        brcGBRC90DaysAPR: 0,
        brcPriceUSD: 0,
        totalReserveDAI: 0,
        brcTotalCirculatingSupply: 0,
        gbrcTotalCirculatingSupply: 0,
        brcTotalSupply: 0,
        gbrcTotalSupply: 0,
        treasuryAssets: {
          dai: 0,
          eth: 0,
          gbrc: 0,
          ethUsd: 0,
          gbrcUsd: 0,
        },
        brcPercentStaked: 0,
        gbrcPercentStaked: 0,
        brcAverageBuyPriceUSD: 0,
        gbrcAverageBuyPriceUSD: 0,
        gbrcPriceInfo: {
          currentPrice: 0,
          change24hrPercent: 0,
          change7dPercent: 0,
        },
        brcPriceInfo: {
          currentPrice: 0,
          change24hrPercent: 0,
          change7dPercent: 0,
        },
        marketCap: 0,
      } as IAnalyticsState;
    },
    setBRCPriceUSD: (state, data) => {
      state.brcPriceUSD = data.payload;
    },
    setMarketCapAnalytics: (state, data) => {
      state.marketCap = data.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(setAPY_APRsAsync.pending, (state) => {})
      .addCase(setAPY_APRsAsync.rejected, (state: any, action: any) => {})
      .addCase(
        setAPY_APRsAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          state.brcGBRC7DaysAPY = payload.brcGBRC7DaysAPY;
          state.brcGBRC30DaysAPY = payload.brcGBRC30DaysAPY;
          state.brcGBRC90DaysAPY = payload.brcGBRC90DaysAPY;
          state.brcOnly7DaysAPY = payload.brcOnly7DaysAPY;
          state.brcOnly30DaysAPY = payload.brcOnly30DaysAPY;
          state.brcOnly90DaysAPY = payload.brcOnly90DaysAPY;
          state.brcGBRC7DaysAPR = payload.brcGBRC7DaysAPR;
          state.brcGBRC30DaysAPR = payload.brcGBRC30DaysAPR;
          state.brcGBRC90DaysAPR = payload.brcGBRC90DaysAPR;
          state.brcOnly7DaysAPR = payload.brcOnly7DaysAPR;
          state.brcOnly30DaysAPR = payload.brcOnly30DaysAPR;
          state.brcOnly90DaysAPR = payload.brcOnly90DaysAPR;
          state.brcOnlyStakingAPY = payload.brcOnlyStakingAPY;
          state.brcGBRCStakingAPY = payload.brcGBRCStakingAPY;
          state.brcOnlyStakingAPR = payload.brcOnlyStakingAPR;
          state.brcGBRCStakingAPR = payload.brcGBRCStakingAPR;
        }
      )

      .addCase(setTotalDAIReserveAsync.pending, (state) => {})
      .addCase(
        setTotalDAIReserveAsync.rejected,
        (state: any, action: any) => {}
      )
      .addCase(
        setTotalDAIReserveAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          state.totalReserveDAI = payload;
        }
      )

      .addCase(setPoolsSupplyAsync.pending, (state) => {})
      .addCase(setPoolsSupplyAsync.rejected, (state: any, action: any) => {})
      .addCase(
        setPoolsSupplyAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          const _payload = payload as PoolsSupply;
          state.brcOnly7DaysSUPPLY = _payload.brcOnly7Days;
          state.brcOnly30DaysSUPPLY = _payload.brcOnly30Days;
          state.brcOnly90DaysSUPPLY = _payload.brcOnly90Days;
          state.brcGBRC7DaysSUPPLY = _payload.brcGBRC7Days;
          state.brcGBRC30DaysSUPPLY = _payload.brcGBRC30Days;
          state.brcGBRC90DaysSUPPLY = _payload.brcGBRC90Days;
        }
      )

      .addCase(setGBRCTotalCirculatingSupplyAsync.pending, (state) => {})
      .addCase(
        setGBRCTotalCirculatingSupplyAsync.rejected,
        (state: any, action: any) => {}
      )
      .addCase(
        setGBRCTotalCirculatingSupplyAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          state.gbrcTotalCirculatingSupply = payload.gBrcTotalCirculatingSupply;
          state.gbrcTotalSupply = payload.gbrcTotalSupply;
        }
      )

      .addCase(setBRCTotalCirculatingSupplyAsync.pending, (state) => {})
      .addCase(
        setBRCTotalCirculatingSupplyAsync.rejected,
        (state: any, action: any) => {}
      )
      .addCase(
        setBRCTotalCirculatingSupplyAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          state.brcTotalCirculatingSupply = payload.brcTotalCirculatingSupply;
          state.brcTotalSupply = payload.brcTotalSupply;
        }
      )

      .addCase(setTreasuryAssetsAsync.pending, (state) => {})
      .addCase(setTreasuryAssetsAsync.rejected, (state: any, action: any) => {})
      .addCase(
        setTreasuryAssetsAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          state.treasuryAssets = payload;
        }
      )

      .addCase(setPercentageStakeAsync.pending, (state) => {})
      .addCase(
        setPercentageStakeAsync.rejected,
        (state: any, action: any) => {}
      )
      .addCase(
        setPercentageStakeAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          state.brcPercentStaked = payload.brcPercentStake;
          state.gbrcPercentStaked = payload.gbrcPercentStake;
        }
      )

      .addCase(setGBRCPriceInfoAsync.pending, (state) => {})
      .addCase(setGBRCPriceInfoAsync.rejected, (state: any, action: any) => {})
      .addCase(
        setGBRCPriceInfoAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          state.gbrcPriceInfo.currentPrice = payload.currentPrice;
          state.gbrcPriceInfo.change24hrPercent = payload.change24hrPercent;
          state.gbrcPriceInfo.change7dPercent = payload.change7dPercent;
        }
      )

      .addCase(setBRCPriceInfoAsync.pending, (state) => {})
      .addCase(setBRCPriceInfoAsync.rejected, (state: any, action: any) => {})
      .addCase(
        setBRCPriceInfoAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          // state.brcPriceInfo.currentPrice = payload.currentPrice;
          state.brcPriceInfo.change24hrPercent = payload.change24hrPercent;
          state.brcPriceInfo.change7dPercent = payload.change7dPercent;
        }
      )

      .addCase(setBRCPriceChartInfoAsync.pending, (state) => {})
      .addCase(
        setBRCPriceChartInfoAsync.rejected,
        (state: any, action: any) => {}
      )
      .addCase(
        setBRCPriceChartInfoAsync.fulfilled,
        (state: IAnalyticsState, { payload }: PayloadAction<any>) => {
          state.brcPriceInfo.chartData = payload;
        }
      );
  },
});

export {
  setTotalDAIReserveAsync,
  setAPY_APRsAsync,
  setTreasuryAssetsAsync,
  setGBRCTotalCirculatingSupplyAsync,
  setBRCTotalCirculatingSupplyAsync,
  setPercentageStakeAsync,
  setGBRCPriceInfoAsync,
  setBRCPriceInfoAsync,
  setBRCPriceChartInfoAsync,
  setPoolsSupplyAsync,
};
export const { resetAnalyticsState, setBRCPriceUSD, setMarketCapAnalytics } =
  analyticsSliceReducer.actions;
export default analyticsSliceReducer.reducer;
export const analyticsSelector = (state: { analyticsState: IAnalyticsState }) =>
  state.analyticsState;
