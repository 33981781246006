import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./walletConnectButton";

const useStyles = makeStyles(() =>
  createStyles({
    WalletConnectButton: {
      // backgroundColor: "transparent",
      // color: (style: Theme & Props) => style.text,
      color: (style: Theme & Props) => style.text,
      marginRight: "15px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // width: "20rem",
      // height: "6rem",
      font: (style: Theme & Props) => style.typography.navItem,
      "&:hover": {
        filter: (style: Theme & Props) => !style.disabled && "brightness(1.5)",
      },
    },
  })
);

export default useStyles;
