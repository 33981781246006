import React, { useContext } from "react";
// import { NavLink } from 'react-router-dom';
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./footerStyles";
// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
// import { ReactComponent as Twitter } from "../../media/icons/twitter_outline.svg";
// import { ReactComponent as Telegram } from "../../media/icons/telegram_outline.svg";
// import { ReactComponent as Instagram } from "../../media/icons/instagram_outline.svg";
// import { ReactComponent as Facebook } from "../../media/icons/facebook_outline.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Nav from "./nav";

export interface Props {}

function Footer(props: Props) {
  const { theme } = useContext(ThemeContext);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles({ ...props, ...theme });

  return (
    <footer className="footer">
      <i className="foot__line"></i>
      <div className="footer__inner">
          <div className="foot__snn__link">
            <button type="button" className="sns__link" onClick={() => window.open('https://discord.com/invite/QFFrBu68rp' , '_blank')}>
                <img src={require("../../media/icons/ic-Discord-sm.svg").default} alt="Discord icon"/>
            </button>
            <button type="button" className="sns__link" onClick={() => window.open('https://twitter.com/BrincFi' , '_blank')}>
                <img src={require("../../media/icons/ico-twitter-sm.svg").default} alt="twitter icon"/>
            </button>
            <button type="button" className="sns__link" onClick={() => window.open('https://t.me/BrincX' , '_blank')}>
                <img src={require("../../media/icons/ico-telegram-sm.svg").default} alt="telegram icon"/>
            </button>
            <button type="button" className="sns__link" onClick={() => window.open('https://www.youtube.com/channel/UCw8CF3-sXihmGB5iyR3_5kA' , '_blank')}>
                <img src={require("../../media/icons/ico-youtube.svg").default} alt="youtube icon"/>
            </button>
          </div>
          {/* <Nav/> */}
          <p className="copyright">© BrincX.io {new Date().getFullYear()}. All rights reserved.</p>
      </div>
  </footer>  
  );
}

export default React.memo(Footer);
