import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./buttonStyles";
import { 
  // Spinner,
  Loader, 
  // Success, 
  // Fail, 
  ICONS } from "../icons/icons";
// import { BelowDesktop, Desktop } from "../screenHelper";

export interface Props {
  clickFunction: () => void;
  label: string;
  disabled?: boolean;
  pending?: boolean;
  success?: boolean;
  fail?: boolean;
  className?: string;
  variant?: "fill" | "outline";
  size?: "sm" | "md";
  icon?: string;
  color?: "error";
}

function FillButton(props: Props) {
  const { theme } = useContext(ThemeContext);

  const classes = useStyles({ ...props, ...theme });
  const { clickFunction, disabled, label } = props;
  const handleClick = () => clickFunction && clickFunction();

  const Icon = ICONS[props.icon];
  return (
    <>
      {/* <Desktop>
        <button
          disabled={disabled}
          onClick={handleClick}
          className={`${classes.FillButton}`}
        >
          {props.icon ? <Icon className={classes.preIcon} />:null}
          
          <p className={classes.text}>{label}</p>

          {props.pending && (
            <Loader
              key={"pending"}
              className={`${classes.postIcon}`}
            />
          )}
        </button>
      </Desktop>
      <BelowDesktop> */}
        <button
          disabled={disabled}
          onClick={handleClick}
          className={`${classes.FillButtonMobile}`}
        >
          {props.icon ? <Icon className={classes.preIcon} />:null}
          
          <p className={classes.text}>{label}</p>

          {props.pending && (
            <Loader
              key={"pending"}
              className={`${classes.postIcon}`}
            />
          )}
        </button>
      {/* </BelowDesktop> */}
    </>
  );
}

export default FillButton;
