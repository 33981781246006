/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./swapStyles";
import Header from "../../containers/header/header";
import { useWeb3React } from "@web3-react/core";
import { useToggle } from "../../hooks/useToggle";
import SwapBox from "./SwapBox";
import Footer from "../../containers/footer/footer";
import AlertUser from "../../components/dialogueBoxes/alertUser/alertUser";
import ToggleSwitch from "../../components/switch/toggleSwitch";
import { useInactiveListener } from "../../store/helpers/walletEventHooks";
// import ChartModal from "../../components/chart/chartModal";
import { Desktop, BelowDesktop, AboveMobile } from "../../components/screenHelper";
import { RootState, useAppDispatch } from "../../store/mystore";
import { areContractsLoaded } from "../../store/helpers/contractBooter";
import { IAppContractsNonNullable } from "../../store/application/AppSlice";
import { MuiAccordion, MuiAccordionDetails, MuiAccordionSummary, MuiGrid } from "../../components/materialUIHelper";
import "./swap.scss";
import { formatNumberCommas, handleNumberDisplay } from "../../utils/utils";
import { GetChangeRate, GetUSDTokenEquivalence } from "../../store/helpers/priceHelper";
import { BIGNUMBER_CONFIG, brc_Token, HTTP_METHOD, SUPPORTED_CHAINS } from "../../constants";
import useDebounce from "../../hooks/useDebounce";
import { setBRCPriceInfoAsync, setBRCPriceUSD, setBRCTotalCirculatingSupplyAsync, setMarketCapAnalytics, setTotalDAIReserveAsync } from "../../store/analytics/AnalyticsSlice";
import { BasicContractCallPayload } from "../../store/analytics/Analytics";
import { all, bignumber, create } from "mathjs";

const math = create(all, BIGNUMBER_CONFIG);

export interface Props { }

function Swap(props: Props) {
  const { theme } = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state);
  
  const [contracts, setContracts] = useState<IAppContractsNonNullable>(state.application.contracts);
  const [brcUsd, setBrcUsd] = useState<string>(Number(state.analytics.brcPriceUSD) > 0 ? state.analytics.brcPriceUSD.toString() : "--");
  const [totalBRCSupply, setTotalBRCSupply] = useState<string>(Number(state.analytics.brcTotalSupply) > 0 ? state.analytics.brcTotalSupply.toString() : "--");
  const [reserve, setReserve] = useState<string>(Number(state.analytics.totalReserveDAI) > 0 ? state.analytics.totalReserveDAI.toString() : "--");
  const [showWalletModal, setShowWalletModal, toggleWalletModal] = useToggle(
    false
  );
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const { connector, active, chainId } = useWeb3React();
  const [disableConnectButton, setDisableConnectButton] = useState(false);
  const [toggleSelected, setToggleSelected] = useState(0);
  const [showNetworkAlert, setShowNetworkAlert] = useState<boolean>(false);
  const [change24hr, setChange24hr] = useState<string>(Number(state.analytics.brcPriceInfo.change24hrPercent) > 0 ? GetChangeRate(state.analytics.brcPriceInfo.change24hrPercent, state.analytics.brcPriceUSD).toString() : "--");
  const [change7d, setChange7d] = useState<string>(Number(state.analytics.brcPriceInfo.change7dPercent) > 0 ? GetChangeRate(state.analytics.brcPriceInfo.change7dPercent, state.analytics.brcPriceUSD).toString() : "--");
  const [marketCap, setMarketCap] = useState<string>(Number(state.analytics.brcTotalSupply) > 0 && Number(state.analytics.brcPriceUSD) > 0  ? math.multiply(math.bignumber(state.analytics.brcTotalSupply.toString()), math.bignumber(state.analytics.brcPriceUSD.toString())).toString() : "--");
  
  useInactiveListener();

  useEffect(() => {
    if (!areContractsLoaded(contracts) && areContractsLoaded(state.application.contracts)) {
      setContracts(state.application.contracts);
    }
  }, [contracts, state.application.contracts]);

  const setPriceChangesData = () => {
    //get BRC 24hr and 7d data
    // dispatch(setBRCPriceInfoAsync());

    if(totalBRCSupply !== "--" && brcUsd !== "--"){
      const marketCap = math.multiply(math.bignumber(totalBRCSupply), math.bignumber(brcUsd))
      
      setMarketCap(marketCap.toString());
      dispatch(setMarketCapAnalytics(marketCap));
    }
  }

  const debounceGetBRCPrice = useDebounce(
    async () => {
        const brcPriceUSD = await GetUSDTokenEquivalence(state, bignumber(1), brc_Token.symbol);
        if(Number(brcPriceUSD) > 0){
            setBrcUsd(brcPriceUSD);
        }
        dispatch(setBRCPriceUSD(brcPriceUSD));      

        // dispatch(setBRCPriceInfoAsync())
        // .then((result)=>{
        //   const data: any = result.payload;
        //   const brcPriceUSD24hr = data?.change24hrPercent;
        //   const brcPriceUSD7d = data?.change7dPercent;

        //   const perc24hr = GetChangeRate(brcPriceUSD24hr === 0 ? brcPriceUSD:brcPriceUSD24hr, brcPriceUSD);
        //   const perc7d = brcPriceUSD7d !== 0 ? GetChangeRate(brcPriceUSD7d === 0 ? brcPriceUSD:brcPriceUSD7d, brcPriceUSD) : "--";
          
        //   // console.log('gbrc price change', {perc24hr, perc7d});
          
        //   setChange24hr(!isNaN(perc24hr) ? perc24hr.toFixed(2) : "0");
        //   setChange7d(perc7d !== "--" ? Number(perc7d).toFixed(2) : "--");
        //   // setChange24hr("1.3");
        //   // setChange7d("-1.34");
          
        //   // console.log({brcPriceUSD, brcPriceUSD24hr, brcPriceUSD7d});
            
        // }) 
    }
    , 300
  )

  const debounceGetBRCTotalCirculatingSupply = useDebounce(
    async () => {
        const payload = {
          contracts: contracts,
          account: state.wallet.address
        } as BasicContractCallPayload;
  
        dispatch(setBRCTotalCirculatingSupplyAsync(payload))
        .then(response => {
          const _res:any = response.payload
          // console.log({_res});
                 
          setTotalBRCSupply(_res.totalSupply.toString());
        })
        .catch(err => {
          console.log(err);
        });
      }
    , 5000
  )

  // useEffect(() => {
  //   setPriceChangesData();
  // }, [state.swap.prices])

  useEffect(() => {
    debounceGetBRCPrice();
    debounceGetTotalReserve();
    debounceGetBRCTotalCirculatingSupply();
    debounceSetPriceChangesData();
  });

  // useEffect(() => {
  //   if(brcUsd.length < 1 || brcUsd === "--"){
  //     debounceGetBRCPrice();
  //   }
  //   if(reserve.length < 1 || reserve === "--"){
  //     debounceGetTotalReserve();
  //   }
  //   if(totalBRCSupply.length < 1 || totalBRCSupply === "--"){
  //     debounceGetBRCTotalCirculatingSupply();
  //   }
  //   if(marketCap.length < 1 || marketCap === "--"){
  //     debounceSetPriceChangesData();
  //   }
  // });

  // handles logic for wallet connect modal.
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
    active && setShowWalletModal(false);
  }, [activatingConnector, connector, active, setShowWalletModal]);

  const classes = useStyles({ ...props, ...theme });

  const chainIdIsCorrect =
    chainId && SUPPORTED_CHAINS.includes(chainId);


  if(!chainIdIsCorrect && !showNetworkAlert){
    setShowNetworkAlert(true);    
  }

  const changeToggleSelected = () =>{
    if(toggleSelected === 1){
      setToggleSelected(0);
    }else{
      setToggleSelected(1);
    }
  }

  // const debounceGetBRCPrice = useDebounce(
  //   async () => {
  //     const brcPriceUSD = GetUSDTokenEquivalence(state, bignumber(1), brc_Token.symbol)?.toString();
  //     setBrcUsd(brcPriceUSD.toString());
  //   }
  //   , 5000
  // )

  const debounceSetPriceChangesData = useDebounce(
    async () => {
      setPriceChangesData();
    }
    , 5000
  )

  const debounceGetTotalReserve = useDebounce(
    async () => {
      const payload = {
        contracts: contracts,
        account: state.wallet.address
      } as BasicContractCallPayload;

      dispatch(setTotalDAIReserveAsync(payload))
      .then(response => {
        const _res = response.payload as number;
        setReserve(_res.toString());
      })
      .catch(err => {
        console.log(err);
      });
    }
    , 5000
  )

  return (
    <div id="wrap" className={classes.Wrapper}>
      {active && !chainIdIsCorrect && showNetworkAlert && <AlertUser closeModal={() => setShowNetworkAlert(false)} />}
      <Header
        disableConnectButton={disableConnectButton}
        showWalletModal={showWalletModal}
        setShowWalletModal={setShowWalletModal}
        toggleWalletModal={toggleWalletModal}
      />
      <div id="main">
        <div className="inner inner__md">
            <ToggleSwitch round={true} toggleList={["Buy", "Sell"]} current={toggleSelected} toggleFunction={option => setToggleSelected(option)} />  

          {/* <div className={classes.boxWrapper}> */}
            {/* <Desktop>
              <article className="content__index__table web__content__table"> */}
                  {/* <article className="content__index__head">
                      <p style={{textAlign: "left"}}>BRC Price</p>
                      <p>24h %</p>
                      <p>7d %</p>
                      <p>Market Cap</p>
                  </article>
                  <article className="content__index__body">
                      <div className="content__index__trow">
                          <p style={{textAlign: "left"}}>${brcUsd !== "--" ? handleNumberDisplay(brcUsd, true, 4):brcUsd}</p>
                          <div style={{display: "flex", justifyContent: "center"}}>
                            <span className={Number(change24hr) > 0 ? "upColor" : Number(change24hr) === 0  || change24hr === "--" ? "" : "downColor" }> 
                              {Number(change24hr) > 0 ? '+' : null}{change24hr !== "--" ? handleNumberDisplay(change24hr, true, 2):change24hr}%
                            </span>
                            <span className={Number(change24hr) > 0 ? "upColor changeIcon" : Number(change24hr) === 0  || change24hr === "--" ? "" : "downColor changeIcon" }>
                              {Number(change24hr) > 0 ? <>&#9650;</> : Number(change24hr) === 0 || change24hr === "--" ? null: <>&#9660;</>}
                            </span>
                          </div>
                          <div style={{display: "flex", justifyContent: "center"}}>
                            <span className={Number(change7d) > 0 ? "upColor" : Number(change7d) === 0  || change7d === "--" ? "" : "downColor" }> 
                              {Number(change7d) > 0 ? '+' : null}{change7d !== "--" ? handleNumberDisplay(change7d, true, 2):change7d}%
                            </span>
                            <span className={Number(change7d) > 0 ? "upColor changeIcon" : Number(change7d) === 0  || change7d === "--" ? "" : "downColor changeIcon" }>
                              {Number(change7d) > 0 ? <>&#9650;</> : Number(change7d) === 0 || change7d === "--" ? null: <>&#9660;</>}
                            </span>
                          </div> */}
                          {/* <p className="pointRedColor">{change24hr !== "--" ? handleNumberDisplay(change24hr, true, 3):change24hr}%</p>
                          <p className="pointGreenColor">{change7d !== "--" ? handleNumberDisplay(change7d, true, 3):change7d}%</p> */}
                          {/* <p>${marketCap !== "--" ? handleNumberDisplay(marketCap, true):marketCap}</p> */}
                          {/* <div style={{textAlign: "right"}}>
                              <p className="totla__txt">
                                  <img width="30px" src={require("../../media/icons/ico-dai-black.svg").default} alt=""/>
                                  {reserve !== "--" ? handleNumberDisplay(reserve, true, 0):reserve}
                              </p>
                              <p className="font__sm">~ ${reserve !== "--" ? handleNumberDisplay(reserve, true):reserve}</p>
                          </div> */}
                      {/* </div>
                  </article> */}
              {/* </article>
            </Desktop> */}

            <BelowDesktop>
              <article className="mob__content__table" style={{marginBottom:"3rem", marginTop:"2.5rem", marginLeft: "2rem"}}>
                {/* <MuiAccordion
                  defaultExpanded={false}
                  >
                  <MuiAccordionSummary
                    //   expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <h2 className={classes.marketInfoheading}>BRC Market Information</h2>
                    <span className="expand__ico"><img style={{width:"20px"}} src={require("../../media/icons/ico-arrow.svg").default} alt=""/></span>
                  </MuiAccordionSummary>
                  <MuiAccordionDetails>
                      <ul className="information__table">
                          <li className="information__item">
                              <strong className="title">BRC Price</strong>
                              <span className="number">${brcUsd !== "--" ? handleNumberDisplay(brcUsd, true, 4):brcUsd}</span>
                          </li>
                          <li className="information__item">
                              <strong className="title">24h %</strong>
                              <div className="number" style={{display: "flex", justifyContent: "center"}}>
                                <span className={Number(change24hr) > 0 ? "upColor" : Number(change24hr) === 0  || change24hr === "--" ? "" : "downColor" }> 
                                  {Number(change24hr) > 0 ? '+' : null}{change24hr !== "--" ? handleNumberDisplay(change24hr, true, 3):change24hr}%
                                </span>
                                <span className={Number(change24hr) > 0 ? "upColor changeIcon-mob" : Number(change24hr) === 0  || change24hr === "--" ? "" : "downColor changeIcon-mob" }>
                                  {Number(change24hr) > 0 ? <>&#9650;</> : Number(change24hr) === 0 || change24hr === "--" ? null: <>&#9660;</>}
                                </span> */}
                                {/* {change24hr !== "--" ? handleNumberDisplay(change24hr, true, 3):change24hr}% */}
                              {/* </div>
                          </li>
                          <li className="information__item">
                              <strong className="title">7d %</strong>
                              <div className="number">
                                <span className={Number(change7d) > 0 ? "upColor" : Number(change7d) === 0  || change7d === "--" ? "" : "downColor" }> 
                                  {Number(change7d) > 0 ? '+' : null}{change7d !== "--" ? handleNumberDisplay(change7d, true, 3):change7d}%
                                </span>
                                <span className={Number(change7d) > 0 ? "upColor changeIcon-mob" : Number(change7d) === 0  || change7d === "--" ? "" : "downColor changeIcon-mob" }>
                                  {Number(change7d) > 0 ? <>&#9650;</> : Number(change7d) === 0 || change7d === "--" ? null: <>&#9660;</>}
                                </span> */}
                                {/* {change7d !== "--" ? handleNumberDisplay(change7d, true, 3):change7d}% */}
                              {/* </div>
                          </li>
                          <li className="information__item">
                              <strong className="title">Market Cap</strong>""
                              <span className="number">${marketCap !== "--" ? handleNumberDisplay(marketCap, true):marketCap}</span>
                          </li>
                      </ul>
                  </MuiAccordionDetails>
                </MuiAccordion>         */}
              </article>
            </BelowDesktop>        

            {areContractsLoaded(contracts) ? 
              <SwapBox
                toggleSelected={toggleSelected}
                walletConnected={active}
                chainIdIsCorrect={chainIdIsCorrect ? true : false}
                toggleWalletModal={toggleWalletModal}
                changeToggleSelected={changeToggleSelected}
              />: null
            }
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default React.memo(Swap);