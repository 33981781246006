import { makeStyles, createStyles } from "@material-ui/core/styles";
import { DefaultToken } from "../../constants";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./buySellBox";

const useStyles = makeStyles(() =>
  createStyles({
    BuySellBox: {
      // backgroundColor: "transparent",
      transition: (style: Theme & Props) => style.transition,
      color: (style: Theme & Props) => style.text,
      width: "100%",
      height: "85px",
      borderRadius: "2.4rem",
      display: "flex",
      flexDirection:"column",
      padding: "0px 16px 0",
      justifyContent: "center",
    },
    BuySellBoxMobile: {
      backgroundColor: "var(--nearBlack)",
      // border: "1px solid var(--nearBlackBorder)",
      transition: (style: Theme & Props) => style.transition,
      color: (style: Theme & Props) => style.text,
      width: "100%",
      height: "85px",
      borderRadius: "1.4rem",
      display: "flex",
      flexDirection:"column",
      padding: "8px",
      justifyContent: "center",
      gap: "3px"
    },
    boxRow:{
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    leftContainer: {
      height: "100%",
      // minWidth: "114px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    rightContainer: {
      height: "100%",
      // minWidth: "150px",
      display: "flex",
      justifyContent: "flex-end",
      textAlign: "right",
      alignItems: "center",
      marginLeft: (style: Theme & Props) => style.token === DefaultToken.symbol ? "50px" : "0px",
    },
    title: {
      font: (style: Theme & Props) => style.typography.h2,
      color: "inherit",
      textTransform: "capitalize",
      userSelect: "none",
      margin: "0 auto auto 0",
    },
    equivalentUSD: {
      font: (style: Theme & Props) => style.typography.p6,
      color: (style: Theme & Props) => style.fadeText,
      width: "100%"
    },
    mobFadeSmallText:{
      fontSize: "12px",
      color: (style: Theme & Props) => style.colorFadeText,
      fontFamily: "var(--font1)"
    }
  })
);

export default useStyles;