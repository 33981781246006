import { Contract, ethers } from "ethers";
import { bignumber } from "mathjs";
import { StakeRow } from "../../components/stakeTable/stakeTable";
import {
  brc_Token,
  chainId,
  gbrc_Token,
  StakeMode,
  StakePool,
  StakeTXType,
  TX_STATUS,
} from "../../constants";
import { noExponents } from "../../utils/utils";
import { calculateCurrentAPR } from "../analytics/Analytics";
import {
  IAppContractsNonNullable,
  IStakeTransactionData,
} from "../application/AppSlice";
import { GetWeb3 } from "../helpers/contractBooter";
import { approve } from "../services/approvals";
import { IBalances } from "../wallet/WalletSlice";

// TODO: set all contract data types

export interface IStakeState {
  tx: {
    type: StakeTXType;
    mode: StakeMode;
    status: TX_STATUS;
    data: IStakeTransactionData;
  };
  allStakes: StakeRow[];
  poolWeights: Weights
}

export type StakingPayload = {
  stakingContract: Contract;
  amount: number;
  userAddress: string;
  stakeMode: StakeMode;
};

export type Weights = {
  MODE1: string;
  MODE2: string;
  MODE3: string;
  MODE4: string;
  MODE5: string;
  MODE6: string;
  TOTAL: string;
};

export type GetStakesPayload = {
  stakingContract: Contract;
  userAddress: string;
};

export type ApproveStakePayload = {
  contracts: IAppContractsNonNullable;
  ethAddress: string;
  amount: number;
  slippage: number;
  mode: StakeMode;
  balances: IBalances;
};

export const stake = async (payload: StakingPayload) => {
  const { stakingContract, amount, userAddress, stakeMode } = payload;
  let web3 = GetWeb3(chainId);
  let gasPrice = await web3.eth.getGasPrice();
  const amountParsed = ethers.utils.parseUnits(
    noExponents(amount),
    brc_Token.decimals
  );
  return await stakingContract.methods
    .deposit(amountParsed, stakeMode)
    .send({ from: userAddress, gasPrice: gasPrice });
};

export const approveBRCStake = async (payload: ApproveStakePayload) => {
  const { contracts, ethAddress, amount, slippage, balances } = payload;
  return await approve(
    contracts.brcContract,
    contracts.stakingContract._address,
    ethAddress,
    bignumber(amount),
    bignumber(balances.brcBalance.toString()),
    slippage
  );
};

export const approveGBRCStake = async (payload: ApproveStakePayload) => {
  const { contracts, ethAddress, amount, slippage, balances } = payload;
  return await approve(
    contracts.gbrcContract,
    contracts.stakingContract._address,
    ethAddress,
    bignumber(amount),
    bignumber(balances.gbrcBalance.toString()),
    slippage
  );
};

export const getAllStakes = async (
  stakingContract: Contract,
  userAddress: string
) => {
  try {
    let allStakes = [];

    if (stakingContract && userAddress) {
      // get all user stakes count for indexing

      let stakesCount = await stakingContract.methods
        .getStakeCount()
        .call({ from: userAddress });

      for (var i = stakesCount - 1; i >= 0; i--) {
        const stake = await getUserStake(stakingContract, userAddress, i);
        const rewards = await getRewards(stakingContract, userAddress, i);

        if (stake) {
          let mode: number = Number.parseInt(stake.mode);
          let period: number;

          if (mode === 0 || mode === 3) {
            period = 7;
          } else if (mode === 1 || mode === 4) {
            period = 30;
          } else if (mode === 2 || mode === 5) {
            period = 90;
          }

          const stakeCreated =
            Number.parseInt(stake.unlockTimestamp) - period * 24 * 60 * 60;

            const expiry = stake.unlockTimestamp;
        

          const daysPassed: number =
            (Math.floor(Date.now() / 1000) - stakeCreated) / (24 * 60 * 60);

          let _stake: StakeRow = {
            stakeIndex: i,
            pool: mode < 3 ? StakePool.BRC : StakePool.gBRC_AND_BRC,
            period: period,
            primaryAmount: ethers.utils.formatUnits(
              stake.brcStakedAmount.toString(),
              brc_Token.decimals
            ),
            secondaryAmount:
              mode < 3
                ? "0"
                : ethers.utils.formatUnits(
                    stake.gBrcStakedAmount.toString(),
                    gbrc_Token.decimals
                  ),
            rewards: ethers.utils.formatUnits(rewards[0], gbrc_Token.decimals),
            apr: calculateCurrentAPR(
              bignumber(
                ethers.utils.formatUnits(
                  stake.brcStakedAmount.toString(),
                  brc_Token.decimals
                )
              ),
              bignumber(
                ethers.utils.formatUnits(rewards[0], gbrc_Token.decimals)
              ),
              daysPassed,
              mode
            ).toString(),
            expiry: expiry,
            daysPassed: daysPassed,
          };

          allStakes.push(_stake);

          // after last input
          if (i === 0) {
            return allStakes;
          }
        }
      }
    }
  } catch (error) {
    console.log("getAllStakes err", error);
  }
};

export const getRewards = async (
  stakingContract: Contract,
  userAddress: string,
  stakeIndex: number
) => {
  return await stakingContract.methods
    .pendingRewards(userAddress, stakeIndex)
    .call();
};

export const getPoolWeight = async (stakingContract: Contract, stakeMode: StakeMode) => {
  return await stakingContract.methods.getPoolWeight(stakeMode).call();
}

export const getWeights = async (stakingContract: Contract) => {
  const [
    MODE1,
    MODE2,
    MODE3,
    MODE4,
    MODE5,
    MODE6,
  ] = await Promise.all([
    stakingContract.methods.getPoolWeight(StakeMode.MODE1).call(),
    stakingContract.methods.getPoolWeight(StakeMode.MODE2).call(),
    stakingContract.methods.getPoolWeight(StakeMode.MODE3).call(),
    stakingContract.methods.getPoolWeight(StakeMode.MODE4).call(),
    stakingContract.methods.getPoolWeight(StakeMode.MODE5).call(),
    stakingContract.methods.getPoolWeight(StakeMode.MODE6).call(),
  ]);

  return {
    MODE1,
    MODE2,
    MODE3,
    MODE4,
    MODE5,
    MODE6,
  }
};

export const getUserStake = async (
  stakingContract: Contract,
  userAddress: string,
  stakeIndex: number
) => {
  return await stakingContract.methods.userInfo(userAddress, stakeIndex).call();
};
