/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../store/themeContext/themeContext";
import useStyles from "./confirmationStyles";
import PopupModal from "../../popupModal/popupModal";
import { ICONS } from "../../icons/icons";
import FillButton from "../../button/button";
import { brc_Token, TX_STEP, TOKEN_ACTION, dai_Token } from "../../../constants";
import { RootState } from "../../../store/mystore";
import { useSelector } from "react-redux";

export interface Props {
	closeModal: () => void;
	confirmationAction: () => void;
	step: TX_STEP;
	transactionType: TOKEN_ACTION;
}

function Reset(props: Props) {
	const { theme } = useContext(ThemeContext);
	const state = useSelector((state: RootState) => state);
	const [sgbrcEnough, setGgbrcEnough] = useState<boolean>(false);

	const classes = useStyles({ ...props, ...theme });

	let label = "Okay, Got it!";
	const DaiTokenIcon = ICONS[dai_Token.symbol];
	
	// separate resolution is done for BRC gBRC icons because the colours change somehow when gotten from the ICONS {}
	
	return (
		<PopupModal closeModal={props.closeModal}>
			<section className="pop__md__w pop__wrap">
				<header className="pop__head" style={{display: "flex", justifyContent:"space-between", hyphens: "auto", wordBreak: "break-word"}}>
					<h2 className={classes.heading}>Oops!<br/> Mint cannot proceed.</h2>
					{/* <br/>Try Again using your DAI */}
					<button type="button" className="pop__close" onClick={props.closeModal}>
						<span className="close"><img src={require("../../../media/icons/ico-close.svg").default} alt=""/></span>
					</button>
				</header>
				<main className="pop__body">
					<section className="" style={{marginTop:"1rem"}}>
						<h2 className="tit__md" style={{marginBottom: "2rem"}}>No Worries! Your DAI tokens are still intact</h2>
						
						<article>
							<h2 className="tit__md font__bold">
								Follow these steps to complete your {`${brc_Token.symbol}`} mint transaction:
							</h2>
							<ul className="explanation__list__box">
								<li className={`explanation__list__item ${classes.bulletPoints}`}>
									<span className="ico__point"><img className={classes.dot} src={require("../../../media/icons/ico-Point.svg").default} alt=""/></span>
									<span className={`${classes.infoText} cont__txt`}>Click OKAY button below</span>
								</li>
								<li className={`explanation__list__item ${classes.bulletPoints}`}>
									<span className="ico__point"><img className={classes.dot} src={require("../../../media/icons/ico-Point.svg").default} alt=""/></span>
									<span className={`${classes.infoText} cont__txt`}>Select DAI as your token mint option and input your desired amount.</span>
								</li>
								<li className={`explanation__list__item ${classes.bulletPoints}`}>
									<span className="ico__point"><img className={classes.dot} src={require("../../../media/icons/ico-Point.svg").default} alt=""/></span>
									<span className={`${classes.infoText} cont__txt`}>Click the MINT button.</span>
								</li>
							</ul>
						</article>
					</section>
				</main>
				<footer className="pop__foot" style={{marginTop: "5rem"}}>
					<FillButton
						label={label} 
						clickFunction={props.confirmationAction} 
						className={classes.actionButton}
					/>
				</footer>
			</section>
		</PopupModal>

	);
}

export default React.memo(Reset);