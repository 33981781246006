import { ethers, Contract } from "ethers";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AppNetworks, brc_Token, dai_Token, gbrc_Token, infuraEndpoint, usdc_Token, usdt_Token, ust_Token, wbtc_Token } from "../../constants";
import { noExponents } from "../../utils/utils";
import {  GetWeb3 } from "../helpers/contractBooter";
import { IBalances } from "../wallet/WalletSlice";

export const getEthBalance = async (ethAddress: string, web3: any = null) => {
  try {
    if(web3 === null){
      web3 = GetWeb3(AppNetworks.ARBITRUM_ONE);
    }
    
    if(web3 === null || !web3){
      web3 = new ethers.providers.JsonRpcProvider(infuraEndpoint);
    }
    
    const ethBalance = 0// await web3.eth.getBalance(ethAddress);
    return ethers.utils.formatEther(noExponents(ethBalance));
  } catch (error) {
    console.log(error);
    return 0;
  }
};

const getERC20Balance = async (
  ethAddress: string,
  contract: Contract,
  decimals: number
) => {
  try {
    if(!ethAddress || ethAddress === "") return;
    const tokenBalance = await contract.methods.balanceOf(ethAddress).call();
    return ethers.utils.formatUnits(noExponents(tokenBalance), decimals);
  } catch (error) {
    console.log(error);
    return 0;
  }
};

export const getBalances = async (payload) => {
  const { ethAddress, contracts } = payload;

  if(!ethAddress || ethAddress === "") return;

  const [
    ethBalance,
    brcBalance,
    gbrcBalance,
    sgbrcBalance,
    daiBalance,
    usdcBalance,
    // wbtcBalance,
    usdtBalance,
    ustBalance,
  ] = await Promise.all([
    // getEthBalance(ethAddress, provider),
    getERC20Balance(ethAddress, contracts.ethContract, 18),
    getERC20Balance(ethAddress, contracts.brcContract, brc_Token.decimals),
    getERC20Balance(ethAddress, contracts.gbrcContract, gbrc_Token.decimals),
    getERC20Balance(ethAddress, contracts.sgbrcContract, gbrc_Token.decimals),
    getERC20Balance(ethAddress, contracts.daiContract, dai_Token.decimals),
    getERC20Balance(ethAddress, contracts.usdcContract, usdc_Token.decimals),
    // getERC20Balance(ethAddress, contracts.wbtcContract, wbtc_Token.decimals),
    getERC20Balance(ethAddress, contracts.usdtContract, usdt_Token.decimals),
    getERC20Balance(ethAddress, contracts.ustContract, ust_Token.decimals),
  ]);

  return {
    ethBalance,
    brcBalance,
    gbrcBalance,
    sgbrcBalance,
    daiBalance,
    usdcBalance,
    // wbtcBalance,
    usdtBalance,
    ustBalance,
  } as IBalances;
};
