/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import { Contract } from "ethers";
import { Token, TradeType } from "@uniswap/sdk";
import useStyles from "./swapBoxStyles";
import BuySellBox from "../../components/buySellBox/buySellBox";
import {
  getCost,
  getReward,
  getCurveData,
  getPrice,
  getTokenSupply,
} from "../../store/services/pricesAndConversions";
import PriceConversionContainer from "../../components/priceConversion/priceConversion";
import ActionButtons from "../../containers/actionButtons/actionButtons";
import { useWeb3React } from "@web3-react/core";
import ProgressBubbles from "../../components/progressBubbles/progressBubbles";
import FillButton from "../../components/button/button";
import useDebounce from "../../hooks/useDebounce.jsx";
import {
  getDaiQuoteForEth,
  getDaiQuoteForToken,
} from "../../store/services/quotes";
import { Failure, Success, Confirmation } from "../../components/dialogueBoxes";
import { RootState, useAppDispatch } from "../../store/mystore";
import {
  setBalancesAsync,
  SetBalancePayload,
} from "../../store/wallet/WalletSlice";
import { IAppContractsNonNullable } from "../../store/application/AppSlice";
import {
  swapEthForDaiAsync,
  swapERC20TokenForDaiAsync,
  resetSwapTXState,
} from "../../store/swap/SwapSlice";
import { SwapPayload } from "../../store/swap/Swap";

import { useSelector } from "react-redux";
import {
  approveMintAsync,
  mintWithCollateralTokenAsync,
  mintWithSpecificCurveTokenAsync,
  resetMintTXState,
} from "../../store/mint/MintSlice";
import {
  approveBurnAsync,
  burnTokenAsync,
  resetBurnTXState,
} from "../../store/burn/BurnSlice";
import {
  FAIL_REASON,
  TX_STATUS,
  TX_STEP,
  TOKEN_ACTION,
  eth_Token,
  Tokens,
  ROUTER_CONTRACT,
  DefaultToken,
  HTTP_METHOD,
  URL,
  linkModifiers,
  TX_CODE,
  infuraEndpoint,
  brc_Token,
  AppNetworks,
  dai_Token,
  SUPPORTED_CHAINS,
} from "../../constants";
import {
  CurrentTXInfo,
  useGetCurrentTXInfo,
} from "../../store/helpers/transactionFlagsHelper";
import {
  areContractsLoaded,
  getProvider,
  isTestnetNetwork,
} from "../../store/helpers/contractBooter";
import { MuiGrid } from "../../components/materialUIHelper";
import CardMain from "../../components/card/cardMain";
import { resetStakeTXState } from "../../store/stake/StakeSlice";
import { resetWithdrawTXState } from "../../store/withdraw/WithdrawSlice";
import { hasEnoughAllowance } from "../../store/services/approvals";
import APICall from "../../utils/apiCall";
import { bignumber } from "mathjs";
import { GetUSDTokenEquivalence } from "../../store/helpers/priceHelper";
import Web3 from "web3";
import { resetClaimTXState } from "../../store/claim/ClaimSlice";
import Reset from "../../components/dialogueBoxes/confirmation/reset";
import { handleNumberDisplay, print2ndDecimal } from "../../utils/utils";
import {
  // isBlink,
  isChrome,
  isEdge,
  // isEdgeChromium,
  isFirefox,
  isIE,
  isOpera,
  isSafari,
} from "../../store/helpers/browserDetectHelper";
import OpenApp from "react-open-app";

declare let window: any;

const web3 = new Web3(
  Number(window.localStorage.getItem("LOCAL_CHAIN_ID")) ===
  Number(AppNetworks.ARBITRUM_ONE)
    ? window.ethereum
    : window.ethereum || infuraEndpoint
);

export interface Props {
  walletConnected: boolean;
  toggleWalletModal: () => void;
  chainIdIsCorrect: boolean;
  toggleSelected: number;
  changeToggleSelected: () => void;
}

type BuySellData = {
  account: string;
  blockNumber: string;
  tx_type: TX_CODE;
  daiCost: string;
  brc: string;
  testNet: 0 | 1;
  timestamp: number;
};

function SwapBox(props: Props) {
  const Grid = MuiGrid;

  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state);
  const [contracts, setContracts] = useState<IAppContractsNonNullable>(
    state.application.contracts
  );
  const { theme } = useContext(ThemeContext);
  const [transaction, setTransaction] = useState(TOKEN_ACTION.BUY);
  const [fromToken, setFromToken] = useState<string>("init"); //DefaultToken.symbol
  const [fromAmount, setFromAmount] = useState<number>(0.0);
  const [showBuySpinner, setShowBuySpinner] = useState(false);
  const [showSellSpinner, setShowSellSpinner] = useState(false);
  const [lockActionButtons, setLockActionButtons] = useState(true);
  const [toToken, setToToken] = useState<string>(brc_Token.symbol);
  const [toAmount, setToAmount] = useState<number>(0.0);
  const [daiAmount, setDaiAmount] = useState<number>(0.0);
  const { account, library, chainId, connector } = useWeb3React();
  const [disableMessage, setDisableMessage] = useState(
    `Enter ${transaction === TOKEN_ACTION.BUY ? "Buy" : "Sell"} Amount`
  );
  const [slippage, setSlippage] = useState<number>(1);
  const [showSlippage, setShowSlippage] = useState(false);
  const [editedCurrency, setEditedCurrency] = useState("");
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showSettingsPopup, setShowSettingsPopup] = useState(false);
  const [brcBalance, setBRCBalance] = useState("0.00");
  const [isTokenAllowanceEnough, setIsTokenAllowanceEnough] = useState(false);
  const [isDAIAllowanceEnough, setIsDAIAllowanceEnough] = useState(false);
  const [txUpdateOccured, setTxUpdateOccured] = useState(false);
  const [lowCurrentGas, setLowCurrentGas] = useState<string>("--");

  const sellAmountRef = useRef(0.0);
  const buyAmountRef = useRef(0.0);

  const [currentTX, setCurrentTX] = useState<CurrentTXInfo>({
    status: TX_STATUS.NOT_INITIALIZED,
    failData: {},
    successData: {},
    reasonForFailure: FAIL_REASON.NOT_SET,
    txStep: TX_STEP.NOT_INITIALIZED,
    nextTXStep: TX_STEP.NOT_INITIALIZED,
  });
  const [activeStep, setActiveStep] = useState(currentTX.txStep);

  if (lockActionButtons && disableMessage === "") {
    setDisableMessage(
      `Enter ${transaction === TOKEN_ACTION.BUY ? "Buy" : "Sell"} Amount`
    );
  }

  useEffect(() => {
    resetAfterTradeComplete();
    if (transaction === TOKEN_ACTION.BUY) {
      setToToken(brc_Token.symbol);
      setFromToken(dai_Token.symbol);
    }
    toggleTransaction(props.toggleSelected);

    // every 5seconds
    const interval_id = setInterval(blockIntervalJob, 5000);
    return () => {
      // Stop the interval when the component unmounts.
      // Otherwise it will keeep going and you will get an error.
      clearInterval(interval_id);
    };
  }, []);

  const blockIntervalJob = () => {
    // fetch gas price estimate
    // APICall(URL.ETH_GAS_STATION, HTTP_METHOD.GET)
    // .then((response)=>{
    //   if(response){
    //     setLowCurrentGas((response.fast / 10).toString()); //convert to gwei
    //     setHighCurrentGas((response.fastest / 10).toString()); //convert to gwei
    //   }
    // })
    // .catch(err=>console.log(err));
  };

  useEffect(() => {
    if (
      !areContractsLoaded(contracts) &&
      areContractsLoaded(state.application.contracts)
    ) {
      setContracts(state.application.contracts);
    }
  }, [contracts, state.application.contracts]);

  useEffect(() => {
    toggleTransaction(props.toggleSelected);
  }, [props.toggleSelected]);

  useEffect(() => {
    if (areContractsLoaded(contracts) && library && account && chainId) {
      tokenBalanceAction();
    }
  }, [contracts, library, account, chainId]);

  useEffect(() => {
    if (!txUpdateOccured) {
      setTxUpdateOccured(true);
    }
  }, [state.burn.tx, state.swap.tx, state.mint.tx]);

  const _currentTX = useGetCurrentTXInfo();

  useEffect(() => {
    if (_currentTX && txUpdateOccured) {
      setCurrentTX(_currentTX);
      setTxUpdateOccured(false);
    }
  }, [txUpdateOccured, _currentTX]);

  useEffect(() => {
    resolveTXStep();
  }, [currentTX]);

  useEffect(() => {
    setBRCBalance(Number(state.wallet.balances[`brcBalance`]).toFixed(2));
  }, [state.wallet.balances]);

  async function tokenBalanceAction() {
    const payload: SetBalancePayload = {
      ethAddress: account,
      contracts,
      provider: library,
    };
    dispatch(setBalancesAsync(payload));
  }

  useEffect(() => {
    resetAfterTradeComplete();
  }, [fromToken]);

  const resetAfterTradeComplete = () => {
    sellAmountRef.current = 0.0;
    buyAmountRef.current = 0.0;
    setToAmount(0.0);
    setFromAmount(0.0);
    setDaiAmount(0.0);
    setActiveStep(TX_STEP.NOT_INITIALIZED);
    setCurrentTX({
      status: TX_STATUS.NOT_INITIALIZED,
      failData: {},
      successData: {},
      reasonForFailure: FAIL_REASON.NOT_SET,
      txStep: TX_STEP.NOT_INITIALIZED,
      nextTXStep: TX_STEP.NOT_INITIALIZED,
    });
    dispatch(resetBurnTXState());
    dispatch(resetMintTXState());
    dispatch(resetSwapTXState());
    dispatch(resetStakeTXState());
    dispatch(resetWithdrawTXState());
    dispatch(resetClaimTXState());

    if (areContractsLoaded(contracts) && library && account && chainId) {
      tokenBalanceAction();
    }
  };

  useEffect(() => {
    if (!props.walletConnected) {
      setToAmount(0.0);
      setFromAmount(0.0);
    }
  }, [props.walletConnected]);

  const hasEnoughBalance = () => {
    return fromAmount >
      Number(
        state.wallet.balances[`${fromToken.toLowerCase()}Balance`]?.toString()
      )
      ? false
      : true;
  };

  useEffect(() => {
    !hasEnoughBalance() &&
      setDisableMessage(`Insufficient ${fromToken.toUpperCase()} Balance`);
    // eslint-disable-next-line
  }, [toAmount, fromAmount, state.wallet.balances, fromToken]);

  useEffect(() => {
    (toAmount === 0 || fromAmount === 0 || !hasEnoughBalance()) &&
      setLockActionButtons(true);
    // eslint-disable-next-line
  }, [toAmount, fromAmount]);

  // We only sell BRQ for DAI, no swaps.
  useEffect(() => {
    if (transaction === TOKEN_ACTION.SELL) {
      setToToken(dai_Token.symbol);
      setFromToken(brc_Token.symbol);
    }
    if (transaction === TOKEN_ACTION.BUY) {
      if (fromToken === brc_Token.symbol) {
        setToToken(fromToken);
        setFromToken(toToken);
      } else {
        setToToken(toToken);
        setFromToken(fromToken);
      }
    }
  }, [transaction]);

  const onInputChangeSell = (amount: number, token: string) => {
    sellAmountRef.current = amount ? amount : 0;
    console.log("sellchange", sellAmountRef.current);
    setEditedCurrency(fromToken);
    if (fromAmount <= 0 - 0.99) {
      setDisableMessage("Typing...");
      setLockActionButtons(true);
    }
    setFromAmount(amount);
    setFromToken(token);
  };

  const onInputChangeBuy = (amount: number, token: string) => {
    buyAmountRef.current = amount ? amount : 0;
    // console.log(buyAmountRef.current);
    setEditedCurrency(toToken);
    if (toAmount <= 0) {
      setDisableMessage("Typing...");
      setLockActionButtons(true);
    }
    setToAmount(amount);
    setToToken(token);
  };

  const debounceGetReward = useDebounce(
    async () =>
      getReward(
        contracts,
        !isNaN(Number(fromAmount)) && Number(fromAmount) > 0
          ? bignumber(fromAmount)
          : bignumber(0),
        fromToken
      ),
    500
  );

  const debounceGetCost = useDebounce(
    async () =>
      getCost(
        contracts,
        !isNaN(Number(toAmount)) && Number(toAmount) > 0
          ? bignumber(toAmount)
          : bignumber(0),
        toToken,
        fromToken
      ),

    500
  );

  const conversionFromSell = async () => {
    if (editedCurrency === fromToken) {
      if (sellAmountRef.current && sellAmountRef.current > 0) {
        setShowBuySpinner(true);
        setLockActionButtons(true);
        setDisableMessage("Calculating...");

        const reward = await debounceGetReward();
        console.log(`Got reward Data => ${reward}`);
   
        if (sellAmountRef.current !== 0) {
          if(transaction === TOKEN_ACTION.BUY && Number(reward) < 1){
            setToAmount(0);
            setShowBuySpinner(false);
            setLockActionButtons(true);
            setDisableMessage(
             `Mint is possible at least 1BRC.`
            )
          } else {
            setDisableMessage("");
            setLockActionButtons(false);
            setShowBuySpinner(false);
            setToAmount(Number.parseFloat(reward));
          }
        } else {
          setToAmount(0);
          setShowBuySpinner(false);
          setLockActionButtons(true);
          setDisableMessage(
            `Enter ${
              transaction === TOKEN_ACTION.BUY ? "Buy" : "Sell"
            } Amount`
          );
        }
      } else {
        setToAmount(0);
        setDisableMessage(
          `Enter ${transaction === TOKEN_ACTION.BUY ? "Buy" : "Sell"} Amount`
        );
        setLockActionButtons(true);
        setShowBuySpinner(false);
        setShowSellSpinner(false);
      }
    }
  };

  const conversionFromBuy = async () => {
    if (editedCurrency === toToken) {
      if (
        Number(toAmount) === 0 ||
        isNaN(toAmount) ||
        Number(
          GetUSDTokenEquivalence(
            state,
            toAmount > 0 ? bignumber(toAmount.toString()) : bignumber(0),
            toToken
          )
        ) < 0.001
      ) {
        setFromAmount(0);
        return;
      }
      if (buyAmountRef.current && buyAmountRef.current > 0) {
        setShowSellSpinner(true);
        setLockActionButtons(true);
        setDisableMessage("Calculating...");
        const cost = await debounceGetCost();
        console.log(`Got Cost Data => ${cost}`);
        if (buyAmountRef.current !== 0) {
          setDisableMessage("");
          setLockActionButtons(false);
          setShowSellSpinner(false);
          setFromAmount(print2ndDecimal(cost));
        } else {
          setFromAmount(0);
          setShowSellSpinner(false);
          setLockActionButtons(true);
          setDisableMessage(
            `Enter ${
              transaction === TOKEN_ACTION.BUY ? "Buy" : "Sell"
            } Amount`
          );
        }
      } else {
        setFromAmount(0);
        setDisableMessage(
          `Enter ${transaction === TOKEN_ACTION.BUY ? "Buy" : "Sell"} Amount`
        );
        setLockActionButtons(true);
        setShowBuySpinner(false);
        setShowSellSpinner(false);
      }
    }
  };

  useEffect(() => {
    if (
      activeStep === TX_STEP.MINT_OR_BURN &&
      fromToken !== dai_Token.symbol &&
      fromToken !== brc_Token.symbol
    ) {
      setShowConfirmationPopup(true);
    }
  }, [activeStep]);

  useEffect(() => {
    if (areContractsLoaded(contracts)) {
      if (
        Number(fromAmount) === 0 ||
        isNaN(toAmount) ||
        (Number(state.swap.prices.usdPrice) > 0 &&
          Number(
            GetUSDTokenEquivalence(
              state,
              fromAmount > 0 ? bignumber(fromAmount.toString()) : bignumber(0),
              fromToken
            )
          ) < 0.001)
      ) {
        // console.log(`from ${fromToken}`, Number(GetUSDTokenEquivalence(state, fromAmount > 0 ? bignumber(fromAmount.toString()) : bignumber(0), fromToken)));

        setToAmount(0);
        setActiveStep(TX_STEP.NOT_INITIALIZED);
        return;
      } else {
        setActiveStep(TX_STEP.APPROVAL);
      }
      conversionFromSell();

      setDisableMessage(`Checking ${fromToken} approval...`);
      //setLockActionButtons(true);

      // tallies with approvals configurations
      let firstSpender: string = ROUTER_CONTRACT;
      let firstContract: Contract =
        contracts[`${fromToken.toLowerCase()}Contract`];
      const secondContract: Contract = contracts.daiContract;
      const secondSpender = brc_Token.address;

      if (fromToken === dai_Token.symbol) {
        //|| fromToken === eth_Token.symbol
        firstContract = contracts.daiContract;
        firstSpender = brc_Token.address;
      }

      if (fromToken === brc_Token.symbol) {
        firstSpender = brc_Token.address;
      }

      const fromTokenObj: Token = Tokens[`${fromToken.toLowerCase()}Token`];
      // console.log('token obj', fromTokenObj);
      hasEnoughAllowance(
        firstContract,
        firstSpender,
        account,
        !isNaN(Number(fromAmount)) && Number(fromAmount) > 0
          ? bignumber(fromAmount)
          : bignumber(0),
        slippage,
        fromTokenObj.decimals
      )
        .then((tokenEnough) => {
          // console.log(`is ${fromToken} enough`, tokenEnough);
          if (tokenEnough) {
            setIsTokenAllowanceEnough(true);

            setDisableMessage(`Checking ${dai_Token.symbol} approval...`);
            setLockActionButtons(true);

            if (
              fromToken === dai_Token.symbol ||
              fromToken === eth_Token.symbol ||
              fromToken === brc_Token.symbol
            ) {
              if (tokenEnough) {
                fromToken === dai_Token.symbol || fromToken === brc_Token.symbol
                  ? setActiveStep(TX_STEP.MINT_OR_BURN)
                  : setActiveStep(TX_STEP.SWAP);
              }
              setDisableMessage(``);
              setLockActionButtons(false);
              return;
            }

            //get DAI quote
            getDaiQuoteForToken({
              amount:
                !isNaN(Number(fromAmount)) && Number(fromAmount) > 0
                  ? bignumber(fromAmount)
                  : bignumber(0),
              swapToken: fromToken,
            })
              .then((daiQuote) => {
                setDaiAmount(Number(daiQuote.toString()));
                // is quote enough?
                hasEnoughAllowance(
                  secondContract,
                  secondSpender,
                  account,
                  bignumber(daiQuote),
                  slippage
                )
                  .then((daiEnough) => {
                    // console.log('is dai enough', daiEnough);
                    if (daiEnough) {
                      setIsDAIAllowanceEnough(true);
                      if (tokenEnough && daiEnough) {
                        // console.log('all enough');
                        setActiveStep(TX_STEP.SWAP);
                      } else {
                        // console.log('all not enough',{tokenEnough, daiEnough})
                        setActiveStep(TX_STEP.NOT_INITIALIZED);
                      }
                    } else {
                      setIsDAIAllowanceEnough(false);
                      // setActiveStep(TX_STEP.NOT_INITIALIZED);
                    }
                    setDisableMessage("");
                    setLockActionButtons(false);
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) => console.log(err));
            // if(transaction === TX_TYPE.MINT || transaction === TX_TYPE.BURN){
            //   setActiveStep(TX_STEP.);
            // }
          } else {
            setIsTokenAllowanceEnough(false);
            // setActiveStep(TX_STEP.NOT_INITIALIZED);
          }
          // setDisableMessage("");
          // setLockActionButtons(false);
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [contracts, fromToken, fromAmount]);

  useEffect(() => {
    if (areContractsLoaded(contracts)) {
      conversionFromBuy();
    }
    // eslint-disable-next-line
  }, [contracts, toAmount, toToken]);

  const toggleTransaction = (selectedIndex) => {
    if (state.application.currentTXstatus === TX_STATUS.PENDING) return;
    // On toggle switch transfer values from sell to buy vice versa

    if (selectedIndex < 0 || selectedIndex > 1) return;

    setToAmount(0);
    setFromAmount(0);
    sellAmountRef.current = 0.0;
    buyAmountRef.current = 0.0;

    switch (selectedIndex) {
      case 0:
        // buy
        setTransaction(TOKEN_ACTION.BUY);
        setDisableMessage(`Enter Buy Amount`);
        break;
      case 1:
        // sell
        setTransaction(TOKEN_ACTION.SELL);
        setDisableMessage(`Enter Sell Amount`);
        break;
    }
  };

  async function swap() {
    const options = {
      userAddress: account,
      daiAddress: contracts.daiContract._address.toString(),
      router: contracts.routerContract,
      amount: fromAmount,
      token: fromToken,
    } as SwapPayload;
    fromToken === eth_Token.symbol
      ? dispatch(swapEthForDaiAsync(options)).then((res) => {
          const _res: any = res;
          if (_res.error) {
            // if(currentTX.status === TX_STATUS.FAILED || currentTX.status === TX_STATUS.REJECTED){
            showFailBox();
          }
        })
      : dispatch(swapERC20TokenForDaiAsync(options)).then((res) => {
          const _res: any = res;
          if (_res.error) {
            // if(currentTX.status === TX_STATUS.FAILED || currentTX.status === TX_STATUS.REJECTED){
            showFailBox();
          }
        });
  }

  const sendTXDataToDynamoDB = (tx_type: TX_CODE, block: number) => {
    let data: BuySellData = {
      account: account,
      blockNumber: block.toString(),
      brc: toAmount.toString(),
      daiCost: state.swap.prices.daiPrice,
      tx_type: tx_type,
      testNet: isTestnetNetwork(chainId) ? 1 : 0,
      timestamp: 0,
    };

    if (Number(data.daiCost) === 0) return;

    web3.eth
      .getBlock(block)
      .then((_block) => {
        data.timestamp = Number(_block.timestamp);
        APICall("/tx/add", HTTP_METHOD.PUT, true, data)
          .then((result) => {
            if (!result) {
              // console.log('FAILED (1): mint data upload failed. Retrying...');
              APICall("/tx/add", HTTP_METHOD.PUT, true, data)
                .then((result) => {
                  if (!result) {
                    // console.log('FAILED (2): mint data upload');
                  } else {
                    // console.log('SUCCESS (2): mint data upload');
                  }
                })
                .catch((error) => {
                  console.log("sendTXData (2):", error);
                });
            } else {
              // console.log('SUCCESS (1): mint data upload');
            }
          })
          .catch((error) => {
            console.log("sendTXData", error);
          });
      })
      .catch((error) => console.log(error));
  };

  async function mintTokens() {
    let daiSellAmount;
    // console.log("sellToken: ", fromToken)
    switch (fromToken) {
      case dai_Token.symbol:
        daiSellAmount = fromAmount;
        break;
      case eth_Token.symbol:
        daiSellAmount = await getDaiQuoteForEth({
          amount: fromAmount,
        });
        break;
      default:
        if (daiAmount > 0) {
          daiSellAmount = daiAmount;
        } else {
          daiSellAmount = await getDaiQuoteForToken({
            amount: fromAmount,
            swapToken: fromToken,
          });
        }
    }
    if (daiSellAmount === 0) {
      showResetBox();
      return;
    }

    if (editedCurrency === brc_Token.symbol.toLowerCase()) {
      const mintOptions = {
        tokenInstanceContract: state.application.contracts.brcContract,
        amount:
          !isNaN(Number(toAmount)) && Number(toAmount) > 0
            ? bignumber(toAmount)
            : bignumber(0),
        userAddress: account as string,
      };
      dispatch(mintWithSpecificCurveTokenAsync(mintOptions)).then((res) => {
        const _res: any = res;
        if (
          _res.payload &&
          _res.payload.status &&
          activeStep === TX_STEP.MINT_OR_BURN
        ) {
          sendTXDataToDynamoDB(TX_CODE.BUY, _res.payload.blockNumber);
          showSuccessBox();
        } else if (
          _res.payload &&
          !_res.payload.status &&
          activeStep === TX_STEP.MINT_OR_BURN
        ) {
          showFailBox();
        } else {
          if (_res.error) {
            // if(currentTX.status === TX_STATUS.FAILED || currentTX.status === TX_STATUS.REJECTED){
            showFailBox();
          }
        }
      });
    } else {
      const mintOptions = {
        tokenInstanceContract: state.application.contracts.brcContract,
        amount: daiSellAmount,
        userAddress: account as string,
      };

      dispatch(mintWithCollateralTokenAsync(mintOptions)).then((res) => {
        const _res: any = res;
        if (
          _res.payload &&
          _res.payload.status &&
          activeStep === TX_STEP.MINT_OR_BURN
        ) {
          // console.log('mint response', res);
          sendTXDataToDynamoDB(TX_CODE.BUY, _res.payload.blockNumber);
          showSuccessBox();
        } else if (
          _res.payload &&
          !_res.payload.status &&
          activeStep === TX_STEP.MINT_OR_BURN
        ) {
          showFailBox();
        } else {
          if (_res.error) {
            // if(currentTX.status === TX_STATUS.FAILED || currentTX.status === TX_STATUS.REJECTED){
            showFailBox();
          }
        }
      });
    }
  }

  async function burnTokens() {
    const options = {
      tokenInstanceContract: state.application.contracts.brcContract,
      amount:
        !isNaN(Number(fromAmount)) && Number(fromAmount) > 0
          ? bignumber(fromAmount)
          : bignumber(0),
      userAddress: account,
    };
    dispatch(burnTokenAsync(options)).then((res) => {
      const _res: any = res;
      // console.log('tx res', _res);

      if (
        _res.payload &&
        _res.payload.status &&
        activeStep === TX_STEP.MINT_OR_BURN
      ) {
        sendTXDataToDynamoDB(TX_CODE.SELL, _res.payload.blockNumber);
        showSuccessBox();
      } else if (
        _res.payload &&
        !_res.payload.status &&
        activeStep === TX_STEP.MINT_OR_BURN
      ) {
        showFailBox();
      } else {
        if (_res.error) {
          // if(currentTX.status === TX_STATUS.FAILED || currentTX.status === TX_STATUS.REJECTED){
          showFailBox();
        }
      }
    });
  }

  const showSuccessBox = () => {
    tokenBalanceAction().then(() => {
      setShowSuccessPopup(true);
    });
  };

  const showResetBox = () => {
    tokenBalanceAction().then(() => {
      setShowResetPopup(true);
    });
  };

  const showFailBox = () => {
    tokenBalanceAction().then(() => {
      setShowFailPopup(true);
    });
  };

  const approveAction = async () => {
    fromToken === brc_Token.symbol
      ? dispatch(
          approveBurnAsync({
            contracts: state.application.contracts,
            ethAddress: account as string,
            amount:
              !isNaN(Number(fromAmount)) && Number(fromAmount) > 0
                ? bignumber(fromAmount)
                : bignumber(0),
            slippage: slippage,
            balances: state.wallet.balances,
          })
        ).then((res) => {
          const _res: any = res;
          if (_res.error) {
            // if(currentTX.status === TX_STATUS.FAILED || currentTX.status === TX_STATUS.REJECTED){
            setShowFailPopup(true);
          }
        })
      : dispatch(
          approveMintAsync({
            contracts: state.application.contracts,
            ethAddress: account as string,
            amount:
              !isNaN(Number(fromAmount)) && Number(fromAmount) > 0
                ? bignumber(fromAmount)
                : bignumber(0),
            token: fromToken,
            slippage: slippage,
            balances: state.wallet.balances,
          })
        )
          .then((res) => {
            const _res: any = res;
            if (_res.error) {
              // if(currentTX.status === TX_STATUS.FAILED || currentTX.status === TX_STATUS.REJECTED){
              setShowFailPopup(true);
            }
          })
          .catch((error) => {
            console.log("SwapBox::approveMintAsync err", error);
          });
  };

  const finalAction = async () =>
    fromToken === brc_Token.symbol ? await burnTokens() : await mintTokens();

  const classes = useStyles({ ...props, ...theme });

  const userPrompt = props.walletConnected
    ? !props.chainIdIsCorrect
      ? "Unsupported Network"
      : `Insufficient ${fromToken.toUpperCase()} Balance`
    : "Connect Wallet";

  const priceToken = fromToken === brc_Token.symbol ? toToken : fromToken;

  const printSupply = async () => {
    const supply = await getTokenSupply(contracts);
    // console.log(`Token Supply: BRC ${supply}`);
  };

  const printPrice = async () => {
    const price = await getPrice(contracts);
    // console.log(`Current Price: DAI ${price}`);
  };

  //? there is no tx hash if tx is rejected, so we rather show user's address on etherscan.
  const failureEtherscanLink =
    currentTX.reasonForFailure === "reverted" && currentTX.failData
      ? `https://${linkModifiers[chainId] || ""}/tx/${
          currentTX.failData?.transactionHash || currentTX.failData?.hash || ""
        }`
      : `https://${linkModifiers[chainId] || ""}/address/${account}`;

  const successEtherscanLink = currentTX.successData
    ? `https://${linkModifiers[chainId] || ""}/tx/${
        currentTX.successData?.transactionHash ||
        currentTX.successData?.hash ||
        ""
      }`
    : `https://${linkModifiers[chainId] || ""}/address/${account}`;

  const confirmationAction = () => {
    finalAction();
    setShowConfirmationPopup(false);
  };

  const resetAction = () => {
    resetAfterTX();
    setShowResetPopup(false);
  };

  const resolveTXStep = () => {
    if (currentTX.nextTXStep > currentTX.txStep) {
      // current step not final step
      if (currentTX.status === TX_STATUS.SUCCESS) {
        // current TX was successful and set next TX step
        setActiveStep(currentTX.nextTXStep);
      } else {
        if (currentTX.status !== TX_STATUS.PENDING) {
          setActiveStep(currentTX.txStep); // current step not final step
        }
      }
    } else {
      // current step is final step
      if (
        currentTX.status !== TX_STATUS.PENDING &&
        currentTX.status !== TX_STATUS.NOT_INITIALIZED
      ) {
        //currentTX.status === TX_STATUS.REJECTED || currentTX.status === TX_STATUS.FAILED
        setActiveStep(currentTX.nextTXStep); // go to reset step
        if (
          currentTX.status === TX_STATUS.REJECTED ||
          currentTX.status === TX_STATUS.FAILED
        ) {
          setActiveStep(currentTX.txStep); // go to reset step
        }
      }
    }
  };

  const resetAfterTX = () => {
    setShowSuccessPopup(false);
    resetAfterTradeComplete();
  };

  const failedAction = () => {
    setShowFailPopup(false);
    resetAfterTradeComplete();
  };

  return (
    <div style={{ maxWidth: "480px", margin: "0px auto", marginTop: "22px" }}>
      <CardMain>
        {showConfirmationPopup && (
          <Confirmation
            transactionType={transaction}
            buyToken={toToken}
            buyAmount={toAmount}
            sellAmount={fromAmount}
            sellToken={fromToken}
            step={activeStep}
            confirmationAction={confirmationAction}
            closeModal={() => setShowConfirmationPopup(false)}
          />
        )}
        {showFailPopup && (
          <Failure
            data={currentTX.failData}
            buyToken={toToken}
            buyAmount={toAmount}
            sellAmount={fromAmount}
            sellToken={fromToken}
            transactionType={transaction}
            reason={currentTX.reasonForFailure}
            etherscanLink={failureEtherscanLink}
            step={activeStep}
            closeModal={failedAction}
          />
        )}
        {showSuccessPopup && (
          <Success
            transactionType={transaction}
            buyToken={toToken}
            sellToken={fromToken}
            buyAmount={toAmount}
            sellAmount={fromAmount}
            data={currentTX.successData}
            etherscanLink={successEtherscanLink}
            step={activeStep}
            closeModal={() => resetAfterTX()}
          />
        )}
        {showResetPopup && (
          <Reset
            transactionType={transaction}
            step={activeStep}
            confirmationAction={resetAction}
            closeModal={resetAction}
          />
        )}

        <div className={classes.SwapBox}>
          <div className="cont__tit__box">
            {/* <BelowDesktop> */}
            <div className={classes.boxTitleMobile}>
              {toToken === brc_Token.symbol ? "* Buy BRC with DAI, USDT, or USDC" : "* Enter amount of BRC to unmint to DAI"}
            </div>
            {/* </BelowDesktop> */}
            <div className="swap__box cont__settings">
              {/* <BelowDesktop> */}
              <div
                style={{ position: "relative", top: "-10px", right: "15px" }}
              >
                <PriceConversionContainer token={priceToken} />
              </div>
              {/* </BelowDesktop>
            <Desktop>
              <a style={{marginRight: "2rem"}} target="_blank"
                    rel="noopener noreferrer"
                    href={`https://arbitrum.sushi.com/swap?inputCurrency=${dai_Token.address}&outputCurrency=${dai_Token.address}`}>                
                    {https://app.uniswap.org/#/swap?inputCurrency=${dai_Token.address}&outputCurrency=${gbrc_Token.address} 
                <button type="button" className="btn__settings btn__gwei">
                    <img style={{width:"25px"}} src={require("../../media/icons/ico-dai-black.svg").default} alt=""/>
                    <span className="number">
                        Buy DAI
                    </span>                                                               
                </button>
              </a>
            </Desktop> */}
              {/* <button type="button" className="btn__settings btn__gwei">
                  <img src={require("../../media/icons/ico-Gas.svg").default} alt=""/>
                  <span className="number">
                    {lowCurrentGas === highCurrentGas ? highCurrentGas : `${lowCurrentGas}~${highCurrentGas}`}
                  </span>
                  <span className="txt">Gwei</span>
              </button> */}

              {/* <button type="button" className="btn__settings btn__setting" onClick={ () => setShowSettingsPopup(true) }>
                  <img src={require("../../media/icons/ico-settings.svg").default} alt="settings icon"/>
              </button>
              {showSettingsPopup && <SettingsPopUp slippage={slippage} onChangeSlippage={setSlippage} closeModal={() => setShowSettingsPopup(false) } />} */}
            </div>
          </div>

          {/* <Desktop>
          <section className="index__section">
            <article className="index__area">
                <div>
                    <header className="tit__box">
                        <span className={classes.title}>From</span>
                    </header>
                    <article className="index__area__inneer">
                      <BuySellBox
                        setBuyAmount={setToAmount}
                        buyAmountRef={buyAmountRef}
                        sellAmountRef={sellAmountRef}
                        setSellAmount={setFromAmount}
                        onSwapInputChange={onInputChangeSell}
                        tokenAction={TOKEN_ACTION.SELL}
                        amount={fromAmount}
                        token={fromToken}
                        buyToken={fromToken}
                        defaultCurrency={DefaultToken.symbol}
                        setBuyToken={setToToken}
                        setSellToken={setFromToken}
                        showSpinner={showSellSpinner}
                      />
                    </article>
                </div>
            </article>
            <div className="arrow__hr__box">
                <p className="arrow__hr">
                    <span className="ico_arrow">
                        <img src={require("../../media/icons/arrow-right.png").default} alt=""/>
                    </span>
                </p>
            </div>
            <article className="index__area">
                <div>
                    <header className="tit__box">
                      <span className={classes.title}>To</span>
                    </header>
                    <article className="index__area__inneer">
                      <BuySellBox
                        setBuyAmount={setToAmount}
                        buyAmountRef={buyAmountRef}
                        sellAmountRef={sellAmountRef}
                        setSellAmount={setFromAmount}
                        onSwapInputChange={onInputChangeBuy}
                        amount={toAmount}
                        tokenAction={TOKEN_ACTION.BUY}
                        token={toToken}
                        buyToken={fromToken}
                        defaultCurrency={toToken}
                        setBuyToken={setToToken}
                        setSellToken={setFromToken}
                        showSpinner={showBuySpinner}
                      />
                    </article>
                </div>
            </article>
          </section>
        
        </Desktop> */}

          {/* <BelowDesktop> */}

          <Grid container spacing={0} alignItems="center">
            <Grid item sm={12} xs={12}>
              <BuySellBox
                setBuyAmount={setToAmount}
                buyAmountRef={buyAmountRef}
                sellAmountRef={sellAmountRef}
                setSellAmount={setFromAmount}
                onSwapInputChange={onInputChangeSell}
                tokenAction={TOKEN_ACTION.SELL}
                amount={fromAmount}
                token={fromToken}
                buyToken={fromToken}
                defaultCurrency={DefaultToken.symbol}
                setBuyToken={setToToken}
                setSellToken={setFromToken}
                showSpinner={showSellSpinner}
              />
            </Grid>
            <Grid
              className={"mob-negative-margin-top-20"}
              item
              sm={12}
              xs={12}
              style={{ zIndex: 1000 }}
            >
              <div className="arrow__hr__box">
                <div className="arrow__hr">
                  <span className="ico_arrow" style={{ marginBottom: "6px" }}>
                    <img
                      src={
                        require("../../media/icons/arrow-bottom.png").default
                      }
                      alt=""
                    />
                  </span>
                </div>
              </div>
            </Grid>
            <Grid className={"mob-negative-margin-top-20"} item sm={12} xs={12}>
              <BuySellBox
                setBuyAmount={setToAmount}
                buyAmountRef={buyAmountRef}
                sellAmountRef={sellAmountRef}
                setSellAmount={setFromAmount}
                onSwapInputChange={onInputChangeBuy}
                amount={toAmount}
                tokenAction={TOKEN_ACTION.BUY}
                token={toToken}
                buyToken={fromToken}
                defaultCurrency={toToken}
                setBuyToken={setToToken}
                setSellToken={setFromToken}
                showSpinner={showBuySpinner}
              />
            </Grid>
          </Grid>
          {/* </BelowDesktop> */}

          <div className={classes.index}>
            {props.walletConnected && props.chainIdIsCorrect ? (
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {/* <Desktop>
              <PriceConversionContainer token={priceToken} />
            </Desktop> */}

                <ProgressBubbles
                  stepCount={
                    fromToken === dai_Token.symbol ||
                    fromToken === brc_Token.symbol
                      ? 2
                      : 3
                  }
                  currentStep={activeStep}
                  transactionType={
                    toToken === brc_Token.symbol
                      ? TOKEN_ACTION.BUY
                      : TOKEN_ACTION.SELL
                  }
                />
              </div>
            ) : null}

            {!props.walletConnected ||
            !props.chainIdIsCorrect ||
            (activeStep < TX_STEP.SWAP && !hasEnoughBalance()) ? (
              // !window.ethereum && !window.web3 && (isOpera || isFirefox || isSafari || isChrome || isIE || isEdge) ? (
              //   <div style={{ width: "100%" }}>
              //     <OpenApp
              //       href="https://metamask.app.link/dapp/app.brinc.fi"
              //       android="https://metamask.app.link/dapp/app.brinc.fi"
              //       ios="https://metamask.app.link/dapp/app.brinc.fi"
              //     >
              //       <div
              //         className={classes.buttonContainer}
              //         style={{ margin: "3rem auto .5rem auto" }}
              //       >
              //         <FillButton
              //           clickFunction={null}
              //           icon={!props.walletConnected ? "WalletBlack" : null}
              //           label={userPrompt}
              //           disabled={props.walletConnected ? true : false}
              //           pending={false}
              //           success={false}
              //           fail={false}
              //         />
              //       </div>
              //     </OpenApp>
              //   </div>
              // ) : (
                <div
                  className={classes.buttonContainer}
                  style={{ margin: "3rem auto .5rem auto" }}
                >
                  <FillButton
                    clickFunction={props.toggleWalletModal}
                    icon={!props.walletConnected ? "WalletBlack" : null}
                    label={userPrompt}
                    disabled={props.walletConnected ? true : false}
                    pending={false}
                    success={false}
                    fail={false}
                  />
                </div>
              // )
            ) : (
              <div
                className={classes.buttonContainer}
                style={{ margin: "3rem auto .5rem auto" }}
              >
                <ActionButtons
                  setShowConfirmationPopup={setShowConfirmationPopup}
                  sellToken={fromToken}
                  walletConnected={props.walletConnected}
                  approveAction={approveAction}
                  swapAction={swap}
                  transactionType={
                    toToken === brc_Token.symbol
                      ? TOKEN_ACTION.BUY
                      : TOKEN_ACTION.SELL
                  }
                  step={activeStep}
                  disabled={lockActionButtons}
                  disableMessage={disableMessage}
                />
              </div>
            )}
          </div>
        </div>
      </CardMain>
    </div>
  );
}

export default SwapBox;
