import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./buyButton";

const useStyles = makeStyles(() =>
  createStyles({
    BuyButton: {
      // backgroundColor: "rgba(54,158,73,.15)",
      font: (style: Theme & Props) => style.typography.maxButton,  
      fontSize: '12px !important',    
      color: (style: Theme & Props) => style.fadeText,    
      textTransform: "uppercase",      
      whiteSpace:"nowrap",
      outline: "none",
      border: "1px solid var(--govBlue)",
      borderRadius: "100px",
      padding: "0px 8px",
      height: "18px",
      cursor: (style: Theme & Props) => (style.disabled ? "auto" : "pointer"),
      // "&:hover": {
      //   color: (style: Theme & Props) =>
      //     !style.disabled && style.hoverText,
      //   background: (style: Theme & Props) =>
      //     !style.disabled && style.maxButtonBgHover,
      // },
      // "&:focus": {
      //   color: (style: Theme & Props) =>
      //     !style.disabled && style.hoverText,
      //   background: (style: Theme & Props) =>
      //     !style.disabled && style.maxButtonBgHover,
      // },
    }
  })
);

export default useStyles;
