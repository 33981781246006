import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link } from "react-router-dom";
import './footer.scss';
    
function SnsLink(){
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const snsLink = "sns__link"
    return <>
        <div className="mob__sns__link">
            <button type="button" className="sns__link" onClick={() => window.open('https://discord.com/invite/QFFrBu68rp' , '_blank')}>
                <img src={require("../../media/icons/ic-Discord-sm.svg").default} alt="Discord icon"/>
            </button>
            <button type="button" className="sns__link" onClick={() => window.open('https://twitter.com/BrincFi' , '_blank')}>
                <img src={require("../../media/icons/ico-twitter-sm.svg").default} alt="twitter icon"/>
            </button>
            <button type="button" className="sns__link" onClick={() => window.open('https://t.me/BrincX' , '_blank')}>
              <img src={require("../../media/icons/ico-telegram-sm.svg").default} alt="telegram icon"/>
            </button>
            <button type="button" className="sns__link" onClick={() => window.open('https://www.youtube.com/channel/UCw8CF3-sXihmGB5iyR3_5kA' , '_blank')}>
                <img src={require("../../media/icons/ico-youtube.svg").default} alt="youtube icon"/>
            </button>
            {/* <Link to="/" className={snsLink}>
                <img src={require("../../media/icons/ico-twitter.svg").default} alt="twitter icon"/>
            </Link>
            <Link to="/" className={snsLink}>
                <img src={require("../../media/icons/ico-insta.svg").default} alt="insta icon"/>
            </Link>
            <Link to="/" className={snsLink}>
                <img src={require("../../media/icons/ico-send.svg").default} alt="send icon"/>
            </Link>
            <Link to="/" className={snsLink}>
                <img src={require("../../media/icons/ico-facebook.svg").default} alt="facebook icon"/>
            </Link> */}
        </div>
    </>
}

export default SnsLink