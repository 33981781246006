import { InjectedConnector } from "@web3-react/injected-connector";
import { PortisConnector } from "@web3-react/portis-connector";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { LedgerConnector } from "@web3-react/ledger-connector";
import { NetworkConnector } from "./NetworkConnector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const supportedChains = {
  0: "Not connected",
  1: "mainnet",
  4: "rinkeby",
  421611: "arbitrum-rinkeby",
  42161: "arbitrum",
  // 1337: "development",
  // 31337: "hardhat",
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const POLLING_INTERVAL = 12000;
const RPC_URLS: { [chainId: number]: string } = {
  1: process.env.REACT_APP_INFURA_ENDPOINT_MAINNET as string,
  4: process.env.REACT_APP_INFURA_ENDPOINT_RINKEBY as string,
  42161: process.env.REACT_APP_INFURA_ENDPOINT_RINKEBY as string,
};

export const injectedConnector = new InjectedConnector({
  supportedChainIds: [
    1, // Mainet
    3, // Ropsten
    4, // Rinkeby
    5, // Goerli
    10, // Optimisc Ethereum
    14, // Flare Mainnet
    17, // Thaichain
    27, // ShibaChain
    40, // Telos EVM Mainnet
    42, // Kovan
    56, // BSC Mainnet
    60, // Go Chain
    61, // ETH Classic mainnet
    69, // Optimistic Kovan
    96, // NEXT Smart Chain
    97, // BSC testnet
    110, // Proton Testnet
    137, // Polygon Mainnet
    200, // Artibrum on xDai
    250, // Fantom Opera
    1024, // Clover Mainnet
    4002, // Fantom Testnet
    31337, // Dev or GoChain Testnet
    32659, // Fusion Mainnet
    42161, // Arbitrum One
    43113, // Avalanche Fuji Testnet
    43114, // Avalanche Mainnet
    80001, // Polygon Testnet Mumbai
    421611, // Arbitrum Rinkeby
    1313161554, // Aurora MainNet
    1313161555, // Aurora Testnet
    // 31337, // devChain
  ],
});

// local chain IDs like 1337 are not supported by default by web3-react's portis connector (kak dom).
// You must pass in the url and chain id for your local chain/node as third config argument in constructor.
const infuraProvider =
  window.localStorage.getItem("LOCAL_CHAIN_ID") === "4"
    ? {
        nodeUrl: process.env.REACT_APP_INFURA_ENDPOINT_RINKEBY as string,
        chainId: 4,
      }
    : {
        nodeUrl: process.env.REACT_APP_INFURA_ENDPOINT_MAINNET as string,
        chainId: 1,
      };

export const portis = new PortisConnector({
  dAppId: process.env.REACT_APP_PORTIS_DAPP_ID as string,
  networks: window.localStorage.getItem("LOCAL_CHAIN_ID") === "4" ? [4] : [1],
  config: infuraProvider,
});

export const walletConnect = new WalletConnectConnector({
  //rpc: { [AppNetworks.ARBITRUM_ONE]: RPC_URLS[AppNetworks.ARBITRUM_ONE] },
  rpc: { 42161: RPC_URLS[42161] },
  qrcode: true,
});

// export const ledger = new LedgerConnector({
//   chainId: 4,
//   url: RPC_URLS[4],
//   pollingInterval: POLLING_INTERVAL,
//   baseDerivationPath: "44'/60'/0'/0'",
//   requestTimeoutMs: 5000,
//   accountFetchingConfigs: {
//     shouldAskForOnDeviceConfirmation: true,
//   },
// });

const RPC = {
  1: 'https://eth-mainnet.alchemyapi.io/v2/q1gSNoSMEzJms47Qn93f9-9Xg5clkmEC',
  3: 'https://eth-ropsten.alchemyapi.io/v2/cidKix2Xr-snU3f6f6Zjq_rYdalKKHmW',
  4: 'https://eth-rinkeby.alchemyapi.io/v2/XVLwDlhGP6ApBXFz_lfv0aZ6VmurWhYD',
  5: 'https://eth-goerli.alchemyapi.io/v2/Dkk5d02QjttYEoGmhZnJG37rKt8Yl3Im',
  42: 'https://eth-kovan.alchemyapi.io/v2/6OVAa_B_rypWWl9HqtiYK26IRxXiYqER',
  42161: 'https://arb-mainnet.g.alchemy.com/v2/m_-QIjfwLgR7QwQBw-3G3336RK5jHt3d',
};

export const network = new NetworkConnector({
  defaultChainId: 42161,
  urls: RPC,
});
