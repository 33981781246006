import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../../store/themeContext/themes";
import { Props } from "./alertUser";

const useStyles = makeStyles(() =>
  createStyles({
    heading:{
			font: (style: Theme & Props) => style.typography.p2,      
			margin: "0rem 0rem 2rem 0rem",
		},
    AlertUser: {
      justifyContent: "center",
      left: "calc(50% - 30rem)",
      display: "flex",
      flexDirection: "column",
      width: "60rem",
      height: "10rem",
      fontSize: "2rem",
      position: "absolute",
      borderRadius: '2rem',
      overflow: "hidden",
      zIndex: 3,
    },
    greenStrip: {
      width: "100%",
      height: "10%",
      backgroundColor: "var(--green)",
    },
    alertBox: {
      backgroundColor: (style: Theme & Props) => style.background2,
      color: (style: Theme & Props) => style.text,
      width: "100%",
      height: "90%",
      padding: "2rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    iconContainer: {
      width: "20%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    textContainer: {
      width: "80%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    icon: {
      fill: (style: Theme & Props) => style.text,
      marginRight: "1rem",
    },
    textHeading: {
      fontSize: "2.4rem",
    },
    text: {
      fontSize: "1.8rem",
    },
  })
);

export default useStyles;
