import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../../store/themeContext/themes";
import { Props } from "./failure";

const useStyles = makeStyles(() =>
  createStyles({
    Failure: {
      transition: (style: Theme & Props) => style.transition,
      color: (style: Theme & Props) => style.text,
      height: '100%',
      width: "100%",
    },
    heading:{
			font: (style: Theme & Props) => style.typography.p2,      
			margin: "0rem 0rem 2rem 0rem",
		},
    actionButton: {
			height: "8rem",			
			font: (style: Theme & Props) => style.typography.h1_light,
		},
    brincIcon: {
			width: "100%",		
		},
    container: {
      position: "relative",
      padding: "4rem",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start"
    },
    closeButton: {
      padding: "1rem",
      position: "absolute",
      right: "4rem",
      top: "4rem"
    },
    chevron: {
      stroke: "white",
      width: "2rem"
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "1rem 0",
      height: "3rem"
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      margin: "1rem 0 0 3rem"
    },
    left: {
      margin: "0 auto 0 0",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "100%"
    },
    right: {
      margin: "0 0 0 auto",
      color: "var(--deep-red)",
      font: (style: Theme & Props) => style.typography.p4
    },
    title: {
      font: (style: Theme & Props) => style.typography.h1
    },
    paragraphSmall: {
      font: (style: Theme & Props) => style.typography.p3
    },
    paragraphBig: {
      font: (style: Theme & Props) => style.typography.p4
    },
    close: {
      width: "2rem",
      stroke: "var(--red)",
      marginRight: "2rem"
    },
    link: {
      "&:hover": {
        filter: "brightness(1.2)"
      }
    },
    dot: {
      position: "relative",
			top: "-5px",
      filter: "invert(52%) sepia(97%) saturate(1144%) hue-rotate(155deg) brightness(102%) contrast(109%)"
    },
    externalLink: {
      width: '1.5rem',
      stroke: 'white',
      marginLeft: "2rem",
    },
    listItem: {
      display: "flex",
      font: (style: Theme & Props) => style.typography.listItem,
      margin: "2rem auto 2rem 1rem"
    },
    redStrip: {
      width: "100%",
      height: "1rem",
      backgroundColor: "var(--red)",
    },
    questionIcon: {
			width: '2rem',
			height: "2rem",
			marginRight: '1rem',
			fill: 'grey'
		},
		infoText: {
			display: "inline-block",
			width: "calc(100% - 16px)",
			paddingLeft: "6px",
			font: (style: Theme & Props) => style.typography.p5,
			color: "--white",
			lineHeight: "15px",
			// marginTop: "6px",
			letterSpacing: "-0.08px"
		},
    bulletPoints:{
			display: "flex",
			flexDirection:"row",
			alignItems:"flex-start",
			marginTop:"0.75rem"
		}
  })
);

export default useStyles;
