import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./swap";

const useStyles = makeStyles(() =>
  createStyles({
    Wrapper: {
      backgroundColor: (style: Props & Theme) => style.background1, // TODO: change page bg
      // minWidth: "100vw",
      minHeight: "100vh",
      position: "relative",
      overflowX: "hidden",
      overflowY: "auto",
    },
    boxWrapper: {
      display: "flex",
      flexDirection:"column",
      alignItems: "center",
      // justifyContent: "space-around",
      margin: "40px",
      // marginBottom: "20rem"
    },
    marketInfoheading:{ 
      fontFamily: "var(--font2)",
      font: (style: Theme & Props) => style.typography.h4b,
      fontWeight: 700,
      color: (style: Theme & Props) => style.fadeText,
      fontSize: "15px !important",
    },
    header:{
      font: (style: Theme & Props) => style.typography.h2b,
      // font-weight: bold;
      fontSize: "2.2rem !important",
      fontStyle: "bold",
      color: "var(--fade-white)",
      lineHeight: "24px",
      letterSpacing: "-0.41px"
    },
    value:{
      font: (style: Theme & Props) => style.typography.h2b,
      fontSize: "2.6rem !important",
      fontStyle: "bold",
      color: (style: Theme & Props) => style.text,
      lineHeight: "24px",
      letterSpacing: "-0.41px",
    },
    smallValue:{
      font: (style: Theme & Props) => style.typography.h2b,
      fontSize: "1.8rem !important",
      fontStyle: "bold",
      color: "var(--fade-white)",
      lineHeight: "24px",
      letterSpacing: "-0.41px",
    },
  })
);

export default useStyles;
