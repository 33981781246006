import { Contract, ethers } from "ethers";
import math, { bignumber } from "mathjs";
import {
  dai_Token,
  MintTXType,
  TX_STATUS,
  usdc_Token,
  usdt_Token,
  wbtc_Token,
  eth_Token,
  chainId,
} from "../../constants";
import { noExponents } from "../../utils/utils";
import {
  IAppContractsNonNullable,
  IMintTransactionData,
} from "../application/AppSlice";
import { GetWeb3 } from "../helpers/contractBooter";
import { approve, approveTwice } from "../services/approvals";
import { getDaiQuoteForToken } from "../services/quotes";
import { IBalances } from "../wallet/WalletSlice";

export interface IMintState {
  tx: {
    type: MintTXType;
    status: TX_STATUS;
    data: IMintTransactionData;
  };
}

export type MintTokenPayload = {
  readonly tokenInstanceContract: Contract;
  readonly amount: math.BigNumber;
  readonly userAddress: string;
};

export type ApproveMintPayload = {
  contracts: IAppContractsNonNullable;
  ethAddress: string; //
  amount: math.BigNumber;
  slippage: number;
  token: string;
  balances: IBalances;
};

export const mintWithSpecificCurveToken = async (payload: MintTokenPayload) => {
  const { tokenInstanceContract, amount, userAddress } = payload;
  let web3 = GetWeb3(chainId);
  let gasPrice = await web3.eth.getGasPrice();
  const amountParsed = ethers.utils.parseUnits(noExponents(amount));
  return await tokenInstanceContract.methods
    .mint(userAddress, amountParsed)
    .send({ from: userAddress, gasPrice: gasPrice }); //, type: "0x2"
};

export const mintWithSpecificCollateralToken = async (
  payload: MintTokenPayload
) => {
  const { tokenInstanceContract, amount, userAddress } = payload;
  let web3 = GetWeb3(chainId);
  let gasPrice = await web3.eth.getGasPrice();
  const amountParsed = ethers.utils.parseUnits(noExponents(amount));
  return await tokenInstanceContract.methods
    .mintForSpecificReserveAmount(userAddress, amountParsed)
    .send({ from: userAddress, gasPrice: gasPrice }); //, type: "0x2"
};

export const approveMint = async (payload: ApproveMintPayload) => {
  const { contracts, ethAddress, amount, token, slippage, balances } = payload;
  switch (token) {
    case usdc_Token.symbol:
      return await approveTwice(
        balances,
        contracts.usdcContract,
        contracts.routerContract._address,
        ethAddress,
        bignumber(noExponents(amount)),
        contracts.daiContract,
        contracts.brcContract._address,
        bignumber(
          (
            await getDaiQuoteForToken({
              amount: bignumber(noExponents(amount)),
              swapToken: usdc_Token.symbol,
            })
          ).toString()
        ),
        slippage,
        token
      );
    case wbtc_Token.symbol:
      return await approveTwice(
        balances,
        contracts.wbtcContract,
        contracts.routerContract._address,
        // contracts.brcContract._address,
        ethAddress,
        bignumber(noExponents(amount)),
        contracts.daiContract,
        contracts.brcContract._address,
        bignumber(
          (
            await getDaiQuoteForToken({
              amount: bignumber(noExponents(amount)),
              swapToken: wbtc_Token.symbol,
            })
          ).toString()
        ),
        slippage,
        token
      );
    case usdt_Token.symbol:
      return await approveTwice(
        balances,
        contracts.usdtContract,
        contracts.routerContract._address,
        // contracts.brcContract._address,
        ethAddress,
        bignumber(noExponents(amount)),
        contracts.daiContract,
        contracts.brcContract._address,
        bignumber(
          (
            await getDaiQuoteForToken({
              amount: bignumber(noExponents(amount)),
              swapToken: usdt_Token.symbol,
            })
          ).toString()
        ),
        slippage,
        token
      );
    case dai_Token.symbol:
      return await approve(
        contracts.daiContract,
        contracts.brcContract._address,
        ethAddress,
        bignumber(noExponents(amount)),
        balances[`${token.toLowerCase()}Balance`],
        slippage
      );
    case eth_Token.symbol:
      return await approveTwice(
        balances,
        contracts.ethContract,
        contracts.routerContract._address,
        // contracts.brcContract._address,
        ethAddress,
        bignumber(noExponents(amount)),
        contracts.daiContract,
        contracts.brcContract._address,
        bignumber(
          (
            await getDaiQuoteForToken({
              amount: bignumber(noExponents(amount)),
              swapToken: eth_Token.symbol,
            })
          ).toString()
        ),
        slippage,
        token
      );
    default:
      return;
  }
};
