import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BurnTXType, FAIL_REASON, TX_STATUS, TX_STEP } from "../../constants";
import { approveBurn, ApproveBurnPayload, burnBRCToken, BurnTokenPayload, IBurnState } from "./Burn";


const initialState : IBurnState = {
    tx: {
        type: BurnTXType.NOT_SET,
        status: TX_STATUS.NOT_INITIALIZED,
        data: {
            txStep: TX_STEP.NOT_INITIALIZED,
            nextTXStep: TX_STEP.NOT_INITIALIZED,
            failData: {},
            successData: {},
            reasonForFail: FAIL_REASON.NOT_SET
        }
    }
}

const burnTokenAsync = createAsyncThunk(
    "burn/token",
    async (data: BurnTokenPayload, { rejectWithValue }) =>{
        try{
            const response = await burnBRCToken(data);
            // console.log("burnTokenAsync", response);        
            return response;
        }catch(err){
            return rejectWithValue(err.response.data);
        }
    }
)

const approveBurnAsync = createAsyncThunk(
    "burn/approve",
    async (data: ApproveBurnPayload, { rejectWithValue }) =>{
        try{
            const response = await approveBurn(data);
            // console.log("approveBurnAsync", response);        
            return response;
        }catch(err){
            return rejectWithValue(err.response.data);
        }
    }
)

const burnSliceReducer = createSlice({
    name: "burn",
    initialState,
    reducers: {        
        resetBurnTXState: (state) => {
            state.tx = {
                type: BurnTXType.NOT_SET,
                status: TX_STATUS.NOT_INITIALIZED,
                data: {
                    txStep: TX_STEP.NOT_INITIALIZED,
                    nextTXStep: TX_STEP.NOT_INITIALIZED,
                    failData: {},
                    successData: {},
                    reasonForFail: FAIL_REASON.NOT_SET
                }
            };
            // console.log('burn slice reset');
        }
    },
    extraReducers: (builder) => {
        builder
        // burn tokens
        // TODO: Handle (verify) when burn is rejected
        // ? burn returns err.code === 4001 if rejected
        .addCase(burnTokenAsync.pending, (state: IBurnState) => {
            state.tx.status = TX_STATUS.PENDING;
            state.tx.type = BurnTXType.BURN_TOKEN;       
            state.tx.data.txStep = TX_STEP.MINT_OR_BURN; 
            state.tx.data.failData = {};
            state.tx.data.successData = {};                             
        })
        .addCase(burnTokenAsync.rejected, (state: IBurnState, action) => {
            state.tx.status = TX_STATUS.REJECTED;
            state.tx.type = BurnTXType.BURN_TOKEN;
            state.tx.data.failData = action.payload;
            state.tx.data.successData = {};    
            state.tx.data.reasonForFail = FAIL_REASON.REJECTED;            
            // update TX step for next
            state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
        })
        .addCase(burnTokenAsync.fulfilled, (state: IBurnState, action) => {
            if(typeof action.payload === undefined){
                state.tx.status = TX_STATUS.FAILED;
                state.tx.type = BurnTXType.BURN_TOKEN;                
                state.tx.data.reasonForFail = FAIL_REASON.REVERTED;                
                state.tx.data.failData = {};
                state.tx.data.successData = {};    
            }else{                
                state.tx.status = TX_STATUS.SUCCESS;
                state.tx.type = BurnTXType.BURN_TOKEN;
                state.tx.data.successData = action.payload;    
                state.tx.data.failData = {};
            }            
            // update TX step for next
            state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
        })

        // approve burn
        // ? approvals return nothing if rejected
        .addCase(approveBurnAsync.pending, (state: IBurnState) => {
            state.tx.status = TX_STATUS.PENDING;
            state.tx.type = BurnTXType.APPROVAL;     
            state.tx.data.txStep = TX_STEP.APPROVAL; 
            state.tx.data.failData = {};
            state.tx.data.successData = {};                      
        })
        .addCase(approveBurnAsync.rejected, (state: IBurnState, action) => {
            state.tx.status = TX_STATUS.REJECTED;
            state.tx.type = BurnTXType.APPROVAL;
            state.tx.data.failData = action.payload;
            state.tx.data.successData = {};    
            state.tx.data.reasonForFail = FAIL_REASON.REJECTED;               
        })
        .addCase(approveBurnAsync.fulfilled, (state: IBurnState, action) => {
            if(typeof action.payload === undefined){
                state.tx.status = TX_STATUS.FAILED;
                state.tx.type = BurnTXType.APPROVAL;
                state.tx.data.failData = {};
                state.tx.data.successData = {};                    
                state.tx.data.reasonForFail = FAIL_REASON.REVERTED;   
            }else{                
                state.tx.status = TX_STATUS.SUCCESS;
                state.tx.type = BurnTXType.APPROVAL;
                state.tx.data.successData = action.payload;   
                state.tx.data.failData = {};
                
                // update TX step for next
                state.tx.data.nextTXStep = TX_STEP.MINT_OR_BURN;
            }            
        })
    }
})

export { burnTokenAsync, approveBurnAsync };
export const { resetBurnTXState } = burnSliceReducer.actions;
export default burnSliceReducer.reducer;
export const burnSelector = (state: { burnState: IBurnState }) => state.burnState