import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import { injectedConnector } from "../../connectors";
import { useDispatch, useSelector } from "react-redux";
import { setWalletConnection } from "../wallet/WalletSlice";
import { RootState } from "../mystore";

export function useInactiveListener(suppress = false) {
  const { active, activate, deactivate } = useWeb3React();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state);

  useEffect(() => {
    const ethereum = state.wallet.provider;

    if (ethereum && ethereum.on && active && !suppress) {
      const handleConnect = () => {
        console.log("handling connect event");
        activate(injectedConnector);
      };

      const handleChainChanged = (chainId) => {
        console.log("chainChanged", chainId);
        chainId &&
          window.localStorage.setItem(
            "LOCAL_CHAIN_ID",
            Number(chainId).toString()
          );
        activate(injectedConnector);
        window.location.reload();
      };

      const handleAccountsChanged = (accounts: string[]) => {
        console.log("accountsChanged", accounts);
        if (accounts.length > 0) {
          activate(injectedConnector);
          window.location.reload();
        } else {
          console.log("disconnected via wallet");
          deactivate();
          dispatch(setWalletConnection(false));
          // actions.setWalletDisconnected();
        }
      };

      const handleNetworkChanged = (networkId: string | number) => {
        console.log("chainChanged", networkId);
        activate(injectedConnector);
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("chainChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("chainChanged", handleNetworkChanged);
        }
      };
    }

    return () => {};
    // eslint-disable-next-line
  }, [state.wallet.isConnected, active, suppress, activate]);
}
