import React, { useContext } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./tokenDropdownStyles";
import { tokenOptions } from "./options";
import { ICONS } from "../icons/icons";
import { useToggle } from "../../hooks/useToggle";
import { ReactComponent as Chevron } from "../../media/icons/chevron_down.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useSelector } from "react-redux";
import { RootState } from "../../store/mystore";
import { TX_STATUS, brc_Token, gbrc_Token, DefaultToken, ust_Token, ScreenSize } from "../../constants";

export interface Props {
  buyAmountRef?: any;
  sellAmountRef?: any;
  stakePrimaryTokenAmountRef?: any;
  stakeSecondaryTokenAmountRef?: any;
  withdrawPrimaryTokenAmountRef?: any;
  withdrawSecondaryTokenAmountRef?: any;
  token: string;
  setToken?: (token: string) => void;
  setBuyAmount?: (amount: number) => void;
  setSellAmount?: (amount: number) => void;
  setStakePrimaryToken?: (token: string) => void;
  setStakeSecondaryToken?: (token: string) => void;
  setStakePrimaryTokenAmount?: (amount: number) => void;
  setStakeSecondaryTokenAmount?: (amount: number) => void;
  setWithdrawPrimaryToken?: (token: string) => void;
  setWithdrawSecondaryToken?: (token: string) => void;
  setWithdrawPrimaryTokenAmount?: (amount: number) => void;
  setWithdrawSecondaryTokenAmount?: (amount: number) => void;
  collapsible: boolean;
  screenSize: ScreenSize;
}

function TokenDropdown(props: Props) {
  const { theme } = useContext(ThemeContext);
  const [showDropdown, setShowDropdown, toggleDropdown] = useToggle(false);
  const state = useSelector((state: RootState) => state);

  const classes = useStyles({ ...props, ...theme });
  const { collapsible, setToken, setSellAmount, sellAmountRef, buyAmountRef, setBuyAmount } = props;


  const handleClick = (token: string) => {
    if(token === "init"){
      setToken("init")
    } else {
      if (token !== props.token) {
        buyAmountRef.current = 0.0
        sellAmountRef.current = 0.0
  
        setSellAmount(0.00);
        setBuyAmount(0.00);
        setToken(token);
        setShowDropdown(false);
      } else {
        setShowDropdown(false);
      }
    }
  };

  const dropdownItems = tokenOptions.map((token) => {
    const { name, Icon } = token;
    return (
      <li
        key={name}
        onClick={() => handleClick(name)}
        className={classes.dropdownItem}
      >
        {token.name === ust_Token.symbol ? 
        <img src={require("../../media/tokens/ust.png").default} alt={token.name} className={classes.tokenIcon} />: <Icon className={classes.tokenIcon} />}
        <span className={classes.tokenName}>{name}</span>
      </li>
    );
  });

  // const Icon = props.token === BRCToken.symbol || props.token === gBRCToken.symbol ? ICONS[props.token + '_Gif'] : ICONS[props.token];
  const Icon = props.token === DefaultToken.symbol ? ICONS.QuestionMarkOutline : ICONS[props.token];
  const widerWidth = props.token === DefaultToken.symbol ? {width: "190px"} :null
  return (
    <div className={classes.TokenDropdownContainer}>
      <button
        className={classes.dropdownButton}
        style={widerWidth}
        disabled={state.application.currentTXstatus === TX_STATUS.PENDING || !collapsible || !state.wallet.isConnected}
        onClick={toggleDropdown}
      >
        {/* change the please default value to 'Select a token' */}
        {/* <span className="select__tit">Select a token</span> */}
        <div className={classes.tokenContainer}>
          {/* {(props.token === brc_Token.symbol && <img style={{width: "21px"}} className={classes.tokenIcon} src={require("../../media/gif/icon.gif").default} alt=""/>) ||
            (props.token === gbrc_Token.symbol && <img style={{width: "21px"}} className={classes.tokenIcon} src={require("../../media/gif/ico-gbrc.gif").default} alt=""/>) ||            
            (props.token !== DefaultToken.symbol && <Icon className={`${classes.tokenIcon} ${props.token === brc_Token.symbol && classes.bigger}`} />)
          }   */}
          {
            (props.token === "init") ||
            (props.token === brc_Token.symbol && <img style={{width: "21px"}} className={classes.tokenIcon} src={require("../../media/tokens/BRQ.svg").default} alt=""/>) ||
            (props.token === gbrc_Token.symbol && <img style={{width: "21px"}} className={classes.tokenIcon} src={require("../../media/tokens/gbrc.svg").default} alt=""/>) ||            
            (props.token !== DefaultToken.symbol && 
              props.token === ust_Token.symbol ? 
                <img src={require("../../media/tokens/ust.png").default} alt={props.token} className={classes.tokenIcon} />:
                <Icon className={`${classes.tokenIcon} ${props.token === brc_Token.symbol && classes.bigger}`} />)
          }          
          {props.token === "init" ? 
            <span className={classes.buttonText} style={{width: "130px"}}>Select Token</span>
            :
            <span className={classes.buttonText}>{props.token}</span>
          }
          {collapsible && <Chevron className={classes.buttonIcon} />}
        </div>
      </button>

      {showDropdown && (
        <ClickAwayListener onClickAway={toggleDropdown}>
          <ul className={classes.dropdownMenu}>
            <li
              key={"init"}
              onClick={() => handleClick("init")}
              className={classes.dropdownItem}
            >
              {/* <img src={require("../../media/tokens/ust.png").default} alt={token.name} className={classes.tokenIcon} />: <Icon className={classes.tokenIcon} />} */}
              <span className={classes.tokenName}>Select Token</span>
            </li>
            {dropdownItems}
          </ul>
        </ClickAwayListener>
      )}
    </div>
  );
}

export default React.memo(TokenDropdown);
