import "./ImgMask.scss"

interface Iprops {
  isOwn: boolean,
  link: {
    opensea: string,
    galxe: string
  }

}
function ImgMask({isOwn, link}: Iprops) {

  const getNft = (site) => {
    if(site === "opensea"){
      window.open(link.opensea);
    } else if(site === "galxe") {
      window.open(link.galxe);
    }
  }

  return(
    <div className="mask-wrap" style={{opacity: isOwn ? 0 : 1}}>
      <div className="mask-logo-btn">
        <img className="nft-img" src={require("../../media/pngs/opensea_logo.png").default} alt="opensea logo" onClick={() => isOwn ? null : getNft("opensea")} />
      </div>
      <div className="mask-logo-btn">
        <img className="nft-img" src={require("../../media/pngs/galxe_logo.png").default} alt="galxe logo" onClick={() => isOwn ? null : getNft("galxe")} />
      </div>
    </div>
  )
}

export default ImgMask;