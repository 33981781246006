import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./progressBubbles";

const useStyles = makeStyles(() =>
  createStyles({
    ProgressBubbles: {
      backgroundColor: "transparent",
      transition: (style: Theme & Props) => style.transition,
      // color: (style: Theme & Props) => style.text,
      // width: "70%",
      height: "3rem",
      margin: "2rem auto 0 auto",
      display: "flex",
      justifyContent: "center",
      alignItems:"center",
      font: (style: Theme & Props) => style.typography.h4,  
      fontSize: "12px"  
    },
    bubble: {
      width: "3rem",
      height: "3rem",
      borderRadius: "50%",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",        
    },
    number: {
      height: "100%",
      width: "100%",
      color: "inherit",
      backgroundColor: "transparent",
      margin: "auto",
    },
    currentStep: {
      color: (style: Theme & Props) => "var(--white) !important"
    },
    otherSteps: {
      // color: "#A8ACB3 !important"
    }
  })
);

export default useStyles;
