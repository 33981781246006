import { ethers } from "ethers";
// import { parseUnits } from "ethers/utils";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import math from "mathjs";
import { AirdropType } from "../../constants";
import ETH_HOLDERS_DATA from "../../pages/Airdrop/eth_holders.json";
import BRC_HOLDERS_DATA from "../../pages/Airdrop/brc_holders.json";
import SOCIAL_MEDIA_DATA from "../../pages/Airdrop/social_media.json";

const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

let leafNodes;
let merkleTree;

export async function getAirdropMerkleData(airdropType: AirdropType){
  try{
    if(airdropType === AirdropType.aETH_HOLDERS){
      leafNodes = ETH_HOLDERS_DATA.data.map(item => ethers.utils.solidityKeccak256([ 'address', 'uint256' ], [ item.address, ethers.utils.parseUnits(item.amount.toString(), 18) ]))
    }
    else if(airdropType === AirdropType.BRC_HOLDERS){
      leafNodes = BRC_HOLDERS_DATA.data.map(item => ethers.utils.solidityKeccak256([ 'address', 'uint256' ], [ item.address, ethers.utils.parseUnits(item.amount.toString(), 18) ]))
    }
    else if(airdropType === AirdropType.SOCIAL_MEDIA){
      leafNodes = SOCIAL_MEDIA_DATA.data.map(item => ethers.utils.solidityKeccak256([ 'address', 'uint256' ], [ item.address, ethers.utils.parseUnits(item.amount.toString(), 18) ]))
    }
    else{
      return;
    }

    merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });

    var s = '0x';
    merkleTree.getRoot().forEach(function(byte) {
      s += ('0' + (byte & 0xFF).toString(16)).slice(-2);
    });

    const merkleData =  {
      rootHash: s,
      merkleTree: merkleTree
    }
    console.log(`merkle data ${airdropType}`, merkleData);
    
    return merkleData;
  }catch(err){
    console.log(err);
  }
};