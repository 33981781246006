import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../../store/themeContext/themes";
import { Props } from "./success";

const useStyles = makeStyles(() =>
	createStyles({
		Success: {
			transition: (style: Theme & Props) => style.transition,
			color: (style: Theme & Props) => style.text,
			height: '100%',
			width: "100%",
		},
		heading:{
			font: (style: Theme & Props) => style.typography.p2,      
			margin: "0rem 0rem 2rem 0rem",
		},
		brincIcon: {
			width: "100%",
		},
		boxValue: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center !important",
			marginLeft:"auto",
		},
		title: {
			font: (style: Theme & Props) => style.typography.h1
		},
		actionButton: {
			height: "8rem",
			marginTop: "5rem",
			font: (style: Theme & Props) => style.typography.h1_light,
		},
		link: {
			fontSize: "1.4rem",
			display: "flex",
			alignItems: "center",
			"&:hover": {
				filter: "brightness(1.2)"
			}
		},
		externalLink: {
			width: '1.5rem',
			stroke: 'white',
			marginLeft: "2rem",
		},		
	})
);

export default useStyles;
