import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AirdropType, FAIL_REASON, TX_STATUS, TX_STEP } from "../../constants";
import { claim, ClaimPayload, IClaimState } from "./Claim";

const initialState: IClaimState = {
  tx: {
    type: AirdropType.NOT_SET,
    status: TX_STATUS.NOT_INITIALIZED,
    data: {
      txStep: TX_STEP.NOT_INITIALIZED,
      nextTXStep: TX_STEP.NOT_INITIALIZED,
      failData: {},
      successData: {},
      reasonForFail: FAIL_REASON.NOT_SET,
    },
  },
};

const claimAirdropAsync = createAsyncThunk(
  "claim/airdrop",
  async (data: ClaimPayload, { rejectWithValue }) => {
    try {
      const response = await claim(data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const claimSliceReducer = createSlice({
  name: "claim",
  initialState,
  reducers: {
    resetClaimTXState: (state) => {
      state.tx = {
        type: AirdropType.NOT_SET,
        status: TX_STATUS.NOT_INITIALIZED,
        data: {
          txStep: TX_STEP.NOT_INITIALIZED,
          nextTXStep: TX_STEP.NOT_INITIALIZED,
          failData: {},
          successData: {},
          reasonForFail: FAIL_REASON.NOT_SET,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(claimAirdropAsync.pending, (state: IClaimState, action) => {
        const data = action.meta.arg;
        state.tx.type = data.airdropType;
        state.tx.status = TX_STATUS.PENDING;
        state.tx.data.txStep = TX_STEP.CLAIM;
      })
      .addCase(claimAirdropAsync.rejected, (state: IClaimState, action) => {
        state.tx.status = TX_STATUS.REJECTED;
        state.tx.data.failData = action.payload;
        state.tx.data.successData = {};
        state.tx.data.reasonForFail = FAIL_REASON.REJECTED;
      })
      .addCase(claimAirdropAsync.fulfilled, (state: IClaimState, action) => {
        if (typeof action.payload === undefined) {
          state.tx.status = TX_STATUS.FAILED;
          state.tx.data.reasonForFail = FAIL_REASON.REVERTED;
        } else {
          state.tx.status = TX_STATUS.SUCCESS;
          state.tx.data.successData = action.payload;
          state.tx.data.failData = {};
        }
        // update TX step for next
        state.tx.data.nextTXStep = TX_STEP.NOT_INITIALIZED;
      });
  },
});

export { claimAirdropAsync };
export const { resetClaimTXState } = claimSliceReducer.actions;
export default claimSliceReducer.reducer;
export const claimSelector = (state: { claimState: IClaimState }) =>
  state.claimState;
