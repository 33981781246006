import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import { RootState } from '../../store/mystore';
import './header.scss';

export interface Props {}
    
function Nav(props: Props){
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const state = useSelector((state: RootState) => state);    
    const itemLink = "gnb__item__link";
    const [open, setOpen] = useState(false);
    let activeClassName = "selected";
    return <>
        <nav className="gnb__area">
            <ul className={open ? 'gnb active' : 'gnb'}>
                {/* {state.wallet.isConnected ? 
                    <li className="gnb__item"><NavLink to="/dashboard" className={({isActive}) => isActive ? itemLink + " " + activeClassName : itemLink} onClick={() => setOpen(false)}>My Assets</NavLink></li>
                :null} */}
                <li className="gnb__item"><NavLink to="/mint"  className={({isActive}) => isActive ? itemLink + " " + activeClassName : itemLink} onClick={() => setOpen(false)}>Mint</NavLink></li>
                {/* <li className="gnb__item"><NavLink to="/stake"  className={({isActive}) => isActive ? itemLink + " " + activeClassName : itemLink} onClick={() => setOpen(false)}>Stake</NavLink></li> */}
                <li className="gnb__item"><NavLink to="/vault"  className={({isActive}) => isActive ? itemLink + " " + activeClassName : itemLink} onClick={() => setOpen(false)}>Vault</NavLink></li>
                <li className="gnb__item"><NavLink to="/analytics"  className={({isActive}) => isActive ? itemLink + " " + activeClassName : itemLink} onClick={() => setOpen(false)}>Analytics</NavLink></li>
                <li className="gnb__item"><NavLink to="/nft"  className={({isActive}) => isActive ? itemLink + " " + activeClassName : itemLink} onClick={() => setOpen(false)}>NFT</NavLink></li>
            </ul>
        </nav>
    </>
}

export default Nav