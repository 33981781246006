/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import "./analytics.scss";
import Header from "../../containers/header/header";
import Footer from "../../containers/footer/footer";
import AnalyticsChart from "../../components/analyticsChart/analyticsChart";
// import AnalyticSlider from '../../components/analyticSlider/analyticsSlider'
import { useInactiveListener } from "../../store/helpers/walletEventHooks";
import {
  areContractsLoaded,
  isMainnetNetwork,
  isTestnetNetwork,
} from "../../store/helpers/contractBooter";
import { useWeb3React } from "@web3-react/core";
import { IAppContractsNonNullable } from "../../store/application/AppSlice";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/mystore";
import { useToggle } from "../../hooks/useToggle";
import useDebounce from "../../hooks/useDebounce";

import {
  GetChangeRate,
  GetProfitLossRate,
  GetUSDTokenEquivalence,
} from "../../store/helpers/priceHelper";
import {
  handleNumberDisplay,
  isFiniteNumber,
  noExponents,
} from "../../utils/utils";
import {
  BasicContractCallPayload,
  PoolsSupply,
  UseAllContractsPayload,
} from "../../store/analytics/Analytics";
import {
  IAPYs,
  ITreasuryAssets,
  setAPY_APRsAsync,
  setBRCPriceChartInfoAsync,
  setBRCPriceInfoAsync,
  setBRCPriceUSD,
  setBRCTotalCirculatingSupplyAsync,
  setGBRCPriceInfoAsync,
  setGBRCTotalCirculatingSupplyAsync,
  setMarketCapAnalytics,
  setPercentageStakeAsync,
  setPoolsSupplyAsync,
  setTotalDAIReserveAsync,
  setTreasuryAssetsAsync,
} from "../../store/analytics/AnalyticsSlice";
import {
  AppNetworks,
  BlockCountHelper,
  brc_Token,
  eth_Token,
  gbrc_Token,
  HTTP_METHOD,
  infuraEndpoint,
  SUPPORTED_CHAINS,
  SUSHI_BRC_GBRC_PAIR_ARBITRUM_ADDRESS,
  teamWhiteList,
  UNISWAP_BRC_GBRC_POOL_ARBITRUM_ADDRESS,
  UNISWAP_WETH_GBRC_POOL_ARBITRUM_ADDRESS,
  URL,
} from "../../constants";
import { add, bignumber, divide } from "mathjs";
import Web3 from "web3";
import { AboveMobile, Desktop, Mobile } from "../../components/screenHelper";
import styled, { css } from "styled-components";
import { createClient } from "urql";
import ToolTippedObject from "../../components/toolTippedObject/toolTippedObject";
import { StakeRow } from "../../components/stakeTable/stakeTable";
import { getAllStakesAsync } from "../../store/stake/StakeSlice";
import APICall from "../../utils/apiCall";
import AlertUser from "../../components/dialogueBoxes/alertUser/alertUser";

declare let window: any;

export interface Props {}

export enum ChartLimit {
  ONE_DAY,
  SEVEN_DAYS,
  ONE_MONTH,
  THREE_MONTHS,
  SIX_MONTHS,
  ONE_YEAR,
  ALL,
}

const crContent = <div>(Collateral + Treasury Assets) / Market Value</div>;

const drContent = (
  <div>
    The Decentralization Ratio (DR)
    <br />
    is the ratio of collateral value that
    <br />
    is decentralized over the total
    <br />
    stablecoin supply backed for those assets.
  </div>
);

const earContent = (
  <div>
    Exogenous Asset Ratio (EAR)
    <br />
    is the % of collateral and treasury assets that
    <br />
    is not native protocol tokens, but held in
    <br />
    stablecoins or large cap coins.
    <br />
    Defi industry avg: 11% (Higher is better)
  </div>
);

// const ImageSize = { width: "22px", marginRight: "10px" };
const Area = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  @media screen and (min-width: 700px) {
    margin: 20px 12rem 12rem;
  }
`;

const AreaBox = styled.article`
  width: 100%;
  // flex: 1 1 100%;
  padding-bottom: 1em;
  background: transparent;
  box-shadow: inset 0 4px 4px rgb(14 14 14 / 50%), inset -2px -2px 2px #272828;
  border-radius: 10px;
  @media screen and (max-width: 420px) {
    padding-bottom: 0.5em;
  }
  @media screen and (max-width: 600px) {
    background: var(--nearBlack);
    border: 1px solid var(--nearBlackBorder);
    box-shadow: none;
  }
`;

const AreaTit = styled.h2`
  padding: 1em 0.25em 0.3em 1em;
  font-size: 1.4em;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  flex: 25%;
  @media screen and (max-width: 1000px) {
    font-size: 1em;
  }
  @media screen and (max-width: 420px) {
    font-size: 0.9em;
    padding: 1em 0.2m 0.3em 0.9em;
  }
  span {
    font-weight: normal;
    font-size: 0.8em;
    margin-left: 10px;
  }
`;
const AreaTitTokens = styled.h2`
  padding: 1em 1.25em 0.3em 0.25em;
  font-size: 1.3em;
  color: #fff;
  font-weight: bold;
  flex: 50%;
  text-align: right;
  @media screen and (max-width: 1000px) {
    font-size: 1em;
  }
  @media screen and (max-width: 420px) {
    font-size: 0.9em;
    padding: 1em 0.4m 0.3em 0.2em;
  }
`;

const AreacontentsBox = styled.section`
  width: 100%;
  padding: 0 1.1em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
  @media screen and (max-width: 420px) {
    padding: 0 0.8em;
  }
`;

const AreacontentsTit = styled.h3`
  padding: 0.7em 0.5em;
  border-bottom: 1px solid #2a2929;
  font-family: var(--font1);
  font-size: 1.4em;
  color: #fff;
  box-sizing: border-box;
  @media screen and (max-width: 1000px) {
    font-size: 1em;
  }
  @media screen and (max-width: 420px) {
    font-size: 0.9em;
    padding-bottom: 0.4em;
  }
`;

const AreaDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.paddingTop} 0.7em 0.5em
    ${(props) => props.paddingLeft};
  font-family: var(--font1);
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  ${(props) =>
    props.sub &&
    css`
      padding: 0.7em 0.5em 0.5em 0.5em;
      padding-bottom: ${(props) => props.borderBottom};
    `}
  @media screen and (max-width: 1000px) {
    padding-bottom: 0.5em;
  }
  @media screen and (max-width: 420px) {
    padding-top: 0.3em;
    padding-bottom: 0.3em;
  }
`;

const PoolsSection = styled.div`
  display: flex;
  direction: row;
  flex: 40%;
  justify-content: end;
`;

const InfoCircle = styled.div`
  margin: 2px 0 0 10px;
  @media screen and (max-width: 1000px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 420px) {
    margin-left: 2px;
    height: 9px;
  }
`;

const UnitTit = styled.strong`
  display: block;
  position: relative;
  font-family: var(--font1);
  font-size: 1.3em;
  padding-left: 0em;
  color: #adadad !important;
  ${(props) =>
    props.apr &&
    css`
      color: var(--fade-white) !important;
    `}
  flex: 60%;
  @media screen and (max-width: 1000px) {
    font-size: 1em;
  }
  @media screen and (max-width: 420px) {
    font-size: 0.9em;
    // padding: 1em 0.2m 0.3em 0.9em;
  }
`;

const ContentsNum = styled.section`
  font-family: var(--font1);
  font-size: 1.4em;
  letter-spacing: 1.1px;
  flex: 50%;
  text-align: right;
  white-space: nowrap;
  ${(props) =>
    props.apr &&
    css`
      color: var(--fade-white) !important;
    `}
  @media screen and (max-width: 1000px) {
    font-size: 1em;
  }
  @media screen and (max-width: 420px) {
    font-size: 0.9em;
  }
`;

const ContentsTit = styled.strong`
  font-family: var(--font1);
  font-size: 1.3em;
  color: #adadad;
  flex: 40%;
  @media screen and (max-width: 1000px) {
    font-size: 1em;
  }
  @media screen and (max-width: 420px) {
    font-size: 0.9em;
  }
`;

function Analytics() {
  const state = useSelector((state: RootState) => state);
  const dispatch = useAppDispatch();
  const { connector, active, chainId, account } = useWeb3React();
  const [contracts, setContracts] = useState<IAppContractsNonNullable>(
    state.application.contracts
  );
  const [activatingConnector, setActivatingConnector] = useState<any>();
  const [showWalletModal, setShowWalletModal, toggleWalletModal] =
    useToggle(false);
  const [brcUsd, setBrcUsd] = useState<string>(
    Number(state.analytics.brcPriceUSD) > 0
      ? state.analytics.brcPriceUSD.toString()
      : "--"
  );
  const [gbrcUsd, setGbrcUsd] = useState<string>(
    Number(state.analytics.gbrcPriceInfo.currentPrice) > 0
      ? state.analytics.gbrcPriceInfo.currentPrice.toString()
      : "--"
  );
  const [brcAvgBuy, setBrcAvgBuy] = useState<string>(
    Number(state.analytics.brcAverageBuyPriceUSD) > 0
      ? state.analytics.brcAverageBuyPriceUSD.toString()
      : "--"
  );
  const [gbrcAvgBuy, setGbrcAvgBuy] = useState<string>(
    Number(state.analytics.gbrcAverageBuyPriceUSD) > 0
      ? state.analytics.gbrcAverageBuyPriceUSD.toString()
      : "--"
  );
  const [perc24hrBrc, setPerc24hrBrc] = useState<string>(
    Number(state.analytics.brcPriceInfo.change24hrPercent) > 0
      ? GetChangeRate(
          state.analytics.brcPriceInfo.change24hrPercent,
          state.analytics.brcPriceUSD
        ).toString()
      : "--"
  );
  const [perc24hrGbrc, setPerc24hrGbrc] = useState<string>(
    Number(state.analytics.gbrcPriceInfo.change24hrPercent) > 0
      ? GetChangeRate(
          state.analytics.gbrcPriceInfo.change24hrPercent,
          state.analytics.gbrcPriceInfo.currentPrice
        ).toString()
      : "--"
  );
  const [perc7dBrc, setPerc7dBrc] = useState<string>(
    Number(state.analytics.brcPriceInfo.change7dPercent) > 0
      ? GetChangeRate(
          state.analytics.brcPriceInfo.change7dPercent,
          state.analytics.brcPriceUSD
        ).toString()
      : "--"
  );
  const [perc7dGbrc, setPerc7dGbrc] = useState<string>(
    Number(state.analytics.gbrcPriceInfo.change7dPercent) > 0
      ? GetChangeRate(
          state.analytics.gbrcPriceInfo.change7dPercent,
          state.analytics.gbrcPriceInfo.currentPrice
        ).toString()
      : "--"
  );
  const [brcPercentStaked, setBrcPercentStaked] = useState<string>(
    Number(state.analytics.brcPercentStaked) > 0
      ? state.analytics.brcPercentStaked.toString()
      : "--"
  );
  const [gbrcPercentStaked, setGbrcPercentStaked] = useState<string>(
    Number(state.analytics.gbrcPercentStaked) > 0
      ? state.analytics.gbrcPercentStaked.toString()
      : "--"
  );
  const [reserve, setReserve] = useState<string>(
    Number(state.analytics.totalReserveDAI) > 0
      ? state.analytics.totalReserveDAI.toString()
      : "--"
  );
  const [treasuryDai, setTreasuryDai] = useState<string>(
    Number(state.analytics.treasuryAssets.dai) > 0 || 
      !state.analytics.treasuryAssets.dai 
      ? state.analytics.treasuryAssets.dai.toString()
      : "--"
  );
  const [treasuryBrc, setTreasuryBrc] = useState<string>(
    Number(state.analytics.treasuryAssets.brc) > 0
      ? state.analytics.treasuryAssets.brc.toString()
      : "--"
  );
  const [treasuryGbrc, setTreasuryGbrc] = useState<string>(
    Number(state.analytics.treasuryAssets.gbrc) > 0
      ? state.analytics.treasuryAssets.gbrc.toString()
      : "--"
  );
  const [treasuryEthUsd, setTreasuryEthUsd] = useState<string>(
    Number(state.analytics.treasuryAssets.ethUsd) > 0
      ? state.analytics.treasuryAssets.ethUsd.toString()
      : "--"
  );
  const [treasuryTotal, setTreasuryTotal] = useState<string>(
    (
      (!isFiniteNumber(state.analytics.treasuryAssets.ethUsd)
        ? 0
        : Number(state.analytics.treasuryAssets.ethUsd)) +
      (!isFiniteNumber(state.analytics.treasuryAssets.dai)
        ? 0
        : Number(state.analytics.treasuryAssets.dai)) +
      (!isFiniteNumber(state.analytics.treasuryAssets.gbrcUsd)
        ? 0
        : Number(state.analytics.treasuryAssets.gbrcUsd)) +
      (!isFiniteNumber(state.analytics.treasuryAssets.brc)
        ? 0
        : Number(state.analytics.treasuryAssets.brc))
    ).toString() ?? "--"
  );

  const [brcTotalSupply, setBRCTotalSupply] = useState<string>(
    Number(state.analytics.brcTotalSupply) > 0
      ? state.analytics.brcTotalSupply.toString()
      : "--"
  );
  const [gbrcTotalSupply, setGBRCTotalSupply] = useState<string>(
    Number(state.analytics.gbrcTotalSupply) > 0
      ? state.analytics.gbrcTotalSupply.toString()
      : "--"
  );
  const [brcTotalCirculatingSupply, setBRCTotalCirculatingSupply] =
    useState<string>(
      Number(state.analytics.brcTotalCirculatingSupply) > 0
        ? state.analytics.brcTotalCirculatingSupply.toString()
        : "--"
    );
  const [gbrcTotalCirculatingSupply, setGBRCTotalCirculatingSupply] =
    useState<string>(
      Number(state.analytics.gbrcTotalCirculatingSupply) > 0
        ? state.analytics.gbrcTotalCirculatingSupply.toString()
        : "--"
    );
  const [brc7apy, setBrc7apy] = useState<string>(
    Number(state.analytics.brcOnly7DaysAPY) > 0
      ? state.analytics.brcOnly7DaysAPY.toString()
      : "--"
  );
  const [brc30apy, setBrc30apy] = useState<string>(
    Number(state.analytics.brcOnly30DaysAPY) > 0
      ? state.analytics.brcOnly30DaysAPY.toString()
      : "--"
  );
  const [brc90apy, setBrc90apy] = useState<string>(
    Number(state.analytics.brcOnly90DaysAPY) > 0
      ? state.analytics.brcOnly90DaysAPY.toString()
      : "--"
  );
  const [brcGbrc7apy, setBrcGbrc7apy] = useState<string>(
    Number(state.analytics.brcGBRC7DaysAPY) > 0
      ? state.analytics.brcGBRC7DaysAPY.toString()
      : "--"
  );
  const [brcGbrc30apy, setBrcGbrc30apy] = useState<string>(
    Number(state.analytics.brcGBRC30DaysAPY) > 0
      ? state.analytics.brcGBRC30DaysAPY.toString()
      : "--"
  );
  const [brcGbrc90apy, setBrcGbrc90apy] = useState<string>(
    Number(state.analytics.brcGBRC90DaysAPY) > 0
      ? state.analytics.brcGBRC90DaysAPY.toString()
      : "--"
  );
  const [brc7apr, setBrc7apr] = useState<string>(
    Number(state.analytics.brcOnly7DaysAPR) > 0
      ? state.analytics.brcOnly7DaysAPR.toString()
      : "--"
  );
  const [brc30apr, setBrc30apr] = useState<string>(
    Number(state.analytics.brcOnly30DaysAPR) > 0
      ? state.analytics.brcOnly30DaysAPR.toString()
      : "--"
  );
  const [brc90apr, setBrc90apr] = useState<string>(
    Number(state.analytics.brcOnly90DaysAPR) > 0
      ? state.analytics.brcOnly90DaysAPR.toString()
      : "--"
  );
  const [brcGbrc7apr, setBrcGbrc7apr] = useState<string>(
    Number(state.analytics.brcGBRC7DaysAPR) > 0
      ? state.analytics.brcGBRC7DaysAPR.toString()
      : "--"
  );
  const [brcGbrc30apr, setBrcGbrc30apr] = useState<string>(
    Number(state.analytics.brcGBRC30DaysAPR) > 0
      ? state.analytics.brcGBRC30DaysAPR.toString()
      : "--"
  );
  const [brcGbrc90apr, setBrcGbrc90apr] = useState<string>(
    Number(state.analytics.brcGBRC90DaysAPR) > 0
      ? state.analytics.brcGBRC90DaysAPR.toString()
      : "--"
  );
  const [brc7supply, setBrc7supply] = useState<string>(
    Number(state.analytics.brcOnly7DaysSUPPLY) > 0
      ? state.analytics.brcOnly7DaysSUPPLY.toString()
      : "--"
  );
  const [brc30supply, setBrc30supply] = useState<string>(
    Number(state.analytics.brcOnly30DaysSUPPLY) > 0
      ? state.analytics.brcOnly30DaysSUPPLY.toString()
      : "--"
  );
  const [brc90supply, setBrc90supply] = useState<string>(
    Number(state.analytics.brcOnly90DaysSUPPLY) > 0
      ? state.analytics.brcOnly90DaysSUPPLY.toString()
      : "--"
  );
  const [brcGbrc7supply, setBrcGbrc7supply] = useState<string>(
    Number(state.analytics.brcGBRC7DaysSUPPLY) > 0
      ? state.analytics.brcGBRC7DaysSUPPLY.toString()
      : "--"
  );
  const [brcGbrc30supply, setBrcGbrc30supply] = useState<string>(
    Number(state.analytics.brcGBRC30DaysSUPPLY) > 0
      ? state.analytics.brcGBRC30DaysSUPPLY.toString()
      : "--"
  );
  const [brcGbrc90supply, setBrcGbrc90supply] = useState<string>(
    Number(state.analytics.brcGBRC90DaysSUPPLY) > 0
      ? state.analytics.brcGBRC90DaysSUPPLY.toString()
      : "--"
  );
  const [showNetworkAlert, setShowNetworkAlert] = useState<boolean>(false);
  const [brcGbrcChartToggle, setBrcGbrcChartToggle] = useState<boolean>(true);
  const [chartData, setChartData] = useState(
    state.analytics.brcPriceInfo.chartData?.length > 0
      ? state.analytics.brcPriceInfo.chartData
      : []
  );
  const [limit, setLimit] = useState<ChartLimit>(ChartLimit.ONE_DAY);
  const [gbrcLimit, setGBRCLimit] = useState<ChartLimit>(ChartLimit.ONE_DAY);
  const [displayChartData, setDisplayChartData] = useState([]);
  const [marketCap, setMarketCap] = useState<string>(
    Number(state.analytics.brcTotalSupply) > 0 &&
      Number(state.analytics.brcPriceUSD) > 0
      ? (
          Number(state.analytics.brcTotalSupply) *
          Number(state.analytics.brcPriceUSD)
        ).toString()
      : "--"
  );

  const [collateralRatio, setCollateralRatio] = useState<string>("--");
  const [decentralizationRatio, setDecentralizationRatio] =
    useState<string>("--");
  const [exAssetRatio, setExAsset] = useState<string>("--");
  const [allStakes, setAllStakes] = useState<StakeRow[]>(
    state.stake.allStakes && state.stake.allStakes.length > 0
      ? state.stake.allStakes
      : []
  );
  const [totalRewards, setTotalRewards] = useState<number>(0);
  const [stakedPrimary, setStakedPrimary] = useState<string>("--");
  const [unstakedPrimary, setUnstakedPrimary] = useState<string>("--");
  const [stakedSecondary, setStakedSecondary] = useState<string>("--");
  const [totalPrimary, setTotalPrimary] = useState<string>("--");
  const [totalSecondary, setTotalSecondary] = useState<string>("--");
  const [unstakedSecondary, setUnstakedSecondary] = useState<string>("--");
  const [brcAvgBuyPrice, setBrcAvgBuyPrice] = useState<string>("--");
  const [oldTotalRewards, setOldTotalRewards] = useState<number>(0);
  const [brcProfitLossPerc, setBrcProfitLossPerc] = useState<string>("--");
  const [lpUniswapBrcGbrc, setLpUniswapBrcGbrc] = useState<string>("--");
  const [lpUniswapEthGbrc, setLpUniswapEthGbrc] = useState<string>("--");
  const [lpUniswapEthUsdc, setLpUniswapEthUsdc] = useState<string>(
    (0).toString()
  );
  
  const chainIdIsCorrect = chainId && SUPPORTED_CHAINS.includes(chainId);
  const totalPrimaryUsd =
    totalPrimary === "--"
      ? "--"
      : handleNumberDisplay(
          GetUSDTokenEquivalence(
            state,
            bignumber(totalPrimary),
            brc_Token.symbol
          ),
          true,
          0
        );
  const totalSecondaryUsd =
    totalSecondary === "--"
      ? "--"
      : handleNumberDisplay(
          GetUSDTokenEquivalence(
            state,
            bignumber(totalSecondary),
            gbrc_Token.symbol
          ),
          true,
          0
        );

  if (!chainIdIsCorrect && !showNetworkAlert) {
    setShowNetworkAlert(true);
  }

  useInactiveListener();

  useEffect(() => {
    if (
      !areContractsLoaded(contracts) &&
      areContractsLoaded(state.application.contracts)
    ) {
      setContracts(state.application.contracts);
    }
  }, [contracts, state.application.contracts]);

  const getStakes = async () => {
    const payload = {
      stakingContract: state.application.contracts.stakingContract,
      userAddress: account,
    };

    dispatch(getAllStakesAsync(payload)).then((response) => {
      const stakes = response.payload as any;
      setAllStakes(stakes ? stakes : []);
    });
  };

  const debounceGetStakes = useDebounce(async () => getStakes(), 500);

  useEffect(() => {
    debounceGetStakes();
    debounceGetBRCPriceChartData();
    getAverageBuyPrice();
  }, []);

  useEffect(() => {
    // Collateral Ratio (CR) = (Collateral + Treasury Assets) / Market Value
    const crRatio = Number(
      Number(
        handleNumberDisplay(
          ((Number(treasuryTotal) + Number(reserve)) * 100) / Number(marketCap),
          true,
          3
        )
      ).toFixed(2)
    );
    setCollateralRatio(isNaN(crRatio) ? "--" : crRatio.toString());
    // Decentralization Ratio (DR) = ((Collateral + Treasury DAI)*0.4 + ETH + gBRC) / (total collateral + total teasury)
    const decRatio = Number(
      Number(
        handleNumberDisplay(
          (((Number(treasuryDai) + Number(reserve)) * 0.4 + 
            Number(treasuryEthUsd)) *
          100) /
            (Number(treasuryTotal) + Number(reserve)),
          true,
          3
        )
      ).toFixed(2)
    );

    setDecentralizationRatio(isNaN(decRatio) ? "--" : decRatio.toString());

    // Exogenous Asset Ratio (EAR)
    // DAI (collateral + treasury) + ETH / all collateral + treasury
    const exAsset = Number(
      handleNumberDisplay(
        ((Number(reserve) + Number(treasuryDai) + Number(treasuryEthUsd)) /
          (Number(reserve) + Number(treasuryTotal))) *
          100,
        true,
        2
      )
    );
    setExAsset(isNaN(exAsset) ? "--" : exAsset.toString());
  }, [treasuryTotal, marketCap, reserve, treasuryEthUsd, treasuryDai]);

  useEffect(() => {
    if (
      treasuryDai.length < 1 ||
      treasuryDai === "--" ||
      ((Number(treasuryGbrc) === 0 ||
        treasuryGbrc.length < 1 ||
        treasuryGbrc === "--") &&
        gbrcUsd.length > 0 &&
        gbrcUsd !== "--")
    ) {
      debounceTreasuryAssets();
    }
  }, [state.swap.prices]);

  useEffect(() => {
    const dai = isNaN(Number(state.analytics.treasuryAssets.dai))
      ? 0
      : Number(state.analytics.treasuryAssets.dai);
    const ethUsd = isNaN(Number(state.analytics.treasuryAssets.ethUsd))
      ? 0
      : Number(state.analytics.treasuryAssets.ethUsd);
    const gbrcUsd = isNaN(Number(state.analytics.treasuryAssets.gbrcUsd))
      ? 0
      : Number(state.analytics.treasuryAssets.gbrcUsd);
    const brc = isNaN(Number(state.analytics.treasuryAssets.brc))
      ? 0
      : Number(state.analytics.treasuryAssets.brc);
    const totalAssets = dai + ethUsd; // + gbrcUsd; // + brc;

    setTreasuryTotal(totalAssets.toString());
  }, [state.analytics.treasuryAssets]);

  useEffect(() => {
    limitChartData();
    // console.log({chartData});
  }, [limit, chartData]);

  const limitChartData = () => {
    let newData = [];
    if (!chartData || chartData?.length < 1) return;
    // console.log('data', chartData);

    switch (limit) {
      case ChartLimit.ONE_DAY:
        newData = chartData.filter(
          (priceItem) =>
            Number(priceItem.name) > Date.now() / 1000 - 24 * 60 * 60
        );
        break;
      case ChartLimit.SEVEN_DAYS:
        newData = chartData.filter(
          (priceItem) =>
            Number(priceItem.name) > Date.now() / 1000 - 24 * 60 * 60 * 7
        );
        break;
      case ChartLimit.ONE_MONTH:
        newData = chartData.filter(
          (priceItem) =>
            Number(priceItem.name) > Date.now() / 1000 - 24 * 60 * 60 * 30
        );
        break;
      case ChartLimit.THREE_MONTHS:
        newData = chartData.filter(
          (priceItem) =>
            Number(priceItem.name) > Date.now() / 1000 - 24 * 60 * 60 * 90
        );
        break;
      case ChartLimit.SIX_MONTHS:
        newData = chartData.filter(
          (priceItem) =>
            Number(priceItem.name) > Date.now() / 1000 - 24 * 60 * 60 * 180
        );
        break;
      case ChartLimit.ONE_YEAR:
        newData = chartData.filter(
          (priceItem) =>
            Number(priceItem.name) > Date.now() / 1000 - 24 * 60 * 60 * 365
        );
        break;
      default:
        newData = chartData;
        break;
    }
    // console.log({newData});

    newData.sort((a, b) => Number(a.name) - Number(b.name)); // ASC

    // console.log({newData});

    setDisplayChartData(newData);
  };

  useEffect(() => {
    if (gbrcUsd.length < 1 || gbrcUsd === "--") {
      debounceGetGBRCPrice();
    }
    if (brcUsd.length < 1 || brcUsd === "--") {
      debounceGetBRCPrice();
    }
    if (reserve.length < 1 || reserve === "--") {
      debounceGetTotalReserve();
    }
    if (
      brcTotalCirculatingSupply.length < 1 ||
      brcTotalCirculatingSupply === "--"
    ) {
      debounceGetBRCTotalCirculatingSupply();
    }
    if (
      gbrcTotalCirculatingSupply.length < 1 ||
      gbrcTotalCirculatingSupply === "--"
    ) {
      debounceGetGBRCTotalCirculatingSupply();
    }
    if (
      treasuryDai.length < 1 ||
      treasuryDai === "--" ||
      ((Number(treasuryGbrc) === 0 ||
        treasuryGbrc.length < 1 ||
        treasuryGbrc === "--") &&
        gbrcUsd.length > 0 &&
        gbrcUsd !== "--")
    ) {
      // || ((Number(treasuryEth) === 0 || treasuryEth.length < 1 || treasuryEth === "--") && (state.swap.prices.ethPrice.length > 0 && state.swap.prices.ethPrice !== "--")))
      debounceTreasuryAssets();
    }
    if (brc7apr.length < 1 || brc7apr === "--") {
      debounceGetAPY_APRs();
    }
    if (brcPercentStaked.length < 1 || brcPercentStaked === "--") {
      debounceGetPercentStakes();
    }
    if (brc7supply.length < 1 || brc7supply === "--") {
      debounceGetPoolsSupply();
    }

    if (brcTotalSupply !== "--" && brcUsd !== "--") {
      const marketCap = Number(brcTotalSupply) * Number(brcUsd);

      setMarketCap(marketCap.toString());
      dispatch(setMarketCapAnalytics(marketCap));
    }
  });

  useEffect(() => {
    if (!state.wallet.isConnected) return;

    let _totalRewards = bignumber(0);
    let _stakedPrimary = bignumber(0);
    let _totalPrimary = state.wallet.balances.brcBalance.toString();
    let _stakedSecondary = bignumber(0);
    let _totalSecondary = state.wallet.balances.gbrcBalance.toString();

    setUnstakedPrimary(state.wallet.balances.brcBalance.toString());
    setUnstakedSecondary(state.wallet.balances.gbrcBalance.toString());
    setTotalPrimary(_totalPrimary.toString());
    setTotalSecondary(_totalSecondary.toString());

    if (allStakes && allStakes.length > 0) {
      for (let i = 0; i < allStakes.length; i++) {
        _totalRewards = bignumber(
          add(
            _totalRewards,
            bignumber(allStakes[i].rewards.toString())
          ).toString()
        );
        _stakedPrimary = bignumber(
          add(
            _stakedPrimary,
            bignumber(allStakes[i].primaryAmount.toString())
          ).toString()
        );
        _stakedSecondary = bignumber(
          add(
            _stakedSecondary,
            bignumber(allStakes[i].secondaryAmount.toString())
          ).toString()
        );
      }
      if (totalRewards > 0) {
        setOldTotalRewards(totalRewards);
      }
      _totalPrimary = add(
        bignumber(_totalPrimary.toString()),
        _stakedPrimary
      ).toString();
      _totalSecondary = add(
        bignumber(_totalSecondary.toString()),
        _stakedSecondary
      ).toString();

      setTotalRewards(Number(_totalRewards.toString()));
      setStakedPrimary(_stakedPrimary.toString());
      setStakedSecondary(_stakedSecondary.toString());
      setTotalPrimary(_totalPrimary.toString());
      setTotalSecondary(_totalSecondary.toString());
    }

    const _gbrcProfitLossPerc = GetProfitLossRate(
      Number(_totalSecondary),
      Number(gbrcUsd),
      Number(0.2)
    ); //avg cost

    const _brcProfitLossPerc = GetProfitLossRate(
      Number(_totalPrimary),
      Number(brcUsd),
      Number(brcAvgBuyPrice)
    );

    // const _rewardsProfitLossPerc = (Number(_totalRewards.toString())) * (rewardsCurrentMarketPrice) - (Number(gbrcAvgBuyPrice)) * (Number(_stakedSecondary.toString())) / (Number(gbrcAvgBuyPrice)) * (Number(_stakedSecondary.toString()));
    // setGbrcProfitLossPerc(Number(_gbrcProfitLossPerc) ? _gbrcProfitLossPerc.toFixed(2) : "--");
    setBrcProfitLossPerc(
      Number(_brcProfitLossPerc) ? _brcProfitLossPerc.toFixed(2) : "--"
    );
    // setRewardsProfitLossPerc(Number(_rewardsProfitLossPerc) ? _rewardsProfitLossPerc.toFixed(2) : "--");

    //TODO: update LP liquidity data fetch with subgraph after PR fix:
    // https://github.com/Uniswap/v3-subgraph/issues/111

    const ethLP = !isFiniteNumber(
      GetUSDTokenEquivalence(state, bignumber(0.78987939), eth_Token.symbol)
    )
      ? 0
      : Number(
          GetUSDTokenEquivalence(state, bignumber(0.78987939), eth_Token.symbol)
        );
    const gbrcLP = !isFiniteNumber(
      GetUSDTokenEquivalence(
        state,
        bignumber(38078.09468751),
        gbrc_Token.symbol
      )
    )
      ? 0
      : Number(
          GetUSDTokenEquivalence(
            state,
            bignumber(38078.09468751),
            gbrc_Token.symbol
          )
        );

    const brcLP2 = !isFiniteNumber(
      GetUSDTokenEquivalence(state, bignumber(0), brc_Token.symbol)
    )
      ? 0
      : Number(GetUSDTokenEquivalence(state, bignumber(0), brc_Token.symbol));
    const gbrcLP2 = !isFiniteNumber(
      GetUSDTokenEquivalence(state, bignumber(0), gbrc_Token.symbol)
    )
      ? 0
      : Number(GetUSDTokenEquivalence(state, bignumber(0), gbrc_Token.symbol));

    // setLpUniswapBrcGbrc((brcLP2 + gbrcLP2).toString());
    setLpUniswapBrcGbrc((0).toString());
    setLpUniswapEthGbrc((ethLP + gbrcLP).toString());
  }, [allStakes, state.wallet.balances, gbrcUsd, brcUsd]);

  useEffect(() => {
    getAndUpdateTotalCirculatingSupply();
  }, [gbrcTotalCirculatingSupply]);

  useEffect(() => {
    updateAPYs();
  }, [brcGbrc90apy]);

  useEffect(() => {
    updatePercentageStaked();
  }, [gbrcPercentStaked]);

  const getPoolsInfo = () => {
    const poolBrcGbrc = `
      {
        pool(id: "${UNISWAP_BRC_GBRC_POOL_ARBITRUM_ADDRESS}") {
          tick
          token0 {
            symbol
            id
            decimals
          }
          token1 {
            symbol
            id
            decimals
          }
          feeTier
          sqrtPrice
          liquidity
        }
      }
    `;

    const poolEthGbrc = `
      {
        pool(id: "${UNISWAP_WETH_GBRC_POOL_ARBITRUM_ADDRESS}") {
          tick
          token0 {
            symbol
            id
            decimals
          }
          token1 {
            symbol
            id
            decimals
          }
          feeTier
          sqrtPrice
          liquidity
        }
      }
    `;
  };

  const getAndUpdateTotalCirculatingSupply = () => {
    if (
      brcTotalCirculatingSupply &&
      brcTotalCirculatingSupply.length >= 1 &&
      brcTotalCirculatingSupply !== "--"
    ) {
      const data = {
        brcCirculatingSupply: Number(brcTotalCirculatingSupply),
        gbrcCirculatingSupply: Number(gbrcTotalCirculatingSupply),
      };
      APICall("/data/circulating-supply", HTTP_METHOD.PUT, true, data).then(
        (result) => {}
      );
    }
  };

  const updatePercentageStaked = () => {
    if (
      gbrcPercentStaked &&
      gbrcPercentStaked.length >= 1 &&
      gbrcPercentStaked !== "--"
    ) {
      const data = {
        brcPercentStaked: Number(brcPercentStaked),
        gbrcPercentStaked: Number(gbrcPercentStaked),
      };

      APICall("/data/staked-percentage", HTTP_METHOD.PUT, true, data).then(
        (result) => {}
      );
    }
  };

  const updateAPYs = () => {
    if (brc7apy && brc7apy.length >= 1 && brc7apy !== "--") {
      const apyData = {
        brc7dApy: Number(brc7apy),
        brc30dApy: Number(brc30apy),
        brc90dApy: Number(brc90apy),
        brcGbrc7dApy: Number(brcGbrc7apy),
        brcGbrc30dApy: Number(brcGbrc30apy),
        brcGbrc90dApy: Number(brcGbrc90apy),
      };

      APICall("/data/apys", HTTP_METHOD.PUT, true, apyData).then(
        (result) => {}
      );
    }
  };

  const getAverageBuyPrice = () => {
    // brc average buy price
    let brcBuyCount = 0;
    let brcTotalBuyPrice = bignumber(0);
    APICall("/tx/account/" + account, HTTP_METHOD.GET, true).then((result) => {
      if (result && result.Items.length > 0) {
        for (let i = 0; i < result.Items.length; i++) {
          if (isMainnetNetwork(chainId) && result.Items[i].testNet !== 1) {
            brcBuyCount++;
            brcTotalBuyPrice = bignumber(
              add(
                brcTotalBuyPrice,
                bignumber(result.Items[i].daiCost)
              ).toString()
            );
            // console.log('totalBuyPrice', brcTotalBuyPrice.toString());
          } else {
            if (isTestnetNetwork(chainId) && result.Items[i].testNet === 1) {
              brcBuyCount++;
              brcTotalBuyPrice = bignumber(
                add(
                  brcTotalBuyPrice,
                  bignumber(result.Items[i].daiCost)
                ).toString()
              );
              // console.log('totalBuyPrice', brcTotalBuyPrice.toString());
            }
          }
        }
        const _avgBuyPrice = divide(
          Number(brcTotalBuyPrice.toString()) > 0
            ? brcTotalBuyPrice
            : bignumber(0),
          bignumber(brcBuyCount)
        );
        setBrcAvgBuyPrice(
          isNaN(Number(_avgBuyPrice.toString()))
            ? "--"
            : _avgBuyPrice.toString()
        );
      } else {
        setBrcAvgBuyPrice("0");
      }
    });

    // gbrc average buy price
  };

  const debounceGetPercentStakes = useDebounce(async () => {
    const payload = {
      contracts: contracts,
      account: state.wallet.address,
    } as BasicContractCallPayload;

    dispatch(setPercentageStakeAsync(payload))
      .then((response) => {
        const percentStakes = response.payload as any;
        const brcPercentStaked = percentStakes.brcPercentStake;
        const gbrcPercentStaked = percentStakes.gbrcPercentStake;
        // console.log('percentStakes', percentStakes);

        brcPercentStaked && setBrcPercentStaked(brcPercentStaked.toString());
        gbrcPercentStaked && setGbrcPercentStaked(gbrcPercentStaked.toString());
      })
      .catch((err) => {
        console.log(err);
      });
  }, 300);

  const debounceGetAPY_APRs = useDebounce(async () => {
    const payload = {
      contracts: contracts,
      appState: state,
    } as BasicContractCallPayload;

    dispatch(setAPY_APRsAsync(payload))
      .then((response) => {
        const apys = response.payload as IAPYs;
        const _brc7apy = apys.brcOnly7DaysAPY;
        const _brc30apy = apys.brcOnly30DaysAPY;
        const _brc90apy = apys.brcOnly90DaysAPY;
        const _brcGbrc7apy = apys.brcGBRC7DaysAPY;
        const _brcGbrc30apy = apys.brcGBRC30DaysAPY;
        const _brcGbrc90apy = apys.brcGBRC90DaysAPY;
        const _brc7apr = apys.brcOnly7DaysAPR;
        const _brc30apr = apys.brcOnly30DaysAPR;
        const _brc90apr = apys.brcOnly90DaysAPR;
        const _brcGbrc7apr = apys.brcGBRC7DaysAPR;
        const _brcGbrc30apr = apys.brcGBRC30DaysAPR;
        const _brcGbrc90apr = apys.brcGBRC90DaysAPR;

        // console.log('apys', apys);
        // console.log('apy', brc30apy);
        _brc7apy && setBrc7apy(_brc7apy.toString());
        _brc30apy && setBrc30apy(_brc30apy.toString());
        _brc90apy && setBrc90apy(_brc90apy.toString());
        _brcGbrc7apy && setBrcGbrc7apy(_brcGbrc7apy.toString());
        _brcGbrc30apy && setBrcGbrc30apy(_brcGbrc30apy.toString());
        _brcGbrc90apy && setBrcGbrc90apy(_brcGbrc90apy.toString());
        _brc7apr && setBrc7apr(_brc7apr.toString());
        _brc30apr && setBrc30apr(_brc30apr.toString());
        _brc90apr && setBrc90apr(_brc90apr.toString());
        _brcGbrc7apr && setBrcGbrc7apr(_brcGbrc7apr.toString());
        _brcGbrc30apr && setBrcGbrc30apr(_brcGbrc30apr.toString());
        _brcGbrc90apr && setBrcGbrc90apr(_brcGbrc90apr.toString());
      })
      .catch((err) => {
        console.log(err);
      });
  }, 300);

  const debounceGetPoolsSupply = useDebounce(async () => {
    const payload = {
      contracts: contracts,
    } as UseAllContractsPayload;

    dispatch(setPoolsSupplyAsync(payload))
      .then((response) => {
        const poolsSupply = response.payload as PoolsSupply;
        const _brc7supply = poolsSupply.brcOnly7Days;
        const _brc30supply = poolsSupply.brcOnly30Days;
        const _brc90supply = poolsSupply.brcOnly90Days;
        const _brcGbrc7supply = poolsSupply.brcGBRC7Days;
        const _brcGbrc30supply = poolsSupply.brcGBRC30Days;
        const _brcGbrc90supply = poolsSupply.brcGBRC90Days;

        // console.log('apys', apys);
        // console.log('apy', brc30apy);
        _brc7supply && setBrc7supply(_brc7supply.toString());
        _brc30supply && setBrc30supply(_brc30supply.toString());
        _brc90supply && setBrc90supply(_brc90supply.toString());
        _brcGbrc7supply && setBrcGbrc7supply(_brcGbrc7supply.toString());
        _brcGbrc30supply && setBrcGbrc30supply(_brcGbrc30supply.toString());
        _brcGbrc90supply && setBrcGbrc90supply(_brcGbrc90supply.toString());
      })
      .catch((err) => {
        console.log(err);
      });
  }, 300);

  const debounceGetBRCPrice = useDebounce(async () => {
    const brcPriceUSD = await GetUSDTokenEquivalence(
      state,
      bignumber(1),
      brc_Token.symbol
    );
    if (Number(brcPriceUSD) > 0) {
      setBrcUsd(brcPriceUSD);
    }

    dispatch(setBRCPriceUSD(brcPriceUSD));

    // dispatch(setBRCPriceInfoAsync()).then((result) => {
    //   const data: any = result.payload;
    //   if (data) {
    //     const brcPriceUSD24hr = data.change24hrPercent;
    //     const brcPriceUSD7d = data.change7dPercent;

    //     const perc24hr = GetChangeRate(
    //       brcPriceUSD24hr === 0 ? brcPriceUSD : brcPriceUSD24hr,
    //       brcPriceUSD
    //     );
    //     const perc7d =
    //       brcPriceUSD7d !== 0
    //         ? GetChangeRate(
    //             brcPriceUSD7d === 0 ? brcPriceUSD : brcPriceUSD7d,
    //             brcPriceUSD
    //           )
    //         : "--";

    //     // console.log('gbrc price change', {perc24hr, perc7d});

    //     setPerc24hrBrc(!isNaN(perc24hr) ? perc24hr.toFixed(2) : "0");
    //     setPerc7dBrc(perc7d !== "--" ? Number(perc7d).toFixed(2) : "--");

    //     // console.log({brcPriceUSD, brcPriceUSD24hr, brcPriceUSD7d});
    //   }
    // });
  }, 300);

  const debounceGetGBRCPrice = useDebounce(async () => {
    // dispatch(setGBRCPriceInfoAsync(state)).then((result) => {
    //   const data: any = result.payload;
    //   const gbrcPriceUSD = data.currentPrice;
    //   const gbrcPriceUSD24hr = data.change24hrPercent;
    //   const gbrcPriceUSD7d = data.change7dPercent;
    //   const perc24hr = GetChangeRate(gbrcPriceUSD24hr, gbrcPriceUSD);
    //   const perc7d =
    //     gbrcPriceUSD7d !== 0
    //       ? GetChangeRate(gbrcPriceUSD7d, gbrcPriceUSD)
    //       : "--";
    //   // console.log('gbrc price change', {perc24hr, perc7d});
    //   setPerc24hrGbrc(!isNaN(perc24hr) ? perc24hr.toFixed(2) : "0");
    //   setPerc7dGbrc(perc7d !== "--" ? Number(perc7d).toFixed(2) : "--");
    //   if (gbrcPriceUSD > 0) {
    //     setGbrcUsd(gbrcPriceUSD.toString());
    //   }
    // });
  }, 300);

  const debounceGetBRCPriceChartData = useDebounce(async () => {
    dispatch(setBRCPriceChartInfoAsync()).then((data) => {
      // console.log('data', data);
      setChartData(data.payload);
    });
  }, 3000);

  const debounceGetBRCTotalCirculatingSupply = useDebounce(async () => {
    const payload = {
      contracts: contracts,
      account: state.wallet.address,
    } as BasicContractCallPayload;

    dispatch(setBRCTotalCirculatingSupplyAsync(payload))
      .then((response) => {
        const _res: any = response.payload;
        const _totalCirculatingSupply = _res.totalCirculatingSupply.toString();
        const _totalSupply = _res.totalSupply.toString();
        setBRCTotalSupply(_totalSupply);
        setBRCTotalCirculatingSupply(_totalCirculatingSupply);
      })
      .catch((err) => {
        console.log(err);
      });
  }, 300);

  const debounceGetGBRCTotalCirculatingSupply = useDebounce(async () => {
    const payload = {
      contracts: contracts,
      account: state.wallet.address,
    } as BasicContractCallPayload;

    dispatch(setGBRCTotalCirculatingSupplyAsync(payload))
      .then((response) => {
        const _res: any = response.payload;
        const _totalCirculatingSupply = _res.totalCirculatingSupply.toString();
        const _totalSupply = _res.totalSupply.toString();
        setGBRCTotalSupply(_totalSupply);
        setGBRCTotalCirculatingSupply(_totalCirculatingSupply);
      })
      .catch((err) => {
        console.log(err);
      });
  }, 300);

  const debounceGetTotalReserve = useDebounce(async () => {
    const payload = {
      contracts: contracts,
      account: state.wallet.address,
    } as BasicContractCallPayload;

    dispatch(setTotalDAIReserveAsync(payload))
      .then((response) => {
        const _res = response.payload as number;
        setReserve(_res.toString());
      })
      .catch((err) => {
        console.log(err);
      });
  }, 300);

  const debounceTreasuryAssets = useDebounce(async () => {
    const payload = {
      contracts: contracts,
      account: state.wallet.address,
      appState: state,
    } as BasicContractCallPayload;

    dispatch(setTreasuryAssetsAsync(payload))
      .then((response) => {
        setTreasuryDai(
          (response.payload as ITreasuryAssets).dai.toString() === "NaN" ||
            (response.payload as ITreasuryAssets).dai.toString() === "0"
            ? "--"
            : (response.payload as ITreasuryAssets).dai.toString()
        );
        setTreasuryBrc(
          (response.payload as ITreasuryAssets).brc.toString() === "NaN" ||
            (response.payload as ITreasuryAssets).brc.toString() === "0"
            ? "--"
            : (response.payload as ITreasuryAssets).brc.toString()
        );
        setTreasuryGbrc(
          (response.payload as ITreasuryAssets).gbrcUsd.toString() === "NaN" ||
            (response.payload as ITreasuryAssets).gbrcUsd.toString() === "0"
            ? "--"
            : (response.payload as ITreasuryAssets).gbrcUsd.toString()
        );
        setTreasuryEthUsd(
          (response.payload as ITreasuryAssets).ethUsd.toString() === "NaN" ||
            (response.payload as ITreasuryAssets).ethUsd.toString() === "0"
            ? "--"
            : (response.payload as ITreasuryAssets).ethUsd.toString()
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, 300);

  // handles logic for wallet connect modal.
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
    active && setShowWalletModal(false);
    if (active) {
      //   debounceGetStakes();
    }
  }, [activatingConnector, connector, active, setShowWalletModal]);

  const showSupply = teamWhiteList.includes(account) ? true : false;

  const shortAddress = account
    ? `${account.slice(0, 4)}...${account.slice(account.length - 4)}`
    : "????...????";


  return (
    <>
      <div id="wrap">
      {active && !chainIdIsCorrect && showNetworkAlert && <AlertUser closeModal={() => setShowNetworkAlert(false)} />} 

        <Header
          // disableConnectButton={disableConnectButton}
          showWalletModal={showWalletModal}
          setShowWalletModal={setShowWalletModal}
          toggleWalletModal={toggleWalletModal}
        />
        <div id="main">
          {/* {active && !chainIdIsCorrect && showNetworkAlert && <AlertUser closeModal={() => setShowNetworkAlert(false)} />} */}
          <div className="inner inner__md">
            {/* <Desktop> */}

            <Area className="analytics-table-wrap">
              {state.wallet.isConnected && (
                <AreaBox>
                  <AreaDiv>
                    <div style={{ flex: "40%" }}>
                      <AreaTit>
                        My Token Balance <span>{shortAddress}</span>
                      </AreaTit>
                    </div>
                    <PoolsSection>
                      <AreaTitTokens>
                        <img
                          src={require("../../media/tokens/gbrc.svg").default}
                          alt="icon"
                          className="image-size"
                        />
                        gBRC
                      </AreaTitTokens>
                      <AreaTitTokens>
                        <img
                          src={require("../../media/tokens/brc.svg").default}
                          alt="icon"
                          className="image-size"
                        />
                        BRC
                      </AreaTitTokens>
                    </PoolsSection>
                  </AreaDiv>
                  <AreacontentsBox>
                    <AreaDiv style={{display:"none"}}>
                      <ContentsTit>Staked</ContentsTit>
                      <PoolsSection>
                        <ContentsNum>
                          {stakedSecondary !== "--"
                            ? handleNumberDisplay(stakedSecondary, true, 0)
                            : stakedSecondary}
                        </ContentsNum>
                        <ContentsNum>
                          {stakedPrimary !== "--"
                            ? handleNumberDisplay(stakedPrimary, true, 0)
                            : stakedPrimary}
                        </ContentsNum>
                      </PoolsSection>
                    </AreaDiv >
                    <AreaDiv style={{display:"none"}}>
                      <ContentsTit>Un-staked</ContentsTit>
                      <PoolsSection>
                        <ContentsNum>
                          {unstakedSecondary !== "--"
                            ? handleNumberDisplay(unstakedSecondary, true, 0)
                            : unstakedSecondary}
                        </ContentsNum>
                        <ContentsNum>
                          {unstakedPrimary !== "--"
                            ? handleNumberDisplay(unstakedPrimary, true, 0)
                            : unstakedPrimary}
                        </ContentsNum>
                      </PoolsSection>
                    </AreaDiv>
                    <AreaDiv style={{display:"none"}} borderBottom="1px solid #2a2929">
                      <ContentsTit>Staking rewards earned</ContentsTit>
                      <PoolsSection>
                        <ContentsNum>
                          {handleNumberDisplay(totalRewards, true, 0)}
                        </ContentsNum>
                        <ContentsNum></ContentsNum>
                      </PoolsSection>
                    </AreaDiv>
                    <AreaDiv>
                      <ContentsTit>
                        Total <br />
                        USD equivalent
                      </ContentsTit>
                      <PoolsSection>
                        <ContentsNum>
                          {totalSecondary !== "--"
                            ? handleNumberDisplay(totalSecondary, true, 0)
                            : totalSecondary}
                          <section>
                            <span
                              style={{
                                color: "var(--fade-white)",
                                marginRight: "3px",
                              }}
                            >
                              $
                              {totalSecondaryUsd === "NaN"
                                ? "--"
                                : totalSecondaryUsd}
                            </span>
                          </section>
                        </ContentsNum>
                        <ContentsNum>
                          {totalPrimary !== "--"
                            ? handleNumberDisplay(totalPrimary, true, 0)
                            : totalPrimary}
                          <section>
                            <span
                              style={{
                                color: "var(--fade-white)",
                              }}
                            >
                              $
                              {totalPrimaryUsd === "NaN"
                                ? "--"
                                : totalPrimaryUsd}
                            </span>
                          </section>
                        </ContentsNum>
                      </PoolsSection>
                    </AreaDiv>
                  </AreacontentsBox>
                </AreaBox>
              )}
              <AreaBox>
                <AreaDiv style={{display: "none"}}>
                  <div style={{ flex: "40%" }}>
                    <AreaTit>Staking pools info</AreaTit>
                  </div>
                  <PoolsSection>
                    <AreaTitTokens>
                      <div
                        style={{
                          display: "flex",
                          position: "relative",
                          justifyContent: "flex-end",
                          alignItems: "flex-start",
                        }}
                      >
                        <img
                          src={require("../../media/tokens/gbrc.svg").default}
                          alt="icon"
                          className="image-size"
                        />
                        <div style={{ textAlign: "left" }}>
                          <span style={{ whiteSpace: "nowrap" }}>gBRC</span>{" "}
                          <span style={{ whiteSpace: "nowrap" }}>- BRC</span>{" "}
                        </div>
                      </div>
                    </AreaTitTokens>
                    {/* <AreaTitTokens>
                      <img
                        src={require("../../media/tokens/brc.svg").default}
                        alt="icon"
                        style={ImageSize}
                      />
                      BRC
                    </AreaTitTokens> */}
                  </PoolsSection>
                </AreaDiv>
                <AreacontentsBox >
                  {/* <AreacontentsTit>90 Days</AreacontentsTit> */}
                  {showSupply ? (
                    <AreaDiv sub style={{display: "none"}}>
                      <UnitTit>- 90d Supply</UnitTit>
                      <PoolsSection>
                        <ContentsNum>
                          {brcGbrc90supply !== "--"
                            ? handleNumberDisplay(brcGbrc90supply, true, 0)
                            : brcGbrc90supply}
                        </ContentsNum>
                        <ContentsNum>
                          {brc90supply !== "--"
                            ? handleNumberDisplay(brc90supply, true, 0)
                            : brc90supply}
                        </ContentsNum>
                      </PoolsSection>
                    </AreaDiv>
                  ) : null}
                  <AreaDiv sub borderBottom="1.2em" style={{display: "none"}}>
                    <UnitTit>- 90d APY</UnitTit>
                    <PoolsSection>
                      <ContentsNum apy>
                        {brcGbrc90apy !== "--"
                          ? handleNumberDisplay(brcGbrc90apy, true, 2)
                          : brcGbrc90apy}
                        %
                      </ContentsNum>
                      {/* <ContentsNum apy>
                        {brc90apy !== "--"
                          ? handleNumberDisplay(brc90apy, true, 2)
                          : brc90apy}
                        %
                      </ContentsNum> */}
                    </PoolsSection>
                  </AreaDiv>
                  {/* <AreaDiv sub borderBottom="1.2em">
                    <UnitTit apr>- 90d APR</UnitTit>
                    <PoolsSection>
                      <ContentsNum apr>
                        {brcGbrc90apr !== "--"
                          ? handleNumberDisplay(brcGbrc90apr, true, 2)
                          : brcGbrc90apr}
                        %
                      </ContentsNum>
                      <ContentsNum apr>
                        {brc90apr !== "--"
                          ? handleNumberDisplay(brc90apr, true, 2)
                          : brc90apr}
                        %
                      </ContentsNum>
                    </PoolsSection>
                  </AreaDiv> */}
                  {/* <AreacontentsTit>30 Days</AreacontentsTit> */}
                  {showSupply ? (
                    <AreaDiv sub style={{display: "none"}}>
                      <UnitTit>- 30d Supply</UnitTit>
                      <PoolsSection>
                        <ContentsNum>
                          {brcGbrc30supply !== "--"
                            ? handleNumberDisplay(brcGbrc30supply, true, 0)
                            : brcGbrc30supply}
                        </ContentsNum>
                        <ContentsNum>
                          {brc30supply !== "--"
                            ? handleNumberDisplay(brc30supply, true, 0)
                            : brc30supply}
                        </ContentsNum>
                      </PoolsSection>
                    </AreaDiv>
                  ) : null}
                  <AreaDiv sub borderBottom="1.2em" style={{display: "none"}}>
                    <UnitTit>- 30d APY</UnitTit>
                    <PoolsSection>
                      <ContentsNum apy>
                        {brcGbrc30apy !== "--"
                          ? handleNumberDisplay(brcGbrc30apy, true, 2)
                          : brcGbrc30apy}
                        %
                      </ContentsNum>
                      {/* <ContentsNum apy>
                        {brc30apy !== "--"
                          ? handleNumberDisplay(brc30apy, true, 2)
                          : brc30apy}
                        %
                      </ContentsNum> */}
                    </PoolsSection>
                  </AreaDiv>
                  {/* <AreaDiv sub borderBottom="1.2em">
                    <UnitTit apr>- 30d APR</UnitTit>
                    <PoolsSection>
                      <ContentsNum apr>
                        {brcGbrc30apr !== "--"
                          ? handleNumberDisplay(brcGbrc30apr, true, 2)
                          : brcGbrc30apr}
                        %
                      </ContentsNum>
                      <ContentsNum apr>
                        {brc30apr !== "--"
                          ? handleNumberDisplay(brc30apr, true, 2)
                          : brc30apr}
                        %
                      </ContentsNum>
                    </PoolsSection>
                  </AreaDiv> */}
                  {/* <AreacontentsTit>7 Days</AreacontentsTit> */}
                  {showSupply ? (
                    <AreaDiv sub style={{display: "none"}}>
                      <UnitTit>- 7d Supply</UnitTit>
                      <PoolsSection>
                        <ContentsNum>
                          {brcGbrc7supply !== "--"
                            ? handleNumberDisplay(brcGbrc7supply, true, 0)
                            : brcGbrc7supply}
                        </ContentsNum>
                        <ContentsNum>
                          {brc7supply !== "--"
                            ? handleNumberDisplay(brc7supply, true, 0)
                            : brc7supply}
                        </ContentsNum>
                      </PoolsSection>
                    </AreaDiv>
                  ) : null}
                  <AreaDiv sub borderBottom="0.5em" style={{display: "none"}}>
                    <UnitTit>- 7d APY</UnitTit>
                    <PoolsSection>
                      <ContentsNum apy>
                        {brcGbrc7apy !== "--"
                          ? handleNumberDisplay(brcGbrc7apy, true, 2)
                          : brcGbrc7apy}
                        %
                      </ContentsNum>
                      {/* <ContentsNum apy>
                        {brc7apy !== "--"
                          ? handleNumberDisplay(brc7apy, true, 2)
                          : brc7apy}
                        %
                      </ContentsNum> */}
                    </PoolsSection>
                  </AreaDiv>
                  {/* <AreaDiv sub borderBottom="1.2em">
                    <UnitTit apr>- 7d APR</UnitTit>
                    <PoolsSection>
                      <ContentsNum apr>
                        {brcGbrc7apr !== "--"
                          ? handleNumberDisplay(brcGbrc7apr, true, 2)
                          : brcGbrc7apr}
                        %
                      </ContentsNum>
                      <ContentsNum apr>
                        {brc7apr !== "--"
                          ? handleNumberDisplay(brc7apr, true, 2)
                          : brc7apr}
                        %
                      </ContentsNum>
                    </PoolsSection>
                  </AreaDiv> */}
                  <Desktop>
                    <div style={{ marginTop: "15px" }}></div>
                  </Desktop>
                  <Mobile>
                    <div style={{ marginTop: "1rem" }}></div>
                  </Mobile>
                  <AreaDiv>
                    <ContentsTit className="analytics-left-text">Circulating supply</ContentsTit>
                    <PoolsSection>
                      <ContentsNum className="analytics-right-text">
                        {gbrcTotalCirculatingSupply !== "--"
                          ? handleNumberDisplay(
                              gbrcTotalCirculatingSupply,
                              true,
                              0
                            )
                          : gbrcTotalCirculatingSupply}
                      </ContentsNum>
                      <ContentsNum className="analytics-right-text">
                        {brcTotalCirculatingSupply !== "--"
                          ? handleNumberDisplay(
                              brcTotalCirculatingSupply,
                              true,
                              0
                            )
                          : brcTotalCirculatingSupply}
                      </ContentsNum>
                    </PoolsSection>
                  </AreaDiv>
                  <AreaDiv borderBottom="1px solid #2a2929" style={{display: "none"}}>
                    <ContentsTit className="analytics-left-text">% Staked <small>(BRC)</small></ContentsTit>
                    <PoolsSection>
                      <ContentsNum>
                        {/* {gbrcPercentStaked !== "--"
                          ? handleNumberDisplay(gbrcPercentStaked, true, 0)
                          : gbrcPercentStaked}
                        % */}
                      </ContentsNum>
                      <ContentsNum>
                        {brcPercentStaked !== "--"
                          ? handleNumberDisplay(brcPercentStaked, true, 0)
                          : brcPercentStaked}
                        %
                      </ContentsNum>
                    </PoolsSection>
                  </AreaDiv>
                  <AreaDiv style={{display: "none"}}>
                    <ContentsTit className="analytics-left-text">Circulating supply <small>(gBRC)</small></ContentsTit>
                    <PoolsSection>
                      <ContentsNum>
                        {gbrcTotalCirculatingSupply !== "--"
                          ? handleNumberDisplay(
                              gbrcTotalCirculatingSupply,
                              true,
                              0
                            )
                          : gbrcTotalCirculatingSupply}
                      </ContentsNum>
                      {/* <ContentsNum>
                        {brcTotalCirculatingSupply !== "--"
                          ? handleNumberDisplay(
                              brcTotalCirculatingSupply,
                              true,
                              0
                            )
                          : brcTotalCirculatingSupply}
                      </ContentsNum> */}
                    </PoolsSection>
                  </AreaDiv>
                  <AreaDiv style={{display: "none"}}>
                    <ContentsTit className="analytics-left-text">% Staked <small>(gBRC)</small></ContentsTit>
                    <PoolsSection>
                      <ContentsNum>
                        {gbrcPercentStaked !== "--"
                          ? handleNumberDisplay(gbrcPercentStaked, true, 0)
                          : gbrcPercentStaked}
                        %
                      </ContentsNum>
                      {/* <ContentsNum>
                        {brcPercentStaked !== "--"
                          ? handleNumberDisplay(brcPercentStaked, true, 0)
                          : brcPercentStaked}
                        %
                      </ContentsNum> */}
                    </PoolsSection>
                  </AreaDiv>
                </AreacontentsBox>
              </AreaBox>

              <AreaBox style={{display: "none"}}>
                <AreaTit>Collateral Assets</AreaTit>
                <AreacontentsBox>
                  <AreaDiv borderBottom="1px solid #2a2929">
                    <ContentsTit className="analytics-left-text">- DAI</ContentsTit>
                    <ContentsNum>
                      <em>$</em>
                      {reserve !== "--"
                        ? handleNumberDisplay(reserve, true, 0)
                        : reserve}
                    </ContentsNum>
                  </AreaDiv>
                </AreacontentsBox>
                <AreaTit>Treasury Assets</AreaTit>
                <AreacontentsBox>
                  <AreaDiv>
                    <ContentsTit className="analytics-left-text">- DAI</ContentsTit>
                    <ContentsNum>
                      <em>$</em>
                      {treasuryDai !== "--"
                        ? handleNumberDisplay(treasuryDai, true, 0)
                        : treasuryDai}
                    </ContentsNum>
                  </AreaDiv>
                  {/* <AreaDiv>
                    <div
                      className={""}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <ContentsTit>- BRC</ContentsTit>
                      <InfoCircle>
                        <div data-tip data-for={"brc"}>
                          <img
                            src={
                              require("../../media/icons/ico-help-circle.svg")
                                .default
                            }
                            width="20px"
                            alt=""
                          />
                        </div>
                      </InfoCircle>

                      <ToolTippedObject
                        name={"brc"}
                        message={"$BRC is not included in total assets."}
                      />
                    </div>
                    <ContentsNum>
                      <em>$</em>
                      {treasuryBrc !== "--"
                        ? handleNumberDisplay(treasuryBrc, true, 0)
                        : treasuryBrc}
                    </ContentsNum>
                  </AreaDiv> */}
                  {/* <AreaDiv>
                    <ContentsTit className="analytics-left-text">- ETH</ContentsTit>
                    <ContentsNum>
                      <em>$</em>
                      {treasuryEthUsd !== "--"
                        ? handleNumberDisplay(treasuryEthUsd, true, 0)
                        : "--"}
                    </ContentsNum>
                  </AreaDiv> */}
                  {/* <AreaDiv>
                    <ContentsTit>- gBRC</ContentsTit>
                    <ContentsNum>
                      <em>$</em>
                      {treasuryGbrc !== "--"
                        ? handleNumberDisplay(treasuryGbrc, true, 0)
                        : treasuryGbrc}
                    </ContentsNum>
                  </AreaDiv> */}
                  <AreaDiv>
                    <div
                      className="analytics-left-text"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <ContentsTit style={{display: "flex", alignItems: "center"}}>
                        - ETH&nbsp;<small>(gBRC-ETH pool)</small>
                          <div data-tip data-for={"arb-grbc-eth"} className="treasury-img">
                            <img
                              src={
                                require("../../media/icons/ico-arb.svg").default
                              }
                              className="image-size__after"
                              alt=""
                              style={{
                                // filter: "gray", /* IE6-9 */
                                WebkitFilter:
                                  "grayscale(1)" /* Google Chrome, Safari 6+ & Opera 15+ */,
                                filter:
                                  "grayscale(1)" /* Microsoft Edge and Firefox 35+ */,
                              }}
                            />
                          </div>
                      <ToolTippedObject
                        name={"arb-grbc-eth"}
                        message={"Pool is deployed on Arbitrum One network"}
                      />
                      </ContentsTit>
                      
                    </div>
                    <ContentsNum>
                      <em>$</em>
                      {/* {lpUniswapEthGbrc !== "--" && lpUniswapEthGbrc !== "--"
                        ? handleNumberDisplay(lpUniswapEthGbrc, true, 0)
                        : lpUniswapEthGbrc} */}
                         {treasuryEthUsd !== "--"
                        ? handleNumberDisplay(treasuryEthUsd, true, 0)
                        : "--"}
                    </ContentsNum>
                  </AreaDiv>
                  {/* <AreaDiv>
                    <ContentsTit>- Uniswap LP (gBRC/BRC)</ContentsTit>
                    <ContentsNum>
                      <em>$</em>
                      {lpUniswapBrcGbrc !== "--" && lpUniswapBrcGbrc !== "--"
                        ? handleNumberDisplay(lpUniswapBrcGbrc, true, 0)
                        : lpUniswapBrcGbrc}
                    </ContentsNum>
                  </AreaDiv> */}
                  <AreaDiv style={{display: "none"}}>
                    <div
                      className="analytics-left-text"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <ContentsTit style={{display: "flex", alignItems: "center"}}>
                        - Uniswap LP <small>(ETH/USDC)</small>
                        <div data-tip data-for={"eth-usdc-eth"} className="treasury-img__eth">
                          <img
                            src={require("../../media/tokens/ETH.svg").default}
                            alt=""
                            className="image-size__after"
                            style={{
                              // filter: "gray", /* IE6-9 */
                              WebkitFilter:
                                "grayscale(1)" /* Google Chrome, Safari 6+ & Opera 15+ */,
                              filter:
                                "grayscale(1)" /* Microsoft Edge and Firefox 35+ */,
                            }}
                          />
                        </div>

                        <ToolTippedObject
                          name={"eth-usdc-eth"}
                          message={"Pool is deployed on Ethereum Mainnet network"}
                        />
                      </ContentsTit>
                       
                    </div>
                    <ContentsNum>
                      <em>$</em>
                      {lpUniswapEthUsdc !== "--" && lpUniswapEthUsdc !== "--"
                        ? handleNumberDisplay(lpUniswapEthUsdc, true, 0)
                        : lpUniswapEthUsdc}
                    </ContentsNum>
                  </AreaDiv>
                  <AreaDiv borderBottom="1px solid #2a2929">
                    <ContentsTit className="analytics-left-text">Total Treasury Assets</ContentsTit>
                    <ContentsNum>
                      <em>$</em>
                      {treasuryTotal !== "--" && reserve !== "--"
                        ? handleNumberDisplay(
                            (!isFiniteNumber(treasuryTotal)
                              ? 0
                              : Number(treasuryTotal)) +
                              // Number(reserve) +
                              (!isFiniteNumber(lpUniswapBrcGbrc)
                                ? 0
                                : Number(lpUniswapBrcGbrc)) +
                              (!isFiniteNumber(lpUniswapEthUsdc)
                                ? 0
                                : Number(lpUniswapEthUsdc)),
                            true,
                            0
                          )
                        : treasuryTotal}
                    </ContentsNum>
                  </AreaDiv>
                </AreacontentsBox>
                <AreaTit>Market capitalization</AreaTit>
                <AreacontentsBox>
                  <AreaDiv borderBottom="1px solid #2a2929">
                    <div
                      className={""}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <ContentsTit className="analytics-left-text" style={{display:"flex", alignItems:"center"}}>
                        - BRC
                      </ContentsTit>
                    </div>

                    <ContentsNum>
                      <em>$</em>
                      {marketCap !== "--"
                        ? handleNumberDisplay(marketCap, true, 0)
                        : marketCap}
                    </ContentsNum>
                  </AreaDiv>
                </AreacontentsBox>
                <AreaTit>Analysis</AreaTit>
                <AreacontentsBox>
                  <AreaDiv>
                    <div
                      className={""}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <ContentsTit className="analytics-left-text" style={{display:"flex", alignItems:"center"}}>
                        Collateral Ratio <small>(CR)</small>
                        <div data-tip data-for={"cr"}>
                          <img
                            src={
                              require("../../media/icons/ico-help-circle.svg")
                                .default
                            }
                            alt=""
                            className="ratio-info-icon"
                          />
                        </div>
                          <ToolTippedObject name={"cr"} message={crContent} />
                      </ContentsTit>
                    </div>

                    <ContentsNum className="ratio-right-text">
                      <em>{collateralRatio}%</em>
                    </ContentsNum>
                  </AreaDiv>
                  <AreaDiv>
                    <div
                      className={""}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <ContentsTit className="analytics-left-text" style={{display:"flex", alignItems:"center"}}>
                        Decentralization Ratio <small>(DR)</small>
                        <div data-tip data-for={"dr"}>
                          <img
                            src={
                              require("../../media/icons/ico-help-circle.svg")
                                .default
                            }
                            alt=""
                            className="ratio-info-icon"
                          />
                        </div>  
                      </ContentsTit>
                       
                      <ToolTippedObject name={"dr"} message={drContent} />
                      {/* <ToolTippedObject name={"dr"} message={"This measures the proportion of an asset's value that comes from decentralized sources"}/> */}
                    </div>
                    <ContentsNum className="ratio-right-text">
                      <em>{decentralizationRatio}%</em>
                    </ContentsNum>
                  </AreaDiv>
                  <AreaDiv>
                    <div
                      className={""}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        position: "relative",
                        cursor: "pointer",
                      }}
                    >
                      <ContentsTit className="analytics-left-text" style={{display:"flex", alignItems:"center"}}>
                        Exogenous Asset Ratio <small>(EAR)</small>
                        <div data-tip data-for={"ear"}>
                          <img
                            src={
                              require("../../media/icons/ico-help-circle.svg")
                                .default
                            }
                            alt=""
                            className="ratio-info-icon"
                          />
                        </div>
                      <ToolTippedObject name={"ear"} message={earContent} />
                      </ContentsTit>
                     
                    </div>
                    <ContentsNum className="ratio-right-text">
                      <em>{exAssetRatio}%</em>
                    </ContentsNum>
                  </AreaDiv>
                </AreacontentsBox>
              </AreaBox>
            </Area>

            <div style={{ marginTop: "30px", display: "none" }}>
              <article
                className={`analytics__cont__box analytics__price__box ${
                  brcGbrcChartToggle ? "" : "dark"
                }`}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <strong
                      className={`tit ${brcGbrcChartToggle ? "on" : null}`}
                      onClick={() => setBrcGbrcChartToggle(true)}
                    >
                      BRC
                    </strong>
                    {/* <strong style={{marginLeft: "30px"}} className={`tit ${!brcGbrcChartToggle ? 'on':null}`} onClick={() => setBrcGbrcChartToggle(false)}>gBRC</strong> */}
                  </div>
                  {brcGbrcChartToggle ? (
                    <p className="number__txt">
                      $
                      {brcUsd !== "--"
                        ? handleNumberDisplay(brcUsd, true, 4)
                        : brcUsd}{" "}
                    </p>
                  ) : (
                    <p className="number__txt">
                      $
                      {gbrcUsd !== "--"
                        ? handleNumberDisplay(gbrcUsd, true, 4)
                        : gbrcUsd}{" "}
                    </p>
                  )}
                </div>

                {brcGbrcChartToggle ? (
                  <>
                    <section className="graph__area">
                      <article className={`graph__box`}>
                        {displayChartData.length < 1 ? (
                          <h1
                            style={{
                              position: "absolute",
                              bottom: "280px",
                              left: "calc(50% - 130px)",
                              opacity: ".3",
                              fontSize: "38px",
                              textAlign: "center",
                            }}
                          >
                            Loading...
                          </h1>
                        ) : null}
                        <AnalyticsChart
                          data={displayChartData}
                          labelX={"Date (Block Number)"}
                        />
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "15px",
                          }}
                        >
                          <button
                            className={`dataTabButton ${
                              limit === ChartLimit.ONE_DAY ? "active" : null
                            }`}
                            onClick={() => setLimit(ChartLimit.ONE_DAY)}
                          >
                            1D
                          </button>
                          <button
                            className={`dataTabButton ${
                              limit === ChartLimit.SEVEN_DAYS ? "active" : null
                            }`}
                            onClick={() => setLimit(ChartLimit.SEVEN_DAYS)}
                          >
                            7D
                          </button>
                          <button
                            className={`dataTabButton ${
                              limit === ChartLimit.ONE_MONTH ? "active" : null
                            }`}
                            onClick={() => setLimit(ChartLimit.ONE_MONTH)}
                          >
                            1M
                          </button>
                          <button
                            className={`dataTabButton ${
                              limit === ChartLimit.THREE_MONTHS
                                ? "active"
                                : null
                            }`}
                            onClick={() => setLimit(ChartLimit.THREE_MONTHS)}
                          >
                            3M
                          </button>
                          <button
                            className={`dataTabButton ${
                              limit === ChartLimit.SIX_MONTHS ? "active" : null
                            }`}
                            onClick={() => setLimit(ChartLimit.SIX_MONTHS)}
                          >
                            6M
                          </button>
                          <button
                            className={`dataTabButton ${
                              limit === ChartLimit.ONE_YEAR ? "active" : null
                            }`}
                            onClick={() => setLimit(ChartLimit.ONE_YEAR)}
                          >
                            1Y
                          </button>
                          <button
                            className={`dataTabButton ${
                              limit === ChartLimit.ALL ? "active" : null
                            }`}
                            onClick={() => setLimit(ChartLimit.ALL)}
                          >
                            All
                          </button>
                        </div>
                      </article>
                    </section>
                  </>
                ) : (
                  <>
                    {/* <div style={{ display: "flex"}}>
                            <div style={{ display: "flex", flexDirection: "row", width: '100%'}}>
                              <div>
                                <strong className={`tit ${!brcGbrcChartToggle ? 'active':null}`}>gBRC Price</strong>
                                <p className="number__txt">${gbrcUsd !== "--" ? handleNumberDisplay(gbrcUsd, true, 4):gbrcUsd} </p>
                              </div>
                              <div>
                                <strong className={`tit ${brcGbrcChartToggle ? 'active':null}`} onClick={() => setBrcGbrcChartToggle(true)}>BRC Price</strong>
                              </div>
                            </div> */}
                    {/* <div className="chg__cont__box" style={{ paddingRight: "25px" }}> */}
                    {/* <p className="chg__txt">
                                  <span className="dateText">24hr</span> 
                                  <span style={{paddingLeft: "25px"}} className={`dateText ${Number(perc24hrBrc) > 0 ? "upText" : Number(perc24hrBrc) === 0  || perc24hrBrc === "--" ? "" : "downText" }`}> 
                                    {Number(perc24hrBrc) > 0 ? '+' : null}{perc24hrBrc !== "--" ? handleNumberDisplay(perc24hrBrc, true, 2):perc24hrBrc}%
                                  </span>
                                  <span className={Number(perc24hrBrc) > 0 ? "upIcon" : Number(perc24hrBrc) === 0  || perc24hrBrc === "--" ? "" : "downIcon" }>
                                    {Number(perc24hrBrc) > 0 ? <>&#9650;</> : Number(perc24hrBrc) === 0 || perc24hrBrc === "--" ? null: <>&#9660;</>}
                                  </span>
                              </p> */}
                    {/* <p className="chg__txt">
                                  <span className="dateText">7day</span> 
                                  <span style={{paddingLeft: "25px"}} className={`dateText ${Number(perc7dBrc) > 0 ? "upText" : Number(perc7dBrc) === 0 || perc7dBrc === "--" ? "":"downText" }`}> 
                                    {Number(perc7dBrc) > 0 ? '+' : null}{perc7dBrc !== "--" ? handleNumberDisplay(perc7dBrc, true, 2):perc7dBrc}%
                                  </span>
                                  <span className={Number(perc7dBrc) > 0 ? "upIcon" : Number(perc7dBrc) === 0 || perc7dBrc === "--" ? "": "downIcon" }>
                                    {Number(perc7dBrc) > 0 ? <>&#9650;</> : Number(perc7dBrc) === 0 || perc7dBrc === "--" ? null: <>&#9660;</>}
                                  </span>
                              </p> */}
                    {/* </div> */}
                    {/* </div> */}

                    {/* <section className="graph__area">
                            <article className="graph__box normal">
                                <h1 style={{position: "absolute", top: "270px", left: "calc(50% - 135px)", opacity: ".3", fontSize: "38px", textAlign: "center"}}>Loading...</h1>
                                <div id="dexscreener-embed"><iframe src="https://dexscreener.com/arbitrum/0xfeF634b2ec633881fE134d5f1b3bA68Fa4Da0704?embed=1&theme=dark&trades=0&info=0"></iframe></div> */}
                    {/* AVOID <AnalyticsChart data={[]} labelX={"Date (Block Number)"} />
                                <div style={{display: 'flex', gap: '10px', marginTop: '15px'}}>
                                    <button className={`dataTabButton ${gbrcLimit === ChartLimit.ONE_DAY ? 'active':null}`} onClick={()=>setGBRCLimit(ChartLimit.ONE_DAY)}>1D</button>
                                    <button className={`dataTabButton ${gbrcLimit === ChartLimit.SEVEN_DAYS ? 'active':null}`} onClick={()=>setGBRCLimit(ChartLimit.SEVEN_DAYS)}>7D</button>
                                    <button className={`dataTabButton ${gbrcLimit === ChartLimit.ONE_MONTH ? 'active':null}`} onClick={()=>setGBRCLimit(ChartLimit.ONE_MONTH)}>1M</button>
                                    <button className={`dataTabButton ${gbrcLimit === ChartLimit.THREE_MONTHS ? 'active':null}`} onClick={()=>setGBRCLimit(ChartLimit.THREE_MONTHS)}>3M</button>
                                    <button className={`dataTabButton ${gbrcLimit === ChartLimit.SIX_MONTHS ? 'active':null}`} onClick={()=>setGBRCLimit(ChartLimit.SIX_MONTHS)}>6M</button>
                                    <button className={`dataTabButton ${gbrcLimit === ChartLimit.ONE_YEAR ? 'active':null}`} onClick={()=>setGBRCLimit(ChartLimit.ONE_YEAR)}>1Y</button>
                                    <button className={`dataTabButton ${gbrcLimit === ChartLimit.ALL ? 'active':null}`} onClick={()=>setGBRCLimit(ChartLimit.ALL)}>All</button>
                              </div> */}

                    {/* </article>
                          </section> */}
                  </>
                )}
              </article>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Analytics;
