import { bignumber, add, subtract } from "mathjs";

export function getDateFromUNIXTime(unix_timestamp: number | string) {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  return new Date(Number.parseInt(unix_timestamp.toString()) * 1000);
}

export function addTimestampDays(
  base_unix_timestamp: number | string,
  days: number
) {
  const daysInSeconds = days * 24 * 60 * 60;
  return Number.parseInt(base_unix_timestamp.toString()) + daysInSeconds;
}

export function objectNotEmpty(object: Object) {
  if (Object.keys(object).length > 0) {
    return true;
  } else {
    return false;
  }
}

export function formatNumberCommas(
  number: string,
  useLeftCommas: boolean,
  useRightCommas: boolean,
  useSpaceSeparator: boolean = false
) {
  var numberArr = number.toString().split(".");
  const separator = useSpaceSeparator ? " " : ",";

  if (useLeftCommas && useRightCommas) {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  } else if (useLeftCommas) {
    //only left side
    numberArr[0] = numberArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return numberArr.join(".");
  } else if (useRightCommas) {
    //only right side
    numberArr[1] = numberArr[1].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return numberArr.join(".");
  } else {
    return number;
  }
}

export function handleInfinity(number: string) {
  if (number && isFinite(Number.parseFloat(number))) {
    return number;
  } else {
    return "--";
  }
}

export function noExponents(number) {
  try {
    var data = String(number).split(/[eE]/);
    if (data.length === 1) return data[0];

    var z = "",
      sign = Number(number.toString()) < 0 ? "-" : "",
      str = data[0].replace(".", ""),
      //mag = Number(data[1])+ 1;
      mag = add(bignumber(data[1]), bignumber(1));

    if (Number(mag.toString()) < 0) {
      z = sign + "0.";
      //while(mag++) z += '0';
      while (add(mag, bignumber(1))) z += "0";
      // eslint-disable-next-line no-useless-escape
      return z + str.replace(/^\-/, "");
    }
    // mag -= str.length;
    mag = subtract(mag, bignumber(str.length));
    // while(mag--) z += '0';
    while (subtract(mag, bignumber(1))) z += "0";
    // console.log('no exponent ', str + z);

    return str + z;
  } catch (e) {
    console.log(e);
  }
}

export function handleNumberDisplay(
  number: number | string,
  useLeftCommas = false,
  decimals = 2,
  useRightCommas = false
) {
  let _number: number = 0;
  let _outNumber: string = "";

  if (typeof number === "string") {
    _number = Number.parseFloat(number);

    if (number.toLowerCase() === "infinity") {
      return "N/A"; //handle infinty numbers
    }
  } else {
    if (!isFinite(number)) return "N/A"; //handle infinty numbers
    _number = number;
  }

  if (_number.toString().includes(".")) {
    _outNumber = _number
      .toString()
      .slice(0, _number.toString().indexOf(".") + decimals);
  } else {
    _outNumber = _number.toFixed(decimals);
  }

  if (_number < 0.000001 && decimals < 6) {
    _outNumber = "0";
  }

  // if >= 1,000,000,000 or < decimal use scientific
  // if(_number > 0 && (_number >= 1000000000000 || _number < (1/(10**decimals)))){
  if (_number > 0 && _number >= 1000000000000) {
    _outNumber = _number.toExponential(decimals);
  }

  if (useLeftCommas || useRightCommas) {
    return formatNumberCommas(_outNumber, useLeftCommas, useRightCommas);
  }

  return _outNumber;
}

// export function walletPromptCancel(error): boolean{
//     if(error.code === 4001){//metamask
//         return true;
//     }else{
//         return false;
//     }
// }

// type MetaMaskError = {
//     code: number,
//     message: string,
//     stack: any
// }

export const checkIfNumber = (value: any) => {
  return /^[\d]*\.?[\d]{0,9}$/.test(value);
};

export const eraseZeroIfFirstNonDecimalDigit = (value: any) => {
  return /^\d+$/.test(value[1]) ? value.replace(/^0+/, "") : value;
};

export const isFiniteNumber = function(num) {
  return isFinite(Number(num)) && !isNaN(Number(num));
};

// For simple output
export const print2ndDecimal = function(num: Number){
    return Math.floor(Number(num) * 100) / 100;
}