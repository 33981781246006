import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./SwapBox";

const useStyles = makeStyles(() =>
  createStyles({
    SwapBox: {
      backgroundColor: "transparent",
      transition: (style: Theme & Props) => style.transition,
      color: (style: Theme & Props) => style.text,
      width: "100%",
      borderRadius: "2rem",
      zIndex: 3,
      display: "flex",
      flexDirection: "column",
    },
    buySellContainer: {
      margin: "0 auto 1rem auto",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    switchIcon: {            
      height: "5rem",
      cursor: "pointer",
      display: "flex",      
      justifyContent: "center",
      // transform: "rotate(0deg)",
      "&:hover": {
        filter: "brightness(1.5)",
      },
    },
    conversionContainer: {
      height: "auto",      
      // margin: "0 auto 1rem auto",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    buttonContainer: {
      margin: "0 auto auto auto",
      width: "100%",
      height: "8rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      font: (style: Theme & Props) => style.typography.h2,
    },
    message: {
      font: "inherit",
    },
    index: {
      position: "relative",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    boxTitle: {      
      font: (style: Theme & Props) => style.typography.h1b_px_light,
      color: (style: Theme & Props) => style.whiteText,
      lineHeight:"28px",
      letterSpacing: "0.8px"
    },
    boxTitleMobile: {      
      fontSize: 12,
      fontFamily: "var(--font2) !important",
      color: "#abacb3",
      marginBottom: "8px",
      marginLeft: "8px",
      // lineHeight:"28px",
      // letterSpacing: "0.8px"
    },
    title:{
      display: "block",
      marginBottom: "8px",
      font: (style: Theme & Props) => style.typography.h3b,
      color: (style: Theme & Props) => style.whiteText,  
      letterSpacing: "-0.08px"
    }
  })
);

export default useStyles;
