import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import './footer.scss'
    
function Nav(){
    const navLink = "foot__nav__link"
    const [open, setOpen] = useState(false);
    const activeClassName = "selected";
    return <>
        <ul className={open ? 'mob__foot__nav active' : 'mob__foot__nav'}>
            <li className="foot__nav__item"><NavLink to="/contact" className={({isActive}) => isActive ? navLink + " " + activeClassName : navLink} onClick={() => setOpen(false)}>Contact</NavLink></li>
            <li className="foot__nav__item"><NavLink to="/helpCenter" className={({isActive}) => isActive ? navLink + " " + activeClassName : navLink}onClick={() => setOpen(false)}>Help center</NavLink></li>
            <li className="foot__nav__item"><NavLink to="/service" className={({isActive}) => isActive ? navLink + " " + activeClassName : navLink} onClick={() => setOpen(false)}>Terms of Service</NavLink></li>
            <li className="foot__nav__item"><NavLink to="/privacy" className={({isActive}) => isActive ? navLink + " " + activeClassName : navLink} onClick={() => setOpen(false)}>Privacy Policy</NavLink></li>
        </ul>
    </>
}

export default Nav