import {
  Token,
  WETH,
  Percent,
  Route,
  TokenAmount,
  Fetcher,
  Trade,
  TradeType,
  Pair,
} from "@uniswap/sdk";
import { ethers } from "ethers";
import {
  dai_Token,
  usdc_Token,
  usdt_Token,
  wbtc_Token,
  eth_Token,
  Tokens,
  ust_Token,
} from "../../constants";
import { noExponents } from "../../utils/utils";
import { checksumAddress } from "./contractBooter";

export const tokenDecimals = {
  [usdc_Token.symbol]: usdc_Token.decimals,
  [wbtc_Token.symbol]: wbtc_Token.decimals,
  [usdt_Token.symbol]: usdt_Token.decimals,
  [ust_Token.symbol]: ust_Token.decimals,
  [dai_Token.symbol]: dai_Token.decimals,
  [eth_Token.symbol]: eth_Token.decimals,
};

export const tokenAddresses = {
  [usdc_Token.symbol]: checksumAddress(usdc_Token.address),
  [wbtc_Token.symbol]: checksumAddress(wbtc_Token.address),
  [usdt_Token.symbol]: checksumAddress(usdt_Token.address),
  [ust_Token.symbol]: checksumAddress(ust_Token.address),
  [dai_Token.symbol]: checksumAddress(dai_Token.address),
  [eth_Token.symbol]: checksumAddress(eth_Token.address),
};

export type TradePath = {
  trade: Trade;
  path: any[];
};

export type TokenPair = {
  pair: Pair;
  token: Token;
  weth?: Token;
};

export const createTradeAndPathTokenWETH = async (
  daiAddress,
  daiDecimal,
  amount = "0.0001",
  reverse?
) => {
  try {
    const { pair, token }: TokenPair = await getTokenWETHPair(dai_Token.symbol);
    const route = new Route([pair], WETH[token.chainId]);

    const trade = reverse
      ? // ? if we need the dai output, pass in output DAI amount, tradeType = exact_output, but keep route the same
        new Trade(
          route,
          new TokenAmount(
            token,
            ethers.utils.parseUnits(noExponents(amount), daiDecimal).toString()
          ),
          TradeType.EXACT_OUTPUT
        )
      : // ? if we need the dai output, pass in input ETH amount, tradeType = exact_input, but keep route the same
        new Trade(
          route,
          new TokenAmount(
            WETH[token.chainId],
            ethers.utils.parseEther(noExponents(amount)).toString()
          ),
          TradeType.EXACT_INPUT
        );

    logTrade(trade);
    const path = [WETH[token.chainId].address, token.address];

    return { trade, path } as TradePath;
  } catch (error) {
    console.log(error);
  }
};

export const createTradeAndPathTokenDai = async (
  daiAddress,
  daiDecimal,
  tokenSymbol: string,
  amount,
  reverse?
) => {
  try {
    const tokenDecimal = tokenDecimals[tokenSymbol];
    const tokenEthPair = await getTokenWETHPair(tokenSymbol);
    const ethDaiPair = await getTokenWETHPair(dai_Token.symbol);

    const route = new Route(
      [tokenEthPair.pair, ethDaiPair.pair],
      tokenEthPair.token
    );

    // ? if we need the input token amount, pass in dai output, tradeType = exact_output, but keep route the same
    const trade = reverse
      ? new Trade(
          route,
          new TokenAmount(
            ethDaiPair.token,
            ethers.utils.parseUnits(noExponents(amount), daiDecimal).toString()
          ),
          TradeType.EXACT_OUTPUT
        )
      : // ? if we need the dai output, pass in input token amount, tradeType = exact_input, but keep route the same
        new Trade(
          route,
          new TokenAmount(
            tokenEthPair.token,
            ethers.utils
              .parseUnits(noExponents(amount), tokenDecimal)
              .toString()
          ),
          TradeType.EXACT_INPUT
        );

    logTrade(trade);

    const path = [
      tokenEthPair.token.address,
      WETH[tokenEthPair.token.chainId].address,
      ethDaiPair.token.address,
    ];

    return { trade, path };
  } catch (error) {
    console.log(error);
  }
};

export const getDeadlineAndInputOutputAmountHex = async (trade: Trade) => {
  const slippageTolerance = new Percent("100", "10000"); // 1%
  const amountOutMin = trade.minimumAmountOut(slippageTolerance).raw;
  const amountOutMinHex = ethers.BigNumber.from(
    amountOutMin.toString()
  ).toHexString();

  const deadline = Math.floor(Date.now() / 1000) + 60 * 20; // 20 mins time
  const inputAmount = trade.inputAmount.raw;
  const inputAmountHex = ethers.BigNumber.from(
    inputAmount.toString()
  ).toHexString();

  return { inputAmountHex, amountOutMinHex, deadline };
};

export const getTokenWETHPair = async (
  tokenSymbol: string,
  tokenFirst: boolean = false
) => {
  try {
    const token = Tokens[`${tokenSymbol.toLowerCase().toString()}Token`];
    const weth = eth_Token;
    const pair = tokenFirst
      ? await Fetcher.fetchPairData(token, WETH[token.chainId])
      : await Fetcher.fetchPairData(WETH[token.chainId], token);

    return {
      pair,
      token,
      weth,
    };
  } catch (error) {
    console.log("Failed to create token Weth pair. \n Error => ", error);
    return error;
  }
};

const logTrade = (trade) => {
  console.log("execution price: $" + trade.executionPrice.toSignificant(6));
  console.log("price impact: " + trade.priceImpact.toSignificant(6) + "%");
};

export const estimateInputToDai = async (
  _tokenAddress: string,
  tokenSymbol: string,
  _output: number,
  daiAddress: string
) => {
  try {
    const { pair, token } = await getTokenWETHPair(tokenSymbol);
    const output = new TokenAmount(
      token,
      ethers.utils
        .parseUnits(noExponents(_output), tokenDecimals[tokenSymbol])
        .toString()
    );
    const wethInput = pair.getInputAmount(output);
    const daiPair = await getTokenWETHPair(dai_Token.symbol);
    const daiInput = daiPair.pair.getInputAmount(wethInput[0]);

    return daiInput[0].toSignificant(6);
  } catch (error) {
    console.log(
      `Failed to estimate token input to Dai for uniswap trade \n Error => `,
      error
    );
  }
};

export const estimateOutputFromDai = async (
  _tokenAddress: string,
  tokenName: string,
  _output: number,
  daiAddress: string
) => {
  try {
    const { pair, token } = await getTokenWETHPair(dai_Token.symbol);
    const output = new TokenAmount(
      token,
      ethers.utils
        .parseUnits(noExponents(_output), dai_Token.decimals)
        .toString()
    );
    const wethInput = pair.getInputAmount(output);
    const tokenPair = await getTokenWETHPair(tokenName);
    const tokenOutput = tokenPair.pair.getInputAmount(wethInput[0]);

    return tokenOutput[0].toSignificant(6);
  } catch (error) {
    console.log(error);
  }
};

// function createTrade(route: Route, token: any, amount: string) {
//   try {
//     return new Trade(route, createToken(token, amount), TradeType.EXACT_INPUT);
//   } catch (error) {
//     console.log(`Failed to create Trade Object \n Error => `, error);
//   }
// }

// function create2PairRoute(tokenEthPair: any, ethDaiPair: any) {
//   try {
//     return new Route([tokenEthPair.pair, ethDaiPair.pair], tokenEthPair.token);
//   } catch (error) {
//     console.log(`Failed to create Route \n Error => `, error);
//   }
// }

// function createRoute(pair: any, token: any) {
//   try {
//     return new Route([pair], WETH[token.chainId]);
//   } catch (error) {
//     console.log(`Failed to create Route \n Error => `, error);
//   }
// }

// function createToken(token: any, amount: string) {
//   try {
//     return new TokenAmount(
//       WETH[token.chainId],
//       ethers.utils.parseEther(amount).toString()
//     );
//   } catch (error) {
//     console.log(`Failed to create new ${token} Token  \n Error => `, error);
//   }
// }
