import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./header";

const useStyles = makeStyles(() =>
  createStyles({
    Header: {
      backgroundColor: (style: Props & Theme) => style.background1,
      transition: (style: Theme & Props) => style.transition,
      color: (style: Theme & Props) => style.text,
      height: "15rem",
      width: "100%",
    },
    navSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      maxWidth: "70%",
      margin: "auto",
    },
    logoContainer: {
      width: "102px",
    },
    logo: {
      userSelect: "none",
    },
    networkName: {
      font: (style: Theme & Props) => style.typography.h3,
      textTransform: "capitalize",
      fontWeight: "bold",
    },    
    ratesContainer: {
      width: "auto",
      margin: "auto 2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    navButton: {
      font: (style: Theme & Props) => style.typography.navItem,
      color: "var(--fade-white)",
      "&:hover": {
        color: (style: Theme & Props) => !style.disableConnectButton && "var(--white)",
        filter: (style: Theme & Props) => !style.disableConnectButton && "brightness(1.4)",
      },
      margin: "auto 2rem auto 2rem",
    },
    navButtonActive: {
      color: "var(--white)",
    },
    creditCardButton: {
      font: (style: Theme & Props) => style.typography.h6,
      color: "var(--green)",
      "&:hover": {
        color: (style: Theme & Props) => !style.disableConnectButton && "var(--almost-white)",
        filter: (style: Theme & Props) => !style.disableConnectButton && "brightness(1.4)",
      },
      margin: "auto 2rem auto 2rem",
    },
    refreshButtonContainer: {
      margin: "auto 2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },    
    account: {
      color: "white",
    },
    chainId: {
      color: "white",
    },
    buttonsWrapper:{
      marginTop: "0rem", width: "100%",
      "& button":{
        marginTop: "3rem"
      },
    }
  })
);

export default useStyles;
