import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Theme } from "../../store/themeContext/themes";
import { Props } from "./addressBar";

const useStyles = makeStyles(() =>
  createStyles({
    AddressBar: {
      // backgroundColor: (style: Theme & Props) => style.background2,
      color: (style: Theme & Props) => style.text,
      width: "20rem",
      height: "25px",
      // borderRadius: "10px",
      font: (style: Theme & Props) => style.typography.navItem,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      "&:hover": {
        filter: "brightness(1.5)",
      },
      backgroundColor: "transparent",
    },
    address: {
      color: "inherit",
      margin: "auto",
      font: "inherit",
    },
    iconContainer: {
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    icon: {
      position:"relative",
      top:"4px",
      margin: "auto",
      width:"3rem",
      height:"3rem",
    },
  })
);

export default useStyles;
