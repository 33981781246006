/* eslint-disable @typescript-eslint/no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import { FAIL_REASON, TX_STATUS, TX_STEP } from "../../constants";
import { resetBurnTXState } from "../burn/BurnSlice";
import { resetMintTXState } from "../mint/MintSlice";
import { RootState, useAppDispatch } from "../mystore";
import { resetStakeTXState } from "../stake/StakeSlice";
import { resetWithdrawTXState } from "../withdraw/WithdrawSlice";
import { resetSwapTXState } from "../swap/SwapSlice";
import { objectNotEmpty } from "../../utils/utils";

export type CurrentTXInfo = {
  status: TX_STATUS;
  reasonForFailure: FAIL_REASON;
  successData: any;
  failData: any;
  txStep: TX_STEP;
  nextTXStep: TX_STEP;
};

// TODO: optimize to use which exactly just got updated
export function useGetCurrentTXInfo(txUpdateOccured: boolean = true) {
  try {
    const state = useSelector((state: RootState) => state);
    if (!txUpdateOccured) {
      return undefined;
    }

    let currentTX: CurrentTXInfo = {
      failData: {},
      status: TX_STATUS.NOT_INITIALIZED,
      reasonForFailure: FAIL_REASON.NOT_SET,
      successData: {},
      txStep: TX_STEP.NOT_INITIALIZED,
      nextTXStep: TX_STEP.NOT_INITIALIZED,
    };

    if (
      state.burn.tx.status === TX_STATUS.PENDING ||
      state.mint.tx.status === TX_STATUS.PENDING ||
      state.swap.tx.status === TX_STATUS.PENDING ||
      state.stake.tx.status === TX_STATUS.PENDING ||
      state.withdraw.tx.status === TX_STATUS.PENDING
    ) {
      currentTX.status = TX_STATUS.PENDING;
      currentTX.txStep =
        state.swap.tx.data.txStep ||
        state.burn.tx.data.txStep ||
        state.mint.tx.data.txStep ||
        state.stake.tx.data.txStep ||
        state.withdraw.tx.data.txStep;
      currentTX.nextTXStep =
        state.swap.tx.data.nextTXStep ||
        state.burn.tx.data.nextTXStep ||
        state.mint.tx.data.nextTXStep ||
        state.stake.tx.data.nextTXStep ||
        state.withdraw.tx.data.nextTXStep;
    } else if (
      state.burn.tx.status === TX_STATUS.FAILED ||
      state.burn.tx.status === TX_STATUS.REJECTED ||
      state.mint.tx.status === TX_STATUS.FAILED ||
      state.mint.tx.status === TX_STATUS.REJECTED ||
      state.swap.tx.status === TX_STATUS.FAILED ||
      state.swap.tx.status === TX_STATUS.REJECTED ||
      state.stake.tx.status === TX_STATUS.FAILED ||
      state.stake.tx.status === TX_STATUS.REJECTED ||
      state.withdraw.tx.status === TX_STATUS.FAILED ||
      state.withdraw.tx.status === TX_STATUS.REJECTED
    ) {
      currentTX.status = TX_STATUS.FAILED;

      if (state.swap.tx.data.reasonForFail !== FAIL_REASON.NOT_SET) {
        currentTX.reasonForFailure = state.swap.tx.data.reasonForFail;
      } else if (state.burn.tx.data.reasonForFail !== FAIL_REASON.NOT_SET) {
        currentTX.reasonForFailure = state.burn.tx.data.reasonForFail;
      } else if (state.mint.tx.data.reasonForFail !== FAIL_REASON.NOT_SET) {
        currentTX.reasonForFailure = state.mint.tx.data.reasonForFail;
      } else if (state.stake.tx.data.reasonForFail !== FAIL_REASON.NOT_SET) {
        currentTX.reasonForFailure = state.stake.tx.data.reasonForFail;
      } else if (state.withdraw.tx.data.reasonForFail !== FAIL_REASON.NOT_SET) {
        currentTX.reasonForFailure = state.withdraw.tx.data.reasonForFail;
      } else {
        currentTX.reasonForFailure = FAIL_REASON.NOT_SET;
      }

      if (
        state.swap.tx.data.failData &&
        objectNotEmpty(state.swap.tx.data.failData)
      ) {
        currentTX.failData = state.swap.tx.data.failData;
      } else if (
        state.burn.tx.data.failData &&
        objectNotEmpty(state.burn.tx.data.failData)
      ) {
        currentTX.failData = state.burn.tx.data.failData;
      } else if (
        state.mint.tx.data.failData &&
        objectNotEmpty(state.mint.tx.data.failData)
      ) {
        currentTX.failData = state.mint.tx.data.failData;
      } else if (
        state.stake.tx.data.failData &&
        objectNotEmpty(state.stake.tx.data.failData)
      ) {
        currentTX.failData = state.stake.tx.data.failData;
      } else if (
        state.withdraw.tx.data.failData &&
        objectNotEmpty(state.withdraw.tx.data.failData)
      ) {
        currentTX.failData = state.withdraw.tx.data.failData;
      } else {
        currentTX.failData = {};
      }

      currentTX.txStep =
        state.swap.tx.data.txStep ||
        state.burn.tx.data.txStep ||
        state.mint.tx.data.txStep ||
        state.stake.tx.data.txStep ||
        state.withdraw.tx.data.txStep;
      currentTX.nextTXStep =
        state.swap.tx.data.nextTXStep ||
        state.burn.tx.data.nextTXStep ||
        state.mint.tx.data.nextTXStep ||
        state.stake.tx.data.nextTXStep ||
        state.withdraw.tx.data.nextTXStep;
    } else if (
      state.burn.tx.status === TX_STATUS.SUCCESS ||
      state.mint.tx.status === TX_STATUS.SUCCESS ||
      state.swap.tx.status === TX_STATUS.SUCCESS ||
      state.stake.tx.status === TX_STATUS.SUCCESS ||
      state.withdraw.tx.status === TX_STATUS.SUCCESS
    ) {
      currentTX.status = TX_STATUS.SUCCESS;

      if (objectNotEmpty(state.swap.tx.data.successData)) {
        currentTX.successData = state.swap.tx.data.successData;
      } else if (objectNotEmpty(state.burn.tx.data.successData)) {
        currentTX.successData = state.burn.tx.data.successData;
      } else if (objectNotEmpty(state.mint.tx.data.successData)) {
        currentTX.successData = state.mint.tx.data.successData;
      } else if (objectNotEmpty(state.stake.tx.data.successData)) {
        currentTX.successData = state.stake.tx.data.successData;
      } else if (objectNotEmpty(state.withdraw.tx.data.successData)) {
        currentTX.successData = state.withdraw.tx.data.successData;
      } else {
        currentTX.successData = {};
      }
      currentTX.txStep =
        state.swap.tx.data.txStep ||
        state.burn.tx.data.txStep ||
        state.mint.tx.data.txStep ||
        state.stake.tx.data.txStep ||
        state.withdraw.tx.data.txStep;
      currentTX.nextTXStep =
        state.swap.tx.data.nextTXStep ||
        state.burn.tx.data.nextTXStep ||
        state.mint.tx.data.nextTXStep ||
        state.stake.tx.data.nextTXStep ||
        state.withdraw.tx.data.nextTXStep;
    } else {
      currentTX.status = TX_STATUS.NOT_INITIALIZED;
      currentTX.txStep =
        state.swap.tx.data.txStep ||
        state.burn.tx.data.txStep ||
        state.mint.tx.data.txStep ||
        state.stake.tx.data.txStep ||
        state.withdraw.tx.data.txStep;
      currentTX.nextTXStep =
        state.swap.tx.data.nextTXStep ||
        state.burn.tx.data.nextTXStep ||
        state.mint.tx.data.nextTXStep ||
        state.stake.tx.data.nextTXStep ||
        state.withdraw.tx.data.nextTXStep;
    }

    // swap data was set if truthy. replace with mint data if set
    if (state.mint.tx.data.txStep > state.swap.tx.data.txStep) {
      currentTX.txStep = state.mint.tx.data.txStep;
      currentTX.nextTXStep = state.mint.tx.data.nextTXStep;
    }

    // swap data was set if truthy. replace with burn data if set
    if (state.burn.tx.data.txStep > state.swap.tx.data.txStep) {
      currentTX.txStep = state.burn.tx.data.txStep;
      currentTX.nextTXStep = state.burn.tx.data.nextTXStep;
    }
    return currentTX;
  } catch (error) {
    console.log(error);
  }
}

export function useResetFlagsAndTXData() {
  // const dispatch = useAppDispatch();
  // dispatch(resetBurnTXState());
  // dispatch(resetMintTXState());
  // dispatch(resetSwapTXState());
  // dispatch(resetStakeTXState());
  // dispatch(resetWithdrawTXState());
  // dispatch(resetClaimTXState());
}
