import React, { useState, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ethers } from "ethers";
import { useWeb3React } from '@web3-react/core';
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import AddressBar from '../../components/addressBar/addressBar';
import { RootState } from "../../store/mystore";
import WalletConnectButton from '../../components/walletConnectButton/walletConnectButton';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { objectNotEmpty } from '../../utils/utils';
import './header.scss'
import { addTokenToWallet } from '../../store/helpers/addTokenHelper';
import { linkModifiers } from '../../constants';
    
export interface Props {
    // disableConnectButton: boolean;
    // showWalletModal: boolean;
    setShowWalletModal: (boolean) => void;
    setShowConnectedOptions: (boolean) => void;
    handleDisconnect: () => void;
    // toggleWalletModal: () => void;
  }

function SelectNav(props: Props){
    const state = useSelector((state: RootState) => state);    
    const { chainId } = useWeb3React();
    
    //header setting event
    const subSelectArea = "sub__select__area";
    const [classState, setState] = useState(subSelectArea);
    const seconSelectItemtoggle = () => {
        setState(classState === subSelectArea ? subSelectArea + " show" : subSelectArea);
    }
    const seconSelectItemLeave = () => {
        setState(classState === subSelectArea + " show" ? subSelectArea : subSelectArea);
    }

    const [open, setOpen] = useState(false);
    const [etherscanAddress, setEtherscanAddress] = useState('');

    useEffect(() => {
        if(state.wallet.address && chainId){
            setEtherscanAddress(`https://${linkModifiers[chainId] || ""}/address/${state.wallet.address}`)
        }
        // if(objectNotEmpty(state.wallet.provider)){
        //   var provider = new ethers.providers.Web3Provider(state.wallet.provider);
        //   provider.getNetwork().then((network) => {
        //     if(network.name !== "homestead") {
        //       setChainEtherscan(network.name + ".");
        //     }
        //   })
        // }
        // eslint-disable-next-line
    }, [chainId, state.wallet.address]);


    return <>
        <ul className={open ? 'select__area active' : 'select__area'}>
            <li className="select__item select__item__wallet">
                {!state.wallet.isConnected ? (
                    <WalletConnectButton clickFunction={() => props.setShowWalletModal(true) } />
                ) : (
                    <AddressBar clickFunction={() => props.setShowConnectedOptions(true) } address={state.wallet.address} />
                )}   
            </li>
            <li className="select__item secon__select__item" onClick={seconSelectItemtoggle}>
                <span className="tr__menu svg__wrap">
                    <img src={require("../../media/icons/ico-more-vertical.svg").default} alt=""/>
                </span>
                <ul className={`${classState}`} onMouseLeave ={seconSelectItemLeave}>
                {state.wallet.isConnected ?
                    <>
                        <li className="sub__select__item">
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={etherscanAddress}
                                    className="sub__select__link" onClick={() => setOpen(false)}>
                                <span className="svg__wrap"><img width="23" src={require("../../media/icons/search.svg").default} alt="View on Etherscan"/></span>
                                
                                <span className="sub__txt">
                                    View on Explorer
                                </span>                                
                            </a>
                        </li>
                        <li className="sub__select__item" onClick={() => addTokenToWallet()}>
                            <div style={{cursor:"pointer"}} className="sub__select__link" onClick={() => setOpen(false)}>
                                <span className="svg__wrap"><img width="23" src={require("../../media/icons/coin-wallet.svg").default} alt="Add Tokens to Wallet"/></span>
                                <span className="sub__txt">Add Tokens</span>
                            </div>                            
                        </li>
                        <li className="sub__select__item" onClick={() => props.handleDisconnect()}>
                            <div style={{cursor:"pointer"}} className="sub__select__link" onClick={() => setOpen(false)}>
                                <span className="svg__wrap"><img src={require("../../media/icons/ico-wallet.svg").default} alt="Disconnect"/></span>
                                <span className="sub__txt">Disconnect</span>
                            </div>                            
                        </li>
                    </>
                :null}
                    <li className="sub__select__item" onClick={() => window.open('https://brincx.io/#/AboutUs' , '_blank')}>
                        <NavLink to="/" className="sub__select__link" onClick={() => setOpen(false)}>
                            <span className="svg__wrap"><img width="24" src={require("../../media/tokens/brc_bw.png").default} alt="About us"/></span>
                            <span className="sub__txt">About us</span>
                        </NavLink>
                    </li>
                    <li className="sub__select__item" onClick={() => window.open('https://brinc-fi.gitbook.io/documentation/' , '_blank')}>
                        <NavLink to="/" className="sub__select__link" onClick={() => setOpen(false)}>
                            <span className="svg__wrap"><img src={require("../../media/icons/ico-frame-text.svg").default} alt="Documentation"/></span>
                            <span className="sub__txt">Documentation</span>
                        </NavLink>
                    </li>
                    <li className="sub__select__item" onClick={() => window.open('https://github.com/brinc-finance' , '_blank')}>
                        <NavLink to="/" className="sub__select__link" onClick={() => setOpen(false)}>
                            <span className="svg__wrap"><img src={require("../../media/icons/ico-frame-code.svg").default} alt="Code"/></span>
                            <span className="sub__txt">Code</span>
                        </NavLink>
                    </li>
                    <li className="sub__select__item" onClick={() => window.open('https://discord.com/invite/QFFrBu68rp' , '_blank')}>
                        <NavLink to="/" className="sub__select__link" onClick={() => setOpen(false)}>
                            <span className="svg__wrap"><img src={require("../../media/icons/ico-frame-Discord.svg").default} alt="Discord"/></span>
                            <span className="sub__txt">Discord</span>
                        </NavLink>
                    </li>
                    {/* <li className="sub__select__item">
                        <NavLink to="/referral" className="sub__select__link" onClick={() => setOpen(false)}>
                            <span className="svg__wrap"><img src={require("../../media/icons/ico-frame-user.svg").default} alt="Referral program"/></span>
                            <span className="sub__txt">Referral program</span>
                        </NavLink>
                    </li> */}
                </ul>
            </li>
        </ul>
    </>
}

export default SelectNav