import React, { useContext, useState, useEffect } from "react";
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Web3 from "web3";

import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./headerStyles";
import Logo from "../../media/brinc_icons/brincx_logo_v2_white.png";
import WalletConnectButton from "../../components/walletConnectButton/walletConnectButton";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import AddressBar from "../../components/addressBar/addressBar";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import AddressDropdown from "../../components/addressDropdown/addressDropdown";
import { useToggle } from "../../hooks/useToggle";
import { supportedChains, injectedConnector, portis, walletConnect } from "../../connectors";
import WalletConnectModal from "../../components/walletConnectModal/walletConnectModal";
import Backdrop from "../../components/backdrop/backdrop";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Grid from '@material-ui/core/Grid';

import { ReactComponent as Metamask } from "../../media/icons/metamask.svg";
// import { ReactComponent as Trust } from "../../media/icons/trust.svg";
// import { ReactComponent as Coinbase } from "../../media/icons/coinbase.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as Portis } from "../../media/icons/portis.svg";
import { ReactComponent as WalletConnect } from "../../media/icons/walletconnect.svg";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ReactComponent as Ledger } from "../../media/icons/ico-ledger-white.svg";
import { RootState } from "../../store/mystore";
import { setPricesAsync } from "../../store/swap/SwapSlice";
import { setWalletConnection, setWalletAddress, setProvider, setBalancesAsync, SetBalancePayload } from "../../store/wallet/WalletSlice";
import { IAppContractsNonNullable, setContractsAsync } from "../../store/application/AppSlice";
import { areContractsLoaded } from "../../store/helpers/contractBooter";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import walletOptionButton from "../../components/walletOptionButton/walletOptionButton";

import './header.scss';
import Nav from './nav';
import SelectNav from './selectNav';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MobNav from './mobNav';
import MobSelectNav from './mobSelectNav';
import MobFootNav from '../footer/mobNav';
import SnsLink from '../footer/snsLink';
import FillButton from "../../components/button/button";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { objectNotEmpty } from "../../utils/utils";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ethers } from "ethers";
import { linkModifiers, SUPPORTED_CHAINS } from "../../constants";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Web3Provider } from "@ethersproject/providers"
import { BelowDesktop, Desktop } from "../../components/screenHelper";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AsyncThunkAction, Dispatch } from "@reduxjs/toolkit";

export interface Props {
  //props are applicable where connect button is external to header
  disableConnectButton?: boolean;
  showWalletModal?: boolean;
  setShowWalletModal?: (boolean) => void;
  toggleWalletModal?: () => void;
}

function Header(props: Props) {
  const dispatch = useDispatch<Dispatch<any>>();
  const state = useSelector((state: RootState) => state);    
  const [contracts, setContracts] = useState<IAppContractsNonNullable>(state.application.contracts);
  
  const { theme } = useContext(ThemeContext); 
  const classes = useStyles({ ...props, ...theme });
  const [wallet, setWallet] = useState<any>(undefined);
  const [connectedWalletName, setConnectedWalletName] = useState("");
  const [isWalletConnect, setIsWalletConnect] = useState(false);
  const [metamaskDisabled, setMetamaskDisabled] = useState(false);
  const [activatingConnector, setActivatingConnector] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refreshClicked, setRefreshClicked] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [networkName, setNetworkName] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showConnectedOptions, setShowConnectedOptions, toggleDropdown] = useToggle(false);
  const { showWalletModal, setShowWalletModal, toggleWalletModal } = props;

  const header = "header";
  const mobNavBtn = "mob__nav__btn";
  const mobHeadNav = "mob__head__area";
  const [mobNav , setMobNav] = useState<string | boolean>(mobNavBtn);
  // const [mobNav , setMobNav] = useState(true);

  let activeClassName = "selected";
  
  const buttonClick = () => { setMobNav(!mobNav); }
  const closeNav = () => {
      setMobNav(mobNav === mobHeadNav + " show" ? mobHeadNav : mobHeadNav);
  }

  const {
    connector,
    error,
    account,
    activate,
    deactivate,
    active,
    chainId,
    library,
  } = useWeb3React();

  // const context = useWeb3React<Web3Provider>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError;

  const [etherscanAddress, setEtherscanAddress] = useState('');

  const chainIdIsCorrect =
    chainId && SUPPORTED_CHAINS.includes(chainId);

  useEffect(() => {
    if(state.wallet.address && chainId){
      setEtherscanAddress(`https://${linkModifiers[chainId] || ""}/address/${state.wallet.address}`)
    }
      // eslint-disable-next-line
  }, [chainId, state.wallet.address]);

  //@ts-ignore
  const isMetamaskDisabled = () => {
    try {
      if ((window as any).ethereum) {
        if ((window as any).ethereum.isMetaMask === true) {
          setMetamaskDisabled(false);
        } else {
          setMetamaskDisabled(true);
        }
      } else {
        setMetamaskDisabled(true);
      }
    } catch (error) {
      setMetamaskDisabled(true);
      console.log(
        `Something went wrong while checking MetaMask installation. \nError => ${error}`
      );
    }
  };

  useEffect(() => {
    if (!areContractsLoaded(contracts) && areContractsLoaded(state.application.contracts)) {
      setContracts(state.application.contracts);
    }
    if(error){
      console.log('w3r error', error);      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.application.contracts, error]);

  useEffect(() => {
    isMetamaskDisabled();
    // window.addEventListener("load", isMetamaskDisabled);

    // // cleanup this component
    // return () => {
    //   window.removeEventListener("load", isMetamaskDisabled);
    // };
  }, []);

  useEffect(() => {
    if(areContractsLoaded(contracts)){
      dispatch(setPricesAsync(contracts));
    }
    // eslint-disable-next-line
  }, [contracts]);

  useEffect(() =>
    chainId ?
      setNetworkName(supportedChains[chainId]) : setNetworkName(supportedChains[0])
    , [chainId]);

  useEffect(() => {
    if (account) {
      setAddress(account);
    }
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line
  }, [account, state.burn.tx.status, state.mint.tx.status, state.swap.tx.status, chainId]);

  // handles logic for wallet connect modal.
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
    active && setShowWalletModal(false);
  }, [activatingConnector, connector, active, setShowWalletModal]);

  const handleRefresh = async () => {
    setRefreshClicked(true);
    await tokenBalanceAction();
    setTimeout(() => {
      setRefreshClicked(false);
    }, 2000);
  };

  useEffect(() => {
    if(wallet){
      console.log('connector', wallet);

      if(wallet.portis){
        if (!active && !error) {
          try{
            activate(wallet);
            setConnectedWalletName("portis");
          }catch(err){
            console.log('activate failed', err);
          }
        }
      }else{
        if(!isWalletConnect){
          wallet
          .isAuthorized()
          .then((isAuthorized) => {
            // setLoaded(true)
            if (isAuthorized && !active && !error) {
              activate(wallet)
            }
          })
          .catch((err) => {
            console.log('wallet auth check error', err)  
            console.log('general connection error', error)  
          })
        }
      }      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate, active, error])

  const initWallet = async () => {
    if (connector) {
      let { provider } = await connector.activate();
      let web3 = new Web3(provider);
      dispatch(setProvider(provider));
      if(chainIdIsCorrect){
        dispatch(setContractsAsync({chainId, web3}));
      }else{
        dispatch(setContractsAsync(undefined));
      }
      dispatch(setWalletConnection(true));
    }
  };

  useEffect(() => {
    initWallet();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const connectMetamask = () => {
    setWallet(injectedConnector);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const connectPortis = () => {
    setWallet(portis);
  };

  const connectWalletConnect = () => {
    setWallet(walletConnect);
    setIsWalletConnect(true);
  };

  const disconnectWallet = () => {
    deactivate();
    setIsWalletConnect(false);
    dispatch(setWalletConnection(false));
    setWallet(undefined);
    //actions.setWalletDisconnected();
  };

  const handleConnect = async () => {
    setActivatingConnector(wallet);
    let web3 = new Web3(Web3.givenProvider);
    if (isWalletConnect) {
      try {
        console.log("I GOT HERE")
        activate(walletConnect);

        // var ledgerWalletSubProvider = await new LedgerWalletSubprovider();
        // if (!ledgerWalletSubProvider.isSupported) {
        //   //TODO: show UNSUPPORTED dialog box
        //   alert("Ledger is NOT supported in this browser");
        //   console.log("Ledger is NOT supported in this browser");
        //   setShowWalletModal(false);
        //   return;
        //   }else{
        //     await activate(wallet);
        //     let { provider } = await wallet.activate();
        //     web3 = new Web3(provider);
            
        //     dispatch(setProvider(provider));
        //     dispatch(setContractsAsync(web3));
        //     dispatch(setWalletConnection(true));
        //   }
      } catch (error) {
        console.log("ERROR CONNECTING TO LEDGER: ", error);
      }
    } else {
      await activate(wallet, undefined, true)
      .catch(error => {
        console.log('wallect connection error', error);        
      });
      let { provider } = await wallet.activate();
      web3 = new Web3(provider);
      
      dispatch(setProvider(provider));
      dispatch(setContractsAsync({chainId, web3}));
      dispatch(setWalletConnection(true));
    }
  };

  const wallets = [
    {
      name: "metamask",
      connectFunction: connectMetamask,
      selected: wallet === injectedConnector,
      activating: activatingConnector === injectedConnector,
      active: connector === injectedConnector,
      icon: Metamask,
      mobileOnly: false
    },
    // {
    //   name: "coinbase",
    //   connectFunction: connectMetamask,
    //   selected: wallet === injectedConnector,
    //   activating: activatingConnector === injectedConnector,
    //   active: connector === injectedConnector,
    //   icon: Coinbase,
    //   mobileOnly: true,
    // },
    // {
    //   name: "trust",
    //   connectFunction: connectMetamask,
    //   selected: wallet === injectedConnector,
    //   activating: activatingConnector === injectedConnector,
    //   active: connector === injectedConnector,
    //   icon: Trust,
    //   mobileOnly: true,
    // },
    // {
    //   name: "portis",
    //   connectFunction: connectPortis,
    //   selected: wallet === portis,
    //   activating: activatingConnector === portis,
    //   active: connector === portis,
    //   icon: Portis,
    // },
    {
      name: "walletConnect",
      connectFunction: connectWalletConnect,
      selected: wallet === walletConnect,
      activating: activatingConnector === walletConnect,
      active: connector === walletConnect,
      icon: WalletConnect,
      mobileOnly: false
    },
  ];

  const enabledWallets = metamaskDisabled
    ? wallets.filter((wallet) => wallet.name !== "metamask")
    : wallets;

  const shortAddress = account ? `${account.slice(0, 4)}...${account.slice(
    account.length - 4
  )}` : "????...????";

  return (
    <header className={`${!mobNav ? header + ' open' : header }`}>
      <button type="button" id="nav-icon2" className="mob__nav__btn" onClick={buttonClick}>
          <img src={require("../../media/icons/ico-menu.svg").default} alt=""/>
      </button>

      <BelowDesktop>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <ul className={'mob-top-nav'}>
            {/* {state.wallet.isConnected ? 
                <li className="gnb__item">
                  <NavLink 
                    to="/dashboard" 
                    className={({ isActive }) =>
                      isActive ? "gnb__item__link " + activeClassName : "gnb__item__link"
                    }
                  >
                    My Assets
                  </NavLink>
                </li>
            :null} */}
            <li className="gnb__item">
              <NavLink 
                to="/mint" 
                className={({ isActive }) =>
                  isActive ? "gnb__item__link " + activeClassName : "gnb__item__link"
                }
              >
                Mint
              </NavLink>
            </li>
            {/* <li className="gnb__item">
              <NavLink 
                to="/stake" 
                className={({ isActive }) =>
                  isActive ? "gnb__item__link " + activeClassName : "gnb__item__link"
                }
              >
                Stake
              </NavLink>
            </li> */}
            <li className="gnb__item">
              <NavLink 
                to="/vault" 
                className={({ isActive }) =>
                  isActive ? "gnb__item__link " + activeClassName : "gnb__item__link"
                }
              >
                Vault
              </NavLink>
            </li>
            <li className="gnb__item">
              <NavLink 
                to="/analytics" 
                className={({ isActive }) =>
                  isActive ? "gnb__item__link " + activeClassName : "gnb__item__link"
                }
              >
                Analytics
              </NavLink>
            </li>
            <li className="gnb__item">
              <NavLink 
                to="/nft"
                className={({ isActive }) =>
                  isActive ? "gnb__item__link " + activeClassName : "gnb__item__link"
                }>
                NFT
              </NavLink>
            </li>
          </ul>
        </div>
      </BelowDesktop>

      <Desktop>
        <h1 className={`${classes.logoContainer} header__logo`} onClick={() => window.open('https://brincx.io/' , '_blank')}>
            <div className="header__logo__link">
                <img src={Logo} alt={"brinc logo"} className={classes.logo}/>
            </div>
        </h1>
      </Desktop>
      <Nav /> 
      
      <SelectNav handleDisconnect={disconnectWallet} setShowWalletModal={setShowWalletModal} setShowConnectedOptions={setShowConnectedOptions}/>
      {showWalletModal && (
        <>
          <Backdrop />
          <WalletConnectModal
            canConnect={wallet && true}
            wallets={enabledWallets}
            setShowWalletModal={setShowWalletModal}
            handleConnect={handleConnect}
            activatingConnector={activatingConnector}
          />
        </>
      )}

      <div className={`${!mobNav ? mobHeadNav + ' show' : mobHeadNav }`}>
          <div className="head__box">
              <section>
                  <article className="mob__index__area mob__head">
                      <div className="title__box">
                          <button type="button" className="navClose" onClick={closeNav}><img src={require("../../media/icons/ico-close.svg").default} alt=""/></button>
                          <h1 className={`${classes.logoContainer} header__logo`}>
                              <Link to="/" className="header__logo__link">
                                  {/* <img src={require("../../media/icons/ico-Logo.svg").default} alt="logo icon"/> */}
                                  <img src={Logo} alt={"brinc logo"} className={classes.logo}/>        
                              </Link>
                          </h1>
                      </div>
                      
                      {!state.wallet.isConnected ? (
                        <WalletConnectButton disabled={props.disableConnectButton} clickFunction={toggleWalletModal} />
                      ) : (
                        <>
                          {/* <AddressBar clickFunction={toggleDropdown} address={account} /> */}
                          <div className={classes.buttonsWrapper}>
                            <FillButton
                              label={`Disconnect (${shortAddress})`}
                              variant="fill"
                              icon={connectedWalletName}
                              size="md"
                              clickFunction={()=>disconnectWallet()}
                            />   
                            <a target="_blank"
                                rel="noopener noreferrer"
                                href={etherscanAddress}
                                className="sub__select__link" style={{width: "100%"}}>
                              <FillButton
                                label={"View on Explorer"}
                                variant="outline"
                                size="md"
                                clickFunction={()=>console.log()}
                              />
                            </a>
                                                            
                          </div>
                        </>
                      )}                          

                      {/* {showConnectedOptions && (
                        <AddressDropdown
                          disconnectWallet={disconnectWallet}
                          setShowConnectedOptions={setShowConnectedOptions}
                          address={account}
                        />
                      )} */}
                      
                  </article>
                  {/* <article className="mob__index__area">
                    <MobNav/>
                  </article> */}
                  <article className="mob__index__area">
                    <MobSelectNav/>
                  </article>
                  <article className="mob__index__area">
                    <MobFootNav/>
                  </article>
              </section>
              <section>
                <SnsLink/>
              </section>
          </div>
      </div>
  </header>
  );

  async function tokenBalanceAction() {
    if (account && areContractsLoaded(contracts)) {
      const payload: SetBalancePayload = {
        ethAddress: account,
        contracts,
        provider: library,
      };

      dispatch(setBalancesAsync(payload));
    }
  }

  async function setAddress(account: string) {
    if (account) {
      dispatch(setWalletAddress(account))
    }
  }
}

export default React.memo(Header);
