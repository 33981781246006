/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../store/themeContext/themeContext";
import useStyles from "./priceConversionStyles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/mystore";
import { GetUSDTokenEquivalence } from "../../store/helpers/priceHelper";
import "./priceConversion.scss";
// import { BelowDesktop, Desktop } from "../screenHelper";
import { brc_Token, gbrcTokenStaking, gbrc_Token } from "../../constants";
import { handleNumberDisplay } from "../../utils/utils";
import { bignumber } from "mathjs";

export interface Props {
  token: string;
}

function PriceConversionContainer(props: Props) {
  const { theme } = useContext(ThemeContext);
  const state = useSelector((state: RootState) => state);
  const [usdAmount, setUsdAmount] = useState(0);

  const classes = useStyles({ ...props, ...theme });
  const price = state.swap.prices[`${props.token === gbrcTokenStaking.symbol ? 'gBRCStaking' : props.token.toLowerCase()}Price`];

  const brcUsd = handleNumberDisplay(GetUSDTokenEquivalence(state, bignumber(1), brc_Token.symbol), true, 4);

  return (
    <article className="index__exchage__box">
      {/* <Desktop>
        <div className="index__exchage__box--web" style={{display: "flex"}}>
            <strong className="exchage__tit">Exchange rate</strong>
            <div className="exchage__list">
                <p className="exchage__txt">
                  <span>1 BRC&nbsp;</span>
                  {/* <span>~ {price ? Number.parseFloat(price).toFixed(2) : '--'} {props.token}&nbsp;</span>                   
                </p>
                <p className="exchage__txt">
                  <span> ~ ${brcUsd ? brcUsd : "--"}</span>
                </p>
            </div>
        </div>
      </Desktop>
      <BelowDesktop> */}
        <div className="index__exchage__box--mob" style={{display: "flex", marginTop: "-12px"}}>
            <div className="exchage__list">
              <p className="exchage__txt">
                <span>1 BRC&nbsp;</span>
                {/* <span>~ {price ? Number.parseFloat(price).toFixed(2) : '--'} {props.token}&nbsp;</span>                   */}
              </p>
              <p className="exchage__txt">
                  <span> ~ ${brcUsd ? brcUsd : "--"}</span>
              </p>
            </div>
        </div>
      {/* </BelowDesktop> */}
  </article>    
  );
}

export default React.memo(PriceConversionContainer);