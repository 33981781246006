import { configureStore } from "@reduxjs/toolkit";
import appSliceReducer from "./application/AppSlice";
import analyticsSliceReducer from "./analytics/AnalyticsSlice";
import walletSliceReducer from "./wallet/WalletSlice";
import swapSliceReducer from "./swap/SwapSlice";
import mintSliceReducer from "./mint/MintSlice";
import burnSliceReducer from "./burn/BurnSlice";
import stakeSliceReducer from "./stake/StakeSlice";
import withdrawSliceReducer from "./withdraw/WithdrawSlice";
import claimSliceReducer from "./claim/ClaimSlice";
import { useDispatch } from "react-redux";

// export type AppThunk = ThunkAction<void, IAppState, unknown, Action<string>>;

export interface IThunkAPI {
    dispatch: AppDispatch,
    state: RootState
}

const store = configureStore({
    reducer: {
        application: appSliceReducer,
        analytics: analyticsSliceReducer,
        wallet: walletSliceReducer,
        swap: swapSliceReducer,
        mint: mintSliceReducer,
        burn: burnSliceReducer,
        stake: stakeSliceReducer,
        withdraw: withdrawSliceReducer,
        claim: claimSliceReducer,
        //other slices
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;