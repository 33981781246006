import BrincnautNFT from "../../interfaces/BrincnautNFT.json";
import { GetWeb3 } from "../../store/helpers/contractBooter";
import { AppNetworks } from "../../constants";


const web3 = GetWeb3(AppNetworks.ARBITRUM_ONE);

const Abi: any = BrincnautNFT;
const CAArr = [
  "0x2D65dDaC370A33E4aAED0975bA7116A3FF293BB9",
  "0xeB7a7274AAF9274DB73f3b1e3B18c8f6E21D68E4",
  "0x9Cab1070E60a76c1f096dE4b8A0273D3c2C5D7FB",
  "0x00136Aa7F14e229F41df6aC3FaF4249B8ccb54b8",
  "0x79335c7DA67e6d49A3048CfC01B026B3B587f538"
]

export async function callContract (account: string) {
  try {
    let resultArr = [];
    
    for(let i = 0; i < CAArr.length; i++){
      const CA = new web3.eth.Contract(Abi, CAArr[i]);
      const NFTBalance = await CA.methods.balanceOf(account).call();
      resultArr.push(NFTBalance);
    }
    return resultArr;
  } catch (error) {
    let resultArr = [];

    for(let i = 0; i < CAArr.length; i++){
      resultArr.push(0);
    }
    return resultArr;
  }
}


