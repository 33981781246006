import React, { useContext } from "react";
import useStyles from "./overlayStyles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { ThemeContext } from "../../store/themeContext/themeContext";

export interface Props {
	handleClickAway?: () => void;
	children: React.ReactElement<any>;
}

function Overlay(props: Props) {
	const { theme } = useContext(ThemeContext);
	const classes = useStyles({ ...props, ...theme });

	return (
		<div className={classes.Overlay}>
			{props.handleClickAway ? (
				<ClickAwayListener onClickAway={props.handleClickAway}>
					{props.children}
				</ClickAwayListener>
			) :
				<>
					{ props.children}
				</>
			}
		</div>
	);
}

export default React.memo(Overlay);
